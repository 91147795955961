import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AutoCompleteField, Label, Button, TextField } from '../../../../../components/widgets';
import css from './AppSiteUpdateForm.css';
import AppsService from '../../../../../services/apps';
import Snackbar from '../../../../../components/widgets/Snackbar/Snackbar';
import { FORM_TRANSLATE_KEY } from './consts';
import { sendMixpanelEventSdkIntegration } from '../../../utils';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../../consts';
import { SDK_INTEGRATION_SECTIONS, TARGET_PLATFORM_TO_PARAM } from '../../../consts';

const appsAPI = new AppsService();

const AppSiteUpdateForm = ({ appSites, isLoadingAppSites, targetPlatform, formInputFields }) => {
    const [selectedAppSiteId, setSelectedAppSiteId] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [updatedFields, setUpdatedFields] = useState({});
    const [formError, setFormError] = useState(null);
    const platform = TARGET_PLATFORM_TO_PARAM[targetPlatform];

    const handleFieldsChange = ({ field, value }) => {
        setUpdatedFields(prevState => {
            return {
                ...prevState,
                [field]: value,
            };
        });
    };

    const onFormParamsChange = () => {
        setSaveSuccess(false);
        setSaveError(false);
        setFormError(null);
    };

    const onAppSiteSelection = appSiteOption => {
        const appSiteId = appSiteOption ? appSiteOption.value : null;

        if (appSiteId !== selectedAppSiteId) {
            const selectedAppSite = appSites.find(appSite => appSite.id === appSiteId);
            formInputFields.forEach(field =>
                handleFieldsChange({
                    field: field.name,
                    value: selectedAppSite && selectedAppSite[field.name] ? selectedAppSite[field.name] : '',
                })
            );

            setSelectedAppSiteId(appSiteId);
            onFormParamsChange();
        }
    };

    const onSave = async () => {
        const selectedAppSite = appSites.find(appSite => appSite.id === selectedAppSiteId);
        if (!selectedAppSite) {
            setFormError(`${FORM_TRANSLATE_KEY}.MISSING_APP_SITE_ERROR`);
            return;
        }

        if (!Object.values(updatedFields).some(fieldValue => !!fieldValue)) {
            setFormError(`${FORM_TRANSLATE_KEY}.${targetPlatform}.FIELDS_ERROR`);
            return;
        }

        setIsSaving(true);
        const { appId } = selectedAppSite;
        const updatedAppSite = {
            ...selectedAppSite,
            ...updatedFields,
        };
        sendMixpanelEventSdkIntegration(
            SDK_INTEGRATION_MIXPANEL_EVENTS.DEEP_LINKING_SAVE,
            SDK_INTEGRATION_SECTIONS.DEEP_LINKING,
            platform,
            {
                appsite: updatedAppSite,
            }
        );

        try {
            await appsAPI.updateSite(appId, updatedAppSite);
            setSaveSuccess(true);
            formInputFields.forEach(field => {
                selectedAppSite[field.name] = updatedFields[field.name];
            });
        } catch (error) {
            setSaveError(true);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <AutoCompleteField
                options={appSites.map(appSite => {
                    const { longname, id } = appSite;
                    return { value: id, label: longname };
                })}
                valueField="id"
                onChange={onAppSiteSelection}
                containerClass={css.formAutoComplete}
                isMulti={false}
                disabled={appSites.length === 0}
                searchable
                placeholder={`${FORM_TRANSLATE_KEY}.SELECT_APP_SITE_PLACEHOLDER`}
                loading={isLoadingAppSites}
                clearable
                label={`${FORM_TRANSLATE_KEY}.SELECT_APP_SITE`}
            />

            {formInputFields.map(field => (
                <>
                    <Label
                        text={field.label}
                        type="fieldLabel"
                        key={`label-${field.name}`}
                        tooltip={field.tooltip}
                        tooltipClassName={field.tooltipClassName || ''}
                    />
                    <TextField
                        value={updatedFields[field.name]}
                        onChange={value => {
                            onFormParamsChange();
                            handleFieldsChange({ field: field.name, value });
                        }}
                        placeholder={field.placeholder}
                        disabled={appSites.length === 0}
                        key={field.name}
                    />
                </>
            ))}

            {formError && <Label text={formError} type="error" />}

            <Button
                text="STATIC.BUTTONS.SAVE"
                onClick={onSave}
                type="primary"
                showV={saveSuccess}
                showSpinner={isSaving}
                className={css.formButton}
                disabled={saveSuccess || saveError || appSites.length === 0}
                disabledDark={appSites.length === 0}
            />

            <Snackbar
                title={`${FORM_TRANSLATE_KEY}.SUCCESS`}
                type="success"
                message={`${FORM_TRANSLATE_KEY}.SUCCESS_MESSAGE`}
                open={saveSuccess}
                autoHideDuration={5000}
            />
            <Snackbar
                title={`${FORM_TRANSLATE_KEY}.ERROR`}
                type="error"
                message={`${FORM_TRANSLATE_KEY}.ERROR_MESSAGE`}
                open={saveError}
                autoHideDuration={5000}
            />
        </>
    );
};

AppSiteUpdateForm.propTypes = {
    appSites: PropTypes.arrayOf(PropTypes.shape({ longname: PropTypes.string, id: PropTypes.number })).isRequired,
    isLoadingAppSites: PropTypes.bool.isRequired,
    targetPlatform: PropTypes.string.isRequired,
    formInputFields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            placeholder: PropTypes.string,
        })
    ).isRequired,
};

export default AppSiteUpdateForm;
