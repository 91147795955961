import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ChevronIcon from '../../../../resources/icons/chevron.svg';
import css from './NavigationHeader.css';
import { Label, Button } from '../../../widgets';

const NavigationHeader = ({ activeItemIndex, handleOnClickBack, handleOnClickNext, itemTitle, itemsCount }) => (
    <>
        <div className={css.sectionHeader}>
            <Label text={itemTitle} />
            <div className={css.navigationButtonsContainer}>
                {activeItemIndex > 0 && (
                    <Button
                        text="Back"
                        type="invertedPrimary"
                        Icon={ChevronIcon}
                        iconClass={classNames(css.chevronIcon, css.backIcon)}
                        className={css.navigationButton}
                        onClick={handleOnClickBack}
                    />
                )}
                {activeItemIndex < itemsCount - 1 && (
                    <Button
                        text="Next"
                        type="primary"
                        className={css.navigationButton}
                        iconClass={classNames(css.chevronIcon, css.nextIcon)}
                        Icon={ChevronIcon}
                        onClick={handleOnClickNext}
                        iconLocation="right"
                    />
                )}
            </div>
        </div>
        <div className={css.contentSeparator} />
    </>
);

NavigationHeader.propTypes = {
    activeItemIndex: PropTypes.number.isRequired,
    handleOnClickBack: PropTypes.func.isRequired,
    handleOnClickNext: PropTypes.func.isRequired,
    itemTitle: PropTypes.string.isRequired,
    itemsCount: PropTypes.number.isRequired,
};

export default NavigationHeader;
