export const CHECKBOX_CLICKED = 'action.{reportType}.checkbox.clicked';
export const FIELD_CLICKED_BULK = 'action.{reportType}.field.clicked.bulk';
export const FIELD_CLICKED_COMPLETE = 'action.{reportType}.field.clicked.complete';
export const FILTER_CHANGED = 'action.table.filter.changed';
export const FIELD_CLICKED = 'action.{reportType}.field.clicked';
export const FIELD_SET = 'action.{reportType}.field.set';
export const FILTER_FIELD_SELECTED = 'action.{reportType}.filter.field.selected';
export const FILTER_OPERATOR_SELECTED = 'action.{reportType}.filter.operator.selected';
export const FILTER_VALUE_ADDED = 'action.{reportType}.filter.value.added';
export const FILTER_VALUE_REMOVED = 'action.{reportType}.filter.value.removed';
export const FILTER_ADD = 'action.{reportType}.filter.add';
export const FILTER_REMOVE = 'action.{reportType}.filter.remove';
export const TOGGLE_FIELDS_SELECTION = 'action.{reportType}.toggle.fields.selection';
export const TAB_SELECTED = 'action.{reportType}.tab.selected';
export const MODE_SELECTED = 'action.{reportType}.mode.selected';
export const MODE_SELECTED_GENERIC = 'action.mode.selected';
export const FILTERS_EXPANDED = 'action.{reportType}.filters.expanded';
export const DATE_CHANGED = 'action.{reportType}.date.changed';
export const REPORT_FORM_CHANGED = 'action.{reportType}.report.form.changed';
export const DATE_CHANGED_GLOBAL = 'action.date.changed';
export const RUN_QUERY = 'action.{reportType}.run_query';
export const DOWNLOAD_REPORT = 'action.{reportType}.download_report';
export const RUN_QUERY_FINISHED = 'action.{reportType}.run_query.finished';
export const TRANSPARENCY_DATA_RESPONSE = 'action.{reportType}.transparency_data_response';
export const TRANSPARENCY_DRILLDOWN = 'action.{reportType}.transparency_drilldown';
export const QUERY_REQUEST_PARAMS = 'action.{reportType}.query.request.params';
export const QUERY_RESPONSE = 'action.{reportType}.query.response';
export const QUERY_ERROR_RESPONSE = 'action.{reportType}.query.error.response';
export const UPDATE_CHART = 'action.{reportType}.chart.update';
export const CHART_RESPONSE = 'action.{reportType}.chart.response';
export const TOTALS_RESPONSE = 'action.{reportType}.totals.response';
export const TOTALS_CLEANED = 'action.{reportType}.totals.cleaned';
export const TOTALS_HIDE = 'action.{reportType}.totals.hide';
export const TOTALS_REQUEST = 'action.{reportType}.totals.request';
export const HIDE_CHART = 'action.{reportType}.chart.hide';
export const SHOW_CHART = 'action.{reportType}.chart.show';
export const TOGGLE_SLOW_LOADING_CHART = 'action.{reportType}.toggle.chart.slow.loading';
export const HIDE_TABLE = 'action.{reportType}.table.hide';
export const HIDE_SPINNER = 'action.{reportType}.spinner.hide';
export const SHOW_REPORT_HEADER = 'action.{reportType}.show.header';
export const SHOW_TABLE = 'action.{reportType}.table.show';
export const CHART_LINES_CHANGED = 'action.chart.lines.changed';
export const CHART_METRIC_SELECTED = 'action.{reportType}.chart.metric.selected';
export const CHART_LOADED = 'action.{reportType}.chart.loaded';
export const UPDATE_CHART_LINES = 'action.{reportType}.chart.update.lines';
export const UPDATE_CHART_SHOW_LEGEND = 'action.{reportType}.chart.show.legend';
export const UPDATE_ANONYMOUS_ID = 'action.{reportType}.update.anonymous.id';
export const UPDATE_ANONYMOUS_LIST = 'action.update.anonymous.list';
export const SAVE_BOOKMARK = 'action.reports.save.bookmark';
export const SAVE_BOOKMARK_ERROR = 'action.{reportType}.save.bookmark.error';
export const DELETE_BOOKMARK = 'action.delete.bookmark';
export const DELETING_BOOKMARK = 'action.{reportType}.deleting.bookmark';
export const UPDATE_BOOKMARK_DATA = 'action.{reportType}.bookmark.data';
export const GOAL_SELECTED = 'action.{reportType}.goal.selected';
export const EXPORT_TABLE = 'action.export.table';
export const UPDATE_REPORT_DATA = 'action.{reportType}.update.report.data';
export const SET_VALIDATION_ERROR = 'action.{reportType}.set.validation.error';
export const CLEAR_TOASTS = 'action.general.clear_toasts';
export const SET_UNENRICHED = 'action.{reportType}.set.unenriched';
export const SET_ADMON_ALIGNMENT = 'action.{reportType}.set.admon.alignment';
export const CHECK_ALERTS_DATA = 'action.{reportType}.check.alerts_data';
export const SET_CHART_ERROR = 'action.{reportType}.set.chart.error';
export const GRID_READY = 'action.grid.ready';
export const CLEAR_REPORT_DATA = 'action.{reportType}.clear.data';
export const CLEAR_REPORT_FILTERS = 'action.{reportType}.clear.filters';
export const SHOW_TRANSPARENCY_POPOVER = 'action.{reportType}.grid.transparency.show';
export const SHOW_DRILLDOWN_POPOVER = 'action.{reportType}.grid.drilldown.show';
export const RUN_QUERY_ON_FIELD_CHECKED = 'action.{reportType}.run_query_on_field_checked';

export const PIVOT_ROW_GROUP_CHANGED = 'action.{reportType}.pivot.row.group.changed';
export const PIVOT_COLUMNS_CHANGED = 'action.{reportType}.pivot.columns.changed';
export const PIVOT_VALUES_CHANGED = 'action.{reportType}.pivot.values.changed';
export const PIVOT_CHART_CHANGED = 'action.{reportType}.pivot.chart.changed';

export const UPDATE_QUERY_URL = 'action.{reportType}.update.query.url';

export const COLUMN_PIN = 'action.grid.column.pin';
export const COLUMN_MOVE = 'action.grid.column.move';
export const COLUMN_TOGGLE = 'action.grid.column.toggle';
export const COLUMN_FREEZE = 'action.grid.column.freeze';
export const RESET_TABLE = 'action.grid.reset';

export const TOGGLE_SLIM_MODE = 'action.{reportType}.slim_mode.selected';
export const CLEAR_FIELD_SELECTION = 'action.{reportType}.clear_field_selection.on';
export const CLEAR_FIELD_SELECTION_OFF = 'action.{reportType}.clear_field_selection.off';

export const DISABLE_REPORT_STATE_CHANGE = 'action.{reportType}.disable_report_state.changed';

export const TOGGLE_FIELDS_AVAILABILITY_SHELF = 'action.{reportType}.fields_availability.toggle';

export const POPUP_DIALOG_CHANGED = 'action.popup_dialog.changed';
export const IS_SHELF_OPEN = 'action.{reportType}.open.shelf';
export const IS_CREATIVE_GALLERY_REPORT_RUNNING = 'action.creative_gallery_report.running';

export const checkboxClicked = () => {
    return {
        type: CHECKBOX_CLICKED,
        payload: {},
    };
};

export const changePopupDialog = (isOpen, popupDialogParams) => {
    return {
        type: POPUP_DIALOG_CHANGED,
        isOpen,
        popupDialogParams,
    };
};

export const updateReportsFilter = (reportType, model, colDefs) => {
    return {
        type: FILTER_CHANGED,
        reportType,
        colDefs,
        model,
    };
};

export const reportsFieldClicked = (field, isSingle) => {
    return {
        type: FIELD_CLICKED,
        field,
        isSingle,
    };
};

export const reportsFieldSet = (field, value) => {
    return {
        type: FIELD_SET,
        field,
        value,
    };
};

export const reportsFieldClickedBulk = (values, fieldType) => {
    return {
        type: FIELD_CLICKED_BULK,
        values,
        fieldType,
    };
};

export const reportsFieldClickedComplete = data => ({
    type: FIELD_CLICKED_COMPLETE,
    data,
});

export const filterFieldSelected = (field, index) => {
    return {
        type: FILTER_FIELD_SELECTED,
        field,
        index,
    };
};

export const filterOperatorSelected = (field, index) => {
    return {
        type: FILTER_OPERATOR_SELECTED,
        field,
        index,
    };
};

export const filterValueAdded = (value, index) => {
    return {
        type: FILTER_VALUE_ADDED,
        value,
        index,
    };
};

export const filterValueRemoved = (value, index) => {
    return {
        type: FILTER_VALUE_REMOVED,
        value,
        index,
    };
};

export const filterAdd = () => {
    return {
        type: FILTER_ADD,
    };
};

export const filterRemove = index => {
    return {
        type: FILTER_REMOVE,
        index,
    };
};

export const toggleShelf = (value, e) => {
    // Scroll to event element
    if (e) {
        const { currentTarget } = e;

        // Chrome has a bug where multiple calls are needed sometimes
        if (currentTarget) {
            currentTarget.scrollIntoView(false);
            currentTarget.scrollIntoView(false);
            currentTarget.scrollIntoView(false);
        }
    }

    return {
        type: TOGGLE_FIELDS_SELECTION,
        value,
    };
};

export const toggleSlimMode = isSlimMode => {
    return {
        type: TOGGLE_SLIM_MODE,
        isSlimMode,
    };
};

export const clearFieldSelection = () => {
    return {
        type: CLEAR_FIELD_SELECTION,
    };
};

export const toggleFieldsAvailability = (field = '', isOpen = true) => {
    return {
        type: TOGGLE_FIELDS_AVAILABILITY_SHELF,
        field,
        isOpen,
    };
};

export const setClearSelectionModeOff = () => {
    return {
        type: CLEAR_FIELD_SELECTION_OFF,
    };
};

export const tabSelected = id => {
    return {
        type: TAB_SELECTED,
        id,
    };
};

export const modeSelected = id => {
    return {
        type: MODE_SELECTED,
        id,
    };
};

export const modeSelectedGeneric = (reportType, id) => {
    return {
        type: MODE_SELECTED_GENERIC,
        reportType,
        id,
    };
};

export const filtersExpanded = isExpanded => {
    return {
        type: FILTERS_EXPANDED,
        isExpanded,
    };
};

export const dateChanged = data => {
    return {
        type: DATE_CHANGED,
        data,
    };
};

export const reportFormChanged = changed => {
    return {
        type: REPORT_FORM_CHANGED,
        value: changed,
    };
};

export const disableReportStateChanged = disableReportState => {
    return {
        type: DISABLE_REPORT_STATE_CHANGE,
        disableReportState,
    };
};

export const dateChangedGlobal = (reportType, data) => {
    return {
        type: DATE_CHANGED_GLOBAL,
        data,
        reportType,
    };
};

export const runQuery = (reportType, queryType, data, bookmark = null, fromUrl = false) => {
    return {
        type: RUN_QUERY,
        reportType,
        queryType,
        data,
        bookmark,
        fromUrl,
    };
};

export const downloadOrExportReport = (
    reportType,
    queryType,
    origin,
    dontExport = false,
    isExportGsheet = false,
    gsheetToken = null,
    isCopyCsv = false
) => {
    return {
        type: DOWNLOAD_REPORT,
        reportType,
        queryType,
        origin,
        dontExport,
        isExportGsheet,
        gsheetToken,
        isCopyCsv,
    };
};

export const transparencyDataResponse = (transparencyData, duration, result = true) => {
    return {
        type: TRANSPARENCY_DATA_RESPONSE,
        transparencyData,
        duration,
        result,
    };
};

export const queryResponse = response => {
    return {
        type: QUERY_RESPONSE,
        response,
    };
};

export const queryErrorResponse = (error, durationBucket) => {
    return {
        type: QUERY_ERROR_RESPONSE,
        error,
        durationBucket,
    };
};

export const updateChartData = reportType => ({ type: UPDATE_CHART, reportType });

export const chartResponse = response => {
    return {
        type: CHART_RESPONSE,
        response,
    };
};

export const totalsResponse = (response, duration) => {
    return {
        type: TOTALS_RESPONSE,
        response,
        duration,
    };
};

export const totalsCleaned = () => {
    return {
        type: TOTALS_CLEANED,
    };
};

export const totalsHide = () => {
    return {
        type: TOTALS_HIDE,
    };
};

export const totalsRequest = () => {
    return {
        type: TOTALS_REQUEST,
    };
};

export const hideChart = () => {
    return {
        type: HIDE_CHART,
    };
};

export const hideTable = () => {
    return {
        type: HIDE_TABLE,
    };
};

export const hideSpinner = () => {
    return {
        type: HIDE_SPINNER,
    };
};

export const showReportHeader = () => {
    return {
        type: SHOW_REPORT_HEADER,
    };
};

export const showChart = () => {
    return {
        type: SHOW_CHART,
    };
};

export const toggleSlowLoadingChart = value => {
    return {
        type: TOGGLE_SLOW_LOADING_CHART,
        value,
    };
};

export const showTable = () => {
    return {
        type: SHOW_TABLE,
    };
};

export const queryRequestParams = (queryType, requestParams) => {
    return {
        type: QUERY_REQUEST_PARAMS,
        queryType,
        requestParams,
    };
};

export const chartLinesChanged = (reportType, value) => {
    return {
        type: CHART_LINES_CHANGED,
        value,
        reportType,
    };
};

export const chartMetricSelected = (metric, chartParams) => {
    return {
        type: CHART_METRIC_SELECTED,
        metric,
        chartParams,
    };
};

export const chartLoaded = chartParams => {
    return {
        type: CHART_LOADED,
        chartParams,
    };
};

export const updateChartLines = value => {
    return {
        type: UPDATE_CHART_LINES,
        value,
    };
};

export const updateChartShowLegend = (show, reportType) => {
    return {
        type: UPDATE_CHART_SHOW_LEGEND,
        show,
        reportType,
    };
};

export const updateAnonymousId = anonymousId => {
    return {
        type: UPDATE_ANONYMOUS_ID,
        anonymousId,
    };
};

export const updateAnonymousList = anonymousList => {
    return {
        type: UPDATE_ANONYMOUS_LIST,
        anonymousList,
    };
};

export const saveBookmark = (reportType, data, resolve) => {
    return {
        type: SAVE_BOOKMARK,
        data,
        reportType,
        resolve,
    };
};

export const updateBookmarkError = (message, instanceId) => {
    return {
        type: SAVE_BOOKMARK_ERROR,
        message,
        instanceId,
    };
};

export const deletingBookmark = bookmark => {
    return {
        type: DELETING_BOOKMARK,
        bookmark,
    };
};

export const updateBookmarkData = (data, request, eventType) => {
    return {
        type: UPDATE_BOOKMARK_DATA,
        data,
        request,
        eventType,
    };
};

export const goalSelected = goal => {
    return {
        type: GOAL_SELECTED,
        goal,
    };
};

export const exportTable = (reportType, exportType, isExportGsheet = false, gsheetToken = null, isCopyCsv = false) => {
    return {
        type: EXPORT_TABLE,
        reportType,
        exportType,
        isExportGsheet,
        gsheetToken,
        isCopyCsv,
    };
};

export const updateReportData = data => {
    return {
        type: UPDATE_REPORT_DATA,
        data,
    };
};

// eslint-disable-next-line camelcase
export const checkAlertsData = reportType => ({
    type: CHECK_ALERTS_DATA,
    reportType,
});

export const setRunQueryFinished = () => {
    return {
        type: RUN_QUERY_FINISHED,
    };
};

export const setValidationError = message => {
    return {
        type: SET_VALIDATION_ERROR,
        message,
    };
};

export const clearToasts = () => ({ type: CLEAR_TOASTS });

export const setUnenriched = value => {
    return {
        type: SET_UNENRICHED,
        value,
    };
};

export const setChartError = value => {
    return {
        type: SET_CHART_ERROR,
        value,
    };
};

export const gridReady = reportType => {
    return {
        type: GRID_READY,
        reportType,
    };
};

export const clearReportData = () => {
    return {
        type: CLEAR_REPORT_DATA,
    };
};

export const clearReportFilters = () => {
    return {
        type: CLEAR_REPORT_FILTERS,
    };
};

export const showTransparencyPopover = value => {
    return {
        type: SHOW_TRANSPARENCY_POPOVER,
        value,
    };
};

export const pivotRowGroupChanged = rowGroup => {
    return {
        type: PIVOT_ROW_GROUP_CHANGED,
        rowGroup,
    };
};

export const pivotColumnsChanged = columns => {
    return {
        type: PIVOT_COLUMNS_CHANGED,
        columns,
    };
};

export const pivotValuesChanged = values => {
    return {
        type: PIVOT_VALUES_CHANGED,
        values,
    };
};

export const pivotChartChanged = chart => {
    return {
        type: PIVOT_CHART_CHANGED,
        chart,
    };
};

export const updateQueryURL = reportType => {
    return {
        type: UPDATE_QUERY_URL,
        reportType,
    };
};

export const showDrilldownPopover = dimensionDrilldownCellId => {
    return {
        type: SHOW_DRILLDOWN_POPOVER,
        dimensionDrilldownCellId,
    };
};

export const isShelfOpen = isOpen => {
    return {
        type: IS_SHELF_OPEN,
        isToOpenShelf: isOpen,
    };
};

export const isCreativesGalleryReportRunning = isRunning => {
    return {
        type: IS_CREATIVE_GALLERY_REPORT_RUNNING,
        isRunning,
    };
};

export const columnPin = params => ({ type: COLUMN_PIN, params });
export const columnMove = params => ({ type: COLUMN_MOVE, params });
export const columnToggle = params => ({ type: COLUMN_TOGGLE, params });
export const columnFreeze = params => ({ type: COLUMN_FREEZE, params });
export const resetTable = params => ({ type: RESET_TABLE, params });

export const setActionType = (action, type) => {
    action.type = action.type.replace(/{reportType}/g, type);
    return action;
};

export const getExportAction = (isExportGsheet, isCopyCsv) => {
    return isExportGsheet ? 'export gsheet' : isCopyCsv ? 'copy csv' : 'download csv';
};
