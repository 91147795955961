import React from 'react';
import PropTypes from 'prop-types';
import css from './FlatIconButton.css';
import Tooltip from '../widgets/Tooltip';

function FlatIconButton({ text, iconPath, buttonStyle, onButtonClick, disabled, tooltipText }) {
    return (
        <Tooltip title={tooltipText} position={'top'} disabled={!tooltipText} >
            <button type="button" className={css.button} style={buttonStyle} disabled={disabled} onClick={() => onButtonClick()}>
                <img src={iconPath} className={css.image} />
                <span className={css.label}>{text}</span>
            </button>
        </Tooltip>
    );
}

FlatIconButton.propTypes = {
    text: PropTypes.string,
    iconPath: PropTypes.string,
    buttonStyle: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    tooltipText: PropTypes.string,
    onButtonClick: PropTypes.func,
};

FlatIconButton.defaultProps = {
    text: '',
    iconPath: '',
    buttonStyle: {},
    disabled: false,
    tooltipText: '',
    onButtonClick: () => {},
};

export default FlatIconButton;
