import React from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';

import Dropdown from 'components/widgets/Dropdown';
import TextField from 'components/widgets/TextField';
import TrashIcon from 'resources/svg/trash.svg';
import VisibleIcon from 'resources/svg/visible.svg';
import CalendarIcon from 'resources/svg/calendar.svg';
import LinkIcon from 'resources/svg/link.svg';
import RadioButtonsGroup from 'components/widgets/RadioButtonsGroup';
import WizardFooter from 'components/widgets/WizardFooter';
import Tooltip from 'components/widgets/Tooltip';
import {
    SHARED_REPORTS_ANONYMOUS_ENDPOINT,
    SHARED_REPORTS_DEFAULT_DAYS,
    SHARED_REPORTS_EXPIRATION_DAYS_CHOICES,
} from 'components/reportsWidgets/consts';
import { getFormattedDate, getSharedReportsExpirationDaysOptions } from 'components/reportsWidgets/utils';
import GeneralPopup from '../organisms/GeneralPopup';
import { SHARED_REPORTS_EVENT_PREFIX, trackMixpanelEvent } from '../../utils/general';
import css from './SharedReportsShelf.css';
import EmptyState from '../widgets/EmptyState';
import Shelf from '../widgets/Shelf';
import Button from '../atoms/Button';

import WarningMessage from '../utilities/WarningMessage';

class SharedReportsShelf extends React.Component {
    constructor(props) {
        super(props);
        const { translate } = this.props;
        this.initial_state = {
            reportName: '',
            password: '',
            expirationDays: SHARED_REPORTS_DEFAULT_DAYS,
            dateRangeModificationOptions: [
                {
                    label: translate('STATIC.PAGES.SHARED_REPORTS.SPECIFY_DATE_RANGE_FALSE'),
                    id: 'specific',
                    checked: true,
                },
                {
                    label: translate('STATIC.PAGES.SHARED_REPORTS.SPECIFY_DATE_RANGE_TRUE'),
                    id: 'all',
                    checked: false,
                },
            ],
            isModified: false,
            hoverIndex: null,
            showDeleteDialog: false,
            reportDeleteInProgress: false,
        };
        this.state = this.initial_state;
        this.toggleDateRangeModification = this._toggleDateRangeModification.bind(this);

        this.handleExpirationDaysChanged = this._handleExpirationDaysChanged.bind(this);
        this.handleReportNameChanged = this._handleReportNameChanged.bind(this);
        this.handlePasswordChanged = this._handlePasswordChanged.bind(this);
        this.handleGenerateButtonClicked = this._handleGenerateButtonClicked.bind(this);
        this.handleDeleteReport = this._handleDeleteReport.bind(this);
        this.handleCloseShelf = this._handleCloseShelf.bind(this);
        this.getSharedReportsList = this._getSharedReportsList.bind(this);
        this.handleCopyButtonClicked = this._handleCopyButtonClicked.bind(this);
        this.setHovered = this._setHovered.bind(this);
        this.unsetHovered = this._unsetHovered.bind(this);
        this.onPopupClose = this._onPopupClose.bind(this);
        this.onAcceptReportDelete = this._onAcceptReportDelete.bind(this);
    }

    componentDidMount() {
        this.getSharedReportsList();
    }

    _toggleDateRangeModification = checkedItem => {
        this.setState({
            isModified: true,
            dateRangeModificationOptions: this.state.dateRangeModificationOptions.map(x => {
                return { ...x, checked: checkedItem.id === x.id };
            }),
        });
    };

    _setHovered(id) {
        this.setState({
            hoverIndex: id,
        });
    }

    _unsetHovered() {
        this.setState({
            hoverIndex: null,
        });
    }

    _handleExpirationDaysChanged({ name }) {
        const expirationDays = name;
        if (!SHARED_REPORTS_EXPIRATION_DAYS_CHOICES.includes(expirationDays)) {
            return;
        }
        this.setState({
            expirationDays,
            isModified: true,
        });
    }

    _handleReportNameChanged(reportName) {
        this.setState({
            reportName,
            isModified: true,
        });
    }

    _handlePasswordChanged(password) {
        this.setState({
            password,
            isModified: true,
        });
    }

    _handleGenerateButtonClicked() {
        const { expirationDays, dateRangeModificationOptions, password, reportName } = this.state;
        const { onGenerateLinkClick } = this.props;
        const enableFlexibleDates = dateRangeModificationOptions[1].checked;
        onGenerateLinkClick(reportName, password, expirationDays, enableFlexibleDates);
        this.setState({
            isModified: false,
        });
        trackMixpanelEvent(SHARED_REPORTS_EVENT_PREFIX, 'Report Created', {
            reportName,
            expirationDays,
        });
    }

    _handleDeleteReport(reportId) {
        this.reportIdToDelete = reportId;
        this.setState({
            showDeleteDialog: true,
        });
        trackMixpanelEvent(SHARED_REPORTS_EVENT_PREFIX, 'Report Deleted', {
            reportId,
        });
    }

    _handleCloseShelf() {
        const { isModified } = this.state;
        const { translate } = this.props;
        if (isModified) {
            if (!confirm(translate('STATIC.PAGES.SHARED_REPORTS.UNSAVED_CHANGES_WARNING'))) {
                return;
            }
        }
        this.setState(this.initial_state);
        this.props.onInnerShowChanged(false);
        this.props.onCloseShelf();
    }

    _getSharedReportsList() {
        this.props.onGenerateList();
    }

    _handleCopyButtonClicked() {
        const copyText = this.linkInput;
        copyText.select();
        document.execCommand('Copy');
    }

    _onPopupClose() {
        this.reportIdToDelete = null;
        this.setState({
            showDeleteDialog: false,
        });
    }

    _onAcceptReportDelete() {
        const { onDeleteReport } = this.props;
        this.setState({
            reportDeleteInProgress: true,
        });
        onDeleteReport(this.reportIdToDelete);
        setTimeout(() => {
            this.setState({
                showDeleteDialog: false,
                reportDeleteInProgress: false,
            });
        }, 2000);
    }

    renderGenerateReport(reportName, password, isModified, expirationDays, sharedReportId, link, reportDates) {
        const { dateRangeModificationOptions } = this.state;
        const { translate } = this.props;
        const dateString = dateRangeModificationOptions[1].checked
            ? 'All dates'
            : ` ${getFormattedDate(reportDates.start_date)} - ${getFormattedDate(reportDates.end_date)}`;
        return (
            <React.Fragment>
                <div className={css.headerText}>
                    <Translate id="STATIC.PAGES.SHARED_REPORTS.GENERATE_REPORT_SECTION_HEADER" />
                </div>
                <div className={css.buttonAndText}>
                    <TextField
                        inputConfig={{ maxLength: 30 }}
                        value={reportName}
                        containerClass={css.textInputLine}
                        label="STATIC.PAGES.SHARED_REPORTS.REPORT_NAME"
                        mandatory
                        onChange={this.handleReportNameChanged}
                        placeholder={translate('STATIC.PAGES.SHARED_REPORTS.REPORT_NAME_HINT')}
                    />
                    <Button
                        type="secondary"
                        level="level1"
                        disabled={!isModified || !reportName}
                        className={css.generateLinkButton}
                        text={translate('STATIC.PAGES.SHARED_REPORTS.GENERATE_LINK_BUTTON')}
                        onClick={this.handleGenerateButtonClicked}
                    />
                </div>
                <TextField
                    inputConfig={{ maxLength: 20 }}
                    value={password}
                    containerClass={css.textInputLine}
                    label="STATIC.PAGES.SHARED_REPORTS.PASSWORD"
                    placeholder={translate('STATIC.PAGES.SHARED_REPORTS.PASSWORD_HINT')}
                    onChange={this.handlePasswordChanged}
                />
                <div className={css.dateRangeSection}>
                    <Translate id="STATIC.PAGES.SHARED_REPORTS.DATES" />
                    <CalendarIcon className={css.CalendarIcon} />
                    <div className={css.dateRangeString}>{dateString}</div>
                </div>
                <div className={css.dateChangeRadioButtons}>
                    <RadioButtonsGroup
                        id="dateRangeModification"
                        radioItems={dateRangeModificationOptions}
                        onChange={this.toggleDateRangeModification}
                    />
                </div>
                <div className={css.numOfDaysContainer}>
                    <div>
                        <Translate id="STATIC.PAGES.SHARED_REPORTS.LINK_EXPIRATION" />
                    </div>
                    <Dropdown
                        items={getSharedReportsExpirationDaysOptions()}
                        selected={{ name: expirationDays, display_name: expirationDays }}
                        containerClass={css.numOfDaysInput}
                        onSelection={this.handleExpirationDaysChanged}
                    />
                    <div>
                        <Translate id="STATIC.PAGES.SHARED_REPORTS.LINK_UNIT" />
                    </div>
                </div>
                <div className={css.buttonAndText}>
                    <div>Generated Link:</div>
                    <Button
                        type="link"
                        text="STATIC.PAGES.SHARED_REPORTS.COPY_BUTTON"
                        className={css.copyButton}
                        style={{ paddingBottom: '12px' }}
                        disabled={!sharedReportId || isModified}
                        onClick={this.handleCopyButtonClicked}
                    />
                </div>
                <textarea
                    readOnly
                    className={css.link}
                    disabled={!sharedReportId || isModified}
                    value={sharedReportId && !isModified ? link : ''}
                    ref={el => {
                        this.linkInput = el;
                    }}
                />
            </React.Fragment>
        );
    }

    renderReportsList(sharedReportsList) {
        const { translate } = this.props;

        if (!sharedReportsList || sharedReportsList.length === 0) {
            return (
                <div className={css.emptyState}>
                    <EmptyState icon="happyPage" header="STATIC.PAGES.SHARED_REPORTS.NO_EXISTING_REPORTS" />
                </div>
            );
        }
        return sharedReportsList.map(sharedReport => {
            const link = `${window.location.origin}${SHARED_REPORTS_ANONYMOUS_ENDPOINT}${sharedReport.id}`;
            const expiration = ` | ${translate('STATIC.PAGES.SHARED_REPORTS.EXPIRES')} ${getFormattedDate(
                sharedReport.expiration
            )}`;
            return (
                <div
                    className={`${css.sharedReportLine} ${
                        this.state.hoverIndex === sharedReport.id ? css.sharedReportLineBorder : ''
                    }`}
                    key={sharedReport.id}
                    onMouseEnter={() => this.setHovered(sharedReport.id)}
                    onMouseLeave={this.unsetHovered}
                >
                    <div className={css.sharedReportLineDescription}>
                        <Tooltip interactive titleTranslationKey="STATIC.PAGES.SHARED_REPORTS.OPEN_REPORT">
                            <a
                                href={link}
                                target="_blank"
                                onClick={() => {
                                    trackMixpanelEvent(SHARED_REPORTS_EVENT_PREFIX, 'Open Report In Anonymous');
                                }}
                                rel="noreferrer"
                            >
                                {sharedReport.name}
                            </a>
                        </Tooltip>
                        {expiration}
                    </div>
                    {this.state.hoverIndex === sharedReport.id && (
                        <div
                            className={css.sharedReportLineButtons}
                            style={{ width: sharedReport.old_report ? 60 : 100 }}
                        >
                            <Tooltip interactive titleTranslationKey="STATIC.PAGES.SHARED_REPORTS.DELETE_REPORT">
                                <div
                                    onClick={() => {
                                        this.handleDeleteReport(sharedReport.id);
                                    }}
                                    className={css.button}
                                >
                                    <TrashIcon />
                                </div>
                            </Tooltip>
                            {!sharedReport.old_report && (
                                <div className={css.button}>
                                    <Tooltip
                                        interactive
                                        titleTranslationKey="STATIC.PAGES.SHARED_REPORTS.OPEN_REPORT_IN_SINGULAR"
                                        onClick={() => {
                                            trackMixpanelEvent(SHARED_REPORTS_EVENT_PREFIX, 'Open Report In Singular');
                                        }}
                                    >
                                        <a href={sharedReport.url} target="_blank" rel="noreferrer">
                                            <VisibleIcon />
                                        </a>
                                    </Tooltip>
                                </div>
                            )}
                            <Tooltip interactive titleTranslationKey="STATIC.PAGES.SHARED_REPORTS.COPY_BUTTON">
                                <div
                                    onClick={() => {
                                        navigator.clipboard.writeText(link);
                                    }}
                                    className={css.button}
                                >
                                    <LinkIcon />
                                </div>
                            </Tooltip>
                        </div>
                    )}
                </div>
            );
        });
    }

    render() {
        const { show, sharedReportId, sharedReportList, queryMode, reportDates } = this.props;
        const {
            expirationDays,
            isModified,
            reportName,
            password,
            showDeleteDialog,
            reportDeleteInProgress,
        } = this.state;

        const link = `${window.location.origin}${SHARED_REPORTS_ANONYMOUS_ENDPOINT}${sharedReportId}`;

        const noExistingSharedReports = !sharedReportList || sharedReportList.length === 0;
        const showEmptyState = queryMode && noExistingSharedReports;

        const generatedLinksHeader = (
            <div className={css.headerText}>
                <Translate id="STATIC.PAGES.SHARED_REPORTS.GENERATED_LINKS" />({sharedReportList.length}):
            </div>
        );
        return (
            <React.Fragment>
                <Shelf
                    open={show}
                    shelfSize="medium"
                    headerText="STATIC.PAGES.SHARED_REPORTS.SHELF_TITLE"
                    onClose={this.handleCloseShelf}
                >
                    <WarningMessage
                        show
                        showIcon={false}
                        showTypeIcon
                        type="info"
                        message="STATIC.PAGES.SHARED_REPORTS.NOTE"
                    />
                    <div
                        className={css.container}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    >
                        {showEmptyState && (
                            <React.Fragment>
                                {generatedLinksHeader}
                                <div className={css.emptyState}>
                                    <EmptyState icon="happyPage" header="STATIC.PAGES.SHARED_REPORTS.EMPTY_STATE" />
                                </div>
                            </React.Fragment>
                        )}
                        {!showEmptyState && !queryMode && (
                            <React.Fragment>
                                {this.renderGenerateReport(
                                    reportName,
                                    password,
                                    isModified,
                                    expirationDays,
                                    sharedReportId,
                                    link,
                                    reportDates
                                )}
                                <div className={css.divider} />
                            </React.Fragment>
                        )}
                        {!showEmptyState && (
                            <div>
                                {generatedLinksHeader}
                                {this.renderReportsList(sharedReportList)}
                            </div>
                        )}
                    </div>
                    <WizardFooter buttons={[<Button text="STATIC.BUTTONS.CLOSE" onClick={this.handleCloseShelf} />]} />
                </Shelf>
                <GeneralPopup
                    open={showDeleteDialog}
                    type="delete"
                    title="STATIC.PAGES.SHARED_REPORTS.DELETE_MODAL.TITLE"
                    text="STATIC.PAGES.SHARED_REPORTS.DELETE_MODAL.MESSAGE"
                    acceptText="STATIC.PAGES.SHARED_REPORTS.DELETE_MODAL.ACCEPT_BUTTON_TEXT"
                    rejectText="STATIC.PAGES.SHARED_REPORTS.DELETE_MODAL.REJECT_BUTTON_TEXT"
                    onAccept={this.onAcceptReportDelete}
                    onReject={this.onPopupClose}
                    showSpinner={reportDeleteInProgress}
                />
            </React.Fragment>
        );
    }
}

SharedReportsShelf.propTypes = {
    show: PropTypes.bool,
    onGenerateLinkClick: PropTypes.func,
    onDeleteReport: PropTypes.func,
    onCloseShelf: PropTypes.func,
    onGenerateList: PropTypes.func,
    sharedReportList: PropTypes.arrayOf(PropTypes.any),
    onInnerShowChanged: PropTypes.func,
    translate: PropTypes.func,
    sharedReportId: PropTypes.string,
    queryMode: PropTypes.bool,
    reportDates: PropTypes.objectOf(PropTypes.any),
};

SharedReportsShelf.defaultProps = {
    show: false,
    sharedReportList: [],
    onGenerateLinkClick: () => {},
    onDeleteReport: () => {},
    onCloseShelf: () => {},
    onGenerateList: () => {},
    onInnerShowChanged: () => {},
    translate: () => {},
    sharedReportId: '',
    queryMode: true,
    reportDates: {},
};

export default withLocalize(SharedReportsShelf);
