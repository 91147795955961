import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from './Tooltip';
import css from '../organisms/Table.css';
import ExtendedMenuIcon from '../../resources/svg/extended_menu.svg';
import MoreActionsPopover from '../../dataSources/components/MoreActionsPopover';

const MAX_ACTIONS = 4;

const TableCellActions = props => {
    const {
        actionComponentsConfigs,
        row,
        rowIndex,
        containerStyle,
        extendedMenuTooltipText,
        isDataDestination,
        className,
        dataTestId,
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [showExtendedMenu, setShowExtendedMenu] = useState(false);

    const actionsToShow = actionComponentsConfigs
        .filter(({ shouldShow }) => shouldShow(row))
        .slice(0, MAX_ACTIONS)
        .reverse();

    const handleLocation = event => {
        setAnchorEl(event.currentTarget);
    };

    const onOpenExtendedMenu = event => {
        if (event.currentTarget === anchorEl) {
            setAnchorEl(null);
            setShowExtendedMenu(false);
            return;
        }
        handleLocation(event);
        setShowExtendedMenu(true);
    };

    const onCloseExtendedMenu = () => {
        setShowExtendedMenu(false);
        setAnchorEl(null);
    };

    return (
        <td
            className={classNames(css.actions, className)}
            key={`${row.key || rowIndex}_actions`}
            style={{
                ...containerStyle,
            }}
        >
            <div>
                {!isDataDestination && actionsToShow.length > 0 && (
                    <>
                        <Tooltip
                            title={extendedMenuTooltipText}
                            key={`tooltip_more_${rowIndex}`}
                            wrapperClass={classNames(css.action, css.extendedMenu)}
                            style={{ float: 'right' }}
                        >
                            <ExtendedMenuIcon
                                data-testid={dataTestId}
                                key={`more_actions_${rowIndex}`}
                                onClick={onOpenExtendedMenu}
                            />
                        </Tooltip>
                        {showExtendedMenu && (
                            <div className={css.popoverComponent}>
                                <MoreActionsPopover
                                    showExtendedMenu={showExtendedMenu}
                                    onCloseExtendedMenu={onCloseExtendedMenu}
                                    anchorEl={anchorEl}
                                    actionComponentsConfigs={actionComponentsConfigs}
                                    row={row}
                                />
                            </div>
                        )}
                    </>
                )}
                {actionsToShow
                    .filter(action => !action.shouldHideIcon)
                    .map(
                        ({
                            ActionComponent,
                            onClick,
                            onMouseEnter,
                            onMouseLeave,
                            key,
                            title,
                            tooltipText,
                            badge,
                            shouldTranslateToolTip,
                        }) => (
                            <Tooltip
                                open={tooltipText ? null : false}
                                title={tooltipText}
                                titleTranslationKey={shouldTranslateToolTip ? tooltipText : null}
                                key={`tooltip_${key}`}
                                wrapperClass={css.action}
                            >
                                {badge && <span className={css.badge}>{badge}</span>}
                                <ActionComponent
                                    title={title}
                                    key={`${row.key || rowIndex}_${key}_actions`}
                                    onClick={() => {
                                        onClick(row);
                                    }}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                />
                            </Tooltip>
                        )
                    )}
            </div>
        </td>
    );
};

TableCellActions.propTypes = {
    actionComponentsConfigs: PropTypes.arrayOf(
        PropTypes.shape({
            ActionComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
            title: PropTypes.string,
            shouldShow: PropTypes.func,
            shouldHideIcon: PropTypes.bool,
        })
    ),
    row: PropTypes.shape({
        uanId: PropTypes.number,
    }),
    rowIndex: PropTypes.number,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    extendedMenuTooltipText: PropTypes.string,
    isDataDestination: PropTypes.bool,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
};

TableCellActions.defaultProps = {
    actionComponentsConfigs: [],
    row: {},
    rowIndex: 0,
    containerStyle: {},
    extendedMenuTooltipText: '',
    isDataDestination: false,
};

export default TableCellActions;
