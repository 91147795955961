import React, { useState, useEffect, useMemo } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { useFetchCurrenciesTimezones } from 'signup/hooks';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import css from './style.css';
import Spinner from '../components/widgets/Spinner';
import SignupForm from './components/signupForm';
import SignupFormSecondPage from './components/signupFormSecondPage';
import ThankYou from './components/thankYou';
import TrialExpired from './components/trialExpired';
import LoadingPageBody from '../components/foundations/LoadingPageBody';

import { ERROR_CODES, getUtmParams, movePopularCurrenciesUpFront, STATUS_ERROR, STATUS_SUCCESS } from './utils';

function Signup({
    onSignupStarted,
    onGoogleSignupStarted,
    onSubmitClick,
    onCompleteGoogleSignUp,
    onResendClick,
    status,
    errorCode,
    isLoading,
    translate,
    emailSent,
    finishedGoogleSignupFirstStep,
}) {
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [finishFirstPage, setFinishFirstPage] = useState(false);
    const [signupWithGoogle, setSignupWithGoogle] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [ongoingGoogleAccountCreation, setOngoingGoogleAccountCreation] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const {
        value: {
            currencies: allCurrencies,
            timezones: allTimeZones,
            black_list_emails: allBlackListEmails,
            countries: allCountries,
        } = {
            currencies: [],
            timezones: [],
            black_list_emails: [],
            countries: [],
        },
    } = useFetchCurrenciesTimezones();

    useEffect(() => {
        const accessTokenFromCookie = Cookies.get('oauth-access-token');
        const isGoogleSignUpFromLogin = searchParams.get('step') === '2';
        if (isGoogleSignUpFromLogin) {
            setSignupWithGoogle(true);
        }
        if (accessTokenFromCookie && (signupWithGoogle || isGoogleSignUpFromLogin)) {
            setAccessToken(accessTokenFromCookie);
        }
    }, []);

    useEffect(() => {
        if (finishedGoogleSignupFirstStep) {
            setSignupWithGoogle(true);
            setFinishFirstPage(true);
        }
    }, [finishedGoogleSignupFirstStep]);

    const currencies = useMemo(() => {
        if (allCurrencies.length > 0) {
            const popularCurrencies = ['EUR', 'KRW', 'INR', 'USD'];
            movePopularCurrenciesUpFront(allCurrencies, popularCurrencies);
            return allCurrencies.map(currency => {
                return {
                    name: currency.name,
                    display_name: currency.display_name,
                    label: currency.display_name,
                };
            });
        }
        return [];
    }, [allCurrencies]);

    const timeZones = useMemo(() => {
        if (allTimeZones.length > 0)
            return allTimeZones.map(timezone => {
                return {
                    name: timezone,
                    display_name: timezone,
                    label: timezone,
                };
            });
        return [];
    }, [allTimeZones]);

    const countries = useMemo(() => {
        if (allCountries.length > 0)
            return allCountries.map(c => {
                return {
                    name: c.name,
                    display_name: c.display_name,
                    label: c.display_name,
                };
            });
        return [];
    }, [allCountries]);

    const renderMainView = () => {
        if (errorCode === ERROR_CODES.TRIAL_EXPIRED_ERROR) {
            return <TrialExpired />;
        } else if (
            !signupWithGoogle &&
            (status === STATUS_SUCCESS ||
                (status === STATUS_ERROR &&
                    (errorCode === ERROR_CODES.ERROR_EMAIL_DOMAIN_ALREADY_IN_USE ||
                        errorCode === ERROR_CODES.ERROR_ORG_EXISTS ||
                        errorCode === ERROR_CODES.USER_EXIST_ERROR)))
        ) {
            return <ThankYou onResendClick={onResendClick} isLoading={isLoading} emailSent={emailSent} email={email} />;
        } else if (!finishFirstPage && !signupWithGoogle) {
            return (
                <SignupForm
                    onSubmitNextPage={data => {
                        onSignupStarted(data);
                        setEmail(data.email);
                        setFullName(data.fullName);
                        setPassword(data.password);
                        setFinishFirstPage(true);
                    }}
                    onGoogleSignIn={oauthAccessToken => {
                        onGoogleSignupStarted({ accessToken: oauthAccessToken });
                        setAccessToken(oauthAccessToken);
                    }}
                    errorCode={errorCode}
                    isLoading={isLoading}
                    allBlackListEmails={allBlackListEmails}
                />
            );
        } else if (ongoingGoogleAccountCreation && isLoading) {
            return <LoadingPageBody />;
        } else {
            return (
                <SignupFormSecondPage
                    email={email}
                    fullName={fullName}
                    password={password}
                    currencies={currencies}
                    timeZones={timeZones}
                    countries={countries}
                    onSubmitClick={data => {
                        onSubmitClick(data);
                    }}
                    onCompleteGoogleSignUp={data => {
                        setTimeout(() => {
                            // the timeout is here to allow enough time for server errors (if any) to return
                            // before rendering the LoadingPageBody (the CountUp loader)
                            setOngoingGoogleAccountCreation(true);
                        }, 1000);
                        onCompleteGoogleSignUp({ accessToken, ...data });
                    }}
                    isLoading={isLoading}
                    signupWithGoogle={signupWithGoogle}
                    errorCode={errorCode}
                    translate={translate}
                />
            );
        }
    };

    useEffect(() => {
        window.mixpanel.track('sign_up_page_load', {
            signup_email: window.signupEmail,
            ...getUtmParams(),
        });
    }, []);

    return (
        <div>
            <Spinner show={false} />
            <div className={css.signupContainer}>{renderMainView()}</div>
        </div>
    );
}

Signup.propTypes = {
    onSignupStarted: PropTypes.func.isRequired,
    onGoogleSignupStarted: PropTypes.func.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    onCompleteGoogleSignUp: PropTypes.func.isRequired,
    onResendClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    emailSent: PropTypes.bool.isRequired,
    translate: PropTypes.func.isRequired,
    status: PropTypes.string,
    errorCode: PropTypes.string,
};

Signup.defaultProps = {
    status: null,
    errorCode: null,
};

export default withLocalize(Signup);
