import React from 'react';
import { Popover as MuiPopover } from '@mui/material';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import popoverStyle from './Popover.css';

const Popover = props => {
    const { showPopover, anchorEl, children, header, wrapperRef, anchorOrigin, className } = props;
    return (
        <MuiPopover
            open={showPopover}
            anchorEl={anchorEl}
            anchorOrigin={
                anchorOrigin || {
                    vertical: 'top',
                    horizontal: 'right',
                }
            }
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div ref={wrapperRef} className={classNames(popoverStyle.content, className)}>
                {header ? <span className={popoverStyle.header}>{header}</span> : null}
                <div className={popoverStyle.content}>{children}</div>
            </div>
        </MuiPopover>
    );
};

Popover.propTypes = {
    children: PropTypes.node.isRequired,
    showPopover: PropTypes.bool,
    anchorEl: PropTypes.objectOf(PropTypes.object),
    header: PropTypes.string,
    wrapperRef: PropTypes.object,
    className: PropTypes.string,
};

Popover.defaultProps = {
    showPopover: false,
    anchorEl: {},
    header: null,
    wrapperRef: null,
    className: '',
};

export default Popover;
