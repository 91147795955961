import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withLocalize } from 'react-localize-redux';
import { snakeCase } from 'lodash';
import css from './style.css';
import Label from '../../components/foundations/Label';
import XIcon from '../../resources/svg/icon_remove_row.svg';
import Chevron from '../../resources/icons/chevron.svg';
import HomeIcon from '../../../../dashboard/img/icons/home.svg';
import { getShowBackPanel, removeShowBackPanel, setPanelShownClasses } from '../utils';
import {
    BACK_PANEL_CONTAINER_CSS_CLASS,
    BACK_PANEL_SHOWN_CSS_CLASS,
    HOMEPAGE_PATH,
    PATH_TO_SECTION_DISPLAY_NAME,
} from '../consts';

function GoBackPanel({ currentPath, goBackContainer }) {
    const [originSectionPath, setOriginSectionPath] = useState();
    const originSectionDisplayName = PATH_TO_SECTION_DISPLAY_NAME[originSectionPath];

    const launchTour = () => {
        const tourId = sessionStorage.getItem('tourId');
        if (tourId) {
            window.Intercom('startTour', tourId);
            sessionStorage.removeItem('tourId');
        }
    };

    const toggleBackPanel = toggleOn => {
        setPanelShownClasses(BACK_PANEL_CONTAINER_CSS_CLASS, BACK_PANEL_SHOWN_CSS_CLASS, toggleOn);

        if (toggleOn) {
            launchTour();
        } else {
            removeShowBackPanel();
            setOriginSectionPath(null);
        }
    };

    useEffect(() => {
        // In case the user moves to another section, hide the back panel.
        if (originSectionPath) {
            toggleBackPanel(false);
            return;
        }

        const { showBackPanel, backPanelOriginPath } = getShowBackPanel();
        showBackPanel && toggleBackPanel(!document.location.href.includes(backPanelOriginPath));
        backPanelOriginPath && setOriginSectionPath(backPanelOriginPath);
    }, [currentPath]);

    const el = (
        <div
            className={css.goBackPanel}
            onClick={() => {
                const { backPanelOriginPath } = getShowBackPanel();
                document.location.hash = backPanelOriginPath;
                toggleBackPanel(false);
            }}
        >
            <div className={css.goBackPanelContent}>
                <Chevron className={css.arrowIcon} />
                {originSectionPath === HOMEPAGE_PATH && <HomeIcon className={css.homeIcon} />}
                {originSectionDisplayName && (
                    <Label
                        text={`STATIC.GO_BACK_PANEL.GO_BACK_TO_${snakeCase(originSectionDisplayName).toUpperCase()}`}
                        className={css.label}
                    />
                )}
            </div>
            <XIcon
                className={css.XIcon}
                onClick={event => {
                    event.stopPropagation();
                    toggleBackPanel(false);
                }}
            />
        </div>
    );

    return ReactDOM.createPortal(el, goBackContainer);
}

GoBackPanel.propTypes = {
    currentPath: PropTypes.shape({ pathname: PropTypes.string }),
    goBackContainer: PropTypes.instanceOf(Element).isRequired,
};

GoBackPanel.defaultProps = {
    currentPath: {},
};

export default withLocalize(GoBackPanel);
