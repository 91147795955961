import { CODE_BLOCK_SNIPPETS_LANGUAGES } from '../../../../../../components/organisms/CodeBlock/consts';

const ANDROID_MANIFEST_CODE_BLOCK_CONFIG = {
    codeLanguage: CODE_BLOCK_SNIPPETS_LANGUAGES.XML,
    customTitle: CODE_BLOCK_SNIPPETS_LANGUAGES.XML.toUpperCase(),
    showLineNumbers: true,
    ShowHideButtonVisibility: true,
    ShowHideButtonTitle: 'Keys',
    hidePlaceholders: true,
    showHeader: true,
};

const STEP_1_CODE_SNIPPET = `<uses-permission android:name="android.permission.INTERNET" />
<uses-permission android:name="android.permission.ACCESS_NETWORK_STATE" />
<uses-permission android:name="BIND_GET_INSTALL_REFERRER_SERVICE" />
<uses-permission android:name="com.android.vending.CHECK_LICENSE" />`;

const STEP_2_CODE_SNIPPET = `<uses-permission android:name="com.google.android.gms.permission.AD_ID" />`;

const STEP_3_CODE_SNIPPET_APP_LINKS = `<intent-filter android:autoVerify="true">
    <action android:name="android.intent.action.VIEW" />
    <category android:name="android.intent.category.DEFAULT" />
    <category android:name="android.intent.category.BROWSABLE" />
    <data android:scheme="http" />
    <data android:scheme="https" />
    <data android:host="example.sng.link" />
    <data android:pathPrefix="/A" />
    <data android:pathPrefix="/B" />
    <data android:pathPrefix="/E" />
    <data android:pathPrefix="/F" />
</intent-filter>`;

const STEP_3_CODE_SNIPPET_URI_SCHEME = `<activity> 
    <intent-filter>
        <data android:scheme="singular-example" />
        <action android:name="android.intent.action.VIEW" />
        <category android:name="android.intent.category.DEFAULT" />
        <category android:name="android.intent.category.BROWSABLE" />
    </intent-filter>
</activity>`;

export {
    STEP_1_CODE_SNIPPET,
    STEP_2_CODE_SNIPPET,
    STEP_3_CODE_SNIPPET_APP_LINKS,
    STEP_3_CODE_SNIPPET_URI_SCHEME,
    ANDROID_MANIFEST_CODE_BLOCK_CONFIG,
};
