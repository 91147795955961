import { gql } from '@apollo/client';

export const DELETE_AB_TEST = gql`
    mutation DeleteAudienceABTest($testId: Int!) {
        deleteAbTest(testId: $testId) {
            ok
            error
        }
    }
`;

export const SAVE_AB_TEST = gql`
    mutation SaveAudienceAbTest($abTest: ABTestInput!) {
        saveAbTest(abTestInput: $abTest) {
            ok
            error
        }
    }
`;
