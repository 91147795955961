import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { AutoCompleteField, Button } from '../../components/widgets';
import css from './TopArea.css';
import PermissionsWrapper from '../../containers/PermissionsWrapper';
import InnerTooltip from './InnerTooltip';

class TopArea extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this._handleClick.bind(this);
    }

    _handleClick(buttonId) {
        const { onClick } = this.props;
        onClick(buttonId);
    }

    render() {
        const { onAppFilterChanged, search, emptyState, searching, filterPlaceholder } = this.props;
        const filterDisabled = emptyState?.appsFilterDisabled;
        return (
            <div className={css.container}>
                <div className={css.leftSide}>
                    <AutoCompleteField
                        containerStyle={{ width: 388 }}
                        onInputChange={onAppFilterChanged}
                        onChange={onAppFilterChanged}
                        value={search}
                        placeholder={filterPlaceholder}
                        searchable
                        clearable={!(searching || filterDisabled)}
                        controlled
                        loading={searching}
                        isMulti={false}
                        disabled={filterDisabled}
                        debounceTime={500}
                    />
                </div>
                <div className={css.rightSide}>
                    <PermissionsWrapper write>
                        <InnerTooltip
                            text="STATIC.PAGES.MANAGE_LINKS.MANAGE_SUBDOMAINS_TOOLTIP"
                            popperOptions={{
                                modifiers: {
                                    preventOverflow: {
                                        enabled: true,
                                    },
                                    hide: {
                                        enabled: false,
                                    },
                                },
                            }}
                        >
                            <Button
                                type="secondary"
                                onClick={() => {
                                    this.handleClick('subdomains');
                                }}
                            >
                                <Translate id="STATIC.PAGES.MANAGE_LINKS.MANAGE_SUBDOMAINS" />
                            </Button>
                        </InnerTooltip>
                    </PermissionsWrapper>
                </div>
            </div>
        );
    }
}

TopArea.propTypes = {
    onAppFilterChanged: PropTypes.func,
    search: PropTypes.string,
    searching: PropTypes.bool,
    onClick: PropTypes.func,
    emptyState: PropTypes.objectOf(PropTypes.any),
    filterPlaceholder: PropTypes.string,
};

TopArea.defaultProps = {
    onAppFilterChanged: () => {},
    search: '',
    searching: false,
    onClick: () => {},
    emptyState: {},
    filterPlaceholder: 'STATIC.PAGES.MANAGE_LINKS.APP_SEARCH_PLACEHOLDER',
};

export default TopArea;
