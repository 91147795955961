import React, { Fragment, useEffect, useState, useCallback, useMemo } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { GeneralPopup, PageWrapper, PopupTypes } from '../components/partials';
import Spinner from '../components/widgets/Spinner';
import Label from '../components/foundations/Label';
import css from './style.css';
import CompleteTasksIcon from '../resources/svg/icons/tasks.svg';
import OnboardingGuideSection from './components/onboardingGuideSection';
import CheckedIcon from '../resources/svg/checked_with_circle_green.svg';
import UnCheckedIcon from '../resources/svg/unchecked_gray400.svg';
import {
    getDefaultActiveItem,
    shouldDisplayItem,
    getFirstItemIdInCategory,
    setLastVisitedMenuItem,
    trackTaskClick,
    trackTaskButtonClick,
    trackCategoryClick,
    trackMarkAsDoneClick,
    getLastVisitedInnerItemIndex,
    setLastVisitedInnerItemIndex,
    setLastVisitedPageParams,
    showOnboardingProgressBar,
    DEBUGGING_TOOLS,
    ADVANCED_SETUP,
    UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL,
    SDK_CONFIGURATION,
} from '../homepage/utils';
import { getOnboardingProgressData } from '../homepage/selector';
import DebuggingIcon from '../resources/svg/icons/Debugging.svg';
import AdvancedSetUpIcon from '../resources/svg/icons/Advanced.svg';
import OnboardingProgressBar from './components/OnboardingProgressBar';
import { setHomepageData } from '../homepage/actions';
import { useGetHomepageData } from './hooks';
import { setShowBackPanel } from '../topPanels/utils';

const MIXPANEL_TOUR_CLICKED_NAME = 'TOUR';
const LAYOUT_DIV_TOP_OFFSET_PX = 50;

function OnboardingGuide(props) {
    const { menuItemsOrder, menuItemsConfig, error, translate, apsalarEnabled, saveCompletedTask, userData } = props;
    const dispatch = useDispatch();
    const { isLoading, data: homepageData } = useGetHomepageData();
    const lastVisitedInnerPageIdx = getLastVisitedInnerItemIndex() || 0;
    const { defaultActiveItemId, defaultActiveCategoryId } = getDefaultActiveItem(
        menuItemsOrder,
        menuItemsConfig,
        apsalarEnabled
    );
    const onboardingProgressBarData = useSelector(state => getOnboardingProgressData(state));
    const [activeInnerPageIdx, setActiveInnerPageIdx] = useState(lastVisitedInnerPageIdx);
    const [activeItemId, setActiveItemId] = useState(defaultActiveItemId);
    const [activeCategoryId, setActiveCategoryId] = useState(defaultActiveCategoryId);
    const [showMarkAsDonePopup, setShowMarkAsDonePopup] = useState(false);
    const [isSDKConfigStepCompleted, setIsSDKConfigStepCompleted] = useState(false);
    const { isLastCategory, isLastItemInCategory, activeItemIndex, activeCategoryIndex } = useMemo(() => {
        const _activeCategoryIndex = menuItemsOrder.findIndex(section => section.categoryId === activeCategoryId);

        if (!menuItemsOrder[_activeCategoryIndex]) {
            return {
                isLastCategory: false,
                isLastItemInCategory: false,
                activeItemIndex: -1,
                activeCategoryIndex: -1,
            };
        }

        const _activeItemIndex = menuItemsOrder[_activeCategoryIndex].items.indexOf(activeItemId);
        return {
            isLastCategory: _activeCategoryIndex === menuItemsOrder.length - 1,
            isLastItemInCategory: _activeItemIndex === menuItemsOrder[_activeCategoryIndex].items.length - 1,
            activeItemIndex: _activeItemIndex,
            activeCategoryIndex: _activeCategoryIndex,
        };
    }, [menuItemsOrder, activeCategoryId, activeItemId]);

    useEffect(() => {
        if (homepageData) {
            dispatch(setHomepageData(homepageData));
        }
    }, [homepageData]);

    useEffect(() => {
        setActiveItemId(defaultActiveItemId);
    }, [defaultActiveItemId]);

    useEffect(() => {
        setActiveCategoryId(defaultActiveCategoryId);
    }, [defaultActiveCategoryId]);

    useEffect(() => {
        const sdkConfigurationStep = menuItemsConfig[SDK_CONFIGURATION];
        setIsSDKConfigStepCompleted(sdkConfigurationStep.hidden || sdkConfigurationStep.completed);
    }, [menuItemsConfig]);

    const pageStartCallbackRef = node => {
        // if the page start ref is needed, initialize it here
        node !== null && node.getBoundingClientRect().top !== LAYOUT_DIV_TOP_OFFSET_PX && node.scrollIntoView();
    };

    const setLastVisitedMetadata = useCallback(
        menuItemId => {
            setLastVisitedMenuItem(menuItemId);
            setLastVisitedInnerItemIndex(0);
            setLastVisitedPageParams(apsalarEnabled);
        },
        [apsalarEnabled]
    );

    const handleItemSelected = useCallback(
        id => {
            if (id in menuItemsConfig) {
                setActiveInnerPageIdx(0);
                setActiveItemId(id);
                setLastVisitedMetadata(id);
            }
        },
        [setActiveInnerPageIdx, setActiveItemId, setLastVisitedMetadata, menuItemsConfig]
    );

    const handleCategoryClick = useCallback(
        id => {
            const newActiveCategoryId = activeCategoryId !== id ? id : -1;
            const newActiveItemId =
                activeCategoryId !== id ? getFirstItemIdInCategory(menuItemsOrder, menuItemsConfig, id) : -1;
            trackCategoryClick(activeCategoryId, newActiveCategoryId);
            setActiveCategoryId(newActiveCategoryId);
            handleItemSelected(newActiveItemId);
            return newActiveItemId;
        },
        [activeCategoryId, menuItemsConfig, menuItemsOrder]
    );

    const onMarkAsDonePopupAccept = () => {
        saveCompletedTask(activeItemId, userData.is_impersonator);
        setShowMarkAsDonePopup(false);
        trackMarkAsDoneClick(activeItemId);
    };

    const handleMarkAsDoneClick = ({ skipConfirmationPopup }) => {
        if (skipConfirmationPopup) {
            onMarkAsDonePopupAccept();
        } else {
            setShowMarkAsDonePopup(true);
        }
    };

    function getNextItemInCategory(categoryIndex, itemIndex) {
        for (let i = itemIndex + 1; i < menuItemsOrder[categoryIndex].items.length; i++) {
            const currItemId = menuItemsOrder[categoryIndex].items[i];
            if (!menuItemsConfig[currItemId].isItemsHeader && shouldDisplayItem(menuItemsConfig[currItemId])) {
                return menuItemsOrder[categoryIndex].items[i];
            }
        }
        // fail cleanly
        return activeItemIndex;
    }

    const handleItemClick = useCallback(
        id => {
            trackTaskClick(activeItemId, id, activeCategoryId, UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL);
            if (!menuItemsConfig[id].isItemsHeader) {
                handleItemSelected(id);
            }
        },
        [activeItemId, activeCategoryId, menuItemsConfig, handleItemSelected]
    );

    function handleTourButtonClicked(activeContentId) {
        setShowBackPanel();
        const buttonToUse =
            menuItemsConfig[activeContentId].onboardingGuideButtonOnClick ||
            menuItemsConfig[activeContentId].buttonOnClick;
        buttonToUse();
    }

    const handleNextButtonClick = () => {
        const { onboardingGuideSubSections } = menuItemsConfig[activeItemId];
        const activeContentId = onboardingGuideSubSections
            ? onboardingGuideSubSections[activeInnerPageIdx]
            : activeItemId;

        let newTaskId = null;

        // Tour
        if (menuItemsConfig[activeContentId].onboardingGuideShowTour) {
            newTaskId = MIXPANEL_TOUR_CLICKED_NAME;
            handleTourButtonClicked(activeContentId);
        }

        // Item Id
        else if (!isLastItemInCategory) {
            const nextItemId = getNextItemInCategory(activeCategoryIndex, activeItemIndex);
            newTaskId = nextItemId;
            handleItemSelected(nextItemId);
        }

        // Category Id
        else if (!isLastCategory) {
            newTaskId = handleCategoryClick(menuItemsOrder[activeCategoryIndex + 1].categoryId);
        } else {
            // close the last category when we're done.
            newTaskId = handleCategoryClick(activeCategoryId);
        }
        trackTaskButtonClick(
            activeContentId,
            newTaskId,
            menuItemsConfig[activeContentId].onboardingGuideCategory,
            UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL
        );
    };

    const getIconByItemId = itemId => {
        if (itemId === DEBUGGING_TOOLS) {
            return <DebuggingIcon className={css.onboardingIcon} />;
        } else if (itemId === ADVANCED_SETUP) {
            return <AdvancedSetUpIcon className={css.onboardingIcon} />;
        }
        return null;
    };

    const shouldHideStep = itemId => {
        return itemId !== SDK_CONFIGURATION && !isSDKConfigStepCompleted;
    };

    return (
        <PageWrapper>
            <Spinner show={isLoading} />
            {!isLoading && !error && (
                <div className={css.onboardingGuideLayout} ref={pageStartCallbackRef}>
                    {isSDKConfigStepCompleted && (
                        <div className={css.onboardingGuidePageHeader}>
                            <div className={css.onboardingGuideMainHeader}>
                                <Translate id="STATIC.PAGES.ONBOARDING_GUIDE.PAGE_HEADER" />
                            </div>
                            <div className={css.onboardingGuideSubHeaderContainer}>
                                <div className={css.onboardingGuideSubHeader}>
                                    <CompleteTasksIcon className={css.onboardingIcon} />
                                    <Translate id="STATIC.PAGES.ONBOARDING_GUIDE.PAGE_SUB_HEADER" />
                                </div>
                                {showOnboardingProgressBar(onboardingProgressBarData, activeItemId) && (
                                    <div className={css.progressBarContainer}>
                                        <div className={css.progressBarText}>
                                            {`${onboardingProgressBarData.doneTasks}/${
                                                onboardingProgressBarData.totalTasks
                                            } 
                                            ${translate('STATIC.PAGES.SIDEBAR_PROGRESS_BAR.NEXT_STEP')}:
                                            ${translate(
                                                `STATIC.PAGES.SIDEBAR_PROGRESS_BAR.${onboardingProgressBarData.nextTask}`
                                            )}`}
                                        </div>
                                        <OnboardingProgressBar
                                            donePercentage={onboardingProgressBarData.donePercentage}
                                            barWidth="330px"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <List component="nav" className={css.onboardingGuideMenuList}>
                        {menuItemsOrder.map(section => {
                            const {
                                categoryId,
                                items,
                                showCompletedTaskCheckbox: showCompletedTaskCheckboxForCategory,
                            } = section;
                            const activeSection = activeCategoryId === categoryId;
                            let itemsCount = 0;
                            return (
                                <Fragment key={`fragment_${categoryId}`}>
                                    <div className={css.sectionContainer}>
                                        {/*
                                            CONSIDER USING <ListItemsContainer /> COMPONENT MIGHT NEED TO EXTEND IT
                                            A BIT TO SUPPORT THE MENU TITLE (S.T `Debugging Tools:`) BUT IT ALREADY
                                            SUPPORTS MOST OF THE UI HERE
                                         */}
                                        <List component="div" disablePadding className={css.sectionList}>
                                            {items
                                                .filter(itemId => shouldDisplayItem(menuItemsConfig[itemId]))
                                                .map((itemId, index) => {
                                                    const label = translate(
                                                        `STATIC.PAGES.ONBOARDING_GUIDE.MENU_ITEMS.${itemId}`
                                                    );
                                                    const {
                                                        badgeText,
                                                        completed,
                                                        isItemsHeader,
                                                        shouldNotShowCheckbox: shouldNotShowCheckboxForItem,
                                                        numberedTask,
                                                    } = menuItemsConfig[itemId];

                                                    if (numberedTask) {
                                                        itemsCount++;
                                                    }
                                                    const labelListItem = (
                                                        <Fragment>
                                                            {isItemsHeader && getIconByItemId(itemId)}
                                                            {numberedTask && (
                                                                <span className={css.sectionNumber}>{itemsCount}</span>
                                                            )}
                                                            <Label
                                                                text={label}
                                                                className={classNames(css.listItemText, {
                                                                    [css.listItemTextHeader]: isItemsHeader,
                                                                    [css.listItemTextSelectedWithNumber]:
                                                                        numberedTask && activeItemId === itemId,
                                                                    [css.listItemTextSelected]:
                                                                        activeItemId === itemId && !numberedTask,
                                                                })}
                                                            />
                                                        </Fragment>
                                                    );
                                                    const hiddenStep = shouldHideStep(itemId);
                                                    return (
                                                        <ListItem
                                                            key={`${categoryId}_${itemId}`}
                                                            disabled={hiddenStep}
                                                            className={classNames(css.listItem, {
                                                                [css.listItemHeader]: isItemsHeader,
                                                                [css.selectedListItem]: activeItemId === itemId,
                                                                [css.hide]: hiddenStep,
                                                                [css.show]: !hiddenStep,
                                                            })}
                                                            onClick={() => {
                                                                !hiddenStep && handleItemClick(itemId);
                                                            }}
                                                            style={
                                                                hiddenStep
                                                                    ? null
                                                                    : {
                                                                          transitionProperty: 'opacity',
                                                                          transitionDelay: `${index * 100}ms`,
                                                                      }
                                                            }
                                                        >
                                                            {isItemsHeader && (
                                                                <div className={css.listItemHeaderInternalDiv}>
                                                                    {labelListItem}
                                                                </div>
                                                            )}
                                                            {!isItemsHeader && labelListItem}
                                                            <span className={css.listItemSuffix}>
                                                                {badgeText && (
                                                                    <span className={css.badgeLayout}>
                                                                        <Label
                                                                            className={css.badgeText}
                                                                            text={badgeText}
                                                                        >
                                                                            {badgeText}
                                                                        </Label>
                                                                    </span>
                                                                )}
                                                                {showCompletedTaskCheckboxForCategory &&
                                                                    !shouldNotShowCheckboxForItem &&
                                                                    (badgeText || completed ? (
                                                                        <CheckedIcon />
                                                                    ) : (
                                                                        <UnCheckedIcon />
                                                                    ))}
                                                            </span>
                                                        </ListItem>
                                                    );
                                                })}
                                        </List>
                                        {activeItemId !== -1 && activeSection && (
                                            <div className={css.sectionContent}>
                                                <OnboardingGuideSection
                                                    {...props}
                                                    itemId={activeItemId}
                                                    activeInnerPageIdx={activeInnerPageIdx}
                                                    onNextButtonClick={handleNextButtonClick}
                                                    onMarkAsDoneClick={handleMarkAsDoneClick}
                                                    isLastItem={isLastItemInCategory && isLastCategory}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Fragment>
                            );
                        })}
                    </List>
                </div>
            )}
            <GeneralPopup
                open={showMarkAsDonePopup}
                text={translate('STATIC.PAGES.ONBOARDING_GUIDE.POPUP.MARK_AS_DONE.TEXT')}
                acceptText={translate('STATIC.PAGES.ONBOARDING_GUIDE.POPUP.MARK_AS_DONE.ACCEPT_TEXT')}
                onAccept={() => onMarkAsDonePopupAccept()}
                onReject={() => setShowMarkAsDonePopup(false)}
                type={PopupTypes.INFO_WITH_CANCEL}
            />
        </PageWrapper>
    );
}

OnboardingGuide.propTypes = {
    menuItemsOrder: PropTypes.arrayOf(PropTypes.object).isRequired,
    menuItemsConfig: PropTypes.objectOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    translate: PropTypes.func.isRequired,
    apiKey: PropTypes.string,
    sdkKey: PropTypes.string,
    apsalarEnabled: PropTypes.bool.isRequired,
    saveCompletedTask: PropTypes.func.isRequired,
    userData: PropTypes.objectOf(PropTypes.any).isRequired,
};

OnboardingGuide.defaultProps = {
    apiKey: '',
    sdkKey: '',
};

export default withLocalize(OnboardingGuide);
