import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import Label from '../../../../components/foundations/Label';
import tableCss from '../../modelTypeForms/RevenueConversionModelForm.css';
import Table from '../../../../components/organisms/Table';
import shelfCss from '../../ConversionModelShelf.css';
import customTableCss from '../CustomTable.css';
import { getTableData, getTableMetadata } from './CoarseTable';
import { conversionModelTypeShape, modelErrorsShape, sdkEventsShape } from '../types';

function CoarseType({ values, onChange, sdkEvents, errors }) {
    const postbackIndex = values.postbackIndex + 1;
    const { tableGeneralError } = errors;

    return (
        <div className={classNames(shelfCss.page)}>
            <Label
                text={`STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.POSTBACK_${postbackIndex}`}
                className={shelfCss.shelfHeaderTitle}
            />
            <Translate
                id={`STATIC.PAGES.SKADNETWORK.SHELF_P${postbackIndex}_HEADER_TEXT`}
                className={shelfCss.shelfInfoBoxText}
            />
            <div className={classNames(tableCss.revenueTableWrapperOuter, customTableCss.customTableWrapperOuter)}>
                <div className={classNames(tableCss.revenueTableWrapperInner, customTableCss.customTableWrapperInner)}>
                    <Table
                        metadata={getTableMetadata(values, onChange, sdkEvents)}
                        data={getTableData(values, errors)}
                        rowHoverEnabled={false}
                    />
                </div>
            </div>
            {tableGeneralError && (
                <div className={shelfCss.modelError}>
                    <Translate id={tableGeneralError.errorMsg} />
                </div>
            )}
        </div>
    );
}

CoarseType.propTypes = {
    values: conversionModelTypeShape.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: modelErrorsShape.isRequired,
    sdkEvents: PropTypes.objectOf(sdkEventsShape),
};

CoarseType.defaultProps = {
    sdkEvents: {},
};

export default CoarseType;
