import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import css from './SingularLinksDomains.css';
import {
    Label,
    TextField,
    Dropdown,
    Button,
    QuestionMark,
    WarningMessage,
    WizardFooter,
} from '../../../components/widgets';
import SubDomainItem from '../SubDomainItem';
import { GeneralPopup, PopupTypes } from '../../../components/partials';
import WizardWarningMessage from '../../../teamManagement/components/WizardWarningMessage';

// Zendesk 76315 - added validations
// https://stackoverflow.com/questions/59392909/javascript-regular-expression-for-domain-validation-doesnt-work-in-safari
const SUBDOMAIN_REGEX_VALIDATION = /^(?![^-\n]*(?:-(?!\.)[^-\n]*)*-\.)(?:(?!-)[A-Za-z0-9-]{1,63})$/;

class SingularLinksDomains extends React.PureComponent {
    constructor(props) {
        super(props);
        this.buttons = {
            cancel: {
                text: 'Cancel',
                disabled: false,
                type: 'flat',
            },
            finish: {
                text: 'Done',
                type: 'primary',
            },
        };
        this.state = {
            archiveDomainData: null,
            subDomainValue: '',
            dnsZone: props.dnsZones && props.dnsZones.length ? props.dnsZones[0] : null,
        };
        this.handleInputChanged = this._handleInputChanged.bind(this);
        this.handleCreateClicked = this._handleCreateClicked.bind(this);
        this.handleDnsZoneSelected = this._handleDnsZoneSelected.bind(this);
        this.buttonDefs = {
            cancel: {
                text: <Translate id="STATIC.BUTTONS.CANCEL" />,
                disabled: false,
                type: 'flat',
                onClick: props.onCloseShelf,
            },
            done: {
                text: <Translate id="STATIC.BUTTONS.DONE" />,
                type: 'primary',
                onClick: props.onCloseShelf,
                onDirty: 'update',
                disabled: false,
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.dnsZones && nextProps.dnsZones.length && (!this.props.dnsZones || !this.props.dnsZones.length)) {
            this.setState({
                dnsZone: nextProps.dnsZones[0],
            });
        }
    }

    _handleInputChanged(value) {
        this.setState({
            subDomainValue: value,
        });
    }

    _handleDnsZoneSelected(value) {
        this.setState({
            dnsZone: value,
        });
    }

    _handleCreateClicked() {
        const { subDomainValue, dnsZone } = this.state;
        const { onCreateClicked } = this.props;
        if (this._getCreateDisabled()) {
            return;
        }
        onCreateClicked(subDomainValue, dnsZone.name);
    }

    _getCreateDisabled() {
        const { subDomainValue, dnsZone } = this.state;
        const {
            shelf: { working },
        } = this.props;
        return working || !dnsZone || !subDomainValue;
    }

    _isSubDomainValid() {
        const { subDomainValue } = this.state;
        return SUBDOMAIN_REGEX_VALIDATION.test(subDomainValue);
    }

    handleArchiveDomain = (subDomainValue, dnsZone) => {
        this.setState({ archiveDomainData: { subDomainValue, dnsZone } });
    };

    handleArchiveDomainAccept = () => {
        const { onArchiveClicked } = this.props;
        const {
            archiveDomainData: { subDomainValue, dnsZone },
        } = this.state;

        onArchiveClicked(subDomainValue, dnsZone);

        this.setState({ archiveDomainData: null });
    };

    handleArchiveDomainReject = () => this.setState({ archiveDomainData: null });

    render() {
        const { shelf, subDomains, dnsZones, domainsLimit, onFavouriteClicked, isAgency, onCloseShelf } = this.props;
        const { working, error, message } = shelf;
        const { subDomainValue, dnsZone, archiveDomainData } = this.state;
        const createDisabled = this._getCreateDisabled();
        return (
            <>
                <WizardWarningMessage show={!!message} showIcon={false} message={message} type={error ? 'error' : 'success'} />
                <div className={css.content}>
                    {!isAgency ? (
                        <>
                            <Label type="shelfHeader" text="STATIC.PAGES.MANAGE_LINKS.SUBDOMAIN_SHELF_TITLE" />
                            <Label type="sideLabel" text="STATIC.PAGES.MANAGE_LINKS.SUBDOMAIN_SHELF_SUB_TITLE" />
                            <div className={css.subDomainInputContainer}>
                                <TextField
                                    value={subDomainValue}
                                    placeholder="Enter sub-domain"
                                    containerClass={css.subDomainInput}
                                    onChange={this.handleInputChanged}
                                    disabled={working}
                                    error={
                                        !subDomainValue || this._isSubDomainValid()
                                            ? null
                                            : 'STATIC.PAGES.MANAGE_LINKS.SUBDOMAIN_SHELF_SUB_ERROR'
                                    }
                                />
                                <Dropdown
                                    items={dnsZones}
                                    selected={dnsZone}
                                    containerClass={css.dnsZones}
                                    onSelection={this.handleDnsZoneSelected}
                                    disabled={working}
                                />
                                <QuestionMark
                                    contentStyle={{ textAlign: 'left' }}
                                    message="STATIC.PAGES.MANAGE_LINKS.SUBDOMAIN_SHELF_INFO"
                                />
                                <Button
                                    disabled={
                                        createDisabled ||
                                        domainsLimit === subDomains.length ||
                                        !this._isSubDomainValid()
                                    }
                                    onClick={this.handleCreateClicked}
                                    type="secondary"
                                    className={css.addButton}
                                >
                                    <Translate id="STATIC.PAGES.MANAGE_LINKS.SUBDOMAIN_SHELF_CREATE" />
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Label type="shelfHeader" text="STATIC.PAGES.MANAGE_LINKS.SUBDOMAIN_SHELF_TITLE_FOR_AGENCIES" />
                    )}
                    <Label
                        type="sideLabel"
                        text="STATIC.PAGES.MANAGE_LINKS.SUBDOMAIN_SHELF_ADD"
                        textReplace={{ subDomainsCount: subDomains.length, limit: domainsLimit }}
                    />
                    {subDomains.length >= domainsLimit ? (
                        <div className={css.warningMessage}>
                            <WarningMessage
                                message="en.STATIC.PAGES.MANAGE_LINKS.SUBDOMAIN_SHELF_LIMIT_REACHED_WARNING"
                                type="warning"
                            />
                        </div>
                    ) : null}
                    {subDomains.map(subDomain => (
                        <div key={subDomain.subdomain} className={css.subDomainItemHolder}>
                            <SubDomainItem
                                {...subDomain}
                                onArchiveClicked={this.handleArchiveDomain}
                                onFavouriteClicked={onFavouriteClicked}
                                isAgency={isAgency}
                            />
                        </div>
                    ))}
                </div>
                <GeneralPopup
                    open={!!archiveDomainData}
                    type={PopupTypes.WARNING_WITH_CANCEL}
                    title="STATIC.PAGES.VIEW_DOMAINS.DELETE_WARNING"
                    acceptText="STATIC.PAGES.VIEW_DOMAINS.ARCHIVE_YES"
                    onAccept={this.handleArchiveDomainAccept}
                    onReject={this.handleArchiveDomainReject}
                />
                <WizardFooter
                    buttons={[
                        <Button onClick={onCloseShelf} onDirty="update">
                            <Translate id="STATIC.BUTTONS.DONE" />
                        </Button>,
                    ]}
                />
            </>
        );
    }
}

SingularLinksDomains.propTypes = {
    subDomains: PropTypes.arrayOf(PropTypes.any),
    dnsZones: PropTypes.arrayOf(PropTypes.any),
    onCreateClicked: PropTypes.func,
    shelf: PropTypes.objectOf(PropTypes.any),
    onFavouriteClicked: PropTypes.func,
    onArchiveClicked: PropTypes.func,
    onCloseShelf: PropTypes.func,
    domainsLimit: PropTypes.number,
    isAgency: PropTypes.bool,
};

SingularLinksDomains.defaultProps = {
    subDomains: [],
    dnsZones: [],
    onCreateClicked: () => {},
    shelf: { working: false, error: '' },
    onFavouriteClicked: () => {},
    onArchiveClicked: () => {},
    onCloseShelf: () => {},
    domainsLimit: 50,
    isAgency: false,
};

export default SingularLinksDomains;
