import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { useFormikContext } from 'formik-2';
import WizardLayout from './WizardLayout';
import StepButtons from './StepButtons';
import css from './TestChannels.css';
import { TEST_CHANNELS_LIMIT, EMPTY_TEST_CHANNEL, DISTRIBUTION_CHANNEL_INIT_VALUE } from '../consts';
import Tooltip from '../../../../components/widgets/Tooltip';
import CollapsableNetwork from './components/CollapsableNetwork';
import useWizard from '../hook/useWizard';

const translatePrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTION_COMPONENTS.TEST_CHANNELS';
export const WIZARD_DATA_TEST_ID_TEST_CHANNELS = 'test-channels-wizard';

const getDistributionId = (distributionData, testChannelName) =>
    distributionData?.[testChannelName]?.distribution?.id ?? null;

const getNetworkId = (distributionData, networkName) => distributionData?.[networkName]?.id ?? null;

const TestChannels = ({ onSaveDraft, pageTitle, onBack, onNext, distributionsData }) => {
    const { setFieldValue, values } = useFormikContext();
    const { shouldShowDraft, isAlreadyStarted } = useWizard(values);
    const [expanded, setExpanded] = useState(Array(values.testChannels.length).fill(values.testChannels.length === 1));

    const onNetworkChange = async (network, rowIndex) => {
        const value =
            values.testChannels.length > 1 ? DISTRIBUTION_CHANNEL_INIT_VALUE : DISTRIBUTION_CHANNEL_INIT_VALUE * 2;
        setExpanded([...expanded, true]);
        setFieldValue(`testChannels.${rowIndex}`, {
            name: network.name,
            uanId: getDistributionId(distributionsData.distributionNetworks, network.name),
            adnetworkIntegrationId: getNetworkId(distributionsData.distributionNetworks, network.name),
            value,
        });
    };

    const onDistributionFieldChange = async (rowIndex, fieldName, value) => {
        setFieldValue(`testChannels.${rowIndex}.config.${fieldName}`, value);
    };

    const onAdd = () => {
        if (TEST_CHANNELS_LIMIT === values.testChannels.length) return;
        setExpanded([...expanded, true]);
        setFieldValue(`testChannels.${values.testChannels.length - 1}.value`, DISTRIBUTION_CHANNEL_INIT_VALUE);
        setFieldValue(`testChannels.${values.testChannels.length}`, {
            ...EMPTY_TEST_CHANNEL,
            value: DISTRIBUTION_CHANNEL_INIT_VALUE,
        });
    };

    const onDelete = index => {
        if (values.testChannels.length <= 1) return;
        const newArray = values.testChannels.filter((_, i) => index !== i);
        newArray[0] = {
            ...newArray[0],
            value: 45,
        };
        setFieldValue(`testChannels`, newArray);
    };

    const onCollapseToggle = index => {
        setExpanded(prev => {
            const newExpanded = [...prev];
            newExpanded[index] = !prev[index];
            return newExpanded;
        });
    };

    return (
        <WizardLayout
            title={pageTitle}
            isContentScrollable={false}
            onSaveDraft={onSaveDraft}
            shouldShowDraft={shouldShowDraft}
            buttons={
                <StepButtons onBack={onBack} onNext={onNext} backTitle="BUTTONS.BACK" nextTitle="STATIC.BUTTONS.NEXT" />
            }
        >
            <div data-testid={WIZARD_DATA_TEST_ID_TEST_CHANNELS}>
                <Translate id={`${translatePrefix}.SUBTITLE`} />
                {values.testChannels.map((channel, rowIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={channel.value + rowIndex} className={css.container}>
                        <CollapsableNetwork
                            allNetworks={(distributionsData?.allNetworks || []).filter(
                                e => !values.testChannels.map(ch => ch.name).includes(e.value)
                            )}
                            key={values.testChannels.length + channel.name}
                            testChannel={channel}
                            expanded={expanded[rowIndex]}
                            disabled={isAlreadyStarted}
                            distributionNetworks={distributionsData?.distributionNetworks || {}}
                            onDistributionFieldChange={(field, val) => onDistributionFieldChange(rowIndex, field, val)}
                            onNetworkChange={network => onNetworkChange(network, rowIndex)}
                            onCollapseToggle={() => onCollapseToggle(rowIndex)}
                            onDelete={() => onDelete(rowIndex)}
                            showDelete={values.testChannels.length > 1 || rowIndex > 0}
                            rowIndex={rowIndex}
                        />
                    </div>
                ))}
                {!isAlreadyStarted && (
                    <div
                        onClick={onAdd}
                        className={`${css.addNetworkButton} ${
                            values.testChannels.length >= TEST_CHANNELS_LIMIT ? css.disabled : ''
                        }`}
                    >
                        <Tooltip
                            titleTranslationKey={`${translatePrefix}.ADD_NETWORK_DISABLE_TOOLTIP`}
                            disabled={values.testChannels.length < TEST_CHANNELS_LIMIT}
                        >
                            <Translate id={`${translatePrefix}.ADD_NETWORK`} />
                        </Tooltip>
                    </div>
                )}
            </div>
        </WizardLayout>
    );
};

TestChannels.propTypes = {
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onSaveDraft: PropTypes.func,
    pageTitle: PropTypes.string,
    distributionsData: PropTypes.shape({
        allNetworks: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                name: PropTypes.string,
                isDisabled: PropTypes.bool,
            })
        ),
        internalDistributions: PropTypes.any,
        distributionNetworks: PropTypes.any,
    }),
};

export default TestChannels;
