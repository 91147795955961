import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import Table from '../../../../components/organisms/Table';
import { getTableData, getTableMetadata } from './FunnelTable';
import revenueModelCss from '../../modelTypeForms/RevenueConversionModelForm.css';
import customTableCss from '../CustomTable.css';
import modelShelfCss from '../../ConversionModelShelf.css';
import { ModelTypes, NUMBER_OF_BITS } from '../../../consts';
import { conversionModelTypeShape, modelErrorsShape } from '../types';
import { useScrollingIntoView } from '../../../../utils/customHooks';
import MaxSlotsIndicator from '../../conversionModelForm/MaxSlotsIndicator';
import { calculateUsedFunnelSlots } from '../../../utils';

function FunnelType({ values, sdkEvents, onChange, errors, shelfElement, multiCoarseMapping, maxSlots, isMultiStep }) {
    const { apsalarEventIds = [], buckets = [], revenueType } = values;

    const maxConversionValue = Math.pow(2, maxSlots);

    useEffect(() => {
        const isRevenueModel = !!buckets.length;

        onChange({
            apsalarEventIds,
            buckets,
            usedSlots: calculateUsedFunnelSlots(apsalarEventIds, buckets),
            revenueType: isRevenueModel ? revenueType : null,
            modelType: isRevenueModel ? ModelTypes.CUSTOM : ModelTypes.EVENTS,
        });
    }, [apsalarEventIds, buckets]);

    const { tableGeneralError, sdkEvents: sdkEventsError } = errors;
    const error = tableGeneralError?.errorMsg || sdkEventsError?.errorMsg;

    const scrollingRef = useRef();
    useScrollingIntoView([tableGeneralError, sdkEventsError], scrollingRef);

    return (
        <>
            <MaxSlotsIndicator label="STATIC.PAGES.SKADNETWORK.MAX_VALUES" slots={maxConversionValue} />
            <div
                className={classNames(revenueModelCss.revenueTableWrapperOuter, customTableCss.customTableWrapperOuter)}
            >
                <div
                    className={classNames(
                        revenueModelCss.revenueTableWrapperInner,
                        customTableCss.customTableWrapperInner
                    )}
                >
                    <Table
                        metadata={getTableMetadata(
                            values,
                            onChange,
                            sdkEvents,
                            shelfElement,
                            multiCoarseMapping,
                            maxConversionValue,
                            isMultiStep
                        )}
                        data={getTableData(values, errors, multiCoarseMapping, isMultiStep)}
                        rowHoverEnabled={false}
                    />
                </div>
            </div>
            {error && (
                <div className={modelShelfCss.modelError} ref={scrollingRef}>
                    <Translate id={error} />
                </div>
            )}
        </>
    );
}

FunnelType.propTypes = {
    values: conversionModelTypeShape.isRequired,
    sdkEvents: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            isRevenue: PropTypes.bool,
            longname: PropTypes.string,
            displayName: PropTypes.string,
        })
    ).isRequired,
    errors: modelErrorsShape,
    onChange: PropTypes.func.isRequired,
    shelfElement: PropTypes.element,
    multiCoarseMapping: PropTypes.bool.isRequired,
    maxSlots: PropTypes.number,
    isMultiStep: PropTypes.bool,
};

FunnelType.defaultProps = {
    errors: {},
    shelfElement: null,
    maxSlots: NUMBER_OF_BITS,
    isMultiStep: false,
};

export default FunnelType;
