import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PageHeader, PageWrapper, PopupTypes } from '../components/partials';
import TopArea from './components/TopArea';
import { FAQ_LINKS } from './consts';
import ABTestingTable from './components/ABTestingTable';
import { GET_AB_TESTING_METADATA } from './queries';
import { DELETE_AB_TEST } from './mutations';
import GeneralPopup from '../components/organisms/GeneralPopup';
import WizardWrapper from './components/Wizard/WizardWrapper';

export default function ABTesting() {
    const [wizardOpen, setWizardOpen] = useState(false);
    const [deletedTestId, setDeletedTestId] = useState(null);
    const [testId, setTestId] = useState(null);
    const getTableTests = useQuery(GET_AB_TESTING_METADATA, {
        fetchPolicy: 'network-only',
    });

    const [deleteAbTest, deleteAbTestResponse] = useMutation(DELETE_AB_TEST, {
        onCompleted: () => {
            getTableTests.refetch();
            deleteAbTestResponse.reset();
            setDeletedTestId(null);
        },
        onError: () => {
            deleteAbTestResponse.reset();
            setDeletedTestId(null);
        },
    });

    const onDeleteConfirm = async () => {
        await deleteAbTest({ variables: { testId: deletedTestId } });
    };

    const onEditClicked = async test => {
        setTestId(test.id);
        setWizardOpen(true);
    };

    const onDeleteClicked = test => {
        setDeletedTestId(test.id);
    };

    const onCreateNew = () => {
        setTestId(null);
        setWizardOpen(true);
    };

    const onClose = () => {
        setWizardOpen(false);
    };

    return !wizardOpen ? (
        <>
            <PageWrapper>
                <PageHeader subText={FAQ_LINKS} text="STATIC.PAGE_HEADERS.AUDIENCE_AB_TESTING" />
                <TopArea onCreateNew={onCreateNew} />
                <ABTestingTable onEdit={onEditClicked} onDelete={onDeleteClicked} />
            </PageWrapper>
            <GeneralPopup
                open={!!deletedTestId}
                type={PopupTypes.DELETE}
                title="STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.DELETE_WARNING"
                acceptText="STATIC.BUTTONS.DELETE"
                onAccept={onDeleteConfirm}
                onReject={() => setDeletedTestId(null)}
                loading={deleteAbTestResponse.loading}
            />
        </>
    ) : (
        <WizardWrapper refetchTable={getTableTests.refetch} onClose={onClose} testId={testId} />
    );
}
