import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import css from './TagInputSuggestion.css';
import OverflowingText from '../foundations/OverflowingText';
import EditIcon from '../../resources/svg/edit.svg';
import TrashIcon from '../../resources/svg/trashInheritColor.svg';

class TagInputSuggestion extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {
            isFocused,
            isSelected,
            isDisabled,
            selectProps: { inputValue },
        } = this.props;
        // render only
        return (
            nextProps.isFocused !== isFocused ||
            nextProps.isSelected !== isSelected ||
            nextProps.isDisabled !== isDisabled ||
            nextProps.selectProps.inputValue !== inputValue
        );
    }

    handleEditClick(optionData, e) {
        const {
            selectProps: { onEditClick },
        } = this.props;

        e.stopPropagation();
        onEditClick(optionData);
    }

    handleDeleteClick(optionData, e) {
        const {
            selectProps: { onDeleteClick },
        } = this.props;

        e.stopPropagation();
        onDeleteClick(optionData);
    }

    render() {
        const {
            label,
            value,
            isDisabled,
            selectOption,
            selectProps: { inputValue },
            innerProps,
            innerRef,
            data,
        } = this.props;
        const { ...rest } = innerProps;
        const { extra, extraOnHover = '', subLabel, className = '' } = data;
        return (
            <div
                className={classNames(css.suggestionRow, className, {
                    [css.disabled]: isDisabled,
                })}
                onClick={() => {
                    selectOption({ value, label });
                }}
                role="button"
                tabIndex={0}
                ref={innerRef}
                {...rest}
            >
                {data.icon && <div className={css.icon}>{data.icon}</div>}
                {data.subTitle && <div className={css.subTitle}>{data.subTitle}</div>}
                <OverflowingText>
                    <Highlighter
                        searchWords={[inputValue]}
                        textToHighlight={label}
                        highlightClassName={css.highlighted}
                        autoEscape
                    />
                </OverflowingText>
                {subLabel && (
                    <OverflowingText>
                        <Highlighter
                            searchWords={[inputValue]}
                            textToHighlight={subLabel}
                            className={classNames(css.subLabel)}
                            highlightClassName={css.highlighted}
                            autoEscape
                        />
                    </OverflowingText>
                )}
                {extra || extraOnHover ? (
                    <div className={extra ? css.extra : css.extraOnHover}>{extra || extraOnHover}</div>
                ) : null}
                {(data.erasable || data.editable) && (
                    <div className={css.editIcons}>
                        {data.erasable && (
                            <div className={css.trashIcon} onClick={e => this.handleDeleteClick(data, e)}>
                                <TrashIcon />
                            </div>
                        )}
                        {data.editable && (
                            <div className={css.editIcon} onClick={e => this.handleEditClick(data, e)}>
                                <EditIcon />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

TagInputSuggestion.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    selectOption: PropTypes.func,
    isFocused: PropTypes.bool,
    selectProps: PropTypes.objectOf(PropTypes.any),
    isSelected: PropTypes.bool,
    innerProps: PropTypes.objectOf(PropTypes.any),
    innerRef: PropTypes.func,
    data: PropTypes.objectOf(PropTypes.any),
    isDisabled: PropTypes.bool,
};

TagInputSuggestion.defaultProps = {
    label: '',
    value: '',
    selectOption: () => {},
    isFocused: false,
    selectProps: {
        inputValue: '',
    },
    isSelected: false,
    innerProps: {},
    innerRef: () => {},
    data: {},
    isDisabled: false,
};

export default TagInputSuggestion;
