import React, { Fragment, memo, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Translate } from 'react-localize-redux';
import XIcon from '@mui/icons-material/Close';
import DashboardGrid from 'components/partials/DashboardGrid';
import css from './dashboard.css';
import Datepicker from '../components/widgets/Datepicker';
import Button from '../components/atoms/Button';
import WidgetShelf, { WIDGET_TYPE_OPTIONS } from './widgetShelf';
import { OPERATOR_TYPES } from './consts';
import { CUSTOM_DASHBOARD_CHART_TYPES } from '../components/partials/PivotChart/consts';
import ActionButton from '../components/widgets/ActionButton';
import { getDaysDiff, getSecondDatePickerRanges } from '../utils/regional';
import { Label } from '../components/widgets';
import { useEffectSkipFirst, useFetchWidgetVersions, useRefreshCounterByInterval } from './hooks';
import { trackDashboardsMixpanelEvent, updateWidgetsWithNewLayout, WIDGET_CHANGE_TYPES } from './utils';
import StickyShadow from '../components/partials/StickyShadow';
import DashboardsEmptyState, { getEmptyWidget } from './DashboardsEmptyState';
import InfoIcon from '../resources/svg/info.svg';
import HistoryIcon from '../resources/svg/history.svg';
import { getEntireGridHeight, getWidgetsHeight } from '../components/partials/DashboardGrid/utils';
import { GRID_COLS, GRID_ROW_HEIGHT, WIDGET_VERSIONS_REFRESH_RATE } from '../components/partials/DashboardGrid/consts';

function canWidgetFit(filledSpaces, gridParams, row, col) {
    const { h, w } = gridParams;

    for (let i = 0; i < h; ++i) {
        for (let j = 0; j < w; ++j) {
            const targetRow = row + i;
            const targetCol = col + j;

            if (filledSpaces[targetRow] && filledSpaces[targetRow][targetCol]) {
                return false;
            }
        }
    }

    return true;
}

export function getNewWidgetPosition(widgets, newWidget) {
    if (!widgets.length) {
        return [0, 0];
    }

    // Compute which spaces are in use
    const filledSpaces = {};
    widgets.forEach(widget => {
        const { x, y, w, h } = widget.gridParams;

        for (let i = 0; i < h; ++i) {
            for (let j = 0; j < w; ++j) {
                const row = y + i;
                const col = x + j;

                if (!filledSpaces[row]) {
                    filledSpaces[row] = {};
                }

                filledSpaces[row][col] = true;
            }
        }
    });

    const lastRow = getWidgetsHeight(widgets);
    for (let i = 0; i < lastRow; ++i) {
        for (let j = 0; j <= GRID_COLS - newWidget.gridParams.w; ++j) {
            if (canWidgetFit(filledSpaces, newWidget.gridParams, i, j)) {
                return [j, i];
            }
        }
    }

    return [0, lastRow + 1];
}

function CustomDashboardGrid({
    dashboard,
    widgets,
    updateWidgets,
    cloneWidget,
    deleteWidget,
    resizable,
    width,
    apiFields,
    disableReportState,
    updatingGraphQL,
    updateDate,
    dateLimit,
}) {
    const dashboardName = dashboard?.name;

    // Report page load on first render
    useEffect(() => trackDashboardsMixpanelEvent('Page Load', { dashboard_name: dashboardName }), []);

    const headerRef = useRef();
    const [dateRange, setDateRange] = useState(
        dashboard.startDate && dashboard.endDate
            ? {
                  startDate: dashboard.startDate,
                  endDate: dashboard.endDate,
              }
            : {
                  startDate: moment()
                      .subtract(6, 'days')
                      .format('YYYY-MM-DD'),
                  endDate: moment().format('YYYY-MM-DD'),
              }
    );

    const compareEndDate = moment(dateRange.startDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');

    const compareStartDate = moment(compareEndDate)
        .subtract(6, 'days')
        .format('YYYY-MM-DD');

    const [dateRange2, setDateRange2] = useState({ startDate2: compareStartDate, endDate2: compareEndDate });

    useEffectSkipFirst(() => {
        trackDashboardsMixpanelEvent('Date Changed', { dashboard_name: dashboardName });
    }, [dateRange, dateRange2]);

    const [editedWidget, setEditedWidget] = useState(null);
    const [compare, setCompare] = useState(false);
    const reducer = (state, action) => {
        return { ...state, ...action };
    };
    const [forceRefreshCounter, setForceRefreshCounter] = useState(0);
    const [currentWidgetVersions, dispatchWidgetVersions] = useReducer(reducer, {});
    const [widgetsLoading, dispatchWidgetsLoading] = useReducer(reducer, {});

    const refreshCounter = useRefreshCounterByInterval(WIDGET_VERSIONS_REFRESH_RATE);
    const widgetVersions = useFetchWidgetVersions(
        dashboard.id,
        refreshCounter,
        forceRefreshCounter,
        dateRange.startDate,
        dateRange.endDate
    );

    // In case the backend calculated the widget (we get the version hash from useFetchWidgetVersions)
    // and its version hash isn't null AND different from the version we already have, we'll show the refresh button
    let newDataAvailable = false;
    if (widgetVersions != null) {
        for (const key in widgetVersions) {
            if (!(updatingGraphQL && currentWidgetVersions[key] === null && widgetVersions[key] !== null)) {
                // In this case (updating graphQL objects AND the current version is null, while the one we fetched from the BE isn't).
                // We can assume that this widget was updated somehow by the user (WIDGET_CHANGE_TYPES.EDITED).
                // This means we don't want to show the refreshButton in this case.
                newDataAvailable =
                    newDataAvailable ||
                    currentWidgetVersions[key] === undefined ||
                    (currentWidgetVersions[key] !== widgetVersions[key] && widgetVersions[key] !== null);
            }
        }
    }

    const loadingAnyWidget = Object.values(widgetsLoading).filter(x => x).length > 0;
    useEffect(
        // eslint-disable-next-line no-shadow
        (loadingAnyWidget, newDataAvailable) => {
            if (!loadingAnyWidget && newDataAvailable) {
                trackDashboardsMixpanelEvent('Refresh data available', { dashboard_name: dashboardName });
            }
        },
        [loadingAnyWidget, newDataAvailable]
    );

    useEffect(
        // eslint-disable-next-line no-shadow
        (loadingAnyWidget, widgetsLoading, widgets) => {
            if (widgets !== undefined && !loadingAnyWidget && Object.keys(widgetsLoading).length === widgets.length) {
                trackDashboardsMixpanelEvent('Page Load', { dashboard_name: dashboardName });
            }
        },
        [loadingAnyWidget, widgetsLoading, widgets]
    );

    const [newDataDismiss, setNewDataDismiss] = useState(false);

    // We got to compute the height dynamically - otherwise sticky header and margins won't work :-/
    const [gridStyle, setGridStyle] = useState(null);
    const recomputeGridStyle = useCallback(
        layout => {
            const effectiveWidgets = !layout ? widgets : layout.map(gridParams => ({ gridParams }));

            setGridStyle({
                height: getEntireGridHeight(effectiveWidgets, GRID_ROW_HEIGHT),
            });
        },
        [widgets]
    );

    useEffect(recomputeGridStyle, [widgets]);

    if (width === null) {
        return null;
    }

    const onGridLayoutChange = layout => {
        if (layout.length !== widgets.length) {
            // Close any gaps in the grid
            setTimeout(() => recomputeGridStyle(layout));
            return;
        }

        // Save the new layout into the widget objects (under `gridProperties`)
        updateWidgets(updateWidgetsWithNewLayout(dashboard, widgets, layout), null, WIDGET_CHANGE_TYPES.LAYOUT);
    };

    return (
        <Fragment>
            {editedWidget !== null && (
                <WidgetShelf
                    widgets={widgets}
                    widget={editedWidget}
                    close={() => setEditedWidget(null)}
                    onUpdateWidget={newWidget => {
                        const editedIndex = widgets.findIndex(x => x === editedWidget);

                        // Existing widget - override
                        if (editedIndex !== -1) {
                            const newWidgets = [...widgets];
                            newWidgets[editedIndex] = newWidget;
                            updateWidgets(newWidgets, newWidget, WIDGET_CHANGE_TYPES.EDITED);

                            return;
                        }

                        // New widget, need to add to the widgets array (Put at last possible position)
                        const [x, y] = getNewWidgetPosition(widgets, newWidget);
                        newWidget.gridParams = {
                            ...newWidget.gridParams,
                            x,
                            y,
                        };

                        updateWidgets([...widgets, newWidget], newWidget, WIDGET_CHANGE_TYPES.ADDED);
                    }}
                    apiFields={apiFields}
                />
            )}
            <div className={css.dashboardGridHeader} ref={headerRef}>
                <div className={css.datePickerWrapper}>
                    <Datepicker
                        id="date1"
                        className={css.dashboardDatePicker}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        dateLimit={dateLimit}
                        onApply={({ start_date: newStartDate, end_date: newEndDate, time_period: timePeriod }) => {
                            updateDate(newStartDate, newEndDate, timePeriod);
                            setDateRange({
                                startDate: newStartDate,
                                endDate: newEndDate,
                            });
                        }}
                        title={
                            compare
                                ? [
                                      'STATIC.PAGES.REPORTS.DATEPICKER_PERIOD_TITLE',
                                      { daysPeriod: getDaysDiff(dateRange.startDate, dateRange.endDate) },
                                  ]
                                : null
                        }
                    />
                    {!compare ? (
                        <ActionButton
                            text="STATIC.BUTTONS.COMPARE_TWO_PERIODS"
                            primary={false}
                            cssModifier="compare"
                            onClick={() => setCompare(true)}
                            disabled={disableReportState}
                        />
                    ) : (
                        <Fragment>
                            <Label text="vs" style={{ margin: '0 10px' }} />

                            <div className={css.secondTimePeriodContainer}>
                                <Datepicker
                                    id="date2"
                                    className={css.dashboardDatePicker}
                                    startDate={dateRange2.startDate2}
                                    endDate={dateRange2.endDate2}
                                    ranges={getSecondDatePickerRanges(dateRange.startDate, dateRange.endDate)}
                                    onApply={({ start_date: newStartDate, end_date: newEndDate }) => {
                                        setDateRange2({
                                            startDate2: newStartDate,
                                            endDate2: newEndDate,
                                        });
                                    }}
                                    title={
                                        compare
                                            ? [
                                                  'STATIC.PAGES.REPORTS.DATEPICKER_PERIOD_TITLE',
                                                  {
                                                      daysPeriod: getDaysDiff(
                                                          dateRange2.startDate2,
                                                          dateRange2.endDate2
                                                      ),
                                                  },
                                              ]
                                            : null
                                    }
                                />
                                <ActionButton
                                    text="STATIC.BUTTONS.REMOVE"
                                    primary={false}
                                    cssModifier="remove_compare"
                                    onClick={() => setCompare(false)}
                                />

                                <div className={css.compareSupportWarning}>
                                    <InfoIcon />
                                    <Translate id="STATIC.PAGES.DASHBOARD.COMPARE_SUPPORT_WARNING" />
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>

                <div>
                    <Button
                        onClick={() => setEditedWidget(getEmptyWidget())}
                        type="primary"
                        level="level1"
                        disabled={disableReportState}
                    >
                        <Translate id="STATIC.PAGES.DASHBOARD.ADD_WIDGET" />
                    </Button>
                </div>
            </div>

            <StickyShadow targetRef={headerRef} />

            <div className={css.pageSeparator} />

            {newDataAvailable && !newDataDismiss && !loadingAnyWidget && (
                <div className={css.refreshDataContainer}>
                    <div className={css.refreshDataTextContainer}>
                        <span>Most recent data is available for you now</span>
                        <Button
                            type="link"
                            onClick={() => {
                                setForceRefreshCounter(forceRefreshCounter + 1);
                                setNewDataDismiss(false);
                            }}
                            textClassName={css.refreshDataButtonContent}
                        >
                            <div>
                                <HistoryIcon />
                                <span>Refresh Data</span>
                            </div>
                        </Button>
                    </div>
                    <XIcon onClick={() => setNewDataDismiss(true)} className={css.dismissRefreshIcon} />
                </div>
            )}

            {widgets.length === 0 && (
                <DashboardsEmptyState setEditedWidget={setEditedWidget} widgetTypeOptions={WIDGET_TYPE_OPTIONS} />
            )}

            {widgets.length !== 0 && (
                <DashboardGrid
                    widgets={widgets}
                    dashboard={dashboard}
                    compare={compare}
                    gridStyle={gridStyle}
                    width={width}
                    resizable={resizable}
                    updatingGraphQL={updatingGraphQL}
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    startDate2={dateRange2.startDate2}
                    endDate2={dateRange2.endDate2}
                    forceRefreshCounter={forceRefreshCounter}
                    onGridLayoutChange={onGridLayoutChange}
                    dispatchWidgetsLoading={dispatchWidgetsLoading}
                    dispatchWidgetVersions={dispatchWidgetVersions}
                    cloneWidget={cloneWidget}
                    deleteWidget={deleteWidget}
                    updateWidgets={updateWidgets}
                    onEditWidget={setEditedWidget}
                    periods={apiFields?.periods}
                />
            )}
        </Fragment>
    );
}

CustomDashboardGrid.propTypes = {
    dashboard: PropTypes.objectOf(PropTypes.any).isRequired,
    widgets: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['table', 'pivot', ...CUSTOM_DASHBOARD_CHART_TYPES.map(x => x.name)]).isRequired,
            query: PropTypes.shape({
                dimensions: PropTypes.arrayOf(PropTypes.string),
                dataTypeFields: PropTypes.arrayOf(PropTypes.string),
                metrics: PropTypes.arrayOf(PropTypes.string),
                filters: PropTypes.arrayOf(
                    PropTypes.shape({
                        field: PropTypes.string.isRequired,
                        operator: PropTypes.oneOf(OPERATOR_TYPES.map(x => x.name)).isRequired,
                        values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
                    })
                ).isRequired,
            }).isRequired,
            options: PropTypes.shape({
                rows: PropTypes.arrayOf(PropTypes.string),
                columns: PropTypes.arrayOf(PropTypes.string),
            }).isRequired,
            gridParams: PropTypes.shape({
                i: PropTypes.string.isRequired,
                x: PropTypes.number.isRequired,
                y: PropTypes.number.isRequired,
                w: PropTypes.number.isRequired,
                h: PropTypes.number.isRequired,
            }).isRequired,
        })
    ).isRequired,
    updateWidgets: PropTypes.func.isRequired,
    cloneWidget: PropTypes.func.isRequired,
    deleteWidget: PropTypes.func.isRequired,
    resizable: PropTypes.bool,
    width: PropTypes.number,
    apiFields: PropTypes.objectOf(PropTypes.any),
    disableReportState: PropTypes.bool,
    updatingGraphQL: PropTypes.bool,
    updateDate: PropTypes.func.isRequired,
    dateLimit: PropTypes.objectOf(PropTypes.any),
};

CustomDashboardGrid.defaultProps = {
    resizable: true,
    width: null,
    apiFields: null,
    disableReportState: false,
    updatingGraphQL: false,
};

export default memo(CustomDashboardGrid);
