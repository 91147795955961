import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Translate } from 'react-localize-redux';
import { Formik } from 'formik-2';
import PropTypes from 'prop-types';
import { GET_AB_TEST } from '../../queries';
import { DEFAULT_END_DATE, DEFAULT_START_DATE, EMPTY_TEST_SKELETON } from './consts';
import css from './WizardWrapper.css';
import MemoChevronIcon from '../MemoChevronIcon';
import { Spinner } from '../../../components/widgets';
import WizardContainer from './WizardContainer';
import { normalizePost, normalizeTestChannels } from './Steps/wizardUtils';
import { SAVE_AB_TEST } from '../../mutations';

const normalizeGetAbTest = values => {
    return {
        id: values?.id,
        testName: values?.name || '',
        description: values?.description || '',
        appsite: {
            label: `${values.appsite.appName} - ${values.appsite.platform}`,
            value: values.appsite.id,
            icon: <img src={values.appsite.icon} alt="" className={css.icon} />,
            className: css.dropdownRow,
        },
        segment: {
            value: values.segment.id,
            label: values.segment.name,
            reach: values.segment.reach,
        },
        startDate: values?.startDate || DEFAULT_START_DATE,
        endDate: values?.endDate || DEFAULT_END_DATE,
        isTestOngoing: !values?.endDate,
        events: values?.events || [],
        testChannels: normalizeTestChannels(values?.testChannels || []),
        status: values.status,
    };
};

const prefixTranslate = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD';

const WizardWrapper = ({ onClose, testId, refetchTable }) => {
    const { data: abTestDataResponseData, loading: isFetchingTest, refetch: refetchAbTest } = useQuery(GET_AB_TEST, {
        skip: !testId,
        fetchPolicy: 'cache-first',
        variables: { testId },
    });
    const [saveAbTest, saveAbTestResponse] = useMutation(SAVE_AB_TEST, {
        onCompleted: () => {
            if (testId) {
                refetchAbTest({ testId });
            }
            refetchTable();
            saveAbTestResponse.reset();
            onClose();
        },
    });

    const initialValues = useMemo(
        () => (isFetchingTest || !testId ? EMPTY_TEST_SKELETON : normalizeGetAbTest(abTestDataResponseData.abTest)),
        [isFetchingTest, abTestDataResponseData, testId]
    );

    const onSubmit = async values => {
        await saveAbTest({ variables: { abTest: normalizePost(values) } });
    };

    const submitData = useMemo(
        () => ({
            loading: !!saveAbTestResponse.loading,
            error: !!saveAbTestResponse.error,
        }),
        [saveAbTestResponse]
    );

    return (
        <div className={css.containerPopup}>
            <div className={css.header}>
                <div className={css.goBackContainer} onClick={onClose}>
                    <MemoChevronIcon className={css.goBackIcon} />
                    <div className={css.goBackText}>
                        <Translate id={`${prefixTranslate}.GO_BACK`} />
                    </div>
                </div>
                <div className={css.title}>
                    {initialValues?.id ? (
                        <span>
                            <Translate id={`${prefixTranslate}.HEADER_EDIT`} /> {initialValues.testName}
                        </span>
                    ) : (
                        <Translate id={`${prefixTranslate}.HEADER`} />
                    )}
                </div>
            </div>
            {isFetchingTest ? (
                <Spinner show expanded />
            ) : (
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    <WizardContainer submitData={submitData} onClose={onClose} />
                </Formik>
            )}
        </div>
    );
};

WizardWrapper.propTypes = {
    onClose: PropTypes.func.isRequired,
    refetchTable: PropTypes.func.isRequired,
    testId: PropTypes.string,
};

export default WizardWrapper;