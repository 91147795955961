import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import XIcon from '@mui/icons-material/Close';
import faWarning from '@fortawesome/fontawesome-free-solid/faExclamationTriangle';
import classNames from 'classnames';
import InfoIcon from '../../resources/svg/info.svg';
import Checked from '../../resources/svg/checked_with_circle.svg';
import ExternalLink from '../../resources/icons/externalLink.svg';
import css from './WarningMessage.css';
import Button, { BUTTON_TYPES } from '../atoms/Button';

const iconTypes = {
    info: <InfoIcon className={css.typeIcon} />,
    whiteInfo: <InfoIcon className={css.typeIcon} />,
    error: <FontAwesomeIcon className={css.typeIcon} icon={faWarning} />,
    warning: <FontAwesomeIcon className={css.typeIcon} icon={faWarning} />,
    success: <Checked className={css.typeIcon} />,
    externalLink: <ExternalLink />,
};

class WarningMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };
        this.handleRemove = this._handleRemove.bind(this);
    }

    _handleRemove() {
        this.setState({
            show: false,
        });
    }

    render() {
        const {
            message,
            containerStyle,
            show: propShow,
            type,
            showIcon,
            textReplace,
            showTypeIcon,
            showSpacer,
            className,
            textAlignCenter,
            buttonAction,
            buttonText,
            buttonType,
            textContainerClass,
            onClose,
        } = this.props;
        const { show } = this.state;
        const useShow = propShow !== null ? propShow : show;
        return (
            <div
                className={`${css.container} ${type ? css[type] : ''} ${className}`}
                style={{
                    ...containerStyle,
                    display: useShow ? containerStyle.display : 'none',
                }}
            >
                {showTypeIcon ? (
                    <>
                        {iconTypes[type]}
                        {showSpacer && <div className={classNames(css.spacer, { [css.solid]: showSpacer })} />}
                    </>
                ) : null}
                <div
                    key={message}
                    className={classNames(css.messageContainer, textContainerClass, {
                        [css.textAlignCenter]: textAlignCenter,
                    })}
                >
                    {typeof message === 'string' ? <Translate id={message} data={textReplace} /> : message}
                </div>
                {buttonText ? (
                    <Button onClick={buttonAction} type={buttonType}>
                        <Translate id={buttonText} />
                    </Button>
                ) : null}
                {showIcon ? (
                    <XIcon onClick={onClose !== null ? onClose : this.handleRemove} className={css.icon} />
                ) : null}
            </div>
        );
    }
}

WarningMessage.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    textReplace: PropTypes.objectOf(PropTypes.any),
    containerStyle: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    show: PropTypes.bool,
    showIcon: PropTypes.bool,
    type: PropTypes.oneOf(['error', 'warning', 'info', 'success', 'whiteInfo', 'externalLink']),
    showTypeIcon: PropTypes.bool,
    showSpacer: PropTypes.bool,
    textAlignCenter: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonAction: PropTypes.func,
    textContainerClass: PropTypes.string,
    onClose: PropTypes.func,
    buttonType: PropTypes.oneOf(BUTTON_TYPES),
};

WarningMessage.defaultProps = {
    message: '',
    textReplace: {},
    containerStyle: {
        display: 'flex',
    },
    className: '',
    show: null,
    showIcon: true,
    showSpacer: true,
    type: 'warning',
    showTypeIcon: true,
    buttonType: 'flat',
    textAlignCenter: false,
    buttonText: '',
    buttonAction: () => {},
    textContainerClass: '',
    onClose: null,
};

export default WarningMessage;
