import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../components/foundations/Label';
import css from '../styles.css';
import EmptyStateBenchmarksMarketShare from '../../resources/svg/benchmarks_market_share_empty_state.svg';

const EmptyState = ({ emptyStateSubText }) => {
    return (
        <div className={css.emptyStateContainer}>
            <Label
                className={css.emptyStateText}
                text="STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.EMPTY_STATE_TEXT"
            />
            <EmptyStateBenchmarksMarketShare className={css.emptyStateImg} />
            <Label className={css.emptyStateSubtext} type="p" text={emptyStateSubText} />
        </div>
    );
};

export default EmptyState;

EmptyState.propTypes = {
    emptyStateSubText: PropTypes.string,
};

EmptyState.defaultProps = {
    emptyStateSubText: '',
};
