import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize, Translate } from 'react-localize-redux';
import { Box, Typography } from '@mui/material';

import Dialog from '../../../components/widgets/MuiDialog';
import NotificationInfoIcon from '../../../resources/icons/notification_info.svg';

import css from './ReorderValuesDialog.css';
import Button from '../../../components/atoms/Button';
import ReorderList from '../../../components/organisms/ReorderList';
import { getSwappedListElements } from '../../../utils/general';

const ReorderValuesDialog = ({ customDimensionName, values, valuesIds, id, onValueReordered, onDone, ...props }) => {
    const [reorderedValues, setReorderedValues] = useState(values);
    const reorderedValuesIds = useRef(valuesIds);

    const disableSave = !valuesIds || reorderedValuesIds.current.toString() === valuesIds?.toString();

    const onDragOver = (currIndex, newIndex) => {
        if (reorderedValuesIds.current) {
            reorderedValuesIds.current = getSwappedListElements(currIndex, newIndex, reorderedValuesIds.current);
        }

        setReorderedValues(prevReorderedValues => getSwappedListElements(currIndex, newIndex, prevReorderedValues));
    };

    const onSave = useCallback(() => {
        onValueReordered(id, reorderedValuesIds.current);
        onDone();
        props.onClose();
    }, [id]);

    return (
        <Dialog title="STATIC.PAGES.CUSTOM_DIMENSIONS.REORDER_VALUES.DIALOG_TITLE" {...props} paperClass={css.dialog}>
            <Box className={css.content}>
                <Box className={css.descriptionContainer}>
                    <NotificationInfoIcon className={css.infoIcon} />
                    <Translate id="STATIC.PAGES.CUSTOM_DIMENSIONS.REORDER_VALUES.DIALOG_TEXT" />
                </Box>
                <Box className={css.valuesContainer}>
                    <Typography className={css.customDimensionName}>
                        {customDimensionName} ({values.length} values)
                    </Typography>
                    <ReorderList values={reorderedValues} onDragOver={onDragOver} />
                </Box>
                <Box className={css.footerButtonContainer}>
                    <Button type="flat" onClick={props.onClose}>
                        <Translate id="STATIC.BUTTONS.CANCEL" />
                    </Button>
                    <Button disabled={disableSave} type="primary" level="level1" onClick={onSave}>
                        <Translate id="STATIC.BUTTONS.DONE" />
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

ReorderValuesDialog.propTypes = {
    customDimensionName: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
    valuesIds: PropTypes.arrayOf(PropTypes.string),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onValueReordered: PropTypes.func,
    id: PropTypes.string,
    onDone: PropTypes.func,
};

ReorderValuesDialog.defaultProps = {
    onClose: () => {},
    open: false,
    values: [],
    valuesIds: [],
    customDimensionName: '',
    onValueReordered: () => {},
    id: '',
    onDone: () => {},
};

export default withLocalize(ReorderValuesDialog);
