import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { HotColumn, HotTable } from '@handsontable/react';
import { Translate, withLocalize } from 'react-localize-redux';
import Handsontable from 'handsontable';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import '../../../node_modules/handsontable/dist/handsontable.full.css';

import { registerAllModules } from 'handsontable/registry';
import { DROPDOWN_TYPES, FIELD_TYPES } from 'governance/consts';
import { READ_CODES, UPDATE_CODES } from '../queries';
import EmptyState from '../../components/widgets/EmptyState';
import CodeShelf from './codeShelf';
import { isAssetFieldType } from '../utils';
import css from './table.css';
import Button from '../../components/atoms/Button';
import { AutoCompleteField, WarningMessage } from '../../components/widgets';

registerAllModules();

update.extend('$autoArray', (value, object) => {
    return object ? update(object, value) : update([], value);
});

const SortOrderTypes = { asc: 'asc', desc: 'desc' };
const CODES_RECENT_PERIOD = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

function CodeTable(props) {
    const { schema, newCode, setNewCode, updateCodesList, translate, showRecentOnly } = props;
    const hotRef = useRef(null);
    const [filterInputText, setFilterInputText] = useState('');

    const hotColumns = useMemo(
        () =>
            schema.fields.map(field => {
                const extra = {};
                if (DROPDOWN_TYPES.includes(field.kind)) {
                    extra.type =
                        field.kind === Handsontable.cellTypes.autocomplete.CELL_TYPE
                            ? Handsontable.cellTypes.autocomplete.CELL_TYPE
                            : Handsontable.cellTypes.dropdown.CELL_TYPE;
                    extra.source = field.values.map(x => x.value);
                } else if (isAssetFieldType(field.kind)) {
                    extra.readOnly = true;
                }

                const fieldKind = translate(FIELD_TYPES.find(x => x.value === field.kind).label);
                return (
                    <HotColumn
                        key={field.guid}
                        data={field.guid}
                        title={`${field.name} (${fieldKind})`}
                        // renderer="my.renderer"
                        {...extra}
                    />
                );
            }),
        [schema.fields]
    );

    const { loading, error, data, refetch } = useQuery(READ_CODES, {
        variables: {
            schemaId: schema.id,
        },
    });

    const [editedCodes, setEditedCodes] = useState({});
    const [revertFlag, setRevertFlag] = useState(false);
    const [countFlag, setCountFlag] = useState(false);
    const numOfChanges = useMemo(
        () =>
            Object.values(editedCodes)
                .map(x => Object.keys(x).length)
                .reduce((a, b) => a + b, 0),
        [countFlag, revertFlag] // should be depended on editedCodes,no countFlag
    );

    const isCodeRecentlyCreated = code => {
        const created = new Date(code.created);
        const now = new Date();
        const timeDiffInMs = now.getTime() - created.getTime();
        return timeDiffInMs < CODES_RECENT_PERIOD;
    };

    const isCodeSatisfyingFilterInput = code => {
        return (
            code.id.toLowerCase().includes(filterInputText.toLowerCase()) ||
            code.name.toLowerCase().includes(filterInputText.toLowerCase()) ||
            code.creatorEmail.toLowerCase().includes(filterInputText.toLowerCase()) ||
            code.fields.some(field =>
                field.values.some(value => value.value.toLowerCase().includes(filterInputText.toLowerCase()))
            )
        );
    };

    const hotData = useMemo(
        () =>
            data &&
            data.codes
                .filter(code => {
                    if (showRecentOnly && !isCodeRecentlyCreated(code)) {
                        return false;
                    }

                    if (filterInputText && !isCodeSatisfyingFilterInput(code)) {
                        return false;
                    }

                    return true;
                })
                .map(code => {
                    const row = {
                        name: code.name,
                        id: code.governanceId,
                        creator: code.creatorEmail,
                        created: new Date(code.created).toLocaleString('en-US'),
                    };
                    schema.fields.forEach(({ guid }) => {
                        const codeField = code.fields.find(x => x.guid === guid);

                        if (codeField) {
                            if (code.governanceId in editedCodes && guid in editedCodes[code.governanceId]) {
                                row[guid] = editedCodes[code.governanceId][guid].newVal;
                            } else {
                                row[guid] = codeField.values.map(x => x.value).join('|'); // .map((x) => `${x.value} (${x.code})`).toString();
                            }
                        }
                    });
                    return row;
                }),
        [data?.codes, revertFlag, filterInputText, showRecentOnly]
    );

    useEffect(() => {
        if (data) updateCodesList(data.codes);
    }, [data && data.codes]);

    // eslint-disable-next-line no-unused-vars
    function cellRenderer(instance, td, row, col, prop, value, cellProperties) {
        const codeId = hotData[row]?.id;

        // fields that exists in the schema but not in the code (e.g. if the schema was updated after the code was created), should be disabled to edit, since changes won't affect the DB object
        const isCellDisabled = !data?.codes
            .find(c => c.governanceId === codeId)
            ?.fields.map(f => f.guid)
            .includes(prop);

        if (['id', 'name', 'creator', 'created'].includes(prop)) {
            // eslint-disable-next-line prefer-rest-params
            Handsontable.renderers.TextRenderer.apply(this, arguments);
        } else if (isCellDisabled) {
            cellProperties.readOnly = true;
            td.classList.add(css.disabled);
            td.title = 'Disabled: field does not exist in code (field added to schema after the code was created)';
        }

        // Columns 2...-1 belong to the schema
        else if (col >= 2 && col < schema.fields.length + 2) {
            const schemaField = schema.fields[col - 2];

            // Autocomplete/Tag show a handsontable editor
            if (DROPDOWN_TYPES.includes(schemaField.kind)) {
                // eslint-disable-next-line prefer-rest-params
                Handsontable.renderers.DropdownRenderer.apply(this, arguments);
            }

            // Other fields
            else {
                // eslint-disable-next-line prefer-rest-params
                Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
        }

        // Color even rows
        if (row % 2 === 0 && !isCellDisabled) {
            td.style.background = '#f8f8f8';
        }

        // This row has edits done to it
        if (codeId in editedCodes) {
            // The entire row becomes green
            td.style.background = '#CEC';

            if (prop in editedCodes[codeId]) {
                td.style.textDecoration = 'underline';
            }

            // The name column should show something else
            if (prop === 'name') {
                td.style.textDecoration = 'line-through';
            }
        }
    }

    // useEffect(() => {
    //     Handsontable.renderers.registerRenderer('my.renderer', cellRenderer);
    // }, [hotRef]);

    const [saveError, setSaveError] = useState('');
    const [saveStatus, setSaveStatus] = useState('');
    const [updateCodes] = useMutation(UPDATE_CODES, {
        update: () => {
            setSaveStatus('saved');
            setTimeout(() => {
                setSaveError('');
                setSaveStatus('');
                setEditedCodes({});
                refetch();
                setCountFlag(!countFlag);
            }, 500);
        },
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }

    const afterChange = changes => {
        if (!changes) return;
        const newEditedCodes = editedCodes;
        changes.forEach(([row, prop, oldVal, newVal]) => {
            const codeId = hotData[row]?.id;

            // If we had a value stored in the DB, and the new value is the same as that value, we can unmark this
            // row and column as edited (so it's not going to be saved again / marked as "changed")
            const dbValue =
                data?.codes
                    ?.find(c => c.governanceId === codeId)
                    ?.fields?.find(f => f.guid === prop)
                    ?.values?.map(x => x.value)
                    ?.join('|') ?? '';

            if (dbValue === newVal) {
                if (codeId in editedCodes && prop in editedCodes[codeId]) {
                    delete newEditedCodes[codeId][prop];
                    if (!Object.keys(editedCodes[codeId]).length) {
                        delete newEditedCodes[codeId];
                    }
                }
            } else {
                // This is a new value, mark this row and column as edited
                if (!(codeId in editedCodes)) {
                    newEditedCodes[codeId] = { [prop]: { oldVal, newVal } };
                } else {
                    newEditedCodes[codeId][prop] = { oldVal, newVal };
                }
            }
        });

        setEditedCodes(newEditedCodes);
        setCountFlag(!countFlag); // computing count upon changed to editedCodes doesn't work, since reference doesn't change and useMemo isn't firing
        setSaveError('');
    };

    const onSaveChanges = () => {
        const updates = [];
        let errorFound = false;

        // editedRows contains a dictionary of each row id that was changed, and within that what fields were changed
        // this helps us build the query to update only the codes that changed
        Object.keys(editedCodes).map(codeId => {
            const code = data.codes.find(c => c.governanceId === codeId);

            // We're going to build a new fields array for this code, with all the changes
            const newFields = code.fields.map(field => {
                // If this particular field wasn't edited, we can copy it as is
                if (!(field.guid in editedCodes[codeId])) {
                    return field;
                }

                // Otherwise translate the values from the table (which are all strings...) into the objects we use
                // in the backend for values [{code: 1, value: "One"}, {code: 2, value: "Two"}, ...]
                const schemaField = schema.fields.find(sf => sf.guid === field.guid);
                let newValues = [];
                if (schemaField.kind === 'tag') {
                    const tableValues = editedCodes[codeId][field.guid].newVal.split('|');
                    newValues = schemaField.values.filter(x => tableValues.includes(x.value));
                } else if (schemaField.kind === Handsontable.cellTypes.autocomplete.CELL_TYPE) {
                    const tableValues = [editedCodes[codeId][field.guid].newVal];
                    newValues = schemaField.values.filter(x => tableValues.includes(x.value));
                } else {
                    newValues = [
                        { code: editedCodes[codeId][field.guid].newVal, value: editedCodes[codeId][field.guid].newVal },
                    ];
                }

                if (newValues.length === 0 && !schemaField.optional) {
                    setSaveError(`Missing value for field ${schemaField.name}`);
                    errorFound = true;
                }

                return { ...field, values: newValues };
            });

            if (errorFound) return;

            updates.push({
                id: code.id,
                fields: newFields,
            });
        });

        if (!errorFound) {
            updateCodes({
                variables: {
                    codes: updates,
                },
            });
            setSaveStatus('saving');
        }
    };

    const onFilterInputChange = e => {
        setFilterInputText(e);
    };

    const dateCompareFunctionFactory = sortOrder => {
        return function comparator(value, nextValue) {
            const d1 = new Date(value);
            const d2 = new Date(nextValue);
            if (d1 === d2) {
                return 0;
            } else if (d1 > d2) {
                return sortOrder === SortOrderTypes.asc ? 1 : -1;
            } else if (d2 > d1) {
                return sortOrder === SortOrderTypes.asc ? -1 : 1;
            }
            return undefined;
        };
    };

    return (
        <>
            <AutoCompleteField
                containerClass={css.schemaSelection}
                onInputChange={onFilterInputChange}
                onChange={onFilterInputChange}
                value={filterInputText}
                placeholder="Type in Name or Governance ID"
                label="Filter By: "
                searchable
                // clearable={!!searchingText}
                clearable
                controlled
                loading={false}
                isMulti={false}
                debounceTime={500}
                options={[]}
                disabled={false} // should be disabled if no codes available
            />
            <div className={css.changesSection}>
                <Button
                    className={css.leftButton}
                    type="jungleGreen"
                    level="level2"
                    disabled={numOfChanges === 0}
                    showSpinner={saveStatus === 'saving'}
                    showV={saveStatus === 'saved'}
                    onClick={onSaveChanges}
                >
                    <Translate id="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.SAVE_CHANGES" data={{ numOfChanges }} />
                </Button>
                <Button
                    type="secondary"
                    level="level2"
                    disabled={numOfChanges === 0}
                    onClick={() => {
                        setEditedCodes({});
                        setSaveStatus('');
                        setSaveError('');
                        refetch();
                        setCountFlag(!countFlag);
                        setRevertFlag(!revertFlag);
                    }}
                >
                    <Translate id="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.REVERT_CHANGES" data={{ numOfChanges }} />
                </Button>
            </div>
            {newCode && (
                <CodeShelf
                    key={`shelf${schema.id}`}
                    schema={schema}
                    newCode={newCode}
                    setNewCode={setNewCode}
                    reloadCodes={refetch}
                />
            )}
            {hotData.length === 0 && (
                <EmptyState
                    icon="happyPage"
                    header="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.EMPTY_STATE_NO_CODES"
                    style={{ margin: '100px 0' }}
                />
            )}
            <WarningMessage show={!!saveError} message={saveError || ''} showIcon={false} type="error" />
            {hotData.length > 0 && (
                <div className={css.codeTable}>
                    <HotTable
                        id="hot"
                        ref={hotRef}
                        data={hotData}
                        colHeaders
                        stretchH="all"
                        licenseKey="non-commercial-and-evaluation"
                        height="100%"
                        manualColumnResize
                        // columnSorting
                        afterChange={afterChange}
                        cells={() => ({ renderer: cellRenderer })}
                        selectionMode="range"
                        // dropdownMenu={['alignment', '---------', 'filter_by_value', 'filter_action_bar']}
                        // filters
                    >
                        <HotColumn data="id" title="Governance ID" readOnly />
                        <HotColumn data="name" title="Name" readOnly />
                        {hotColumns}
                        <HotColumn data="creator" title="Creator" readOnly />
                        <HotColumn
                            data="created"
                            title="Created"
                            readOnly
                            columnSorting={{ compareFunctionFactory: dateCompareFunctionFactory }}
                        />
                    </HotTable>
                </div>
            )}
        </>
    );
}

CodeTable.propTypes = {
    schema: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        pattern: PropTypes.string,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                guid: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                kind: PropTypes.oneOf(FIELD_TYPES.map(x => x.value)),
                values: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: PropTypes.string,
                        value: PropTypes.string,
                    })
                ).isRequired,
                default: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: PropTypes.string,
                        value: PropTypes.string,
                    })
                ).isRequired,
                optional: PropTypes.bool,
                hidden: PropTypes.bool,
            })
        ).isRequired,
    }).isRequired,
    newCode: PropTypes.bool.isRequired,
    setNewCode: PropTypes.func.isRequired,
    updateCodesList: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    showRecentOnly: PropTypes.bool.isRequired,
};

export default withLocalize(CodeTable);
