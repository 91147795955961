import React from 'react';
import css from '../organisms/Table.css';
import FauxButton from './FauxButton';

const TableCellLink = props => {
    const { value, label, align, highlight, onClick, buttonStyle = {}, buttonClassName = '' } = props;
    let text = value;
    if (highlight) {
        const i = text.toLowerCase().indexOf(highlight);
        text = (
            <span>
                <span>{text.substr(0, i)}</span>
                <b>{text.substr(i, highlight.length)}</b>
                <span>{text.substr(i + highlight.length)}</span>
            </span>
        );
    }
    if (label) {
        text = label;
    }
    return (
        <td style={{ textAlign: align || 'left' }}>
            <FauxButton
                containerStyle={buttonStyle}
                className={buttonClassName}
                onClick={() => {
                    onClick(props);
                }}
            >
                {text}
            </FauxButton>
        </td>
    );
};

export default TableCellLink;
