import {
    WIZARD_EVENTS,
    WIZARD_INTERNAL_DISTRIBUTION,
    WIZARD_OVERVIEW,
    WIZARD_TEST_CHANNELS,
    WIZARD_TEST_DETAILS,
    WIZARD_DISTRIBUTION,
    WIZARD_TEST_PARAMETERS,
} from './consts';
import getCustomSection from './customSectionsConfig';
import { formatItem, formatItemWithChildren } from '../../../sdkIntegration/sdkPlanning/sectionsWrapper/utils';

function getSectionConfig(
    itemKey,
    onNext,
    onBack,
    onDraftClick,
    distributionsData = {},
    submitData = {},
    nestedItems = {}
) {
    return {
        component: getCustomSection(itemKey, onNext, onBack, onDraftClick, distributionsData, submitData),
        itemTitle: `STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTIONS_LABELS.${itemKey}`,
        nestedItems,
    };
}

const SECTIONS_TREE = (onNext, onBack, onDraftClick, distributionsData, submitData) => {
    return {
        [WIZARD_TEST_DETAILS]: getSectionConfig(WIZARD_TEST_DETAILS, onNext, onBack, onDraftClick),
        [WIZARD_DISTRIBUTION]: getSectionConfig(WIZARD_DISTRIBUTION, onNext, onBack, onDraftClick, distributionsData, submitData, {
            [WIZARD_TEST_CHANNELS]: getSectionConfig(
                WIZARD_TEST_CHANNELS,
                onNext,
                onBack,
                onDraftClick,
                distributionsData
            ),
            [WIZARD_TEST_PARAMETERS]: getSectionConfig(
                WIZARD_TEST_PARAMETERS,
                onNext,
                onBack,
                onDraftClick,
                distributionsData
            ),
            [WIZARD_INTERNAL_DISTRIBUTION]: getSectionConfig(
                WIZARD_INTERNAL_DISTRIBUTION,
                onNext,
                onBack,
                onDraftClick,
                distributionsData
            ),
        }),
        [WIZARD_EVENTS]: getSectionConfig(WIZARD_EVENTS, onNext, onBack, onDraftClick),
        [WIZARD_OVERVIEW]: getSectionConfig(
            WIZARD_OVERVIEW,
            onNext,
            onBack,
            onDraftClick,
            distributionsData,
            submitData
        ),
    };
};

const getSectionsListItems = (onNext, onBack, onDraftClick, distributionsData, submitData) => {
    const rawItems = SECTIONS_TREE(onNext, onBack, onDraftClick, distributionsData, submitData) || {};

    return Object.fromEntries(
        Object.entries(rawItems).map(([key, item]) => [
            key,
            item.hasOwnProperty('nestedItems') ? formatItemWithChildren(item, false, []) : formatItem(item),
        ])
    );
};

export { getSectionsListItems };
