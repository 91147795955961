import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import posed, { PoseGroup } from 'react-pose';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Label, Button, Spinner, TextField, WizardFooter } from '../../../components/widgets';
import css from './AllowedOverrides.css';
import { getIsAgency } from '../../../selectors/user';
import DeleteIcon from '../../../resources/svg/trash.svg';
import { GET_ALLOWED_DOMAINS, SAVE_ALLOWED_DOMAINS } from '../../queries';
import RadioButtonsGroup from '../../../components/widgets/RadioButtonsGroup';
import WizardWarningMessage from '../../../teamManagement/components/WizardWarningMessage';
import Tooltip from '../../../components/widgets/Tooltip';
import GeneralPopup, { PopupTypes } from '../../../components/organisms/GeneralPopup';
import CheckedIcon from '../../../resources/icons/check_mark.svg';
import XIcon from '../../../resources/svg/icon_remove_row.svg';

const FieldAnimationItem = posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 },
});

const DOMAIN_REGEX_VALIDATION = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

const AllowedDomainsOptions = {
    ALLOW_ALL_OVERRIDES_OPTION: 'ALLOW_ALL_OVERRIDES_OPTION',
    DONT_ALLOW_OVERRIDES_OPTION: 'DONT_ALLOW_OVERRIDES_OPTION',
    LIMIT_OVERRIDES_OPTION: 'LIMIT_OVERRIDES_OPTION',
};

const getSelectedOptionFromAllowedDomains = allowedDomains => {
    if (!allowedDomains) return AllowedDomainsOptions.ALLOW_ALL_OVERRIDES_OPTION;

    if (allowedDomains.length === 0) return AllowedDomainsOptions.DONT_ALLOW_OVERRIDES_OPTION;

    return AllowedDomainsOptions.LIMIT_OVERRIDES_OPTION;
};

const getAllowedDomainsFromSelectedOption = (option, allowedDomainsState) => {
    if (option === AllowedDomainsOptions.ALLOW_ALL_OVERRIDES_OPTION) return null;

    if (option === AllowedDomainsOptions.DONT_ALLOW_OVERRIDES_OPTION) return [];

    return allowedDomainsState;
};

export default function AllowedOverrides({ onChange, onSave, onClose }) {
    const allowedDomainsResponse = useQuery(GET_ALLOWED_DOMAINS);
    const [saveAllowedDomains, saveAllowedDomainsResponse] = useMutation(SAVE_ALLOWED_DOMAINS);

    const isAgency = useSelector(state => getIsAgency(state));
    const [allowedDomains, setAllowedDomains] = useState(null);
    const [selectedOption, setSelectedOption] = useState(AllowedDomainsOptions.ALLOW_ALL_OVERRIDES_OPTION);
    const [domainForAdding, setDomainForAdding] = useState(null);
    const [domainForDeletion, setDomainForDeletion] = useState(null);
    const [addingError, setAddingError] = useState(null);
    const [savingError, setSavingError] = useState(null);

    useEffect(() => {
        if (!allowedDomainsResponse.loading && allowedDomainsResponse.data?.allowedDomains) {
            setAllowedDomains(allowedDomainsResponse.data.allowedDomains);
            setSelectedOption(getSelectedOptionFromAllowedDomains(allowedDomainsResponse.data.allowedDomains));
        }
    }, [allowedDomainsResponse.loading, allowedDomainsResponse.data]);

    useEffect(() => {
        const { called, loading, data } = saveAllowedDomainsResponse;
        if (!called || loading) return;

        if (data?.saveAllowedDomains?.ok) {
            onSave();
        } else {
            setSavingError('STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.SAVING_FAILURE_MESSAGE');
        }

        saveAllowedDomainsResponse.reset();
    }, [saveAllowedDomainsResponse]);

    const onSelectedOptionChanged = option => {
        setSelectedOption(option);
        onChange(getAllowedDomainsFromSelectedOption(option, allowedDomains));
    };

    const onDeleteClicked = domain => {
        setDomainForDeletion(domain);
    };

    const onDeleteApproved = () => {
        const newAllowedDomains = allowedDomains.filter(d => d !== domainForDeletion);

        setDomainForDeletion(null);
        setAllowedDomains(newAllowedDomains);
        onChange(newAllowedDomains);
    };

    const onAllowedDomainChanged = value => {
        setDomainForAdding(value);
        setAddingError(null);
    };

    const onAllowedDomainAdded = () => {
        if (!domainForAdding) return;

        if (allowedDomains?.includes(domainForAdding)) {
            setAddingError('STATIC.PAGES.MANAGE_LINKS.OVERRIDE_ALREADY_EXISTS');
            return;
        }

        if (!DOMAIN_REGEX_VALIDATION.test(domainForAdding)) {
            setAddingError('STATIC.PAGES.MANAGE_LINKS.OVERRIDE_NOT_VALID_DOMAIN');
            return;
        }

        const newAllowedDomains = [...(allowedDomains || []), domainForAdding];

        setDomainForAdding(null);
        setAllowedDomains(newAllowedDomains);
        onChange(newAllowedDomains);
    };

    const onSaveClicked = async () => {
        if (selectedOption === AllowedDomainsOptions.LIMIT_OVERRIDES_OPTION && !allowedDomains?.length) {
            setAddingError('STATIC.PAGES.MANAGE_LINKS.NO_ALLOWED_DOMAINS_ERROR');
            return;
        }

        await saveAllowedDomains({
            variables: { allowedDomains: getAllowedDomainsFromSelectedOption(selectedOption, allowedDomains) },
        });
    };

    if (allowedDomainsResponse.loading) {
        return <Spinner show expanded />;
    }

    return (
        <>
            <WizardWarningMessage show={!!savingError} showIcon={false} message={savingError} type="error" />
            <div className={css.content}>
                <Label className={css.title} text="STATIC.PAGES.MANAGE_LINKS.ALLOWED_OVERRIDES_TITLE" />
                <Label className={css.subtitle} text="STATIC.PAGES.MANAGE_LINKS.ALLOWED_OVERRIDES_SUB_TITLE" />
                {!isAgency ? (
                    <>
                        <RadioButtonsGroup
                            itemsClassName={css.option}
                            checkmarkClassName={css.optionCheckmark}
                            onChange={({ id }) => onSelectedOptionChanged(id)}
                            radioItems={Object.values(AllowedDomainsOptions).map(option => ({
                                id: option,
                                label: `STATIC.PAGES.MANAGE_LINKS.${option}`,
                                checked: selectedOption === option,
                            }))}
                        />
                        {selectedOption === AllowedDomainsOptions.LIMIT_OVERRIDES_OPTION && (
                            <div className={css.allowedDomainsContainer}>
                                <div className={css.addAllowedDomain}>
                                    <TextField
                                        value={domainForAdding || ''}
                                        clearable
                                        containerClass={css.allowedDomainInput}
                                        onChange={onAllowedDomainChanged}
                                        error={addingError}
                                        label="STATIC.PAGES.MANAGE_LINKS.ALLOWED_DOMAINS_LABEL"
                                        labelTooltip="STATIC.PAGES.MANAGE_LINKS.ALLOWED_DOMAINS_TOOLTIP"
                                        placeholder="STATIC.PAGES.MANAGE_LINKS.ALLOWED_DOMAIN_PLACEHOLDER"
                                    />
                                    <Button onClick={onAllowedDomainAdded} type="secondary">
                                        <Translate id="STATIC.BUTTONS.ADD" />
                                    </Button>
                                </div>
                                <div className={css.allowedDomainsListTitle}>
                                    <Translate id="STATIC.PAGES.MANAGE_LINKS.ALLOWED_DOMAINS_LIST_TITLE" />
                                </div>
                                {!allowedDomains?.length && (
                                    <div className={css.noAllowedDomainsConfigured}>
                                        <Translate id="STATIC.PAGES.MANAGE_LINKS.NO_ALLOWED_DOMAINS_CONFIGURED" />
                                    </div>
                                )}
                                <PoseGroup animateOnMount>
                                    {allowedDomains?.map(domain => (
                                        <FieldAnimationItem key={domain}>
                                            <div className={css.allowedDomainItem}>
                                                {domain}
                                                <Tooltip titleTranslationKey="STATIC.BUTTONS.DELETE">
                                                    <DeleteIcon
                                                        className={css.deleteIcon}
                                                        onClick={() => onDeleteClicked(domain)}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </FieldAnimationItem>
                                    ))}
                                </PoseGroup>
                            </div>
                        )}
                        <GeneralPopup
                            open={!!domainForDeletion}
                            type={PopupTypes.DELETE}
                            title="STATIC.PAGES.MANAGE_LINKS.DELETE_DOMAIN_MESSAGE"
                            acceptText="STATIC.BUTTONS.DELETE"
                            onAccept={onDeleteApproved}
                            onReject={() => setDomainForDeletion(null)}
                        />
                        <WizardFooter
                            buttons={[
                                <Button type="flat" onClick={onClose}>
                                    <Translate id="STATIC.BUTTONS.CANCEL" />
                                </Button>,
                                <Button onClick={onSaveClicked} showSpinner={saveAllowedDomainsResponse?.loading}>
                                    <Translate id="STATIC.BUTTONS.SAVE" />
                                </Button>,
                            ]}
                        />
                    </>
                ) : (
                    <div className={css.agency}>
                        {selectedOption === AllowedDomainsOptions.ALLOW_ALL_OVERRIDES_OPTION && (
                            <Label
                                className={css.title}
                                icon={CheckedIcon}
                                iconClassName={css.checkmark}
                                text="STATIC.PAGES.MANAGE_LINKS.ALLOW_ALL_OVERRIDES_OPTION"
                            />
                        )}
                        {selectedOption === AllowedDomainsOptions.DONT_ALLOW_OVERRIDES_OPTION && (
                            <Label
                                className={css.title}
                                icon={XIcon}
                                text="STATIC.PAGES.MANAGE_LINKS.DONT_ALLOW_OVERRIDES_OPTION"
                            />
                        )}
                        {selectedOption === AllowedDomainsOptions.LIMIT_OVERRIDES_OPTION && (
                            <>
                                <Label
                                    className={css.title}
                                    text="STATIC.PAGES.MANAGE_LINKS.LIMIT_OVERRIDES_OPTION"
                                    tooltip="STATIC.PAGES.MANAGE_LINKS.ALLOWED_DOMAINS_TOOLTIP"
                                />
                                {allowedDomains?.map(domain => (
                                    <div className={css.allowedDomainItem}>{domain}</div>
                                ))}
                            </>
                        )}
                        <WizardFooter
                            buttons={[
                                <Button onClick={onClose}>
                                    <Translate id="STATIC.BUTTONS.DONE" />
                                </Button>,
                            ]}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

AllowedOverrides.propTypes = {
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};

AllowedOverrides.defaultProps = {
    onChange: () => {},
    onSave: () => {},
    onClose: () => {},
};
