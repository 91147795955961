import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import { TESTING_CONSOLE_STATES } from '../utils';
import css from './LogsEmptyState.css';
import Accordion from '../../components/organisms/Accordion';
import { FAQ_ITEMS, MIXPANEL_EVENT_PREFIX, TIMEOUT_SHOW_FAQ } from '../consts';
import { Label } from '../../components/widgets';
import { trackMixpanelEvent } from '../../utils/general';

function LogsEmptyState({ pageProgressState, translate }) {
    const [logsSectionEmptyStateText, setLogsSectionEmptyStateText] = useState('');
    const [showFaqsText, setShowFaqsText] = useState(false);

    useEffect(() => {
        if (pageProgressState === TESTING_CONSOLE_STATES.NO_LOGS_FOR_CURRENT_DEVICE) {
            setTimeout(() => {
                setShowFaqsText(true);
            }, TIMEOUT_SHOW_FAQ);
        }
        return () => {
            setShowFaqsText(false);
        };
    }, [pageProgressState]);

    // Different Empty State Logic
    useEffect(() => {
        switch (pageProgressState) {
            case TESTING_CONSOLE_STATES.NEED_TO_CHOOSE_DEVICE:
                setLogsSectionEmptyStateText(
                    translate('STATIC.PAGES.TESTING_CONSOLE.EVENTS_INSTRUCTION_EMPTY_STATES.NEED_TO_CHOOSE_DEVICE')
                );
                break;
            default:
                setLogsSectionEmptyStateText('');
        }
    }, [pageProgressState]);

    const onFAQItemClick = item => {
        const { title } = item;
        trackMixpanelEvent(MIXPANEL_EVENT_PREFIX, 'No data - click accordion', {
            question: translate(title),
        });
    };

    return (
        <div className={css.logsEmptyStateWrapper}>
            {logsSectionEmptyStateText && <div className={css.emptyStateText}>{logsSectionEmptyStateText}</div>}

            {pageProgressState === TESTING_CONSOLE_STATES.NO_LOGS_FOR_CURRENT_DEVICE && (
                <>
                    <div className={css.mainLoaderContainer}>
                        <CircularProgress className={css.mainLoader} />
                        <Label text="STATIC.PAGES.TESTING_CONSOLE.EMPTY_STATE.FIRE_EVENTS" className={css.label} />
                        <img
                            src="/static/dashboard_react/src/resources/gif/open_app.gif"
                            alt="open_app_gif"
                            className={classNames({ [css.imgMain]: !showFaqsText, [css.imgSub]: showFaqsText })}
                        />
                    </div>
                    {showFaqsText && (
                        <div className={css.faqText}>
                            <Label text="STATIC.PAGES.TESTING_CONSOLE.EMPTY_STATE.FAQ.HEADER" className={css.header} />
                            <Accordion items={FAQ_ITEMS} className={css.accordion} onItemClick={onFAQItemClick} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

LogsEmptyState.propTypes = {
    pageProgressState: PropTypes.number.isRequired,
    translate: PropTypes.func,
};

LogsEmptyState.defaultProps = {
    translate: () => {},
};

export default withLocalize(LogsEmptyState);
