import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import { useFormikContext } from 'formik-2';
import WizardLayout from './WizardLayout';
import StepButtons from './StepButtons';
import useWizard from '../hook/useWizard';
import Checkbox from '../../../../components/atoms/Checkbox';
import css from './InternalDistribution.css';
import collapseCss from './components/CollapsableNetwork.css';
import { INTERNAL_S3_KEY } from '../consts';
import { ExpandablePanel, TextField } from '../../../../components/widgets';

const translatePrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTION_COMPONENTS.INTERNAL_DISTRIBUTION';

const InternalDistribution = ({ onSaveDraft, pageTitle, onNext, onBack, distributionsData, translate }) => {
    const { values, setFieldValue } = useFormikContext();
    const { shouldShowDraft } = useWizard(values);
    const [isExpanded, setIsExpanded] = useState(false);

    const { internalDistributions } = distributionsData;
    const internalDistribution = internalDistributions.distribution;
    const isDistributionEnabled = !!(
        internalDistribution?.active &&
        internalDistribution?.config?.folder_prefix &&
        internalDistribution.config?.bucket
    );

    const isInternalDistributionSelected = isDistributionEnabled && !!values.internalDistribution;

    const handleToggleDistribution = useCallback(
        newValue => {
            if (!newValue) {
                setIsExpanded(false);
            }
            const distributionConfig = newValue
                ? {
                      bucket: internalDistribution?.config?.bucket,
                      folder_prefix: internalDistribution?.config?.folder_prefix,
                  }
                : null;
            setFieldValue('internalDistribution', distributionConfig);
        },
        [setFieldValue]
    );

    const handleExpandPanel = useCallback(() => {
        setIsExpanded(prevExpanded => !prevExpanded);
    }, []);

    const renderS3Fields = () => (
        <>
            <TextField
                key="bucket"
                label={`${translatePrefix}.S3_BUCKET_LABEL`}
                disabled
                value={internalDistribution?.config?.bucket}
                id="bucket_field"
            />
            <TextField
                key="folder_prefix"
                label={`${translatePrefix}.S3_FOLDER_PREFIX_LABEL`}
                disabled
                containerClass={css.lastInputContainer}
                value={internalDistribution?.config?.folder_prefix}
                id="folder_prefix_field"
            />
        </>
    );

    const renderDistributionHeader = () => (
        <>
            {internalDistributions?.logo_path && (
                <img
                    className={css.icon}
                    src={internalDistributions.logo_path}
                    alt={`${internalDistributions.display_name} logo`}
                />
            )}
            {internalDistributions.display_name}{' '}
            {translate(
                internalDistribution?.config?.bucket
                    ? `${translatePrefix}.S3_WITH_BUCKET`
                    : `${translatePrefix}.S3_WITHOUT_BUCKET`
            )}
        </>
    );

    return (
        <WizardLayout
            title={pageTitle}
            shouldShowDraft={shouldShowDraft}
            onSaveDraft={onSaveDraft}
            buttons={
                <StepButtons
                    onBack={onBack}
                    onNext={onNext}
                    showSkip
                    backTitle="BUTTONS.BACK"
                    nextTitle="STATIC.BUTTONS.NEXT"
                />
            }
        >
            <div className={css.container}>
                {!isDistributionEnabled && (
                    <div>
                        <Translate id={`${translatePrefix}.SUBTITLE`} />
                    </div>
                )}

                <div className={css.checkboxContainer}>
                    <Checkbox
                        checked={isInternalDistributionSelected}
                        size="small"
                        disabled={!isDistributionEnabled}
                        onChange={handleToggleDistribution}
                        labelClass={css.checkmarkLabel}
                        label={<Translate id={`${translatePrefix}.ALLOW_SENDING`} />}
                    />
                </div>

                <ExpandablePanel
                    id={INTERNAL_S3_KEY}
                    key={INTERNAL_S3_KEY}
                    expanded={isExpanded}
                    disabled={!isInternalDistributionSelected}
                    content={<div>{renderS3Fields()}</div>}
                    header={renderDistributionHeader()}
                    overrideClasses={{
                        container: collapseCss.testChannelsContainerOverride,
                        headerContainer: collapseCss.testChannelsHeaderOverride,
                        contentArea: collapseCss.testChannelsContentContainerOverride,
                        arrowArea: collapseCss.testChannelsArrowAreaOverride,
                        externalHeaderArea: collapseCss.externalHeaderAreaOverride,
                    }}
                    onExpandClick={handleExpandPanel}
                    animate
                />
            </div>
        </WizardLayout>
    );
};

InternalDistribution.propTypes = {
    onSaveDraft: PropTypes.func,
    onNext: PropTypes.func,
    translate: PropTypes.func,
    onBack: PropTypes.func,
    pageTitle: PropTypes.string,
    distributionsData: PropTypes.shape({
        allNetworks: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                name: PropTypes.string,
                isDisabled: PropTypes.bool,
            })
        ),
        internalDistributions: PropTypes.any,
        distributionNetworks: PropTypes.any,
    }),
};

export default withLocalize(InternalDistribution);
