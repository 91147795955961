import React from 'react';
import Step from '../../../../../../components/organisms/Step';
import css from './content.css';
import StepsList from '../../../../../../components/organisms/StepsList';
import ScreenshotStepOne from '../../../../../../resources/png/sdk-planning/swift-package-manager.png';
import ScreenshotStepThree from '../../../../../../resources/png/sdk-planning/build-phases.png';
import ScreenshotStepFour from '../../../../../../resources/png/sdk-planning/build-settings.png';
import { Label } from '../../../../../../components/widgets';
import CodeBlock from '../../../../../../components/organisms/CodeBlock';
import { COCOA_PODS, defaultConfigIOS, IMPORT_CODE_SNIPPET } from './consts';
import { useSDKCodeSnippetContext } from '../../../../codeSnippets/context/useSDKCodeSnippetContext';
import ScreenshotStepFive from '../../../../../../resources/png/sdk-planning/swift-bridger.png';
import ScreenshotStepSeven from '../../../../../../resources/png/sdk-planning/objective-c-bridger.png';
import { sendMixpanelEventSdkIntegration } from '../../../../utils';
import { IOS_NATIVE, SDK_INTEGRATION_SECTIONS } from '../../../../consts';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../../../consts';

const { CODEBLOCK_THEME_TOGGLED, SHOW_KEYS, COPY_CODE } = SDK_INTEGRATION_MIXPANEL_EVENTS;
const { GET_SDK_FILES } = SDK_INTEGRATION_SECTIONS;

const XcodeSetVersion = () => {
    const { codeBlockDarkTheme, toggleCodeBlockTheme } = useSDKCodeSnippetContext();

    const handleSendMixpanelEvent = event => sendMixpanelEventSdkIntegration(event, GET_SDK_FILES, IOS_NATIVE);

    const handleToggleCodeBlockTheme = toggle => {
        toggleCodeBlockTheme(toggle);
        handleSendMixpanelEvent(CODEBLOCK_THEME_TOGGLED, {
            step: SDK_INTEGRATION_SECTIONS.GET_SDK_FILES,
        });
    };

    return (
        <div className={css.container}>
            <StepsList>
                <Step title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_1.TITLE">
                    <Label
                        type="p"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_1.TEXT"
                        className={css.doubleMargin}
                    />
                    <img className={css.landscapeImage} src={ScreenshotStepOne} />
                </Step>
                <Step title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_2.TITLE" />
                <Step
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_3.TITLE"
                    className={css.doubleMargin}
                >
                    <img className={css.landscapeImage} src={ScreenshotStepThree} />
                </Step>
                <Step
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_4.TITLE"
                    titleClassName={css.doubleMargin}
                >
                    <img className={css.stepFourImage} src={ScreenshotStepFour} />
                </Step>
                <Step title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_5.TITLE">
                    <Label
                        type="p"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_5.TEXT"
                        className={css.doubleMargin}
                    />
                    <img className={css.landscapeImage} src={ScreenshotStepFive} />
                </Step>
                <Step
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_6.TITLE"
                    titleClassName={css.doubleMargin}
                >
                    <CodeBlock
                        {...IMPORT_CODE_SNIPPET}
                        {...defaultConfigIOS}
                        customTitle={COCOA_PODS}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        containerClassName={css.codeBlock}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
                <Step title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_7.TITLE">
                    <Label
                        type="p"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.IOS.GET_SDK_FILES.XCODE_SET_VERSION.STEP_7.TEXT"
                        className={css.doubleMargin}
                    />
                    <img className={css.landscapeImage} src={ScreenshotStepSeven} />
                </Step>
            </StepsList>
        </div>
    );
};

export default XcodeSetVersion;
