import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Translate, withLocalize } from 'react-localize-redux';
import { useFormikContext } from 'formik-2';
import WizardLayout from './WizardLayout';
import StepButtons from './StepButtons';
import css from './Events.css';
import { Spinner, WarningMessage } from '../../../../components/widgets';
import NewTagInput from '../../../../components/widgets/NewTagInput';
import { DISABLED_EVENTS, EVENTS_LIMIT } from '../consts';
import { GET_EVENTS } from '../../../queries';
import { normalizeEventForDisplay } from './wizardUtils';
import useWizard from '../hook/useWizard';

const translatePrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTION_COMPONENTS.EVENTS';
export const WIZARD_DATA_TEST_ID_EVENTS = 'events-wizard';
const DISABLED_EVENTS_VALUES = DISABLED_EVENTS.map(event => event.value);

const Events = ({ onSaveDraft, pageTitle, onBack, onNext, translate }) => {
    const { values, setFieldValue } = useFormikContext();
    const { shouldShowDraft, isAlreadyStarted } = useWizard(values);
    const { data: eventsData, loading } = useQuery(GET_EVENTS, {
        variables: { siteId: values.appsite?.value },
        skip: !values.appsite?.value,
        fetchPolicy: 'cache-first',
    });

    const normalizeEvents = useMemo(() => {
        if (loading || !eventsData) return [];
        const { events } = eventsData;
        return normalizeEventForDisplay(events);
    }, [eventsData, loading]);

    const normalizeSelectedEvents = (events, normalizedEvents) => {
        if (!events) return [];
        return events.map(eventName => {
            return normalizedEvents.find(optionEvent => optionEvent.value === eventName);
        });
    };

    const onTagsChange = tags => {
        setFieldValue(
            'events',
            tags.filter(event => !DISABLED_EVENTS_VALUES.includes(event.value)).map(event => event.value)
        );
    };

    if (loading) {
        return <Spinner show expanded />;
    }

    return (
        <WizardLayout
            title={pageTitle}
            onSaveDraft={onSaveDraft}
            shouldShowDraft={shouldShowDraft}
            isContentScrollable={false}
            buttons={
                <StepButtons onBack={onBack} onNext={onNext} backTitle="BUTTONS.BACK" nextTitle="STATIC.BUTTONS.NEXT" />
            }
        >
            <div data-testid={WIZARD_DATA_TEST_ID_EVENTS} className={css.container}>
                <Translate id={`${translatePrefix}.SUBTITLE`} />
                <div className={css.container}>
                    {values.events.length >= EVENTS_LIMIT && (
                        <WarningMessage
                            className={css.errorContainer}
                            showIcon={false}
                            type="warning"
                            message={`${translatePrefix}.LIMIT_EVENTS`}
                            textReplace={{ limitNumber: EVENTS_LIMIT + DISABLED_EVENTS.length }}
                        />
                    )}
                    <div className={css.contentContainer}>
                        <span>
                            <Translate id={`${translatePrefix}.ADD_EVENTS`} /> ({values.events.length + DISABLED_EVENTS.length}/
                            {EVENTS_LIMIT + DISABLED_EVENTS.length})
                        </span>
                        <NewTagInput
                            disabled={isAlreadyStarted}
                            tagsLimitMessage={translate(`${translatePrefix}.LIMIT_EVENTS`)}
                            tags={[...DISABLED_EVENTS, ...normalizeSelectedEvents(values.events, normalizeEvents)]}
                            className={css.inputContainer}
                            options={values.events.length === EVENTS_LIMIT ? [] : normalizeEvents}
                            onChange={newTags => onTagsChange(newTags)}
                            virtualScrolling
                        />
                    </div>
                </div>
            </div>
        </WizardLayout>
    );
};

Events.propTypes = {
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    onSaveDraft: PropTypes.func,
    translate: PropTypes.func,
    pageTitle: PropTypes.string,
};

export default withLocalize(Events);
