import React from 'react';
import PropTypes from 'prop-types';

import { Translate } from 'react-localize-redux';
import css from './CreativePopupDimensionTags.css';
import { CreativeType, CreativeTypes, OrientationTypes } from '../../types';
import OverflowingText from '../../../components/foundations/OverflowingText';
import { AutoCompleteOptionType } from '../../../components/widgets/AutoCompleteField';
import OSIcon from '../OSIcon/OSIcon';
import { NO_VALUE } from '../../consts';

export const TRANSLATION_KEY_PREFIX = 'STATIC.PAGES.TAGS_VISIBILITY.DIMENSIONS_INFO';
export const DURATION_KEY = 'DURATION';
export const TYPE_KEY = 'TYPE';
export const SIZE_KEY = 'SIZE';
export const ORIENTATION_KEY = 'ORIENTATION';
const TranslationElement = (translationKey, value) => (
    <Translate id={`${TRANSLATION_KEY_PREFIX}.${translationKey}`} data={{ value: value || NO_VALUE }} />
);

const Tag = React.memo(({ icon, value }) => (
    <div className={css.tag}>
        {icon}
        {value && <OverflowingText>{value}</OverflowingText>}
    </div>
));

Tag.propTypes = {
    icon: PropTypes.element,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const CreativeMetadata = React.memo(({ name, values }) => (
    <div>
        {name}:{values.map(value => value)}
    </div>
));

CreativeMetadata.propTypes = {
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
};

const getOrientationType = (width, height) =>
    width === height
        ? OrientationTypes.SQUARE
        : width > height
        ? OrientationTypes.LANDSCAPE
        : OrientationTypes.PORTRAIT;

const CreativePopupDimensionTags = ({ app, source, creative, videoDuration }) => {
    const isSizeKnown = creative.width && creative.height;

    return (
        <div className={css.dimensionsContainer}>
            <div className={css.dimensionsRow}>
                <Tag value={app.label} icon={app.icon} />
                <Tag value={source.label} icon={source.icon} />
                {creative.multiValuedDimensions.osList?.map(os => (
                    <Tag key={os} icon={<OSIcon className={css.osIcon} osValue={os} />} />
                ))}
            </div>
            <div className={css.dimensionsRow}>
                {creative.type === CreativeTypes.VIDEO && (
                    <Tag value={TranslationElement(DURATION_KEY, videoDuration && `${videoDuration.toFixed(0)} Sec`)} />
                )}
                <Tag value={TranslationElement(TYPE_KEY, creative.type)} />
                <Tag value={TranslationElement(SIZE_KEY, isSizeKnown && `${creative.width}X${creative.height}`)} />
                <Tag
                    value={TranslationElement(
                        ORIENTATION_KEY,
                        isSizeKnown && getOrientationType(creative.width, creative.height)
                    )}
                />
            </div>
        </div>
    );
};

CreativePopupDimensionTags.propTypes = {
    creative: PropTypes.shape(CreativeType).isRequired,
    app: AutoCompleteOptionType.isRequired,
    source: AutoCompleteOptionType.isRequired,
    videoDuration: PropTypes.number,
};

CreativePopupDimensionTags.defaultProps = {};

export default CreativePopupDimensionTags;
