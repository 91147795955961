import React, { Fragment, useState, useEffect } from 'react';
import { Translate } from 'react-localize-redux';

import { useQuery } from '@apollo/client';
import * as Papa from 'papaparse';

import { AutoCompleteField, Toggle } from 'components/widgets';
import { addSchema } from 'governance/schemas/schemasTable';
import GovernanceEmptyState from 'governance/schemas/GovernanceEmptyState';
import { getSchemaTypeDisplayName } from 'governance/schemas/schema';
import css from '../governance.css';
import { PageHeader, PageWrapper } from '../../components/partials';
import Button from '../../components/atoms/Button';
import { READ_SCHEMAS } from '../queries';
import CodeTable from './codeTable';

let LAST_CODES_LIST = null;
let reloadOnNextRender = false;

function setReloadOnNextRender() {
    reloadOnNextRender = true;
}

function csvExport() {
    if (!LAST_CODES_LIST || LAST_CODES_LIST.length === 0) return;

    // Build a list of lists representing the records, and allFields represents the header row
    const allFields = ['Governance ID', 'Name'];
    const csvData = LAST_CODES_LIST.map(code => {
        const record = [code.governanceId, code.name];
        code.fields.forEach(field => {
            let idx = allFields.indexOf(field.name);
            if (idx === -1) {
                allFields.push(field.name);
                idx = allFields.length - 1;
            }
            record[idx] = field.values.map(x => x.value).join(', ');
        });
        return record;
    });

    // Convert to CSV
    const csv = Papa.unparse({ data: csvData, fields: allFields });

    // Build a blog that is a downloadable file, create a link to it, and click it, to initiate download
    const blob = new Blob([csv], { type: 'text/csv' });
    const filename = 'taxonomy_code_export.csv';
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
    }, 100);
}

function Page() {
    const { loading, error, data, refetch } = useQuery(READ_SCHEMAS, { notifyOnNetworkStatusChange: true });
    const [activeSchema, setActiveSchema] = useState(null);
    const [newCode, setNewCode] = useState(false);
    // const [selectedSchemaOption, setSelectedSchemaOption] = useState(undefined);
    const [isShowRecentOnlyChecked, setIsShowRecentOnlyChecked] = useState(true);
    const schemas = data?.schemas?.filter(schema => !schema.id.startsWith('local'));

    useEffect(() => {
        if (data) {
            setActiveSchema(schemas[0]);
        }
    }, [data]);

    const selectedSchemaChange = option => {
        const selectedSchema = option ? schemas.find(schema => schema.id === option.value) : null;
        setActiveSchema(selectedSchema);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (reloadOnNextRender) {
        refetch();
        reloadOnNextRender = false;
    }

    if (error) {
        return <div>Error!</div>;
    }

    if (!schemas || schemas.length === 0) {
        return <GovernanceEmptyState />;
    }

    const schemaOptions = schemas.map(schema => ({
        value: schema.id,
        label: `${getSchemaTypeDisplayName(schema.type)} - ${schema.name}`,
    }));
    const selectedSchemaOption = schemaOptions?.find(option => option.value === activeSchema?.id);

    const onShowRecentToggle = () => {
        setIsShowRecentOnlyChecked(!isShowRecentOnlyChecked);
    };

    return (
        <div className={`${css.manageCodes} ${css.mainArea}`}>
            <div className={css.headerRow}>
                <div className={css.schemaSelection}>
                    <AutoCompleteField
                        containerClass={css.schemaSelectionDropdown}
                        options={schemaOptions}
                        searchable
                        clearable
                        onChange={selectedSchemaChange}
                        controlled
                        key={selectedSchemaOption}
                        value={selectedSchemaOption}
                        label="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.SELECT_SCHEMA"
                        placeholder="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.SELECT_SCHEMA"
                        isMulti={false}
                        debounceTime={500}
                    />
                </div>
                <div className={css.buttonsContainer}>
                    <Toggle
                        label={<Translate id="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.SHOW_RECENT_ONLY" />}
                        checked={isShowRecentOnlyChecked}
                        onToggle={onShowRecentToggle}
                        className={css.leftToggle}
                    />
                    <Button onClick={csvExport} type="secondary" level="level1">
                        <Translate id="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.EXPORT_CODES" />
                    </Button>
                    <Button onClick={() => setNewCode(true)} type="primary" level="level1">
                        <Translate id="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.CREATE_GOVERNANCE_CODE" />
                    </Button>
                </div>
            </div>
            {activeSchema && (
                <CodeTable
                    key={`table${activeSchema.id}`}
                    schema={activeSchema}
                    newCode={newCode}
                    setNewCode={setNewCode}
                    updateCodesList={newCodeList => {
                        LAST_CODES_LIST = newCodeList;
                    }}
                    showRecentOnly={isShowRecentOnlyChecked}
                />
            )}
        </div>
    );
}

function PageWithHeader() {
    return (
        <PageWrapper className={css.container}>
            <PageHeader
                text="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.PAGE_HEADER"
                subText={{
                    faqLink:
                        'https://support.singular.net/hc/en-us/articles/4404810576283-How-to-Manage-Governance-Codes',
                }}
            />
            <Page />
        </PageWrapper>
    );
}

PageWithHeader.locationHandler = location => {
    if (location.pathname !== '/react/governance/manage-codes') {
        setReloadOnNextRender();
    }
};

export default PageWithHeader;
