import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './PacingCard.css';
import Label from '../../components/foundations/Label';
import { WarningMessage } from '../../components/widgets';
import ProgressBar from '../../components/widgets/ProgressBar';
import { DATA_TEST_ID_ORG_PLAN_PROGRESS, PACING_TYPES, WARNING_TYPES } from '../consts';
import { calculateWarningVariables, calculateColorByPercent } from '../utils';

const pacingTypes = {
    spend: {
        title: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.TITLES.AD_SPEND',
        isMoney: true,
        tracked: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.DESCRIPTION.AD_SPEND',
        warningValueType: 'USD',
    },
    installs: {
        title: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.TITLES.CONVERSIONS',
        isMoney: false,
        tracked: 'STATIC.PAGES.ACCOUNT_PLAN.PACING_CARD.DESCRIPTION.CONVERSIONS',
        warningValueType: 'conversions',
    },
};

function PacingCard({ type, currentValue, maxValue, minValue, expiresInDays, isFreeTier, isPaying }) {
    const [percent, setPercent] = useState(0);
    const [color, setColor] = useState('var(--warning1Color)');
    const [warningType, setWarningType] = useState('externalLink');
    const [warningMessage, setWarningMessage] = useState('');

    useEffect(() => setPercent(maxValue > 0 ? Math.floor((currentValue / maxValue) * 100) : 0), [
        currentValue,
        maxValue,
    ]);

    useEffect(() => {
        setColor(calculateColorByPercent(percent));
        const warning = calculateWarningVariables(isFreeTier, isPaying, expiresInDays, percent);
        setWarningMessage(warning.message);
        setWarningType(warning.type);
    }, [isPaying, percent, expiresInDays, isFreeTier]);

    return (
        <div
            className={classNames(css.pacingCardContainer, {
                [css.attention]: warningType === WARNING_TYPES.ERROR,
            })}
            style={{ '--pacing-bar-header-color': color }}
            data-testid={DATA_TEST_ID_ORG_PLAN_PROGRESS}
        >
            <div className={css.pacingCardTop}>
                <Label className={css.pacingTitle} text={pacingTypes[type].title} />
                <div className={css.lineSeperator} />
                <div className={css.pacingBarHeaderContainer}>
                    <Label
                        className={css.pacingBarHeader}
                        text={`${pacingTypes[type].isMoney ? '$' : ''}${currentValue.toLocaleString()} ${
                            pacingTypes[type].isMoney ? 'USD' : ''
                        }`}
                    />
                    <Label
                        className={css.pacingBarInfo}
                        text={pacingTypes[type].tracked}
                        tooltip={type === PACING_TYPES.INSTALLS ? 'STATIC.PAGES.ACCOUNT_PLAN.TOOLTIPS.PACING_CARD' : ''}
                    />
                </div>

                <ProgressBar
                    progressPercentage={percent}
                    barHeight="15px"
                    barWidth="100%"
                    containerColor="var(--gray200, #EDEDED)"
                    fillColor={color}
                    showLegend
                    range={[minValue, maxValue]}
                    valueType={pacingTypes[type].isMoney ? 'money' : 'number'}
                />
            </div>
            <WarningMessage
                className={css.warningMessage}
                type={warningType}
                message={warningMessage}
                showIcon={false}
                showTypeIcon
                showSpacer={false}
                textReplace={{
                    usageLeft: Math.abs(maxValue - currentValue).toLocaleString(),
                    maxValue: Math.abs(maxValue).toLocaleString(),
                    expiresInDays: Math.abs(expiresInDays).toLocaleString(),
                    valueType: pacingTypes[type].warningValueType,
                }}
            />
        </div>
    );
}

PacingCard.propTypes = {
    type: PropTypes.oneOf(Object.values(PACING_TYPES)).isRequired,
    currentValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    minValue: PropTypes.number,
    expiresInDays: PropTypes.number,
    isFreeTier: PropTypes.bool,
    isPaying: PropTypes.bool,
};

PacingCard.defaultProps = {
    minValue: 0,
    expiresInDays: 365,
    isFreeTier: false,
    isPaying: false,
};

export default PacingCard;
