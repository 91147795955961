import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import css from './WhitePageHeader.css';
import Label from '../../foundations/Label';

const WhitePageHeader = ({ text, subheaderText, className, style, textStyle }) => {
    return (
        <div className={className} style={style}>
            <div className={css.text} style={textStyle}>
                <Translate id={text} />
            </div>
            {subheaderText && (
                <div className={css.subHeader}>
                    <Label type="p" text={subheaderText} />
                </div>
            )}
        </div>
    );
};

WhitePageHeader.propTypes = {
    text: PropTypes.string,
    subheaderText: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
    textStyle: PropTypes.objectOf(PropTypes.any),
};

WhitePageHeader.defaultProps = {
    text: '',
    subheaderText: undefined,
    className: '',
    style: undefined,
    textStyle: {},
};

export default WhitePageHeader;
