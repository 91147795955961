import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import css from './Label.css';
import QuestionMark from '../widgets/QuestionMark';

function Label(props) {
    const {
        style,
        text,
        type,
        textReplace,
        mandatory,
        className,
        onClick,
        tooltip,
        tooltipClassName,
        icon: Icon,
        iconClassName,
    } = props;
    return (
        <div
            className={classNames(css.container, css[type], className, { [css.mandatory]: mandatory })}
            style={style}
            onClick={onClick}
        >
            {Icon ? <Icon className={classNames(css.icon, iconClassName)} /> : null}
            <Translate id={text} data={textReplace} />
            {tooltip ? (
                <QuestionMark
                    containerClassName={classNames(css.tooltip, tooltipClassName)}
                    interactive
                    message={tooltip}
                />
            ) : null}
        </div>
    );
}

Label.propTypes = {
    style: PropTypes.objectOf(PropTypes.any),
    mandatory: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'shelfHeader',
        'p',
        'subtitle',
        'fieldLabel',
        'tableTd',
        'tableTh',
        'dialogTitle',
        'dialogBody',
        'sideLabel',
        'error',
        'header',
        null,
    ]),
    textReplace: PropTypes.objectOf(PropTypes.any),
    onClick: PropTypes.func,
    tooltipClassName: PropTypes.string,
    tooltip: PropTypes.string,
    icon: PropTypes.func,
    iconClassName: PropTypes.string,
};

Label.defaultProps = {
    style: {},
    mandatory: undefined,
    text: '',
    type: null,
    textReplace: {},
    className: undefined,
    onClick: e => {},
    tooltip: null,
    tooltipClassName: '',
    icon: null,
    iconClassName: '',
};

export default Label;
