import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Button } from '../../components/widgets';
import css from './TopArea.css';

export default function TopArea({ onCreateNew }) {
    return (
        <div className={css.subHeaderContainer}>
            <Button onClick={onCreateNew} type="primary" level="level1">
                <Translate id="STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.CREATE_NEW_TEST" />
            </Button>
        </div>
    );
}

TopArea.propTypes = {
    onCreateNew: PropTypes.func,
};

TopArea.defaultProps = {};
