import React from 'react';
import Label from '../../components/foundations/Label';
import css from '../styles.css';
import EmptyStateBenchmarksMarketShare from '../../resources/svg/benchmarks_market_share_empty_state.svg';

const EmptyChartState = () => {
    return (
        <div className={css.emptyChartStateContainer}>
            <Label
                className={css.emptyChartStateText}
                text="STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.EMPTY_CHART_STATE_TEXT"
            />
            <EmptyStateBenchmarksMarketShare className={css.emptyChartStateImg} />
            <Label
                className={css.emptyChartStateSubtext}
                type="p"
                text="STATIC.PAGES.BENCHMARKS_MARKET_SHARE_OF_VOICE.EMPTY_CHART_STATE_SUBTEXT"
            />
        </div>
    );
};

export default EmptyChartState;
