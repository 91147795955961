import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Badge.css';

const Badge = ({ color, className, children }) => (
    <div style={{ color }} className={classNames(css.badge, className)}>
        {children}
    </div>
);

Badge.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};

Badge.defaultProps = {
    color: 'gray',
};

export default Badge;
