import React, { Fragment, useLayoutEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Label, Button, EmptyState, AutoCompleteField } from '../components/widgets';
import TopArea from './components/TopArea';
import SearchArea from './components/SearchArea';
import SortingPanel from './components/SortingPanel';
import AppsList from '../components/partials/VerticalList/AppsList';
import css from './page.css';
import { PageWrapper } from '../components/partials';
import BannerShelf from './components/bannerShelf/BannerShelf';
import BannersList from './components/BannersList';
import { selectApp, addBannerClicked, selectAppSiteChanged } from './actions';
import { getApps, getAppsFilter, getBanners, getLoading, getLoadingConfigs, getSelectedAppSiteId } from './selectors';
import { filterBannersByName, sortBanners } from './utils';

export default function Banners() {
    const apps = useSelector(state => getApps(state));
    const appsFilter = useSelector(state => getAppsFilter(state));
    const allBanners = useSelector(state => getBanners(state));
    const loadingConfigs = useSelector(state => getLoadingConfigs(state));
    const loading = useSelector(state => getLoading(state));

    const dispatch = useDispatch();
    const onAddBannerClicked = () => dispatch(addBannerClicked());

    const [banners, setBanners] = useState(allBanners);
    const [searchingText, setSearchingText] = useState('');
    const selectedAppSiteId = useSelector(state => getSelectedAppSiteId(state));
    const [sites, setSites] = useState([]);

    // set the filtered banners before painting
    useLayoutEffect(() => {
        const filteredBanners = filterBannersByName(allBanners, searchingText);
        setBanners(filteredBanners);
    }, [allBanners, searchingText]);

    const onSortingChanged = (field, isDescending) => {
        const sortedBanners = sortBanners(banners, field, isDescending);
        setBanners(sortedBanners);
    };

    const onAppSelected = appId => {
        const getLabelFromSite = site => {
            const url = site?.destinations[0]?.destination_url;
            if (url) {
                return url;
            } else {
                return site.site_public_id;
            }
        };

        // get sites:
        const sitesForApp = apps
            .filter(app => app.id === appId)[0]
            .sites.filter(site => site.platform === 'Web')
            .map(site => ({
                value: site.site_id.toString(),
                label: getLabelFromSite(site),
            }));
        setSites(sitesForApp);
        dispatch(selectApp(appId));
        dispatch(selectAppSiteChanged(sitesForApp[0].value.toString()));
    };

    if (loading) {
        return <Spinner show expanded />;
    }

    const onSelectedAppSiteChanged = e => {
        const siteId = e.value;
        dispatch(selectAppSiteChanged(siteId));
    };

    const selectedAppSiteOption = sites.find(({ value }) => value === selectedAppSiteId);

    return (
        <PageWrapper className={css.container} allowDropDownPageScrolling disablePadding>
            <div className={css.topArea}>
                <TopArea search={appsFilter} />
            </div>
            <div className={css.mainArea}>
                <AppsList className={css.leftArea} apps={apps} appsFilter={appsFilter} onAppSelected={onAppSelected} />
                <div className={css.rightArea}>
                    <div className={css.rightAreaTop}>
                        <div className={css.mainText}>
                            <div className={css.headerTopAreaContainer}>
                                <div>
                                    <Label
                                        text="STATIC.PAGES.BANNERS.BANNERS_HEADER"
                                        style={{ marginBottom: 12 }}
                                        type="h2"
                                    />
                                    <Label text="STATIC.PAGES.BANNERS.BANNERS_SUB_HEADER" type="shelfHeader" />
                                </div>
                                <AutoCompleteField
                                    containerStyle={{ width: '300px' }}
                                    containerClass="sites-ac"
                                    name="sitesac"
                                    options={sites}
                                    placeholder="STATIC.PAGES.BANNERS.SITES_PLACEHOLDER"
                                    searchable
                                    disabled={sites.length === 1}
                                    isMulti={false}
                                    label="STATIC.PAGES.BANNERS.SITES_LABEL"
                                    value={selectedAppSiteOption}
                                    onChange={onSelectedAppSiteChanged}
                                    onInputChange={onSelectedAppSiteChanged}
                                    controlled
                                    openMenuOnClick
                                    key={selectedAppSiteId} // trigger reconciliation and thus re-rendering the selected item
                                />
                            </div>
                            <div className={css.actionsContainer}>
                                <SearchArea
                                    searchingText={searchingText}
                                    onChange={setSearchingText}
                                    placeholder="STATIC.PAGES.BANNERS.BANNERS_SEARCH_PLACEHOLDER"
                                    disabled={!allBanners?.length}
                                />
                                <Button type="secondary" onClick={onAddBannerClicked} disabled={loadingConfigs}>
                                    <Translate id="STATIC.PAGES.BANNERS.ADD_BANNER" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={css.rightAreaMain}>
                        {loadingConfigs ? (
                            <Spinner show className={css.spinner} />
                        ) : (
                            <>
                                {allBanners?.length ? (
                                    <Fragment>
                                        <SortingPanel onSortingChanged={onSortingChanged} />
                                        <BannersList banners={banners} />
                                    </Fragment>
                                ) : (
                                    <EmptyState
                                        icon="startAdding"
                                        header="STATIC.PAGES.BANNERS.EMPTY_STATE_HEADER"
                                        subHeader="STATIC.PAGES.BANNERS.EMPTY_STATE_SUBHEADER"
                                        containerClass={css.emptyState}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <BannerShelf />
        </PageWrapper>
    );
}
