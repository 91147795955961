import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TableCellTags.css';
import Tag from '../atoms/Tag';
import CellLoader from './CellLoader';

const TableCellTags = ({ value: tags, className, tagClassName, color, isLoading, loaderClass }) => {
    return (
        <td key={tags.join('_')} className={classNames(className, css.tagsContainer, { [css.loading]: isLoading })}>
            {isLoading ? (
                <CellLoader loaderClass={loaderClass} />
            ) : (
                tags.map(tag => <Tag key={tag} value={tag} color={color} className={tagClassName} />)
            )}
        </td>
    );
};

TableCellTags.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    tagClassName: PropTypes.string,
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    loaderClass: PropTypes.string,
};

TableCellTags.defaultProps = {
    value: [],
    className: '',
    color: null,
    isLoading: false,
};

export default TableCellTags;
