import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate, withLocalize } from 'react-localize-redux';
import posed, { PoseGroup } from 'react-pose';
import CustomDimensionValue from 'customDimensions/containers/CustomDimensionValue';
import Tooltip from 'components/widgets/Tooltip';
import FauxButton from 'components/widgets/FauxButton';
import TrashIcon from 'resources/svg/trash.svg';
import CalendarIcon from 'resources/svg/calendar.svg';
import ExpandablePanel from 'components/widgets/ExpandablePanel';
import EditableTitle from 'components/widgets/EditableTitle';
import css from 'customDimensions/components/CustomDimension.css';
import AutoComplete from 'components/widgets/AutoComplete';
import WizardWarningMessage from 'teamManagement/components/WizardWarningMessage';
import AddIcon from 'resources/icons/plus_link.svg';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReorderValuesDialog from 'customDimensions/containers/ReorederValuesDialog';
import Button from '../../components/atoms/Button';
import ReorderValuesIcon from '../../resources/icons/reorder_values.svg';
import { trackMixpanelEvent } from '../../utils/general';

const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 300,
        },
        delay: 50,
    },
    exit: {
        opacity: 0,
        y: -50,
        transition: {
            duration: 0,
        },
    },
});

const CONDITIONS_AMOUNT_WARNING_DIFF = 10;

const getSubHeaderData = (maxConditionsPerDimension, numberOfConditions, saveStatus, name) => {
    let subHeaderMessage = '';
    let subHeaderType = 'info';
    let subHeaderMiddle = false;

    if (maxConditionsPerDimension <= numberOfConditions + CONDITIONS_AMOUNT_WARNING_DIFF) {
        subHeaderMessage = `Only ${maxConditionsPerDimension} conditions are allowed, you currently have ${numberOfConditions}`;
        subHeaderType = 'warning';
    }
    if (saveStatus === 'displayErrors') {
        subHeaderMessage = 'Error while saving - some fields are misconfigured';
        subHeaderType = 'error';
    }
    if (maxConditionsPerDimension < numberOfConditions) {
        subHeaderMessage = `Only ${maxConditionsPerDimension} conditions are allowed, you currently have ${numberOfConditions}`;
        subHeaderType = 'error';
    }
    if (saveStatus === 'saved') {
        subHeaderMessage = `Custom dimension ${name} saved successfully`;
        subHeaderType = 'success';
        subHeaderMiddle = true;
    }

    return {
        subHeaderMessage,
        subHeaderType,
        subHeaderMiddle,
    };
};

const TRACK_EVENT_PREFIX = 'Custom Dimension';
const TRACK_REORDER_EVENT_NAME = 'Reordered values done';

const CustomDimension = ({
    id,
    name,
    defaultValue,
    values,
    valueIds,
    onDimensionExpansionToggle,
    expanded,
    onDimensionNameChanged,
    onDimensionDeleted,
    onValueAdded,
    onDefaultValueChanged,
    onDimensionUndoChanges,
    onDimensionSave,
    translate,
    saveStatus,
    maxConditionsPerDimension,
    numberOfConditions,
    supportedMacroDimensions,
    v3DimensionInUse,
    tab,
    showCalendarIcon,
}) => {
    const [macroOptions, setMacroOptions] = useState([]);
    const [selectedDefaultValue, setSelectedDefaultValue] = useState();
    const [openReorderValuesDialog, setOpenReorderValuesDialog] = useState(false);

    const reordered = useRef(false);

    useEffect(() => {
        const optionsDefaultValue = supportedMacroDimensions.find(({ value }) => value === defaultValue);
        if (!optionsDefaultValue && !!defaultValue) {
            const defaultValueOption = { value: defaultValue, label: defaultValue };
            setSelectedDefaultValue({ value: defaultValue, label: defaultValue });
            setMacroOptions([...supportedMacroDimensions, defaultValueOption]);
        } else {
            setSelectedDefaultValue(optionsDefaultValue);
            setMacroOptions(supportedMacroDimensions);
        }
    }, [supportedMacroDimensions, defaultValue]);

    const { subHeaderMessage, subHeaderType, subHeaderMiddle } = useMemo(
        () => getSubHeaderData(maxConditionsPerDimension, numberOfConditions, saveStatus, name),
        [maxConditionsPerDimension, numberOfConditions, saveStatus, name]
    );

    const onReorderDone = useCallback(() => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_REORDER_EVENT_NAME, { dimensionId: id });
        reordered.current = true;
    }, []);

    return (
        <>
            <AnimationItem key={id}>
                <ExpandablePanel
                    id={id}
                    animate={false}
                    onExpandClick={onDimensionExpansionToggle}
                    expanded={expanded}
                    isSticky
                    overrideClasses={{ arrowArea: css.overrideArrowArea }}
                    header={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: 25 }}>
                                {v3DimensionInUse && showCalendarIcon && (
                                    <Tooltip
                                        title={translate(
                                            'STATIC.PAGES.CUSTOM_DIMENSIONS.CUSTOM_DIMENSION_DATE_WARNING',
                                            {
                                                v3_dimension: v3DimensionInUse,
                                            }
                                        )}
                                    >
                                        <CalendarIcon className={css.customDimensionCalendarIcon} />
                                    </Tooltip>
                                )}
                            </div>
                            <div style={{ fontSize: 18, fontWeight: 500 }}>
                                {expanded ? (
                                    <EditableTitle
                                        placeholder="Add a dimension name"
                                        value={name}
                                        onTextChanged={value => {
                                            onDimensionNameChanged(id, value);
                                        }}
                                        containerStyle={{ marginTop: 4 }}
                                        error={
                                            saveStatus === 'displayErrors' && name.length === 0
                                                ? 'must not be empty'
                                                : ''
                                        }
                                    />
                                ) : (
                                    <div style={{ marginTop: 2 }}>
                                        {name ? (
                                            <span>{name}</span>
                                        ) : (
                                            <span style={{ fontStyle: 'italic', color: '#ccc' }}>
                                                Add a dimension name
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                            <TrashIcon
                                className={css.deleteDimensionContainer}
                                style={{ marginTop: expanded ? 7 : 4, marginLeft: 'auto' }}
                                onClick={e => {
                                    e.stopPropagation();
                                    onDimensionDeleted(id);
                                }}
                            />
                        </div>
                    }
                    collapsedContent={
                        <div className={css.collapsedContentContainer}>
                            {values.map((value, i) => (
                                <div
                                    key={`collapsed_${valueIds[i]}`}
                                    className={`${css.collapsedValueItem} ${css[tab]}`}
                                >
                                    {value ? (
                                        <span>{value}</span>
                                    ) : (
                                        <span style={{ fontStyle: 'italic', color: '#bbb' }}>Add a value name</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    }
                    subHeader={
                        expanded ? (
                            <WizardWarningMessage
                                show={!!(subHeaderMessage && expanded)}
                                showIcon={false}
                                message={subHeaderMessage}
                                type={subHeaderType}
                                textAlignCenter={subHeaderMiddle}
                            />
                        ) : null
                    }
                    content={
                        <div style={{ padding: 20 }}>
                            <PoseGroup>
                                {valueIds.map(valueId => {
                                    return <CustomDimensionValue id={valueId} key={valueId} />;
                                })}
                            </PoseGroup>
                            <div className={classNames(css.footerBlock, css.valuesActionsButtonsBlock)}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        onValueAdded(id);
                                    }}
                                    className={css.addValueButton}
                                    contentClass={css.addValueButtonContent}
                                    text="STATIC.PAGES.CUSTOM_DIMENSIONS.ADD_VALUE"
                                    Icon={AddIcon}
                                    iconClass={css.addValueIcon}
                                />
                                {(values?.length || 0) > 1 && (
                                    <FauxButton
                                        onClick={() => {
                                            setOpenReorderValuesDialog(true);
                                        }}
                                        className={css.reorderValuesButton}
                                    >
                                        <ReorderValuesIcon className={css.reorderValuesIcon} />
                                        <Translate id="STATIC.PAGES.CUSTOM_DIMENSIONS.REORDER_VALUES.BUTTON_LABEL" />
                                    </FauxButton>
                                )}
                            </div>
                            <div className={css.footerBlock}>
                                <div>
                                    <Translate id="STATIC.PAGES.CUSTOM_DIMENSIONS.IF_NO_VALUE_MATCHED_SET_DEFAULT_TO" />
                                </div>
                                <div className={css.defaultValueContainer}>
                                    <AutoComplete
                                        isCreatable
                                        hideDropdownIndicator
                                        selectOptions={{ options: macroOptions, isClearable: true }}
                                        defaultValue={defaultValue}
                                        placeholder={translate('STATIC.PAGES.CUSTOM_DIMENSIONS.PLACEHOLDERS.VALUE')}
                                        controlledValue={selectedDefaultValue}
                                        onChange={option => {
                                            onDefaultValueChanged(id, option ? option.value : null);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={css.footerButtonContainer}>
                                <Button
                                    type="secondary"
                                    onClick={() => saveStatus !== 'saving' && onDimensionUndoChanges(id)}
                                >
                                    <Translate id="STATIC.BUTTONS.CANCEL" />
                                </Button>
                                <Button
                                    type="primary"
                                    level="level1"
                                    forceActive={saveStatus === 'saving'}
                                    showV={saveStatus === 'saved'}
                                    disabled={maxConditionsPerDimension < numberOfConditions}
                                    onClick={() =>
                                        saveStatus !== 'saving' &&
                                        maxConditionsPerDimension >= numberOfConditions &&
                                        onDimensionSave(id, reordered.current)
                                    }
                                >
                                    <Translate id="STATIC.BUTTONS.SAVE" />
                                </Button>
                            </div>
                        </div>
                    }
                />
            </AnimationItem>
            <DndProvider backend={HTML5Backend}>
                {openReorderValuesDialog && (
                    <ReorderValuesDialog
                        open={openReorderValuesDialog}
                        onClose={() => setOpenReorderValuesDialog(false)}
                        id={id}
                        customDimensionName={name}
                        values={values}
                        valuesIds={valueIds}
                        onDone={onReorderDone}
                    />
                )}
            </DndProvider>
        </>
    );
};

CustomDimension.propTypes = {
    // Add your props definitions here
    translate: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string,
    expanded: PropTypes.bool,
    saveStatus: PropTypes.string,
    defaultValue: PropTypes.string,
    valueIds: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(PropTypes.string),
    onValueAdded: PropTypes.func,
    onDimensionExpansionToggle: PropTypes.func,
    onDimensionNameChanged: PropTypes.func,
    onDefaultValueChanged: PropTypes.func,
    onDimensionDeleted: PropTypes.func,
    onDimensionUndoChanges: PropTypes.func,
    onDimensionSave: PropTypes.func,
    maxConditionsPerDimension: PropTypes.number,
    numberOfConditions: PropTypes.number,
    v3DimensionInUse: PropTypes.string,
    supportedMacroDimensions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    tab: PropTypes.string,
    showCalendarIcon: PropTypes.bool,
};

CustomDimension.defaultProps = {
    // Add your props defaults here
    translate: () => {},
    id: '',
    name: '',
    expanded: false,
    saveStatus: false,
    defaultValue: '',
    valueIds: [],
    values: [],
    onValueAdded: () => null,
    onDimensionExpansionToggle: () => null,
    onDimensionNameChanged: () => null,
    onDefaultValueChanged: () => null,
    onDimensionDeleted: () => null,
    onDimensionUndoChanges: () => null,
    onDimensionSave: () => null,
    maxConditionsPerDimension: 0,
    numberOfConditions: 0,
    supportedMacroDimensions: [
        { value: '{source}', label: 'Source (macro)' },
        { value: '{sub_ad_network}', label: 'Sub Ad Network (macro)' },
    ],
    v3DimensionInUse: null,
    tab: '',
    showCalendarIcon: false,
};

export default withLocalize(CustomDimension);
