import React from 'react';
import PropTypes from 'prop-types';
import { REPORT_TYPE_MAP } from 'alerts/consts';
import css from './CustomAlertsEmptyState.css';
import Label from '../../components/foundations/Label';
import { EmptyStateBox } from '../../components/widgets';

function CustomAlertsEmptyState({ reportTypes, onCreateNewAlert }) {
    return (
        <div className={css.noDataContainer}>
            <div className={css.textContainer}>
                <Label className={css.emptyStateHeader} text="STATIC.PAGES.ALERTS_V2.EMPTY_STATE.HEADER" />
            </div>
            <div className={css.emptyStateBoxesContainer}>
                {reportTypes.map(reportType => {
                    const { title, Icon, style } = REPORT_TYPE_MAP[reportType.name];
                    return (
                        <EmptyStateBox
                            key={title}
                            title={title}
                            Icon={Icon}
                            imageStyle={style || {}}
                            onClick={() => {
                                onCreateNewAlert(reportType);
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}

CustomAlertsEmptyState.propTypes = {
    reportTypes: PropTypes.arrayOf(PropTypes.object),
    onCreateNewAlert: PropTypes.func,
};

CustomAlertsEmptyState.defaultProps = {
    reportTypes: [],
    onCreateNewAlert: () => {},
};

export default CustomAlertsEmptyState;
