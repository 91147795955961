import React from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Label } from '../../../../../components/widgets';
import FooterHelpCenterLink from '../FooterHelpCenterLink';
import css from './events.css';
import Accordion from '../../../../../components/organisms/Accordion';
import { SDK_INTEGRATION_SECTIONS } from '../../../consts';
import { sendMixpanelEventSdkIntegration } from '../../../utils';
import { useSDKPlanningContext } from '../../../context/useSDKPlanningContext';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../../consts';
import { LEARN_MORE_SECTIONS_LINKS } from '../consts';
import { getSectionTranslateID, onHelpCenterLinkClick } from '../../../sectionsWrapper/utils';
import useMixpanelEventOnPageLoad from '../../../../hooks/useMixpanelEventOnPageLoad';
import InfoIcon from '../../../../../resources/svg/info.svg';
import { getUserData } from '../../../../../mta/selectors';

const EVENTS_FAQ_ITEMS_COUNT = 5;
const { EVENTS } = SDK_INTEGRATION_SECTIONS;

const Events = ({ translate }) => {
    const { selectedTargetPlatformShortName: platform, selectedTargetPlatform } = useSDKPlanningContext();
    const { max_event_slots: maxEventSlots } = useSelector(state => getUserData(state));
    useMixpanelEventOnPageLoad(EVENTS, selectedTargetPlatform);

    const onItemClick = item => {
        sendMixpanelEventSdkIntegration(
            SDK_INTEGRATION_MIXPANEL_EVENTS.ACCORDION_ITEM_CLICK,
            EVENTS,
            selectedTargetPlatform,
            { question: translate(item.title) }
        );
    };

    return (
        <div className={css.container}>
            <Label type="sideLabel" text={getSectionTranslateID(EVENTS, 'ABOUT_EVENTS', platform)} />
            <Label
                className={css.content}
                type="p"
                text={getSectionTranslateID(EVENTS, 'ABOUT_EVENTS_TEXT', platform)}
            />
            <Label
                className={css.content}
                type="p"
                icon={InfoIcon}
                iconClassName={css.icon}
                text={getSectionTranslateID(EVENTS, 'CAPACITY_NOTICE', platform)}
                textReplace={{ capacity: maxEventSlots }}
            />
            <FooterHelpCenterLink
                text={getSectionTranslateID(EVENTS, 'CONTENT_FOOTER', platform)}
                helpCenterLink={LEARN_MORE_SECTIONS_LINKS[EVENTS]}
                onHelpCenterLinkClick={url => onHelpCenterLinkClick(url, EVENTS, selectedTargetPlatform)}
            />
            <Label
                className={css.eventsFqaTitle}
                type="sideLabel"
                text={getSectionTranslateID(EVENTS, 'EVENTS_FAQ.TITLE', platform)}
            />
            <Accordion
                className={css.eventsAccordionFaq}
                onItemClick={onItemClick}
                items={Array.from({ length: EVENTS_FAQ_ITEMS_COUNT }, (_, i) => i).map(number => {
                    return {
                        title: getSectionTranslateID(
                            EVENTS,
                            `EVENTS_FAQ.CONTENT.QUESTION_${number + 1}_TITLE`,
                            platform
                        ),
                        content: getSectionTranslateID(
                            EVENTS,
                            `EVENTS_FAQ.CONTENT.ANSWER_${number + 1}_TEXT`,
                            platform
                        ),
                    };
                })}
            />
        </div>
    );
};

Events.propTypes = {
    translate: PropTypes.func.isRequired,
};

export default withLocalize(Events);
