import React from 'react';
import posed from 'react-pose';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './Button.css';
import SpinnerIcon from '../../resources/svg/singular_loader.svg';
import VIcon from '../../resources/svg/v_icon.svg';

export const SPINNER_CLASS = css.showSpinner;
export const BUTTON_TYPES = [
    'primary',
    'secondary',
    'flat',
    'attention',
    'new',
    'link',
    'jungleGreen',
    'skanPlus',
    'success1Color',
    'invertedPrimary',
];
export const BUTTON_LEVEL = ['level1', 'level2', 'important'];

export const ICON_LOCATION = ['left', 'right'];

const AnimationItem = posed.div({
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            y: { type: 'spring', stiffness: 500, damping: 15 },
            default: { duration: 300 },
        },
    },
    hidden: {
        opacity: 0,
        y: -10,
        transition: {
            duration: 300,
        },
    },
});

class Button extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleClick = this._handleClick.bind(this);
        this.handleMouseEnter = this._handleMouseEnter.bind(this);
        this.handleMouseLeave = this._handleMouseLeave.bind(this);
    }

    _handleClick(...args) {
        const { onClick, showSpinner } = this.props;

        if (!showSpinner) {
            onClick(...args);
        }
    }

    _handleMouseEnter() {
        const { onMouseEnter } = this.props;
        onMouseEnter();
    }

    _handleMouseLeave() {
        const { onMouseLeave } = this.props;
        onMouseLeave();
    }

    render() {
        const {
            children,
            disabled,
            disabledDark,
            textReplace,
            type,
            level,
            showSpinner,
            className,
            textClassName,
            showV,
            forceActive,
            text,
            hide,
            style,
            Icon,
            iconClass,
            iconStyle,
            submit,
            iconLocation,
            contentClass,
        } = this.props;
        return hide ? null : (
            <button
                className={classNames(css.container, css[type], css[level], className, {
                    [css.disabled]: disabled,
                    [css.disabledDark]: disabledDark,
                    [css.showSpinner]: showSpinner,
                    [css.showV]: showV,
                    [css.forceActive]: forceActive,
                })}
                onClick={this.handleClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                disabled={disabled || disabledDark}
                type={submit ? 'submit' : 'button'}
                style={style}
            >
                <div
                    className={classNames(css.buttonContent, contentClass, {
                        [css.iconAlignedRight]: iconLocation === 'right',
                    })}
                >
                    {Icon && <Icon className={classNames(css.icon, iconClass)} style={iconStyle} />}
                    <span className={classNames(css.textContainer, textClassName)}>
                        {children || <Translate id={children || text} data={textReplace} />}
                    </span>
                </div>
                <SpinnerIcon className={css.buttonSpinner} />
                <AnimationItem pose={showV ? 'visible' : 'hidden'} withParent={false} className={css.buttonVContainer}>
                    <VIcon className={css.buttonV} />
                </AnimationItem>
            </button>
        );
    }
}

Button.propTypes = {
    /** String / Element - the content of the button */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    textReplace: PropTypes.objectOf(PropTypes.any),
    onClick: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    /** Boolean indicating whether the button should render as disabled */
    disabled: PropTypes.bool,
    disabledDark: PropTypes.bool,
    type: PropTypes.oneOf(BUTTON_TYPES),
    level: PropTypes.oneOf(BUTTON_LEVEL),
    showSpinner: PropTypes.bool,
    showV: PropTypes.bool,
    className: PropTypes.string,
    iconClass: PropTypes.string,
    forceActive: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.any),
    submit: PropTypes.bool,
    hide: PropTypes.bool,
    Icon: PropTypes.elementType,
    iconLocation: PropTypes.oneOf(ICON_LOCATION),
    text: PropTypes.string,
    contentClass: PropTypes.string,
};

Button.defaultProps = {
    children: '',
    textReplace: {},
    onClick: () => {},
    onMouseLeave: () => {},
    onMouseEnter: () => {},
    disabled: false,
    disabledDark: false,
    type: 'primary',
    level: 'level1',
    showSpinner: false,
    showV: false,
    className: '',
    iconClass: undefined,
    forceActive: false,
    style: {},
    submit: false,
    hide: false,
    Icon: undefined,
    iconLocation: 'left',
    text: '',
    contentClass: '',
};

export default Button;
