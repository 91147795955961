import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Manager, Popper, Reference } from 'react-popper';
import DropdownBody from './DropdownBody';
import css from './Dropdown.css';
import LinkIcon from '../../resources/svg/export_external_link.svg';
import Tooltip from './Tooltip';
import OverflowingText from '../foundations/OverflowingText';

function Dropdown(props) {
    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [itemClicked, setItemClicked] = useState(false);

    const {
        selected,
        items,
        onSelection,
        valueField,
        onBlur,
        translate,
        containerStyle,
        containerClass,
        selectedContainer,
        selectedContainerStyle,
        popperClass,
        wrapperStyle,
        wrapperClass,
        bodyClassName,
        iconClassName,
        disabled,
        id,
        error,
        warning,
        dropDownBodyTestId,
        ErrorIcon,
        WarningIcon,
        popperModifiers,
    } = props;

    useEffect(() => {
        if (
            (selected.name !== selectedItem.name && selected.display_name !== selectedItem.display_name) ||
            selected.headerText !== selectedItem.headerText
        ) {
            setSelectedItem(selected);
        }
    }, [selected]);

    const handleDropdownToggle = () => {
        if (disabled) {
            return;
        }

        setShow(!show);
    };

    const handleSelection = event => {
        if (!show) {
            return;
        }

        const selectedItemId = event.target.id.toString();

        const selectedValue = items.find(item => {
            return selectedItemId === item[valueField].toString();
        });

        if (selectedValue?.link) {
            setShow(false);
            setItemClicked(false);
            return;
        }

        if (!selectedValue || selectedValue.divider || selectedValue.disabled) {
            return;
        }

        setSelectedItem(selectedValue);
        setShow(false);
        setItemClicked(false);

        onSelection(selectedValue);

        // Prevent the button onClick from being fired and open back the dropdown
        event.preventDefault();
    };

    const handleClose = () => {
        if (itemClicked) {
            return;
        }

        setShow(false);
        onBlur && onBlur();
    };

    const getInnerHtml = (option, key, explanationKey = null, icon = null) => {
        const baseInnerHtml = (
            <>
                {option.link && (
                    <div className={classNames(css.contentIcon, css.linkIcon)}>
                        <LinkIcon />
                    </div>
                )}
                {option.icon && !option.link && (
                    <span className={classNames(css.contentIcon, iconClassName)}>{option.icon}</span>
                )}
                {option.subTitle && <span className={classNames(css.subTitle)}>{option.subTitle}</span>}
                <span className={classNames(css.optionText, { [css.default]: option.name === 'default' })}>
                    {option[key] && typeof option[key] === 'string' ? translate(option[key]) : option[key]}
                </span>
                {option.new && <span className={css.newBadge}>(New)</span>}
                {explanationKey && option[explanationKey] && (
                    <span className={classNames(css.explanation, css.optionText)}>
                        {option[explanationKey] && typeof option[explanationKey] === 'string'
                            ? translate(option[explanationKey])
                            : option[key]}
                    </span>
                )}
            </>
        );

        return (
            <span className={css.content}>
                {option.link ? (
                    <a
                        className={classNames(css.contentIcon, css.link)}
                        href={option.link}
                        target="_blank"
                        rel="noreferrer"
                        id={`${option[valueField]}`}
                    >
                        {baseInnerHtml}
                    </a>
                ) : (
                    baseInnerHtml
                )}
            </span>
        );
    };

    return (
        <div
            style={{
                position: 'relative',
                display: 'inline-block',
                ...wrapperStyle,
            }}
            className={classNames(wrapperClass)}
        >
            <Manager>
                <Reference>
                    {({ ref }) => (
                        <div
                            ref={ref}
                            className={classNames(containerClass, css.container, {
                                [css.disabled]: disabled,
                            })}
                            style={{
                                ...containerStyle,
                            }}
                            role="button"
                            tabIndex="0"
                            onBlur={handleClose}
                        >
                            <button
                                disabled={disabled}
                                id={id}
                                type="button"
                                style={selectedContainerStyle}
                                className={classNames(selectedContainer, css.selectedContainer, {
                                    [css.show]: show,
                                    [css.error]: error,
                                    [css.emptyList]: !items.length,
                                })}
                                onClick={handleDropdownToggle}
                            >
                                <OverflowingText className={classNames(css.generalItem)}>
                                    {getInnerHtml(selectedItem, 'display_name', null, 'icon')}
                                </OverflowingText>
                                {error && ErrorIcon && (
                                    <Tooltip titleTranslationKey={error}>
                                        <ErrorIcon className={css.errorIcon} />
                                    </Tooltip>
                                )}
                                {warning && WarningIcon && (
                                    <Tooltip titleTranslationKey={warning}>
                                        <WarningIcon className={css.errorIcon} />
                                    </Tooltip>
                                )}
                                {!!items.length && <ArrowDownIcon className={css.arrow} />}
                            </button>
                        </div>
                    )}
                </Reference>
                {show && !!items.length && (
                    <Popper placement="bottom" modifiers={popperModifiers}>
                        {({ ref, style, placement, outOfBoundaries }) => {
                            if (outOfBoundaries && show) {
                                handleClose();
                            }

                            return (
                                <div
                                    ref={ref}
                                    data-placement={placement}
                                    className={classNames(popperClass, css.dropdownContainer)}
                                    style={style}
                                >
                                    <DropdownBody
                                        items={items}
                                        onMouseDown={() => {
                                            setItemClicked(true);
                                        }}
                                        onSelection={handleSelection}
                                        translate={translate}
                                        placement={placement}
                                        show={show}
                                        getInnerHtml={getInnerHtml}
                                        valueField={valueField}
                                        className={bodyClassName}
                                        dataTestId={dropDownBodyTestId}
                                    />
                                </div>
                            );
                        }}
                    </Popper>
                )}
            </Manager>
        </div>
    );
}

const dropdownItemShape = PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    display_name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
});

Dropdown.propTypes = {
    items: PropTypes.arrayOf(dropdownItemShape),
    selected: dropdownItemShape,
    onSelection: PropTypes.func,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    containerClass: PropTypes.string,
    selectedContainer: PropTypes.string,
    popperClass: PropTypes.string,
    wrapperStyle: PropTypes.objectOf(PropTypes.any),
    wrapperClass: PropTypes.string,
    bodyClassName: PropTypes.string,
    translate: PropTypes.func,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    error: PropTypes.string,
    warning: PropTypes.string,
    onBlur: PropTypes.func,
    valueField: PropTypes.string,
    spacingStyle: PropTypes.objectOf(PropTypes.any),
    selectedContainerStyle: PropTypes.objectOf(PropTypes.any),
    dropDownBodyTestId: PropTypes.string,
    ErrorIcon: PropTypes.func,
    WarningIcon: PropTypes.func,
    popperModifiers: PropTypes.shape({
        preventOverflow: PropTypes.shape({
            enabled: PropTypes.bool,
            boundariesElement: PropTypes.string,
        }),
    }),
};

Dropdown.defaultProps = {
    items: [],
    selected: {
        name: 'default',
        display_name: 'Select',
    },
    onSelection: () => {},
    containerStyle: {},
    containerClass: '',
    selectedContainer: '',
    popperClass: '',
    wrapperStyle: {},
    wrapperClass: '',
    translate: () => {},
    disabled: false,
    id: undefined,
    error: null,
    warning: null,
    onBlur: null,
    valueField: 'name',
    spacingStyle: null,
    selectedContainerStyle: null,
    dropDownBodyTestId: undefined,
    ErrorIcon: undefined,
    WarningIcon: undefined,
    popperModifiers: {
        // Zendesk 75717
        // Important for dropdowns opening in custom dimensions (even when scrolling)
        preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
        },
    },
};

export default withLocalize(Dropdown);
