import API from './api';
import { DEFAULT_END_DATE, DEFAULT_START_DATE, defaultReportQueryParams } from '../utils/reports';
import { REPORT_EXCEEDS_SIZE_LIMIT_ERROR } from '../utils/reportsConsts';

let instance = null;
export const URL_REPORTING_NEW_DATA = '/api/get_new_data';
const api = new API();
const URL_REPORTING_DATES = '/api/users/get_report_dates';
const URL_REPORTING_INIT_STATE = '/api/users/get_report_init_state';
const URL_REPORTING_TRANSPARENCY = '/api/get_source_drilldown';
const URL_REPORTING_NEW_DATA_ANONYMOUS = '/api/get_anonymous_data';
const URL_GET_ANONYMOUS_REPORTS_LIST = '/shared_reports/get_reports_list';
const URL_SHARED_REPORTS_CREATE = '/shared_reports/create_report';
const URL_SHARED_REPORTS_DELETE = '/shared_reports/delete_report';
const URL_GET_ALERTS_DATA = '/api/get_report_alerts';
const URL_SAVE_BOOKMARK = '/api/bookmark';
const URL_REPORT_PRIVACY = '/api/get_report_privacy';
const URL_CREATE_ASYNC_REPORT = '/api/v2.0/create_async_report';
const URL_EXPORT_DATA_TO_GSHEET = '/api/report_data_to_gsheet';

class Reports {
    constructor() {
        this.getTransparencyData = this.getTransparencyData.bind(this);
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    static get ERROR_ANONYMOUS_INVALID_ID() {
        return new Error('anonymous invalid id');
    }

    static get ERROR_ANONYMOUS_EXPIRED_ID() {
        return new Error('anonymous expired id');
    }

    static get ERROR_ANONYMOUS_INVALID_EMAIL() {
        return new Error('anonymous invalid email');
    }

    static get ERROR_RUN_QUERY() {
        return new Error('run query The request has failed due to an internal error.');
    }

    static get ERROR_GET_ALERTS() {
        return new Error('get alerts The request has failed due to an internal error');
    }

    static get ERROR_TRANSPARENCY_DATA_NOT_JSON() {
        return new Error('get transparency data an error has occurred while parsing JSON');
    }

    static get ERROR_GET_SDK_HYBRID_STATUS() {
        return new Error('get unified report status has failed due to an internal error');
    }

    updateReportInitState() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_REPORTING_INIT_STATE, {
                method: 'GET',
                credentials: 'include',
                unique: true,
            })
                .then(response => {
                    if (response.status === 0) {
                        resolve(response.value);
                    }
                })
                .catch(reject);
        });
    }

    updateReportDates() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_REPORTING_DATES, {
                method: 'GET',
                credentials: 'include',
                cache: true,
                unique: true,
            })
                .then(response => {
                    if (response.status === 0) {
                        resolve(response.value);
                    } else {
                        resolve({
                            start_date: DEFAULT_START_DATE,
                            end_date: DEFAULT_END_DATE,
                        });
                    }
                })
                .catch(() => {
                    resolve({
                        start_date: DEFAULT_START_DATE,
                        end_date: DEFAULT_END_DATE,
                    });
                });
        });
    }

    runAsyncReport(params, options) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_CREATE_ASYNC_REPORT, {
                method: 'POST',
                credentials: 'include',
                body: {
                    ...defaultReportQueryParams,
                    ...params,
                    format: 'csv_v2',
                    with_append_tables: [],
                    druid_aggregated_data_sources_mode: [],
                },
                cache: false,
                unique: true,
                ...options,
            })
                .then(response => {
                    if (response.status !== 0) {
                        reject(Reports.ERROR_RUN_QUERY);
                    }
                    resolve(response.value);
                })
                .catch(reject);
        });
    }

    runQuery(params, options) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_REPORTING_NEW_DATA, {
                method: 'POST',
                credentials: 'include',
                body: {
                    ...defaultReportQueryParams,
                    ...params,
                },
                cache: false,
                unique: true,
                ...options,
            })
                .then(response => {
                    if (response.status !== 0 && !response.value.includes(REPORT_EXCEEDS_SIZE_LIMIT_ERROR)) {
                        reject(Reports.ERROR_RUN_QUERY);
                    }
                    resolve(response.value);
                })
                .catch(reject);
        });
    }

    runAnonymousQuery(secretId, password = '', query = '', options = {}) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_REPORTING_NEW_DATA_ANONYMOUS, {
                method: 'POST',
                credentials: 'include',
                params: {
                    secret_id: secretId,
                    report_password: password,
                    query,
                },
                ...options,
                cache: false,
                unique: true,
            })
                .then(response => {
                    if (response.status === 0) {
                        resolve(response.value);
                    } else if (response.substatus === 0) {
                        reject(Reports.ERROR_ANONYMOUS_INVALID_ID);
                    } else if (response.substatus === 1) {
                        reject(Reports.ERROR_ANONYMOUS_EXPIRED_ID);
                    } else if (response.substatus === 2) {
                        reject(Reports.ERROR_ANONYMOUS_INVALID_EMAIL);
                    } else {
                        reject(Reports.ERROR_ANONYMOUS_INVALID_ID);
                    }
                })
                .catch(reject);
        });
    }

    getTransparencyData(reportId) {
        return new Promise((resolve, reject) => {
            const getTransparencyDataInner = () => {
                api.getJson(URL_REPORTING_TRANSPARENCY, {
                    method: 'GET',
                    credentials: 'include',
                    params: { drilldown_key: reportId },
                    cache: false,
                    disableBrowserCache: true,
                    unique: true,
                })
                    .then(response => {
                        if (response.status === 0) {
                            try {
                                const retJson = JSON.parse(response.value);
                                resolve(retJson);
                            } catch (e) {
                                reject(Reports.ERROR_TRANSPARENCY_DATA_NOT_JSON);
                            }
                        } else {
                            setTimeout(() => {
                                // Keep calling the transparency data until we get it successfully
                                getTransparencyDataInner(reportId);
                            }, 5000); // 20s
                        }
                    })
                    .catch(reject);
            };
            getTransparencyDataInner();
        });
    }

    getAlertsData(params) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_GET_ALERTS_DATA, {
                method: 'POST',
                credentials: 'include',
                params,
                cache: false,
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        return reject(Reports.ERROR_GET_ALERTS);
                    }
                    return resolve(JSON.parse(response.value));
                })
                .catch(reject);
        });
    }

    generateAnonymousReportsList() {
        return new Promise((resolve, reject) => {
            api.getJson(URL_GET_ANONYMOUS_REPORTS_LIST, {
                method: 'GET',
                credentials: 'include',
                cache: false,
                unique: true,
            })
                .then(response => {
                    resolve(response.value.shared_reports_list);
                })
                .catch(reject);
        });
    }

    generateSharedReportLink(params) {
        return api.getJson(URL_SHARED_REPORTS_CREATE, {
            method: 'POST',
            credentials: 'include',
            body: params,
            cache: false,
            unique: true,
        });
    }

    deleteSharedReport(params) {
        return api.getJson(URL_SHARED_REPORTS_DELETE, {
            method: 'POST',
            credentials: 'include',
            body: params,
            cache: false,
            unique: true,
        });
    }

    saveBookmark(params) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_SAVE_BOOKMARK, {
                method: 'POST',
                credentials: 'include',
                body: params,
                cache: false,
                unique: true,
            })
                .then(response => {
                    if (response.status !== 0) {
                        reject(response.value);
                    }
                    resolve(response.value);
                })
                .catch(reject);
        });
    }

    deleteBookmark(bookmarkId) {
        return new Promise((resolve, reject) => {
            api.getJson(`${URL_SAVE_BOOKMARK}/${bookmarkId}`, {
                method: 'DELETE',
                credentials: 'include',
                cache: false,
                unique: true,
            })
                .then(response => {
                    resolve(response.value);
                })
                .catch(reject);
        });
    }

    hasPassword(secretId) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_REPORT_PRIVACY, {
                method: 'GET',
                credentials: 'include',
                cache: true,
                params: {
                    secret_id: secretId,
                },
            })
                .then(response => {
                    let ret = false;
                    if (response.value && 'has_password' in response.value) {
                        ret = response.value.has_password;
                    }
                    resolve(ret);
                })
                .catch(reject);
        });
    }

    exportDataToGsheet(fileName, gsheetToken, data) {
        return new Promise((resolve, reject) => {
            api.getJson(URL_EXPORT_DATA_TO_GSHEET, {
                method: 'POST',
                credentials: 'include',
                body: {
                    gsheet_token: gsheetToken,
                    csv_data: data,
                    title: fileName,
                },
                cache: false,
                unique: true,
            })
                .then(response => {
                    if (response.status === 0) {
                        resolve(response.value);
                    } else {
                        reject(new Error('Exporting to Google Sheet failed'));
                    }
                })
                .catch(reject);
        });
    }
}

export default Reports;
