import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Table from '../../../components/organisms/Table';
import { calculateCustomMapping, getTableData, getTableMetadata } from './ConversionModelShelfStep1CoarseTable';
import css from '../ConversionModelShelf.css';
import Label from '../../../components/foundations/Label';
import { conversionModelTypeShape, decodingTableShape, modelErrorsShape } from './types';
import { useScrollingIntoView } from '../../../utils/customHooks';

function ConversionModelShelfStep1Coarse({ decodingTable, values, revenueType, multiCoarseMapping, errors, onChange }) {
    const { customMapping = {}, eventsType, currency } = values;
    const scrollingRef = useRef();

    useEffect(() => {
        if (!decodingTable) return;

        const cvAmount = Object.keys(decodingTable).length;
        const cvMappingAmount = Object.keys(customMapping).length;

        const shouldUseCurrentMapping = multiCoarseMapping ? cvAmount === cvMappingAmount : cvMappingAmount > 0;
        const currentMapping = shouldUseCurrentMapping ? customMapping : {};

        onChange({ customMapping: calculateCustomMapping(decodingTable, currentMapping, multiCoarseMapping) });
    }, []);

    useScrollingIntoView([errors.tableGeneralError], scrollingRef);

    if (!decodingTable) return null;

    const tableMetadata = getTableMetadata(values, onChange, revenueType, eventsType, multiCoarseMapping);
    const tabledata = getTableData(decodingTable, customMapping, currency, multiCoarseMapping);

    return (
        <div className={css.page}>
            <Label text="STATIC.PAGES.SKADNETWORK.SKAN_4_SHELF.COARSE_VALUE_MAPPING" className={css.shelfHeaderTitle} />
            <Translate id="STATIC.PAGES.SKADNETWORK.SHELF_COARSE_MAPPING_TEXT" className={css.shelfInfoBoxText} />
            <div className={css.coarseMappingTableWrapper}>
                <Table metadata={tableMetadata} data={tabledata} rowHoverEnabled={false} />
            </div>
            {errors.tableGeneralError && (
                <div className={css.modelError} ref={scrollingRef}>
                    <Translate id={errors.tableGeneralError.errorMsg} />
                </div>
            )}
        </div>
    );
}

ConversionModelShelfStep1Coarse.propTypes = {
    values: conversionModelTypeShape.isRequired,
    revenueType: PropTypes.string,
    multiCoarseMapping: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    decodingTable: decodingTableShape,
    errors: modelErrorsShape,
};

ConversionModelShelfStep1Coarse.defaultProps = {
    revenueType: null,
    decodingTable: null,
    errors: {},
};

export default ConversionModelShelfStep1Coarse;
