import React, { Fragment, memo } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from './ChartWidgetWrapper.css';
import { WIDGET_DATA_TYPES } from '../../../customDashboards/widgetDataTypes';
import OverflowingText from '../../foundations/OverflowingText';
import Tooltip from '../../widgets/Tooltip';
import SettingsIcon from '../../../resources/svg/settings.svg';
import ExportIcon from '../../../resources/svg/export_widget.svg';
import TrashIcon from '../../../resources/svg/trash.svg';
import Spinner from '../../widgets/Spinner';
import { EmptyState } from '../../widgets';
import { ACTION_TYPES, WIDGET_TYPE_TO_SCHEMA } from './consts';
import Icon, { IconSizes, IconVariants } from '../../foundations/Icon';

function ChartWidgetWrapper({
    widgetTitle,
    widgetType,
    widgetDataType,
    showSpinner,
    showNoData,
    showError,
    onMenuClick,
    onCloneClick,
    onDeleteClick,
    onExportClick,
    allowedActions,
    children,
}) {
    return (
        <Fragment>
            <div className={css.gridWidgetHeader}>
                <div className={css.gridHeaderDataType}>
                    <span className={css.gridHeaderDataTypeIcon}>{WIDGET_DATA_TYPES[widgetDataType].icon}</span>
                    <div className={css.gridHeaderDataTypeText}>{WIDGET_DATA_TYPES[widgetDataType].display_name}</div>
                </div>
                <OverflowingText className={css.gridHeaderContents}>
                    <Translate id={widgetTitle} />
                </OverflowingText>

                <div className={css.gridIconsSector}>
                    {allowedActions.includes(ACTION_TYPES.TOGGLE_MENU) && (
                        <Tooltip
                            wrapperClass={css.gridIconContainer}
                            interactive
                            titleTranslationKey="STATIC.PAGES.DASHBOARD.ACTIONS.EDIT_WIDGET"
                            position="bottom"
                            distance={25}
                        >
                            <SettingsIcon className={css.editWidgetIcon} onClick={onMenuClick} />
                        </Tooltip>
                    )}
                    {allowedActions.includes(ACTION_TYPES.CLONE) && (
                        <Tooltip
                            wrapperClass={css.gridIconContainer}
                            interactive
                            titleTranslationKey="STATIC.PAGES.DASHBOARD.ACTIONS.CLONE_WIDGET"
                            position="bottom"
                            distance={25}
                        >
                            <Icon
                                name="copy"
                                className={css.cloneWidgetIcon}
                                variant={IconVariants.LIGHT}
                                size={IconSizes.SMALL}
                                onClick={onCloneClick}
                            />
                        </Tooltip>
                    )}
                    {allowedActions.includes(ACTION_TYPES.EXPORT) && (
                        <Tooltip
                            wrapperClass={css.gridIconContainer}
                            interactive
                            titleTranslationKey="STATIC.PAGES.DASHBOARD.ACTIONS.EXPORT_WIDGET"
                            position="bottom"
                            distance={25}
                        >
                            <ExportIcon className={css.exportWidgetIcon} onClick={onExportClick} />
                        </Tooltip>
                    )}
                    {allowedActions.includes(ACTION_TYPES.DELETE) && (
                        <Tooltip
                            wrapperClass={css.gridIconContainer}
                            interactive
                            titleTranslationKey="STATIC.PAGES.DASHBOARD.ACTIONS.DELETE_WIDGET"
                            position="bottom"
                            distance={25}
                        >
                            <TrashIcon
                                className={css.deleteWidgetIcon}
                                onClick={e => {
                                    e.stopPropagation();
                                    onDeleteClick();
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className={css.gridWidgetContent}>
                {showSpinner && (
                    <div className={css.gridWidgetCenterContainer}>
                        <Spinner show />
                    </div>
                )}
                {showNoData && (
                    <div className={css.gridWidgetCenterNoData}>
                        <EmptyState
                            headerStyle={{ color: 'var(--gray500)', fontSize: '15px' }}
                            customIcon={WIDGET_TYPE_TO_SCHEMA[widgetType]}
                            header="STATIC.PAGES.DASHBOARD.WIDGET.QUERY_NO_DATA"
                        />
                    </div>
                )}
                {showError && (
                    <div className={css.gridWidgetCenterContainer}>
                        <EmptyState
                            header={
                                allowedActions.includes(ACTION_TYPES.TOGGLE_MENU)
                                    ? 'STATIC.PAGES.DASHBOARD.WIDGET.QUERY_ERROR'
                                    : 'STATIC.PAGES.DASHBOARD.WIDGET.QUERY_ERROR_QUICK_REPORT'
                            }
                        />
                    </div>
                )}
                {children}
            </div>
        </Fragment>
    );
}

ChartWidgetWrapper.propTypes = {
    widgetTitle: PropTypes.string.isRequired,
    widgetType: PropTypes.string.isRequired,
    widgetDataType: PropTypes.string.isRequired,
    showSpinner: PropTypes.bool.isRequired,
    showNoData: PropTypes.bool.isRequired,
    showError: PropTypes.bool.isRequired,
    onMenuClick: PropTypes.func,
    onCloneClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onExportClick: PropTypes.func,
    allowedActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ACTION_TYPES))),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ChartWidgetWrapper.defaultProps = {
    children: null,
    onMenuClick: () => {},
    onCloneClick: () => {},
    onDeleteClick: () => {},
    onExportClick: () => {},
    allowedActions: Object.values(ACTION_TYPES),
};

export default memo(ChartWidgetWrapper);
