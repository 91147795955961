import React from 'react';
import Button from '../atoms/Button';

class ReportPresetButton extends React.Component {
    render() {
        return <Button type="secondary" style={{ marginRight: '10px' }} {...this.props} />;
    }
}

export default ReportPresetButton;
