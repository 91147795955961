import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import css from './GeneralPopup.css';
import Spinner from '../widgets/Spinner';
import SmallDialog from '../widgets/SmallDialog';
import Button from '../atoms/Button';

export const PopupTypes = {
    INFO: 'info',
    DELETE: 'delete',
    INFO_WITH_CANCEL: 'info-with-cancel',
    WARNING_WITH_CANCEL: 'warning-with-cancel',
    VIDEO: 'video',
};

function GeneralPopup({
    title,
    text,
    textReplace,
    titleTextReplace,
    acceptText,
    rejectText,
    open,
    type,
    headerStyle,
    onAccept,
    onReject,
    showSpinner,
    translate,
    children,
    videoLink,
    loading,
}) {
    return (
        <SmallDialog
            open={open}
            onClose={onReject}
            onEscapePress={onReject}
            classes={{ paperScrollBody: classNames({ [css.video]: type === PopupTypes.VIDEO }) }}
        >
            {loading ? (
                <Spinner show />
            ) : (
                <div className={css.container}>
                    <div className={css.title} style={headerStyle}>
                        {translate(title, titleTextReplace) || title}
                    </div>
                    <div className={css.text}> {translate(text, textReplace) || text} </div>
                    {children}
                    {type === PopupTypes.DELETE && (
                        <div className={css.actionButtons}>
                            <Button level="level1" type="flat" text={rejectText} onClick={onReject} />
                            <Button
                                level="level1"
                                type="attention"
                                text={acceptText}
                                onClick={onAccept}
                                className={css.acceptButton}
                                showSpinner={showSpinner}
                            />
                        </div>
                    )}
                    {type === PopupTypes.INFO && (
                        <Button
                            level="level1"
                            type="primary"
                            text={acceptText}
                            onClick={onReject}
                            className={css.floatRight}
                        />
                    )}
                    {(type === PopupTypes.INFO_WITH_CANCEL || type === PopupTypes.WARNING_WITH_CANCEL) && (
                        <div className={css.actionButtons}>
                            <Button level="level1" type="flat" text={rejectText} onClick={onReject} />
                            <Button
                                level="level1"
                                type={type === PopupTypes.WARNING_WITH_CANCEL ? 'attention' : 'primary'}
                                text={acceptText}
                                onClick={onAccept}
                                className={css.acceptButton}
                                showSpinner={showSpinner}
                            />
                        </div>
                    )}
                    {type === PopupTypes.VIDEO && (
                        <iframe
                            className={css.videoFrame}
                            src={videoLink}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            allowFullScreen
                        />
                    )}
                </div>
            )}
        </SmallDialog>
    );
}

GeneralPopup.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    textReplace: PropTypes.objectOf(PropTypes.any),
    titleTextReplace: PropTypes.objectOf(PropTypes.any),
    acceptText: PropTypes.string,
    rejectText: PropTypes.string,
    open: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(PopupTypes)),
    headerStyle: PropTypes.objectOf(PropTypes.any),
    onAccept: PropTypes.func,
    onReject: PropTypes.func,
    showSpinner: PropTypes.bool,
    translate: PropTypes.func,
    children: PropTypes.element,
    videoLink: PropTypes.string,
    loading: PropTypes.bool,
};

GeneralPopup.defaultProps = {
    title: '',
    text: '',
    textReplace: {},
    titleTextReplace: {},
    acceptText: '',
    rejectText: 'STATIC.BUTTONS.CANCEL',
    open: false,
    type: PopupTypes.INFO,
    headerStyle: {},
    onAccept: () => {},
    onReject: () => {},
    showSpinner: false,
    translate: () => {},
    children: null,
    videoLink: '',
    loading: false,
};

export default withLocalize(GeneralPopup);
