import css from 'teamManagement/styles.css';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import { Translate, withLocalize } from 'react-localize-redux';
import { PageHeader, PageWrapper, GeneralPopup } from 'components/partials';
import { AutoComplete, GroupContainer, Button, Spinner, Tabs } from 'components/widgets';
import { MemberTypes, MemberGroupTypes, trackTeamManagementMixpanelEvent } from 'teamManagement/utils';
import MemberBox from 'teamManagement/components/MemberBox';
import TeamManagementAPI from 'teamManagement/service';
import AnimatedWarningMessage from './components/AnimatedWarningMessage';
import TeamManagementShelf from './containers/TeamManagementShelf';
import DownloadIcon from '../resources/icons/download.svg';
import WarningMessage, { MessageTypes } from './components/WizardWarningMessage';
import { useAsync } from '../utils/customHooks';
import { convertToCsv, download } from '../utils/export';

const api = new TeamManagementAPI();

const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        y: 0,
        delay: ({ i }) => i * 50,
    },
    exit: { opacity: 0, y: 20 },
});

const getExportTeamFileName = () => `singular_team_${new Date().toISOString()}.csv`;

function Page({
    memberTypes,
    activeMemberType,
    onActiveMemberTypeChange,
    membersByGroup,
    membersGroups,
    watchingMemberId,
    pageLoaded,
    onDeleteMember,
    onDeleteBtnClicked,
    onCreateNewMember,
    onManagePermissions,
    onResendInvitation,
    translate,
}) {
    const [memberToDelete, setMemberToDelete] = useState(null);
    const [search, setSearch] = useState('');
    const [exportTeamMembersResponse, runExportTeamMembers] = useAsync();
    const exportStartTime = useRef(null);

    const {
        data: exportTeamMembers,
        isLoading: isLoadingExportAll,
        error: exportTeamMembersError,
    } = exportTeamMembersResponse;

    const _handleDeleteClicked = member => {
        onDeleteBtnClicked();
        setMemberToDelete(member);
    };

    const _onDeleteApproved = () => {
        onDeleteMember(memberToDelete);
        setMemberToDelete(null);
    };

    const _onDeleteRejected = () => setMemberToDelete(null);

    const _getActionButtons = (group, member) => {
        if (group === MemberGroupTypes.active) {
            return [
                {
                    buttonName: <Translate id="STATIC.PAGES.TEAM_MEMBERS.MANAGE_PERMISSIONS" />,
                    onClick: () => onManagePermissions(member),
                },
            ];
        }

        if (group === MemberGroupTypes.pending) {
            return [
                {
                    buttonName: member.inProgress ? (
                        <Translate id="STATIC.PAGES.TEAM_MEMBERS.SENDING_INVITE" />
                    ) : (
                        <Translate id="STATIC.PAGES.TEAM_MEMBERS.RESEND_INVITE_REACT" />
                    ),
                    onClick: () => onResendInvitation(member),
                    inProgress: member.inProgress,
                    disabled: member.disabled,
                    disabledTooltip: member.disabledTootlip,
                },
            ];
        }

        return [];
    };

    const _onExportClick = () => runExportTeamMembers(api.exportTeamMembers());

    useEffect(() => {
        if (isLoadingExportAll) {
            exportStartTime.current = performance.now();
        }

        if (exportTeamMembers) {
            const fileName = getExportTeamFileName();
            const csvData = convertToCsv(exportTeamMembers.fields, exportTeamMembers.data);
            download(fileName, csvData);

            trackTeamManagementMixpanelEvent('Export Team Button Clicked', {
                durationInMilliseconds: performance.now() - exportStartTime.current,
            });
        }
    }, [exportTeamMembersResponse]);

    return (
        <PageWrapper>
            <PageHeader
                text="STATIC.MANAGE_TEAM"
                leftComponents={[]}
                rightComponent={[]}
                subText={{
                    faqLink: 'https://support.singular.net/hc/en-us/articles/360020835952-Team-Management-FAQ',
                }}
            />
            {memberTypes && memberTypes.length > 1 && (
                <Tabs
                    containerStyle={{ marginTop: 10 }}
                    selected={activeMemberType}
                    onClick={onActiveMemberTypeChange}
                    tabs={memberTypes.map(type => ({
                        id: type,
                        label: `STATIC.PAGES.TEAM_MEMBERS.TAB_LABEL_${type.toUpperCase()}`,
                    }))}
                />
            )}
            <div>
                <div className={css.filterRow}>
                    <AutoComplete
                        placeholder="Start typing to quick search member"
                        containerStyle={{ flexBasis: '500px', marginRight: '20px' }}
                        selectOptions={{
                            options: [],
                            isSearchable: true,
                            inputValue: search,
                            noOptionsMessage: () => null,
                        }}
                        onInputChange={value => setSearch(value.toLowerCase())}
                    />
                    <div className={css.buttonsContainer}>
                        {activeMemberType === MemberTypes.USER && (
                            <Button
                                type="secondary"
                                className={css.exportTeamMembersButton}
                                showSpinner={!!isLoadingExportAll}
                                Icon={DownloadIcon}
                                onClick={_onExportClick}
                                iconClass={css.downloadIcon}
                                disabled={!pageLoaded}
                            >
                                <Translate id="STATIC.BUTTONS.EXPORT" />
                            </Button>
                        )}
                        <Button
                            type="primary"
                            level="level1"
                            className={css.newMemberButton}
                            onClick={onCreateNewMember}
                            disabled={!pageLoaded}
                        >
                            <Translate id={`STATIC.BUTTONS.${activeMemberType}_CREATE_NEW_MEMBER`} />
                        </Button>
                    </div>
                </div>
                <WarningMessage
                    type={MessageTypes.ERROR}
                    show={!!exportTeamMembersError}
                    duration={1000}
                    message="STATIC.PAGES.TEAM_MEMBERS.EXPORT_ERROR"
                />
                <div>
                    <Spinner show={!pageLoaded} />
                    {membersGroups.map(({ name: groupName, displayName: groupDisplayName, infoMessages }) => {
                        const members = membersByGroup[groupName];
                        const translatedGroup = translate(groupDisplayName);
                        return (
                            <GroupContainer
                                key={`group_${groupName}`}
                                title={`${translatedGroup} (${members.length})`}
                                defaultOpen
                                style={{ marginBottom: 20 }}
                                titleStyle={{ marginLeft: -13 }}
                            >
                                <div>
                                    {infoMessages.map((infoMessage, index) => (
                                        <AnimatedWarningMessage
                                            key={`${infoMessage.message}_${index}`}
                                            message={infoMessage}
                                        />
                                    ))}
                                    <PoseGroup animateOnMount>
                                        {members
                                            .filter(member => member.searchText.includes(search))
                                            .map((member, index) => (
                                                <AnimationItem key={`groupItem_${member.id}`} i={index}>
                                                    <MemberBox
                                                        {...member}
                                                        status={groupName}
                                                        style={{ marginTop: index > 0 ? 10 : 0 }}
                                                        highlightedText={search}
                                                        isMe={watchingMemberId === member.id}
                                                        onDelete={() => _handleDeleteClicked(member)}
                                                        actionButtons={_getActionButtons(groupName, member)}
                                                    />
                                                </AnimationItem>
                                            ))}
                                    </PoseGroup>
                                </div>
                            </GroupContainer>
                        );
                    })}
                </div>
                <GeneralPopup
                    open={!!memberToDelete}
                    type="delete"
                    title="STATIC.PAGES.TEAM_MEMBERS.DELETE_MODAL.TITLE"
                    text="STATIC.PAGES.TEAM_MEMBERS.DELETE_MODAL.MESSAGE"
                    acceptText="STATIC.PAGES.TEAM_MEMBERS.DELETE_MODAL.ACCEPT_BUTTON_TEXT"
                    rejectText="STATIC.PAGES.TEAM_MEMBERS.DELETE_MODAL.REJECT_BUTTON_TEXT"
                    onAccept={_onDeleteApproved}
                    onReject={_onDeleteRejected}
                />
                <TeamManagementShelf />
            </div>
        </PageWrapper>
    );
}

Page.propTypes = {
    pageLoaded: PropTypes.bool,
    memberTypes: PropTypes.arrayOf(PropTypes.string),
    membersByGroup: PropTypes.objectOf(PropTypes.any),
    membersGroups: PropTypes.arrayOf(PropTypes.any),
    activeMemberType: PropTypes.string,
    watchingMemberId: PropTypes.number,
    onActiveMemberTypeChange: PropTypes.func,
    onDeleteMember: PropTypes.func,
    onDeleteBtnClicked: PropTypes.func,
    onManagePermissions: PropTypes.func,
    onResendInvitation: PropTypes.func,
    onCreateNewMember: PropTypes.func,
    translate: PropTypes.func,
};

Page.defaultProps = {
    pageLoaded: false,
    membersByGroup: {},
    membersGroups: [],
    memberTypes: [],
    activeMemberType: MemberTypes.USER,
    watchingMemberId: null,
    onActiveMemberTypeChange: () => {},
    onDeleteMember: () => {},
    onDeleteBtnClicked: () => {},
    onManagePermissions: () => {},
    onResendInvitation: () => {},
    onCreateNewMember: () => {},
    translate: () => {},
};

export default withLocalize(Page);
