import React from 'react';
import css from './StickyPageHeader.css';
import EmptyState from '../../../partials/EmptyState/EmptyState';
import { emptyStateDefaultProps, emptyStatePropsType } from '../../../partials/EmptyState/types';

const StickyPageHeaderEmptyState = ({
    titleText,
    subTitleText,
    tasksTodo,
    tasksDone,
    onCloseEmptyState,
    showCloseIcon,
    pageName,
    onTaskClick,
    imageSrc,
}) => {
    return (
        <div className={css.emptyStateContainer}>
            <EmptyState
                titleText={titleText}
                subTitleText={subTitleText}
                tasksTodo={tasksTodo}
                tasksDone={tasksDone}
                onTaskClick={onTaskClick}
                imageSrc={imageSrc}
                onCloseEmptyState={onCloseEmptyState}
                pageName={pageName}
                showCloseIcon={showCloseIcon}
            />
        </div>
    );
};

StickyPageHeaderEmptyState.propTypes = emptyStatePropsType;

StickyPageHeaderEmptyState.defaultProps = emptyStateDefaultProps;

export default StickyPageHeaderEmptyState;
