import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import css from './UpgradePlans.css';
import withTranslate from '../../containers/widgets/withTranslate';
import PlanCard from './PlanCard';
import { CUSTOMER_TIERS } from '../../utils/consts';
import { useFetchAllProducts } from '../hooks';
import AccountPlanAPI from '../service';
import { getIsBlocked, getUserData } from '../../selectors/user';
import Label from '../../components/foundations/Label';
import ProductSelect from './ProductSelect';
import { route } from '../../utils/routerUtils';
import { CHECKOUT_URL, MIXPANEL_UPGRADE_ACCOUNT, MIXPANEL_PAY_NOW_CLICK, TIER_CARD_COLORS } from '../consts';
import { trackMixpanelEvent, ACCOUNT_PLAN_EVENT_PREFIX } from '../../utils/general';
import { canEnablePlanCardCta } from '../utils';

const accountPlanAPI = new AccountPlanAPI();

const UpgradePlans = ({ translate, hasAccountPlan }) => {
    const { data: productData } = useFetchAllProducts();
    const orgTier = useSelector(state => getUserData(state).organization_tier);
    const orgIsBlocked = useSelector(state => getIsBlocked(state));
    const [productSelected, setProductSelected] = React.useState({});
    const [upgradeRequestedTier, setUpgradeRequestedTier] = React.useState(null);

    const handleUpgradeButtonClick = tier => {
        setUpgradeRequestedTier(tier);
        trackMixpanelEvent(`${ACCOUNT_PLAN_EVENT_PREFIX}:`, MIXPANEL_UPGRADE_ACCOUNT, {
            upgradeToTier: tier,
            blocked: orgIsBlocked,
        });
        return accountPlanAPI.submitContactUs(tier);
    };

    useEffect(() => {
        if (hasAccountPlan && !productSelected?.product_id && productData?.length) {
            setProductSelected(productData[0]);
        }
    }, [hasAccountPlan, productData, productSelected]);

    const thankYouMessage = <Label text="STATIC.PAGES.ACCOUNT_PLAN.UPGRADE_CLICKED" className={css.thankYouLabel} />;

    return (
        <div
            id="upgrade-plan"
            className={css.upgradePlansContainer}
            style={{ '--thank-you-color': TIER_CARD_COLORS[upgradeRequestedTier] }}
        >
            <div className={css.upgradePlansHeaderContainer}>
                <Label
                    text="STATIC.PAGES.ACCOUNT_PLAN.PRICING_CARDS.CONTAINER.HEADER"
                    className={css.upgradePlanHeader}
                />
                <div className={css.lineSeperator} />
            </div>
            <div className={css.planCardsContainer}>
                <PlanCard
                    tier={CUSTOMER_TIERS.FREE}
                    ctaEnabled={canEnablePlanCardCta(
                        orgTier,
                        CUSTOMER_TIERS.FREE,
                        upgradeRequestedTier,
                        hasAccountPlan
                    )}
                    ctaExtraComponent={
                        hasAccountPlan && (
                            <ProductSelect
                                productData={productData}
                                onProductSelected={product => setProductSelected(product)}
                                disabled={orgTier !== CUSTOMER_TIERS.FREE}
                                selectedProductId={productSelected?.product_id}
                            />
                        )
                    }
                    ctaAction={() => {
                        if (productSelected.product_id && productSelected.amount) {
                            trackMixpanelEvent(`${ACCOUNT_PLAN_EVENT_PREFIX}:`, MIXPANEL_PAY_NOW_CLICK, {
                                blocked: orgIsBlocked,
                            });
                            route(CHECKOUT_URL, {
                                price_id: productSelected.product_id,
                                quantity: productSelected.amount,
                            });
                        }
                    }}
                />
                <PlanCard
                    tier={CUSTOMER_TIERS.STANDARD_UNIFIED}
                    ctaEnabled={canEnablePlanCardCta(
                        orgTier,
                        CUSTOMER_TIERS.STANDARD_UNIFIED,
                        upgradeRequestedTier,
                        hasAccountPlan
                    )}
                    ctaExtraComponent={
                        upgradeRequestedTier === CUSTOMER_TIERS.STANDARD_UNIFIED ? thankYouMessage : null
                    }
                    ctaAction={() => handleUpgradeButtonClick(CUSTOMER_TIERS.STANDARD_UNIFIED)}
                />
                <PlanCard
                    tier={CUSTOMER_TIERS.ENTERPRISE}
                    ctaEnabled={canEnablePlanCardCta(
                        orgTier,
                        CUSTOMER_TIERS.ENTERPRISE,
                        upgradeRequestedTier,
                        hasAccountPlan
                    )}
                    ctaExtraComponent={upgradeRequestedTier === CUSTOMER_TIERS.ENTERPRISE ? thankYouMessage : null}
                    ctaAction={() => handleUpgradeButtonClick(CUSTOMER_TIERS.ENTERPRISE)}
                />
            </div>
        </div>
    );
};

UpgradePlans.propTypes = {
    translate: PropTypes.func,
    hasAccountPlan: PropTypes.bool,
};

UpgradePlans.defaultProps = {
    translate: () => {},
};

export default withTranslate(UpgradePlans);
