import { INTERNAL_S3_KEY, FILTERED_EVENTS_NAMES, EMPTY_TEST_CHANNEL } from '../consts';

const processSegmentFields = (segmentFields, adAccounts) => {
    return segmentFields.map(segmentField => {
        const processedField = { ...segmentField };

        if (segmentField.name === 'ad_account_id') {
            processedField.choices = adAccounts.map(account => ({
                name: account.account_id,
                display: `${account.account_id}: ${account.account_name}`,
            }));
        }

        return processedField;
    });
};

const isAnyEmptyDistributionConfig = distribution =>
    Object.values(distribution?.config || {}).some(fieldValue => !fieldValue);

export const normalizeDistributionsAndNetworks = distributionNetworks => {
    const normalizeNetworks = {};
    const allNetworks = Object.keys(distributionNetworks)
        .map(networkName => {
            const networkData = distributionNetworks[networkName];
            if (!networkData) return null;

            const { distribution } = networkData;
            const displayName = networkData.display_name || '';
            const networkValue = networkData.name || '';
            const distributionFields = networkData.distribution_fields || [];
            const segmentFields = networkData.segment_fields || [];

            const adAccounts = Object.values(distribution?.config?.enabled_ad_accounts || {});
            const requiresAdAccounts = distributionFields.some(field => field.name === 'enabled_ad_accounts');

            const isDisabled =
                !distribution?.active ||
                (requiresAdAccounts && adAccounts.length === 0) ||
                isAnyEmptyDistributionConfig(distribution);

            normalizeNetworks[networkName] =
                adAccounts.length > 0
                    ? {
                          ...networkData,
                          label: displayName,
                          value: networkValue,
                          segment_fields: processSegmentFields(segmentFields, adAccounts),
                      }
                    : {
                          ...networkData,
                          label: displayName,
                          value: networkValue,
                      };
            return {
                label: displayName,
                name: networkName,
                value: networkName,
                isDisabled,
            };
        })
        .filter(Boolean);

    const internalS3 = distributionNetworks[INTERNAL_S3_KEY];

    return {
        allNetworks,
        internalDistributions: internalS3,
        distributionNetworks: normalizeNetworks,
    };
};

export const normalizeTestChannelsOverviewForPie = (testChannels, networks) => {
    if (!testChannels) return [];
    const colors = ['#3784db', '#9d7be0', '#f4a622'];
    const controlGroupValue = testChannels.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
    const data = testChannels
        .filter(testChannel => !!testChannel.name)
        .map((testChannel, index) => ({
            name: networks[testChannel.name].display_name,
            y: testChannel.value,
            color: colors[index],
        }));
    data.push({
        name: 'Control Group',
        y: 100 - controlGroupValue,
        color: '#c4c4c4',
    });
    return data;
};

export const normalizeEventForDisplay = events =>
    events
        .filter(event => !FILTERED_EVENTS_NAMES.includes(event.displayName))
        .map(event => ({
            value: event.eventName,
            label: event.displayName,
        }));

export const normalizeTestChannels = testChannels => {
    if (!testChannels.length) {
        return [EMPTY_TEST_CHANNEL];
    }

    return testChannels
        .filter(e => !e.isControlGroup)
        .sort((a, b) => a.minRange - b.minRange)
        .map(testChannel => ({
            id: testChannel?.id,
            name: testChannel.adnetworkName,
            uanId: testChannel.uanId,
            adnetworkIntegrationId: testChannel.adnetworkIntegrationId,
            value: testChannel.maxRange - testChannel.minRange,
            config: JSON.parse(testChannel.distributionConfigValues),
        }));
};

export const normalizePost = formValues => {
    let minValue = 0;
    const testChannels = formValues.testChannels
        .filter(testChannel => testChannel.name)
        .map(testChannel => {
            const res = {
                id: testChannel?.id,
                isControlGroup: false,
                uanId: testChannel?.uanId,
                adnetworkIntegrationId: testChannel?.adnetworkIntegrationId,
                distributionConfigValues: testChannel.config ? JSON.stringify(testChannel.config) : '{}',
                minRange: minValue,
                maxRange: minValue + testChannel.value,
            };
            minValue += testChannel.value;
            return res;
        });
    testChannels.push({
        isControlGroup: true,
        distributionConfigValues: '{}',
        adnetworkIntegrationId: null,
        uanId: null,
        minRange: minValue,
        maxRange: 100,
    });
    return {
        id: formValues?.id,
        name: formValues?.testName || '',
        description: formValues?.description || '',
        appsiteId: formValues.appsite.value,
        segmentId: formValues.segment.value,
        events: formValues?.events || [],
        isDraft: !!formValues.isDraft,
        isTestOngoing: !!formValues.isTestOngoing,
        startDate: formValues.startDate,
        endDate: formValues.isTestOngoing ? null : formValues.endDate,
        testChannels,
    };
};

export const getSegmentDropdownExtra = (translate, translatePrefix, segment) => {
    if (segment.hasAbTest) return translate(`${translatePrefix}.SEGMENT_WARNING.TEST`);
    if (segment.hasActiveDistribution) return translate(`${translatePrefix}.SEGMENT_WARNING.NETWORK`);
    return '';
};
