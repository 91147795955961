import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import { PAGE_HEADER_WITH_PADDING_STYLE } from '../../../utils/consts';
import { trackMixpanelEvent } from '../../../utils/general';
import HeaderComponentWrapper from '../../partials/HeaderComponentWrapper';
import { HelpMenu } from '../../widgets';
import StickyShadow from '../../partials/StickyShadow';
import EmptyState from '../../partials/EmptyState/EmptyState';
import OverflowingText from '../../foundations/OverflowingText';
import css from './PageHeader.css';

const PageHeader = forwardRef(
    (
        {
            imgSrc,
            text,
            textReplace,
            additionalText,
            subText,
            showEmptyStateCloseIcon,
            leftComponents,
            rightComponents,
            sticky,
            stickyShadow,
            onVideoClick,
            emptyStateHeaderData,
            emptyStateOrgData,
            showEmptyState,
            onCloseEmptyState,
            reportFormHasChanged,
            onTaskClick,
            shouldAddPadding,
            children,
            mixpanelEventPrefix,
            stickyStyle,
        },
        ref
    ) => {
        let containerStyle;

        if (shouldAddPadding) {
            containerStyle = PAGE_HEADER_WITH_PADDING_STYLE;
        }

        const stickyRef = useRef();

        const isShowElement = (elem, isStickyArea) => {
            return (
                (!sticky && !isStickyArea) ||
                (elem.disableSticky && !isStickyArea) ||
                (!elem.disableSticky && isStickyArea)
            );
        };

        const onHelpClicked = type => {
            if (mixpanelEventPrefix) {
                trackMixpanelEvent(mixpanelEventPrefix, 'Need Help Clicked', { type });
            }
        };

        const getSideComponents = (isStickyArea, leftComponentsWidth = 'initial') => {
            const leftChildren = leftComponents
                .filter(elem => isShowElement(elem, isStickyArea))
                .map(comp => <HeaderComponentWrapper key={comp.id} {...comp} />);

            const rightChildren = rightComponents
                .filter(elem => isShowElement(elem, isStickyArea))
                .map(comp => (
                    <HeaderComponentWrapper key={comp.id} {...comp} props={{ ...comp.props, reportFormHasChanged }} />
                ));

            if (!leftChildren.length && !rightChildren.length) {
                return null;
            }

            return (
                <>
                    {!!leftChildren.length && (
                        <div className={css.actionsLeft} style={{ width: leftComponentsWidth }}>
                            {leftChildren}
                        </div>
                    )}
                    {!!rightChildren.length && <div className={css.actionsRight}>{rightChildren}</div>}
                </>
            );
        };

        const showEmptyStateComponent = emptyStateOrgData && showEmptyState && emptyStateHeaderData;

        const sideComponent = getSideComponents(false, '100%');
        const stickySideComponent = sticky ? getSideComponents(true) : null;

        return (
            <div ref={ref}>
                <div style={containerStyle} className={css.topPartContainer}>
                    <div className={classNames(css.header, { [css.textWithMargin]: showEmptyState })}>
                        {imgSrc ? <img src={imgSrc} className={css.boxImage} /> : null}
                        <div>
                            <Translate id={text} data={textReplace} />
                            {subText?.subTitle && (
                                <div className={css.subTitle}>
                                    <Translate id={subText.subTitle} />
                                </div>
                            )}
                        </div>
                        {additionalText && (
                            <OverflowingText>
                                &nbsp;- <Translate id={additionalText} />
                            </OverflowingText>
                        )}
                    </div>
                    <div>
                        {subText && (
                            <HelpMenu
                                {...subText}
                                onVideoClick={onVideoClick}
                                containerClass={classNames({ [css.subHeaderContainer]: shouldAddPadding })}
                                onClick={onHelpClicked}
                            />
                        )}
                    </div>
                </div>
                {showEmptyStateComponent && (
                    <div
                        className={classNames({
                            [css.emptyStateContainer]: shouldAddPadding,
                            [css.emptyStateContainerWithMargin]: !shouldAddPadding,
                        })}
                    >
                        <EmptyState
                            titleText={emptyStateHeaderData.titleText}
                            subTitleText={emptyStateHeaderData.subTitleText}
                            tasksTodo={emptyStateHeaderData.tasks}
                            tasksDone={emptyStateOrgData.tasks}
                            showCloseIcon={
                                showEmptyStateCloseIcon &&
                                emptyStateHeaderData.tasks.some(({ topic }) => emptyStateOrgData.tasks[topic])
                            }
                            onCloseEmptyState={onCloseEmptyState}
                            pageName={text}
                            onTaskClick={onTaskClick}
                            imageSrc={emptyStateHeaderData.imageSrc}
                        />
                    </div>
                )}
                {sideComponent && <div className={css.actionsContainer}>{sideComponent}</div>}
                {/* Separate actionsContainer so sticky works */}
                {sticky && (
                    <>
                        {stickySideComponent && (
                            <div
                                ref={stickyRef}
                                style={stickyStyle}
                                className={classNames(css.actionsContainer, css.sticky, {
                                    [css.textWithPadding]: !children,
                                })}
                            >
                                {stickySideComponent}
                            </div>
                        )}
                        {children && (
                            <div
                                style={stickyStyle}
                                className={classNames(css.actionsContainer, css.sticky, css.stickyChildren)}
                            >
                                {children}
                            </div>
                        )}
                    </>
                )}
                {/* Sticky border with cover to hide it when in initial position */}
                {sticky && stickyShadow ? <StickyShadow targetRef={stickyRef} /> : null}
            </div>
        );
    }
);

PageHeader.propTypes = {
    text: PropTypes.string,
    textReplace: PropTypes.objectOf(PropTypes.any),
    additionalText: PropTypes.string,
    showEmptyStateCloseIcon: PropTypes.bool,
    subText: PropTypes.shape({
        faqLink: PropTypes.string.isRequired,
        tourId: PropTypes.number,
        subTitle: PropTypes.string,
    }),
    leftComponents: PropTypes.arrayOf(PropTypes.object),
    rightComponents: PropTypes.arrayOf(PropTypes.object),
    onVideoClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    sticky: PropTypes.bool,
    reportFormHasChanged: PropTypes.bool,
    stickyShadow: PropTypes.bool,
    emptyStateHeaderData: PropTypes.objectOf(PropTypes.any),
    emptyStateOrgData: PropTypes.objectOf(PropTypes.any),
    showEmptyState: PropTypes.bool,
    onCloseEmptyState: PropTypes.func,
    onTaskClick: PropTypes.func,
    shouldAddPadding: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    imgSrc: PropTypes.string,
    mixpanelEventPrefix: PropTypes.string,
    stickyStyle: PropTypes.objectOf(PropTypes.any),
};

PageHeader.defaultProps = {
    text: '',
    additionalText: '',
    showEmptyStateCloseIcon: true,
    subText: { faqLink: '', tourId: null, subTitle: '' },
    leftComponents: [],
    rightComponents: [],
    onVideoClick: null,
    sticky: false,
    emptyStateHeaderData: null,
    emptyStateOrgData: null,
    showEmptyState: false,
    onCloseEmptyState: () => {},
    reportFormHasChanged: false,
    stickyShadow: true,
    onTaskClick: () => {},
    shouldAddPadding: false,
    imgSrc: null,
    children: null,
    mixpanelEventPrefix: null,
    stickyStyle: {},
};

export default PageHeader;
