import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useFormikContext } from 'formik-2';
import { Translate, withLocalize } from 'react-localize-redux';
import WizardLayout from './WizardLayout';
import StepButtons from './StepButtons';
import css from './Overview.css';
import PieChart from './components/PieChart';
import EventsDisplay from './components/EventsDisplay';
import { GET_EVENTS } from '../../../queries';
import { Spinner, WarningMessage } from '../../../../components/widgets';
import OverviewCalender from './components/OverviewCalender';
import { normalizeEventForDisplay, normalizeTestChannelsOverviewForPie } from './wizardUtils';
import useWizard from '../hook/useWizard';

const translatePrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTION_COMPONENTS.OVERVIEW';

const Overview = ({ onSaveDraft, pageTitle, onNext, onBack, distributionsData, submitData, translate }) => {
    const { values } = useFormikContext();
    const { shouldShowDraft, isAlreadyStarted, isAlreadyEnded } = useWizard(values);
    const { data: eventsData, loading } = useQuery(GET_EVENTS, {
        variables: { siteId: values.appsite?.value },
        skip: !values.appsite?.value,
        fetchPolicy: 'cache-first',
    });

    const normalizeEvents = useMemo(() => {
        if (loading || !eventsData) return [];
        const { events } = eventsData;
        return normalizeEventForDisplay(events.filter(event => values.events.includes(event.eventName)));
    }, [eventsData, loading, values.events]);

    const nextTitle = useMemo(() => {
        if (isAlreadyEnded) return '';
        if (isAlreadyStarted) return 'STATIC.BUTTONS.SAVE';
        return 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.BUTTONS.SET_UP_TEST';
    }, [isAlreadyEnded, isAlreadyStarted]);

    if (loading) {
        return <Spinner show expanded />;
    }

    return (
        <WizardLayout
            onSaveDraft={onSaveDraft}
            title={pageTitle}
            isContentScrollable={false}
            shouldShowDraft={shouldShowDraft}
            buttons={
                <StepButtons
                    showNextIcon={false}
                    isLast
                    isLoading={submitData.loading}
                    onBack={onBack}
                    onNext={onNext}
                    backTitle="BUTTONS.BACK"
                    nextTitle={nextTitle}
                />
            }
        >
            <div className={css.container}>
                <WarningMessage
                    type="error"
                    showIcon={false}
                    show={submitData.error}
                    message={`${translatePrefix}.SAVE_ERROR`}
                />
                <div className={`${css.formItem} ${css.isRow}`}>
                    <div className={css.title}>
                        <Translate id={`${translatePrefix}.TEST_NAME`} />
                    </div>
                    {values.testName}
                </div>
                <div className={`${css.formItem} ${css.isRow}`}>
                    <div className={css.title}>
                        <Translate id={`${translatePrefix}.DESCRIPTION`} />
                    </div>
                    {values.description}
                </div>
                <div className={`${css.formItem} ${css.isRow}`}>
                    <div className={css.title}>
                        <Translate id={`${translatePrefix}.APPSITE`} />
                    </div>
                    {values?.appsite?.icon}
                    <div>{values?.appsite?.label}</div>
                </div>
                <div className={`${css.formItem} ${css.isRow}`}>
                    <div className={css.title}>
                        <Translate id={`${translatePrefix}.SEGMENT`} />
                    </div>
                    {values?.segment?.label}
                </div>
                <div className={css.formItem}>
                    <div className={css.title}>
                        <Translate id={`${translatePrefix}.DISTRIBUTION`} />
                    </div>
                </div>
                <div className={css.pieContainer}>
                    <PieChart
                        data={normalizeTestChannelsOverviewForPie(
                            values.testChannels,
                            distributionsData.distributionNetworks
                        )}
                        title={
                            values.segment?.reach
                                ? `${translate(`${translatePrefix}.SEGMENT_SIZE`)} ${values.segment?.reach}`
                                : ''
                        }
                    />
                </div>
                <div className={css.formItem}>
                    <div className={css.title}>
                        <Translate id={`${translatePrefix}.EVENTS`} /> ({values.events.length + 2})
                    </div>
                    <EventsDisplay events={normalizeEvents} />
                </div>
                <div className={css.formItem}>
                    <div className={css.title}>
                        <Translate id={`${translatePrefix}.EXPERIMENT_PERIOD`} />
                    </div>
                    <OverviewCalender
                        startDate={values.startDate}
                        endDate={values.isTestOngoing ? null : values.endDate}
                    />
                </div>
            </div>
        </WizardLayout>
    );
};

Overview.propTypes = {
    onSaveDraft: PropTypes.func,
    translate: PropTypes.func,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    pageTitle: PropTypes.string,
    submitData: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.bool,
    }),
    distributionsData: PropTypes.shape({
        allNetworks: PropTypes.arrayOf(
            PropTypes.shape({
                display_name: PropTypes.string,
                name: PropTypes.string,
                isDisabled: PropTypes.bool,
            })
        ),
        internalDistributions: PropTypes.any,
        distributionNetworks: PropTypes.any,
    }),
};

export default withLocalize(Overview);
