import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Button } from '../../../../components/widgets';
import css from './testASession.css';
import { useSDKPlanningContext } from '../../context/useSDKPlanningContext';
import { TARGET_PLATFORM_SHORT_NAME, TARGET_PLATFORM_TO_PARAM } from '../../consts';
import { sendMixpanelEventSdkIntegration } from '../../utils';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../consts';
import { setShowBackPanel } from '../../../../topPanels/utils';
import { CONTENT_FOOTER_TRANSLATE_KEY, CONTENT_TRANSLATE_KEY } from './consts';
import { getSectionTranslateID, translateExists } from '../../sectionsWrapper/utils';

const TestingPage = ({ translate, sectionKey }) => {
    const { selectedTargetPlatform } = useSDKPlanningContext();
    const platform = TARGET_PLATFORM_SHORT_NAME[selectedTargetPlatform];
    const contentTranslateID = getSectionTranslateID(sectionKey, CONTENT_TRANSLATE_KEY, platform);
    const contentFooterTranslateID = getSectionTranslateID(sectionKey, CONTENT_FOOTER_TRANSLATE_KEY, platform);

    const handleClick = () => {
        sendMixpanelEventSdkIntegration(
            SDK_INTEGRATION_MIXPANEL_EVENTS.GO_TO_TESTING_CONSOLE,
            sectionKey,
            TARGET_PLATFORM_TO_PARAM[selectedTargetPlatform]
        );
        setShowBackPanel(window.location.hash.replace('#', ''));
        window.location.hash = '#/react/testing-console';
    };

    return (
        <div className={css.container}>
            <img src="/static/dashboard/img/contact_us_animated.gif" alt="" />
            <p>
                <Translate id={contentTranslateID} />
            </p>
            <Button
                level="important"
                text="STATIC.PAGES.SDK.BUTTONS.GO_TO_TESTING_CONSOLE"
                type="primary"
                onClick={handleClick}
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
            />
            {translateExists(translate, contentFooterTranslateID) && (
                <p>
                    <Translate id={contentFooterTranslateID} />
                </p>
            )}
        </div>
    );
};

TestingPage.propTypes = {
    translate: PropTypes.func.isRequired,
    sectionKey: PropTypes.string.isRequired,
};
export default withLocalize(TestingPage);
