import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Masonry from '@mui/lab/Masonry';
import { useMediaQuery } from '@mui/material';
import debounce from 'lodash/debounce';
import times from 'lodash/times';
import CreativeCard, { MAX_CREATIVE_CARD_HEIGHT } from '../creativeCard/CreativeCard';
import { OPTIONAL_CREATIVES_SIZES } from '../../creativeSizingUtils';
import { CreativeType } from '../../types';
import css from './CreativesGrid.css';
import { AutoCompleteOptionType } from '../../../components/widgets/AutoCompleteField';

const NUMBER_OF_ROWS_TO_LOAD = 10;

export const SKELETONS = times(100).map(index => ({
    ...OPTIONAL_CREATIVES_SIZES[index % OPTIONAL_CREATIVES_SIZES.length],
}));

export const CREATIVES_GRID_TEST_ID = 'creatives-grid';
export const LOADING_GRID_TEST_ID = 'creatives-grid-loading';

const CreativesGrid = forwardRef(
    ({ creatives, onCreativeClick, isLoading, isLoadingDimensions, selectedMetrics }, ref) => {
        const lastCreativeRef = useRef(null);

        const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));
        const isTablet = useMediaQuery(theme => theme.breakpoints.between('mobile', 'tablet'));
        const isXs = useMediaQuery(theme => theme.breakpoints.between('tablet', 'sm'));
        const isSm = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));
        const isMd = useMediaQuery(theme => theme.breakpoints.between('md', 'lg'));
        const isLg = useMediaQuery(theme => theme.breakpoints.between('lg', 'xl'));
        const isXl = useMediaQuery(theme => theme.breakpoints.up('xl'));

        const columns = isMobile ? 1 : isTablet ? 2 : isXs ? 3 : isSm ? 4 : isMd ? 5 : isLg ? 6 : isXl ? 7 : 4;

        const [numberOfDisplayedItems, setNumberOfDisplayedItems] = useState(columns * NUMBER_OF_ROWS_TO_LOAD);
        const [minHeight, setMinHeight] = useState(null);

        const items = isLoading ? SKELETONS : creatives;
        const itemsToDisplay = items.slice(0, numberOfDisplayedItems);

        useEffect(() => {
            if (items.length === numberOfDisplayedItems) return;

            // Ensure full rows are displayed for pagination purposes
            const newRows = Math.max(NUMBER_OF_ROWS_TO_LOAD, Math.ceil(numberOfDisplayedItems / columns));
            const newNumberOfItems = Math.min(items.length, columns * newRows);
            setNumberOfDisplayedItems(newNumberOfItems);

            // Workaround for Masonry flickering issue: https://github.com/mui/material-ui/issues/32518
            // Adds extra height to Masonry for pagination, to ensure smooth layout when loading more creatives.
            setMinHeight(`${Math.floor((items.length / columns) * MAX_CREATIVE_CARD_HEIGHT)}px`);
        }, [columns, numberOfDisplayedItems, items]);

        useEffect(() => {
            // If we reached the end of pagination, reset grid's height to the original height.
            if (numberOfDisplayedItems === items.length) {
                setMinHeight(null);
            }
        }, [numberOfDisplayedItems, items]);

        const loadMoreItems = debounce(
            () => {
                if (items.length === numberOfDisplayedItems) return;

                const numberOfItemsToLoad = columns * NUMBER_OF_ROWS_TO_LOAD;

                setNumberOfDisplayedItems(numberOfItems => Math.min(items.length, numberOfItems + numberOfItemsToLoad));
            },
            100,
            { leading: true, trailing: true }
        );

        const onScroll = e => {
            if (!lastCreativeRef.current) return;

            const { target } = e;
            const container = target.getBoundingClientRect();
            const lastCreative = lastCreativeRef.current.getBoundingClientRect();

            // Check if the last creative's top is within the container's view
            if (target.clientHeight <= lastCreative.top && lastCreative.top <= container.bottom) {
                loadMoreItems();
            }
        };

        const onClick = e => {
            // If there's a text selection, do nothing
            const selection = window.getSelection();
            if (selection && selection.toString().length > 0) {
                return;
            }

            onCreativeClick(e);
        };

        // Allow the scrollable container to load more creatives to the grid
        useImperativeHandle(ref, () => ({
            onScroll(e) {
                onScroll(e);
            },
        }));

        return (
            <div className={css.creativesGrid} onScroll={onScroll}>
                <Masonry
                    columns={columns}
                    spacing={2}
                    className={css.masonry}
                    sx={minHeight ? { minHeight } : undefined}
                    data-testid={isLoading ? LOADING_GRID_TEST_ID : CREATIVES_GRID_TEST_ID}
                >
                    {itemsToDisplay.map((element, index) => {
                        const itemKey = element.imageHash ? `${element.imageHash}-${element.type}` : index;
                        const isLastItem = index === itemsToDisplay.length - 1;
                        return (
                            <CreativeCard
                                key={itemKey}
                                onClick={onClick}
                                ref={isLastItem ? lastCreativeRef : null}
                                isLoadingDimensions={isLoadingDimensions}
                                selectedMetrics={selectedMetrics}
                                {...element}
                            />
                        );
                    })}
                </Masonry>
            </div>
        );
    }
);

CreativesGrid.propTypes = {
    creatives: PropTypes.arrayOf(PropTypes.shape(CreativeType)),
    onCreativeClick: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    isLoadingDimensions: PropTypes.bool,
    selectedMetrics: PropTypes.arrayOf(AutoCompleteOptionType),
};

CreativesGrid.defaultProps = {
    creatives: [],
    onCreativeClick: () => {},
};

export default CreativesGrid;
