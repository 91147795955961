import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { withLocalize } from 'react-localize-redux';

function SingularTooltip({
    style,
    wrapperClass,
    contentClass,
    children,
    title,
    titleTranslationKey,
    titleTranslationData,
    interactive,
    translate,
    distance,
    disabled,
    trigger,
    position,
    followCursor,
    delay,
}) {
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const tooltipHtml =
        isMounted.current && (titleTranslationKey || title) ? (
            <div className={contentClass}>
                {titleTranslationKey ? translate(titleTranslationKey, titleTranslationData) : title}
            </div>
        ) : null;

    if (!children) {
        return children;
    }

    if (disabled || !tooltipHtml) {
        return <div className={wrapperClass}>{children}</div>;
    }

    return (
        <Tooltip
            theme="light"
            html={tooltipHtml}
            size="big"
            interactive={interactive}
            style={style}
            className={wrapperClass}
            delay={delay !== null ? delay : interactive ? 700 : 0}
            distance={distance}
            disabled={disabled}
            trigger={trigger}
            position={position}
            followCursor={followCursor}
        >
            {children}
        </Tooltip>
    );
}

SingularTooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    translate: PropTypes.func.isRequired,
    style: PropTypes.objectOf(PropTypes.any),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    titleTranslationKey: PropTypes.string,
    titleTranslationData: PropTypes.objectOf(PropTypes.any),
    wrapperClass: PropTypes.string,
    contentClass: PropTypes.string,
    interactive: PropTypes.bool,
    distance: PropTypes.number,
    disabled: PropTypes.bool,
    trigger: PropTypes.string,
    position: PropTypes.oneOf([
        'top',
        'top-start',
        'top-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'left',
        'left-start',
        'left-end',
        'right',
        'right-start',
        'right-end',
    ]),
    followCursor: PropTypes.bool,
    delay: PropTypes.number,
};

SingularTooltip.defaultProps = {
    style: {},
    wrapperClass: undefined,
    contentClass: '',
    interactive: false,
    title: undefined,
    titleTranslationKey: undefined,
    distance: 10,
    disabled: false,
    trigger: 'mouseenter focus',
    position: 'top',
    followCursor: false,
    delay: null,
};

export default withLocalize(SingularTooltip);
