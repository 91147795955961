import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import CheckedIcon from '../../../resources/svg/checked_with_circle_blue.svg';
import { useLocalStorageState } from '../../../utils/customHooks';
import css from './sdkConfiguration.css';
import stepsCss from './sdkConfigurationStepPages.css';
import SDKConfigurationPlatformSelection from './sdkConfigurationPlatformSelection';
import SDKConfigurationQuestionsTablePage from './sdkConfigurationQuestionsTablePage';
import {
    SDK_CONFIGURATION_PLATFORMS_STEP,
    SDK_CONFIGURATION_OTHER_SDKS_STEP,
    SDK_CONFIGURATION_PRIVACY_STEP,
    SDK_CONFIGURATION_CAMPAIGNS_CONFIG_STEP,
    SDK_CONFIGURATION_MONETIZATION_STEP,
    SDK_CONFIGURATION_STEPS,
    QUESTIONS_METADATA,
    LOCAL_STORAGE_SDK_CONFIG_ANSWERS_KEY,
    LOCAL_STORAGE_SDK_CONFIG_PLATFORMS_KEY,
    SKIP_MIXPANEL_EVENT_NAME,
    SKIP_ACCEPT_MIXPANEL_PARAM,
    BACK_MIXPANEL_EVENT_NAME,
    STEP_MIXPANEL_PARAM,
    SDK_CONFIGURATION_MIXPANEL_PREFIX,
} from './consts';
import { Button, Wizard, WizardFooter } from '../../../components/widgets';
import { isFirstStep, isLastStep } from '../../../utils/wizard';
import {
    getTableData,
    getTableMetadata,
    isQuestionsCategorised,
    sendQuestionAnswersMixpanelEventsByStep,
} from './utils';
import { GeneralPopup, PopupTypes } from '../../../components/partials';
import { trackMixpanelEvent } from '../../../utils/general';

const SDKConfigurationWizard = ({
    translate,
    sdkConfig,
    activeStepIndex,
    onPrevClick,
    onNextClick,
    onSubmit,
    onSkipClick,
    isValidByStep,
    runActiveStepValidation,
    isWizardCompleted,
    inEditMode,
    onStepClick,
    organizationTier,
}) => {
    const {
        platforms: responsePlatforms = [],
        answers: sdkConfigurationAnswersResponse = {},
        metadata: answersMetadata = {},
    } = sdkConfig;

    const [skipped, setSkipped] = useState(false);
    const [showSkipPlanPopup, setShowSkipPlanPopup] = useState(false);
    const prevPlatforms = useRef(responsePlatforms);
    const [sdkConfigurationAnswers, setSdkConfigurationAnswers] = useLocalStorageState(
        LOCAL_STORAGE_SDK_CONFIG_ANSWERS_KEY,
        sdkConfigurationAnswersResponse
    );
    const [platforms, setPlatforms] = useLocalStorageState(LOCAL_STORAGE_SDK_CONFIG_PLATFORMS_KEY, responsePlatforms);

    const handlePlatformChange = name => {
        if (platforms.includes(name)) {
            setPlatforms(platforms.filter(platform => platform !== name));
            return;
        }
        setPlatforms([...platforms, name]);
    };

    useEffect(() => {
        if (skipped) {
            onSkipClick();
        }
    }, [skipped, onSkipClick]);

    useEffect(() => {
        if ((prevPlatforms?.current?.length || 0) !== platforms?.length) {
            runActiveStepValidation({ platforms });
        }
        prevPlatforms.current = platforms;
    }, [platforms]);

    const handleToggle = (questionID, currentAnswer) => {
        setSdkConfigurationAnswers({
            ...sdkConfigurationAnswers,
            [questionID]: currentAnswer,
        });
    };

    const tableMetadata = getTableMetadata(handleToggle, platforms);
    const questionsMetadataOtherSDKStep = QUESTIONS_METADATA[SDK_CONFIGURATION_OTHER_SDKS_STEP];
    const questionsMetadataPrivacyStep = QUESTIONS_METADATA[SDK_CONFIGURATION_PRIVACY_STEP];
    const questionsMetadataCampaignsConfigStep = QUESTIONS_METADATA[SDK_CONFIGURATION_CAMPAIGNS_CONFIG_STEP];
    const questionsMetadataMonetizationStep = QUESTIONS_METADATA[SDK_CONFIGURATION_MONETIZATION_STEP];

    const otherSDKStepTableData = getTableData(
        questionsMetadataOtherSDKStep,
        sdkConfigurationAnswers,
        translate,
        answersMetadata,
        organizationTier,
        platforms
    );
    const privacyStepTableData = getTableData(
        questionsMetadataPrivacyStep,
        sdkConfigurationAnswers,
        translate,
        answersMetadata,
        organizationTier,
        platforms
    );
    const campaignConfigStepTableData = getTableData(
        questionsMetadataCampaignsConfigStep,
        sdkConfigurationAnswers,
        translate,
        answersMetadata,
        organizationTier,
        platforms
    );
    const monetizationStepTableData = getTableData(
        questionsMetadataMonetizationStep,
        sdkConfigurationAnswers,
        translate,
        answersMetadata,
        organizationTier,
        platforms
    );

    const handleNextStepClick = () => {
        sendQuestionAnswersMixpanelEventsByStep(
            SDK_CONFIGURATION_STEPS[activeStepIndex],
            inEditMode,
            platforms,
            sdkConfigurationAnswers
        );

        if (isLastStep(activeStepIndex, SDK_CONFIGURATION_STEPS)) {
            onSubmit(platforms, sdkConfigurationAnswers);
        } else {
            onNextClick({ platforms });
        }
    };

    const handlePrevStepClick = () => {
        trackMixpanelEvent(SDK_CONFIGURATION_MIXPANEL_PREFIX, BACK_MIXPANEL_EVENT_NAME, {
            [STEP_MIXPANEL_PARAM]: SDK_CONFIGURATION_STEPS[activeStepIndex],
        });
        if (isFirstStep(activeStepIndex)) {
            console.log('CANCELED');
        } else {
            onPrevClick();
        }
    };

    const handleSkipClick = () => {
        setShowSkipPlanPopup(true);
    };

    const handleSkipPlanAccept = () => {
        trackMixpanelEvent(SDK_CONFIGURATION_MIXPANEL_PREFIX, SKIP_MIXPANEL_EVENT_NAME, {
            [SKIP_ACCEPT_MIXPANEL_PARAM]: true,
        });
        setShowSkipPlanPopup(false);
        setPlatforms(responsePlatforms);
        setSdkConfigurationAnswers(sdkConfigurationAnswersResponse);
        setSkipped(true);
    };

    const handleSkipPlanReject = () => {
        trackMixpanelEvent(SDK_CONFIGURATION_MIXPANEL_PREFIX, SKIP_MIXPANEL_EVENT_NAME, {
            [SKIP_ACCEPT_MIXPANEL_PARAM]: false,
        });
        setShowSkipPlanPopup(false);
    };

    const isWizardFirstStep = isFirstStep(activeStepIndex);

    return (
        <>
            <Wizard
                activeStep={SDK_CONFIGURATION_STEPS[activeStepIndex]}
                showStepper
                showStepsNumbers
                rootContainerStyle={{ height: 'unset', alignItems: 'center' }}
                cssClassThemeName="blueThemeWizard"
                stepperContainerStyle={{ margin: '0 auto', width: '700px' }}
                handleStepClicked={onStepClick}
                stepperIconClass={css.stepperIconCustom}
                CheckedIcon={CheckedIcon}
                stepLabelWidth={125}
                isWizardCompleted={isWizardCompleted}
            >
                <SDKConfigurationPlatformSelection
                    id={SDK_CONFIGURATION_PLATFORMS_STEP}
                    key={SDK_CONFIGURATION_PLATFORMS_STEP}
                    label={translate('STATIC.PAGES.SDK_CONFIGURATION.PLATFORMS_STEP_WIZARD_LABEL')}
                    platforms={platforms}
                    onPlatformChange={handlePlatformChange}
                    isValid={isValidByStep[SDK_CONFIGURATION_PLATFORMS_STEP]}
                    translate={translate}
                />
                <SDKConfigurationQuestionsTablePage
                    id={SDK_CONFIGURATION_OTHER_SDKS_STEP}
                    key={SDK_CONFIGURATION_OTHER_SDKS_STEP}
                    label={translate('STATIC.PAGES.SDK_CONFIGURATION.OTHER_SDKS_STEP_WIZARD_LABEL')}
                    stepTitle="STATIC.PAGES.SDK_CONFIGURATION.OTHER_SDKS_STEP_TITLE"
                    tableMetadata={tableMetadata}
                    tableData={otherSDKStepTableData}
                    zebraTable={!isQuestionsCategorised(questionsMetadataOtherSDKStep)}
                />
                <SDKConfigurationQuestionsTablePage
                    id={SDK_CONFIGURATION_PRIVACY_STEP}
                    key={SDK_CONFIGURATION_PRIVACY_STEP}
                    label={translate('STATIC.PAGES.SDK_CONFIGURATION.PRIVACY_STEP_WIZARD_LABEL')}
                    stepTitle="STATIC.PAGES.SDK_CONFIGURATION.PRIVACY_STEP_TITLE"
                    tableMetadata={tableMetadata}
                    tableData={privacyStepTableData}
                    zebraTable={!isQuestionsCategorised(questionsMetadataPrivacyStep)}
                />
                <SDKConfigurationQuestionsTablePage
                    id={SDK_CONFIGURATION_CAMPAIGNS_CONFIG_STEP}
                    key={SDK_CONFIGURATION_CAMPAIGNS_CONFIG_STEP}
                    label={translate('STATIC.PAGES.SDK_CONFIGURATION.CAMPAIGNS_CONFIG_STEP_WIZARD_LABEL')}
                    stepTitle="STATIC.PAGES.SDK_CONFIGURATION.CAMPAIGNS_CONFIG_STEP_TITLE"
                    tableMetadata={tableMetadata}
                    tableData={campaignConfigStepTableData}
                    tableClassName={stepsCss.tableOverflow}
                    zebraTable={!isQuestionsCategorised(questionsMetadataCampaignsConfigStep)}
                />
                <SDKConfigurationQuestionsTablePage
                    id={SDK_CONFIGURATION_MONETIZATION_STEP}
                    key={SDK_CONFIGURATION_MONETIZATION_STEP}
                    label={translate('STATIC.PAGES.SDK_CONFIGURATION.MONETIZATION_STEP_WIZARD_LABEL')}
                    stepTitle="STATIC.PAGES.SDK_CONFIGURATION.MONETIZATION_STEP_TITLE"
                    tableMetadata={tableMetadata}
                    tableData={monetizationStepTableData}
                    zebraTable={!isQuestionsCategorised(questionsMetadataMonetizationStep)}
                />
            </Wizard>
            <WizardFooter
                containerClass={css.wizardFooter}
                buttons={[
                    <Button
                        type="flat"
                        onClick={isWizardFirstStep ? handleSkipClick : handlePrevStepClick}
                        hide={isWizardFirstStep && isWizardCompleted}
                    >
                        <Translate id={`STATIC.BUTTONS.${isWizardFirstStep ? 'SKIP_MARKETING_GOALS' : 'BACK'}`} />
                    </Button>,
                    <Button onClick={handleNextStepClick} style={{ justifyContent: 'center', width: '126px' }}>
                        <Translate
                            id={`STATIC.BUTTONS.${
                                isLastStep(activeStepIndex, SDK_CONFIGURATION_STEPS) ? 'FINISH' : 'NEXT'
                            }`}
                        />
                    </Button>,
                ]}
            />
            <GeneralPopup
                open={showSkipPlanPopup}
                text={translate('STATIC.PAGES.SDK_CONFIGURATION.SKIP_POPUP.BODY_TEXT')}
                acceptText={translate('STATIC.PAGES.SDK_CONFIGURATION.SKIP_POPUP.ACCEPT_BUTTON')}
                onAccept={handleSkipPlanAccept}
                onReject={handleSkipPlanReject}
                type={PopupTypes.INFO_WITH_CANCEL}
            />
        </>
    );
};

SDKConfigurationWizard.propTypes = {
    translate: PropTypes.func.isRequired,
    sdkConfig: PropTypes.shape({
        platforms: PropTypes.array,
        answers: PropTypes.object,
        metadata: PropTypes.object,
    }).isRequired,
    activeStepIndex: PropTypes.number,
    onPrevClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSkipClick: PropTypes.func.isRequired,
    isValidByStep: PropTypes.object.isRequired,
    runActiveStepValidation: PropTypes.func.isRequired,
    isWizardCompleted: PropTypes.bool,
    inEditMode: PropTypes.bool,
    onStepClick: PropTypes.func,
    organizationTier: PropTypes.string.isRequired,
};

SDKConfigurationWizard.defaultProps = {
    activeStepIndex: undefined,
    isWizardCompleted: false,
    inEditMode: false,
    onStepClick: undefined,
};

export default withLocalize(SDKConfigurationWizard);
