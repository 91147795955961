import React, { useEffect, useState } from 'react';
import MetricGroup from 'alerts/components/CustomAlertsShelfGroups/MetricGroup';
import ThresholdGroup from 'alerts/components/CustomAlertsShelfGroups/ThresholdGroup';
import FilterGroup from 'alerts/components/CustomAlertsShelfGroups/FilterGroup';
import BreakdownGroup from 'alerts/components/CustomAlertsShelfGroups/BreakdownGroup';
import PropTypes from 'prop-types';
import { Shelf, Button } from 'components/widgets';
import WizardFooter from 'components/widgets/WizardFooter';
import { Translate, withLocalize } from 'react-localize-redux';
import Spinner from 'components/widgets/Spinner';
import { useSaveCustomAlerts } from 'alerts/hooks';
import EmailTagInput from 'alerts/components/EmailTagInput';
import TextField from 'components/widgets/TextField';
import OneCircleIcon from 'resources/svg/timeline/one.svg';
import TwoNoFillCircleIcon from 'resources/svg/timeline/two_no_fill.svg';
import TwoCircleIcon from 'resources/svg/timeline/two.svg';
import OvalIcon from 'resources/svg/oval.svg';
import Dropdown from 'components/widgets/Dropdown';
import { DEFAULT_METRIC, METRIC_TYPES } from 'alerts/consts';
import { getItemsWithDividers } from 'alerts/utils';
import WarningMessage from '../../components/utilities/WarningMessage';
import css from './CustomAlertsShelf.css';

const CustomAlertsShelf = ({
    reportTypes,
    reportType,
    onSetReportType,
    showShelf,
    onCloseShelf,
    onAddCustomAlertToTop,
    translate,
    existingAlert,
    onSetSelectedAlert,
    orgUsers,
    onSetShouldPullAlerts,
    alertsPageMetadata,
}) => {
    const [reportName, setReportName] = useState('');
    const [reportTypeInfo, setReportTypeInfo] = React.useState(null);

    const [alertEmails, setAlertEmails] = React.useState([]);
    const [alertSendToAdmins, setAlertSendToAdmins] = React.useState(false);

    const [selectedMetric, setSelectedMetric] = React.useState(null);
    const [selectedCohort, setSelectedCohort] = React.useState(null);
    const [selectedCondition, setSelectedCondition] = React.useState(null);
    const [selectedValue, setSelectedValue] = React.useState('');
    const [selectedTimePeriod, setSelectedTimePeriod] = React.useState(null);
    const [selectedBreakdownDimensions, setBreakdownDimensions] = React.useState([]);
    const [selectedThresholdMetric, setSelectedThresholdMetric] = React.useState(null);
    const [selectedThresholdCohort, setSelectedThresholdCohort] = React.useState(null);
    const [selectedThresholdCondition, setSelectedThresholdCondition] = React.useState(null);
    const [selectedThresholdValue, setSelectedThresholdValue] = React.useState('');
    const [selectedFilters, setSelectedFilters] = React.useState([{}]);

    const [isLoading, setIsLoading] = React.useState(true);

    const [showSavingSpinner, setShowSavingSpinner] = useState(false);
    const [shouldSaveAlert, setShouldSaveAlert] = useState(false);
    const [showSaveSuccess, setShowSaveSuccess] = useState(false);

    // Save Alerts Related
    const isMetricGroupFilled =
        selectedMetric !== null &&
        (selectedCohort !== null || !selectedMetric.is_cohort) &&
        selectedCondition !== null &&
        selectedValue !== '' &&
        selectedTimePeriod !== null;

    const isThresholdFilled =
        selectedThresholdMetric !== null &&
        (selectedThresholdCohort !== null || !selectedThresholdMetric.is_cohort) &&
        selectedThresholdCondition !== null &&
        selectedThresholdValue !== '';

    const isSettingsFilled = reportName !== '';

    const getMetricCondition = () => {
        if (!isMetricGroupFilled) return null;
        const condition = {
            name: `${selectedMetric.name}${selectedMetric.is_cohort ? `__${selectedCohort.name}` : ''}`,
            operator: selectedCondition.name,
            value: selectedValue,
        };
        return condition;
    };

    const getThresholdCondition = () => {
        if (!isThresholdFilled) return null;
        const condition = {
            name: `${selectedThresholdMetric.name}${
                selectedThresholdMetric.is_cohort ? `__${selectedThresholdCohort.name}` : ''
            }`,
            operator: selectedThresholdCondition.name,
            value: selectedThresholdValue,
        };
        return condition;
    };

    const validatedFilters = selectedFilters.filter(
        selectedFilter => selectedFilter.tags && selectedFilter.tags.length > 0
    );

    const onSaveCleanup = () => {
        setShouldSaveAlert(false);
    };

    const updatedAlert = useSaveCustomAlerts(
        shouldSaveAlert,
        onSaveCleanup,
        existingAlert.id,
        reportType.name,
        getMetricCondition(),
        getThresholdCondition(),
        isMetricGroupFilled ? selectedTimePeriod.name : null,
        selectedBreakdownDimensions.map(dimension => dimension.name),
        validatedFilters.map(filter => ({
            dimension: filter.name,
            operator: filter.operator.name,
            values: filter.tags.map(tag => (tag.name || tag.name === 0 ? tag.name : tag)),
        })),
        reportName,
        alertEmails,
        alertSendToAdmins
    );
    // Save Alerts Related ends here

    const initiateSaveAlert = () => {
        setShowSaveSuccess(false);
        onSetShouldPullAlerts(false);
        setShowSavingSpinner(true);
        setShouldSaveAlert(true);
    };

    const setInitialState = () => {
        setReportTypeInfo(null);
        setSelectedMetric(null);
        setSelectedCohort(null);
        setSelectedCondition(null);
        setSelectedValue('');
        setSelectedTimePeriod(null);
        setBreakdownDimensions([]);
        setSelectedThresholdMetric(null);
        setSelectedThresholdCohort(null);
        setSelectedThresholdCondition(null);
        setSelectedThresholdValue('');
        setSelectedFilters([{}]);
        setIsLoading(false);
        // setShowSavingSpinner(false);
        setReportName('');
        setAlertEmails([]);
        setAlertSendToAdmins(false);
        onSetSelectedAlert({ id: -1 });
    };

    useEffect(() => {
        if (updatedAlert.status === 'OK') {
            onSetShouldPullAlerts(true);
            onAddCustomAlertToTop(updatedAlert);
            setShowSaveSuccess(true);
            setShowSavingSpinner(false);
            setTimeout(() => {
                onCloseShelf();
                setInitialState();
                onSetReportType(reportTypes[0]);
                setShowSaveSuccess(false);
            }, 2000);
        }
    }, [updatedAlert.modified]);

    const _getReportTypeInfo = _reportType => {
        const _reportTypeInfo = alertsPageMetadata.report_types.filter(info => info.name === _reportType.name)[0];
        return _reportTypeInfo;
    };

    const _getMetricName = alert => {
        const conditionName = alert.condition.name;
        const lastIndexOfDunder = conditionName.lastIndexOf('__');
        return lastIndexOfDunder === -1 ? conditionName : conditionName.substring(0, lastIndexOfDunder);
    };

    useEffect(() => {
        if (Object.keys(existingAlert).length > 0 && existingAlert.id !== -1) {
            if (Object.keys(alertsPageMetadata).length > 0) {
                const _reportTypeInfo = _getReportTypeInfo(reportType);
                const metricName = _getMetricName(existingAlert);
                setSelectedMetric(_reportTypeInfo.metrics.find(metric => metric.name === metricName) || null);
                setSelectedCondition(
                    alertsPageMetadata.operators.filter(
                        operator => operator.name === existingAlert.condition.operator
                    )[0]
                );
                setSelectedValue(existingAlert.condition.value);
                setSelectedCohort(
                    existingAlert.condition.is_cohort
                        ? alertsPageMetadata.cohort_periods.filter(
                              cohort_period =>
                                  cohort_period.period_id.toString() === existingAlert.condition.cohort_period
                          )[0]
                        : null
                );
                setSelectedTimePeriod(
                    alertsPageMetadata.time_periods.filter(
                        timePeriod => timePeriod.name === existingAlert.time_period
                    )[0]
                );
                setBreakdownDimensions(
                    existingAlert.breakdown_dimensions.map(dimension => ({
                        ...dimension,
                        ...{ deleteEnabled: !_reportTypeInfo.mandatory_breakdown_dimensions.includes(dimension.name) },
                    }))
                );
                setAlertEmails(existingAlert.emails);
                setAlertSendToAdmins(existingAlert.send_to_admins);
                if (existingAlert.filters.length) {
                    const newFilters = [];
                    existingAlert.filters.map((existingFilter, index) => {
                        const newFilter = { ...existingFilter };
                        const existingFilterInfo = alertsPageMetadata.filter_operators.filter(
                            filterInfo => filterInfo.name === existingAlert.filters[index].operator
                        )[0];
                        newFilter.operator = existingFilterInfo;
                        newFilter.name = existingFilter.dimension;
                        newFilter.tags = existingFilter.values;
                        newFilters[index] = newFilter;
                    });
                    setSelectedFilters(newFilters);
                }
                if (existingAlert.threshold) {
                    setSelectedThresholdMetric(
                        _reportTypeInfo.metrics.filter(
                            metric => metric.name === existingAlert.threshold.name.split('__')[0]
                        )[0]
                    );
                    setSelectedThresholdCohort(
                        existingAlert.threshold.is_cohort
                            ? alertsPageMetadata.cohort_periods.filter(
                                  cohort_period =>
                                      cohort_period.period_id.toString() === existingAlert.threshold.cohort_period
                              )[0]
                            : null
                    );
                    setSelectedThresholdCondition(
                        alertsPageMetadata.operators.filter(
                            operator => operator.name === existingAlert.threshold.operator
                        )[0]
                    );
                    setSelectedThresholdValue(existingAlert.threshold.value);
                }
                setReportName(existingAlert.name);
            }
        } else {
            setInitialState();
        }
    }, [existingAlert.id || -1, alertsPageMetadata]);

    const isNewAlert = Object.keys(existingAlert).length === 0 || existingAlert.id === -1;

    useEffect(() => {
        if (reportTypeInfo && Object.keys(reportTypeInfo).length > 0 && isNewAlert) {
            const mandatoryDimensions = reportTypeInfo.dimensions
                .filter(dimension => reportTypeInfo.mandatory_breakdown_dimensions.includes(dimension.name))
                .map(dimension => ({ ...dimension, ...{ deleteEnabled: dimension.deleteEnabled } }));
            setBreakdownDimensions(mandatoryDimensions);
        }
    }, [reportTypeInfo]);

    const isModified = () => {
        if (isLoading) {
            return false;
        } else if (isNewAlert) {
            return (
                selectedMetric !== null ||
                reportName !== '' ||
                alertEmails.length > 0 ||
                selectedThresholdMetric !== null ||
                (selectedFilters.length > 0 &&
                    (selectedFilters.length !== 1 || Object.keys(selectedFilters[0]).length !== 0)) ||
                selectedBreakdownDimensions.filter(
                    dimension => !reportTypeInfo.mandatory_breakdown_dimensions.includes(dimension.name)
                ).length > 0
            );
        }
        return (
            existingAlert.name !== reportName ||
            existingAlert.emails.length !== alertEmails.length ||
            existingAlert.send_to_admins !== alertSendToAdmins ||
            existingAlert.emails.filter(email => !alertEmails.includes(email)).length > 0 ||
            existingAlert.condition.name.split('__')[0] !== selectedMetric?.name ||
            existingAlert.condition.operator !== selectedCondition.name ||
            existingAlert.condition.value !== selectedValue ||
            existingAlert.time_period !== selectedTimePeriod.name ||
            existingAlert.breakdown_dimensions.length !== selectedBreakdownDimensions.length ||
            existingAlert.breakdown_dimensions.filter(
                dimension => !selectedBreakdownDimensions.map(_dimension => _dimension.name).includes(dimension.name)
            ).length > 0 ||
            (existingAlert.threshold === null &&
                selectedThresholdMetric !== null &&
                selectedThresholdMetric.name !== DEFAULT_METRIC.name) ||
            (existingAlert.threshold !== null && selectedThresholdMetric === null) ||
            (existingAlert.threshold &&
                selectedThresholdMetric &&
                (existingAlert.threshold.name !== selectedThresholdMetric.name ||
                    existingAlert.threshold.operator !== selectedThresholdCondition.name ||
                    existingAlert.threshold.value !== selectedThresholdValue)) ||
            (existingAlert.filters.length !== selectedFilters.length &&
                (existingAlert.filters.length !== 0 ||
                    selectedFilters.length !== 1 ||
                    Object.keys(selectedFilters[0]).length !== 0)) ||
            existingAlert.filters.filter(
                (filter, i) =>
                    selectedFilters[i].name !== filter.dimension ||
                    selectedFilters[i].operator.name !== filter.operator ||
                    filter.values.length !== selectedFilters[i].tags.length ||
                    JSON.stringify(selectedFilters[i].tags.map(tag => tag.name)) !==
                        JSON.stringify(filter.values.map(tag => tag.name))
            ).length > 0
        );
    };

    const closeShelf = () => {
        onCloseShelf();
        setInitialState();
        onSetReportType(reportTypes[0]);
    };
    const handleCloseShelf = () => {
        if (isModified()) {
            if (!confirm(translate('STATIC.PAGES.SHARED_REPORTS.UNSAVED_CHANGES_WARNING'))) {
                return;
            }
        }
        closeShelf();
    };

    useEffect(() => {
        if (Object.keys(alertsPageMetadata).length > 0) {
            const _reportTypeInfo = _getReportTypeInfo(reportType);
            _reportTypeInfo.dimensions.sort(function(a, b) {
                return a.display_name.localeCompare(b.display_name);
            });
            _reportTypeInfo.metrics.sort(function(a, b) {
                return a.display_name.localeCompare(b.display_name);
            });
            setReportTypeInfo(_reportTypeInfo);
        }
    }, [alertsPageMetadata, reportType]);

    const onChangeReportType = _reportType => {
        onSetReportType(_reportType);
        if (Object.keys(alertsPageMetadata).length > 0) {
            setInitialState();
        }
    };

    // Show spinner until we get data from all api calls
    useEffect(() => {
        if (Object.keys(alertsPageMetadata).length > 0 && orgUsers.length > 0) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [alertsPageMetadata, orgUsers]);

    function isFormInvalid() {
        if (
            isMetricGroupFilled &&
            isSettingsFilled &&
            ((selectedFilters.length === 1 && Object.keys(selectedFilters[0]).length === 0) ||
                selectedFilters.length === validatedFilters.length)
        ) {
            return false;
        }
        return true;
    }

    return (
        <React.Fragment>
            <Shelf
                open={showShelf}
                shelfSize="medium"
                headerText="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.TITLE"
                onClose={handleCloseShelf}
            >
                <WarningMessage
                    show={false}
                    showIcon={false}
                    showTypeIcon
                    type="info"
                    message="STATIC.PAGES.SHARED_REPORTS.NOTE"
                />
                <div
                    className={css.container}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <div>
                        <div>
                            <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.TOP_TEXT" />
                        </div>
                        <div className={css.inputHeader} style={{ paddingTop: '15px' }}>
                            <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.ALERT_TYPE" />
                        </div>
                        <Dropdown
                            items={reportTypes}
                            selected={reportType}
                            containerClass={css.reportTypeDropdown}
                            onSelection={onChangeReportType}
                            wrapperStyle={{ display: 'flex' }}
                            disabled={!isNewAlert}
                        />
                    </div>
                    {isLoading && <Spinner style={{ top: '10vh' }} show expanded />}
                    {!isLoading && reportTypeInfo && (
                        <React.Fragment>
                            <div className={css.divider} />
                            <div className={css.rulesTitle}>
                                <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.ABOUT_TITLE" />
                            </div>
                            <div className={css.gridWrapper}>
                                <div className={css.leftBorderIcons}>
                                    <OneCircleIcon />
                                </div>
                                <div>
                                    <div className={css.text} style={{ height: '30px' }}>
                                        <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.WHAT_TITLE" />
                                    </div>
                                    <MetricGroup
                                        metrics={getItemsWithDividers(METRIC_TYPES, reportTypeInfo.metrics)}
                                        selectedMetric={selectedMetric}
                                        alertsPageMetadata={alertsPageMetadata}
                                        onMetricChanged={setSelectedMetric}
                                        selectedCohort={selectedCohort}
                                        onCohortChanged={setSelectedCohort}
                                        selectedCondition={selectedCondition}
                                        onConditionChanged={setSelectedCondition}
                                        selectedValue={selectedValue}
                                        onValueChanged={setSelectedValue}
                                        selectedTimePeriod={selectedTimePeriod}
                                        onTimePeriodChanged={setSelectedTimePeriod}
                                    />
                                </div>
                                <div
                                    className={`${css.ovalIcons}  ${
                                        selectedBreakdownDimensions.length > 0 ? css.ovalFilled : ''
                                    }`}
                                >
                                    <OvalIcon />
                                </div>
                                <BreakdownGroup
                                    translate={translate}
                                    reportType={reportType}
                                    dimensions={reportTypeInfo.dimensions}
                                    selectedBreakdownDimensions={selectedBreakdownDimensions}
                                    onBreakdownChanged={setBreakdownDimensions}
                                    mandatory_breakdown_dimensions={reportTypeInfo.mandatory_breakdown_dimensions}
                                />
                                <div
                                    className={`${css.ovalIcons} ${
                                        selectedFilters.length > 0 &&
                                        selectedFilters[0].tags &&
                                        selectedFilters[0].tags.length > 0
                                            ? css.ovalFilled
                                            : ''
                                    }`}
                                >
                                    <OvalIcon />
                                </div>
                                <FilterGroup
                                    translate={translate}
                                    dimensions={reportTypeInfo.dimensions}
                                    filterOperators={alertsPageMetadata.filter_operators}
                                    selectedFilters={selectedFilters}
                                    validatedFiltersCount={validatedFilters.length}
                                    onFiltersChanged={setSelectedFilters}
                                />
                                <div className={`${css.ovalIcons} ${isThresholdFilled ? css.ovalFilled : ''}`}>
                                    <OvalIcon />
                                </div>
                                <ThresholdGroup
                                    translate={translate}
                                    alertsPageMetadata={alertsPageMetadata}
                                    metrics={getItemsWithDividers(METRIC_TYPES, reportTypeInfo.metrics)}
                                    selectedThresholdMetric={selectedThresholdMetric}
                                    onThresholdMetricChanged={setSelectedThresholdMetric}
                                    selectedThresholdCohort={selectedThresholdCohort}
                                    onThresholdCohortChanged={setSelectedThresholdCohort}
                                    selectedThresholdCondition={selectedThresholdCondition}
                                    onThresholdConditionChanged={setSelectedThresholdCondition}
                                    selectedThresholdValue={selectedThresholdValue}
                                    onThresholdValueChanged={setSelectedThresholdValue}
                                />
                            </div>
                            <div className={css.sectionHeader}>
                                <div style={{ marginLeft: '-8px' }}>
                                    {!isMetricGroupFilled && <TwoNoFillCircleIcon />}
                                    {isMetricGroupFilled && <TwoCircleIcon />}
                                </div>
                                <div className={css.text}>
                                    <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.SETTINGS_TITLE" />
                                </div>
                            </div>
                            <div style={{ paddingLeft: '27px' }}>
                                <div className={css.inputHeader}>
                                    <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.REPORT_NAME" />
                                </div>
                                <TextField
                                    inputConfig={{ maxLength: 40 }}
                                    disabled={!isMetricGroupFilled}
                                    value={reportName}
                                    containerClass={css.textInputLine}
                                    // mandatory
                                    onChange={setReportName}
                                    placeholder={translate(
                                        'STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.REPORT_NAME_HINT'
                                    )}
                                />
                                <div className={css.inputHeader}>
                                    <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.MAIL_TO" />
                                </div>
                                <EmailTagInput
                                    orgUsers={orgUsers}
                                    disabled={!isMetricGroupFilled}
                                    emails={alertEmails}
                                    setEmails={setAlertEmails}
                                    sendToAdmins={alertSendToAdmins}
                                    setSendToAdmins={setAlertSendToAdmins}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <WizardFooter
                    leftComponents={[
                        <span key="Saving Emails">
                            {showSavingSpinner && (
                                <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.SAVING_ALERT" />
                            )}
                        </span>,
                    ]}
                    buttons={[
                        <Button type="flat" disabled={showSavingSpinner || showSaveSuccess} onClick={closeShelf}>
                            Cancel
                        </Button>,
                        <Button
                            onClick={initiateSaveAlert}
                            showSpinner={showSavingSpinner && !showSaveSuccess}
                            showV={showSaveSuccess}
                            disabledDark={!isModified() || isFormInvalid() || showSaveSuccess || showSavingSpinner}
                        >
                            Save
                        </Button>,
                    ]}
                />
            </Shelf>
        </React.Fragment>
    );
};

CustomAlertsShelf.propTypes = {
    reportTypes: PropTypes.arrayOf(PropTypes.object),
    reportType: PropTypes.objectOf(PropTypes.string),
    onSetReportType: PropTypes.func,
    showShelf: PropTypes.bool,
    onCloseShelf: PropTypes.func,
    onAddCustomAlertToTop: PropTypes.func,
    translate: PropTypes.func,
    existingAlert: PropTypes.objectOf(PropTypes.any),
    onSetSelectedAlert: PropTypes.func,
    orgUsers: PropTypes.arrayOf(PropTypes.object),
    onSetShouldPullAlerts: PropTypes.func,
    alertsPageMetadata: PropTypes.objectOf(PropTypes.any),
};

CustomAlertsShelf.defaultProps = {
    reportTypes: [],
    reportType: {},
    onSetReportType: () => {},
    showShelf: false,
    onCloseShelf: () => {},
    onAddCustomAlertToTop: () => {},
    translate: () => {},
    existingAlert: {},
    onSetSelectedAlert: () => {},
    orgUsers: [],
    onSetShouldPullAlerts: () => {},
    alertsPageMetadata: {},
};

export default withLocalize(CustomAlertsShelf);
