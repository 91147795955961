import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import css from './WizardEditor.css';
import { ListItemsContainer } from '../../../components/partials';
import { getSectionsListItems } from './sectionsConfig';
import { TEST_WIZARD_STEPS } from './consts';

const WizardEditor = ({
    activeStepIndex,
    onPrevClick,
    onNextClick,
    submitData,
    onStepClick,
    onDraftClick,
    distributionsData,
}) => {
    const listItems = useMemo(
        () => getSectionsListItems(onNextClick, onPrevClick, onDraftClick, distributionsData, submitData),
        [onNextClick, onPrevClick, onDraftClick, distributionsData, submitData]
    );

    return (
        <ListItemsContainer
            listItems={listItems}
            isExpandable
            showCheckedIcon
            pageContainerClassName={css.container}
            sectionContentClassName={css.content}
            sectionContentContainerClassName={css.contentSectionContainer}
            sectionListClassName={css.list}
            onClickListItem={id => onStepClick(id)}
            activeItem={TEST_WIZARD_STEPS[activeStepIndex]}
        />
    );
};

WizardEditor.propTypes = {
    activeStepIndex: PropTypes.number,
    onPrevClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    onDraftClick: PropTypes.func.isRequired,
    submitData: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.bool,
    }),
    onStepClick: PropTypes.func,
    distributionsData: PropTypes.shape({
        allNetworks: PropTypes.arrayOf(
            PropTypes.shape({
                display_name: PropTypes.string,
                name: PropTypes.string,
                isDisabled: PropTypes.bool,
            })
        ),
        internalDistributions: PropTypes.any,
        distributionNetworks: PropTypes.any,
    }),
};

export default WizardEditor;
