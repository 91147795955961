import React, { useCallback } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from './sdkKeysDocs.css';
import Label from '../../components/foundations/Label';
import Button from '../../components/atoms/Button';
import { trackMixpanelEvent } from '../../utils/general';
import { UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL } from '../../homepage/utils';

function SdkKeysDocs(props) {
    const { translate, sdkToken, sdkKey, itemId } = props;
    const [showSecretKeys, setShowSecretKeys] = React.useState(false);
    const setShowSecretKeysCallback = useCallback(() => {
        setShowSecretKeys(!showSecretKeys);
        trackMixpanelEvent(UNIFIED_ONBOARDING_PAGE_NAME_FOR_MIXPANEL, 'Click on Show/Hide SDK keys', {
            showKeys: !showSecretKeys,
        });
    }, [showSecretKeys, setShowSecretKeys]);

    return (
        <div className={css.layout}>
            <div>{translate('STATIC.PAGES.ONBOARDING_GUIDE.CONTENT.SDK_KEYS_AND_DOCUMENTATION')}</div>
            <br />
            <div className={css.exampleSdkKeyContainer}>
                <div className={css.exampleSdkKeyTitle}>
                    <Label text="SDK Keys" className={css.titleText} />
                </div>
                <div className={css.exampleSdkKeyContent}>
                    {showSecretKeys ? (
                        <span className={css.exampleSdkKeyText}>
                            <strong>SDK Key:</strong> {sdkKey}
                            <br />
                            <strong>SDK Secret:</strong> {sdkToken}
                        </span>
                    ) : (
                        <span className={css.exampleSdkKeyText}>**********************************************</span>
                    )}
                    <Button
                        onClick={setShowSecretKeysCallback}
                        text={showSecretKeys ? 'Hide Keys' : 'Show Keys'}
                        type="secondary"
                    />
                </div>
            </div>
        </div>
    );
}

SdkKeysDocs.propTypes = {
    translate: PropTypes.func.isRequired,
    sdkToken: PropTypes.string,
    sdkKey: PropTypes.string,
    itemId: PropTypes.string,
};

SdkKeysDocs.defaultProps = {
    sdkToken: '',
    sdkKey: '',
    itemId: '',
};

export default withLocalize(SdkKeysDocs);
