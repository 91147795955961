import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import addHighchartsMore from 'highcharts/highcharts-more';
import { withHighcharts, HighchartsChart } from 'react-jsx-highcharts';
import {
    BENCHMARKS_GRAPH_TYPES,
    BENCHMARKS_PERCENTILES_GRAPHS,
    networkOptimizationsPlotOptions,
    PERCENTILES_GRAPH_DEFAULT_LIMITS,
    PERCENTILES_GRAPH_TH_LABELS,
} from '../consts';
import ChartTooltip from './chartTooltip';
import ChartXAxis from './chartXAxis';
import ChartYAxis from './chartYAxis';
import Chart from './chart';
import {
    seriesRangeValueFormatter,
    getPercentileCategories,
    seriesRangePercentageValueFormatter,
    networkHasData,
    seriesRangeValueFormatterNoSymbol,
} from '../utils';
import { getTooltipValuesPercentiles } from '../../utils/charts';
import Label from '../../components/foundations/Label';
import TableCSS from '../../components/organisms/Table.css';
import css from '../styles.css';
import Percentile from './percentile';

addHighchartsMore(Highcharts);

const percentilesChart = ({ chartData, logoByName }) => {
    const renderTh = (label, max, formatter) => {
        return (
            <div className={css.thPercentile}>
                <div className={css.thLabel}>
                    <Label type="tableTh" text={label} />
                </div>
                {max && [
                    <div key={`${label}-range`} className={css.thPercentileRange}>
                        <div className={css.thPercentileRangeText}>{`${formatter(0, false)}`}</div>
                        <div className={css.thPercentileRangeText}>{`${formatter(max, false)}`}</div>
                    </div>,
                    <div key={`${label}-bar`} className={css.thPercentileRangeBar} />,
                ]}
            </div>
        );
    };

    const renderNoDataCell = () => {
        return (
            <div className={css.noDataCell}>
                <Label type="h4" text="No Data" />
            </div>
        );
    };

    const renderCell = (percentileName, networkData, networkName, max) => {
        const percentileCategories = getPercentileCategories(percentileName);
        const { vertical, app } = networkData;
        let formatter = seriesRangePercentageValueFormatter;

        if (
            percentileName === BENCHMARKS_GRAPH_TYPES.CPI_PERCENTILES ||
            percentileName === BENCHMARKS_GRAPH_TYPES.CPM_PERCENTILES
        ) {
            formatter = seriesRangeValueFormatter;
        } else if (percentileName === BENCHMARKS_GRAPH_TYPES.IPM_PERCENTILES) {
            formatter = seriesRangeValueFormatterNoSymbol;
        }

        return (
            <HighchartsChart plotOptions={networkOptimizationsPlotOptions}>
                <Chart type="columnrange" inverted grouping={false} height={40} />
                <ChartTooltip
                    tooltipHeader={networkName}
                    categories={Object.values(percentileCategories)}
                    isTextHeader
                    getTooltipValuesFunction={getTooltipValuesPercentiles}
                />
                <ChartXAxis
                    id={percentileName}
                    enableLabels={false}
                    gridLineWidth={3}
                    gridLineColor="#ebebeb"
                    lineWidth={0}
                />
                <ChartYAxis
                    id={percentileName}
                    labelsEnabled={false}
                    visible={false}
                    min={0}
                    max={parseFloat(max.toFixed(2))}
                >
                    <Percentile
                        name={percentileCategories.percentile_25_75_range}
                        formatter={formatter}
                        pointWidth={11}
                        lowPoint={vertical[0]} // 0.25 percentile
                        highPoint={vertical[2]} // 0.75 percentile
                        maxRangeValue={max}
                        color="#4479a9"
                        borderRadius={2.5}
                    />
                    {app && app.length > 0 && (
                        <Percentile
                            name={percentileCategories.userDataMedian}
                            formatter={formatter}
                            pointWidth={20}
                            lowPoint={app[0]} // 0.5 percentile (median of user data)
                            maxRangeValue={max}
                            color="#f37773"
                            type="marker"
                        />
                    )}
                    <Percentile
                        name={percentileCategories.networkDataMedian}
                        formatter={formatter}
                        pointWidth={11}
                        lowPoint={vertical[1]} // 0.5 percentile (median of network data)
                        maxRangeValue={max}
                        color="#cccccc"
                        type="marker"
                    />
                </ChartYAxis>
            </HighchartsChart>
        );
    };

    return (
        <div>
            <table className={classNames(TableCSS.table, css.percentilesTable)}>
                <thead>
                    <tr>
                        <th className={css.thPercentile}>
                            <div className={css.thNetworkLabel}>
                                <Label type="tableTh" text="Network" />
                            </div>
                        </th>
                        {BENCHMARKS_PERCENTILES_GRAPHS.map(graphType => {
                            let formatter = seriesRangePercentageValueFormatter;

                            if (
                                graphType === BENCHMARKS_GRAPH_TYPES.CPI_PERCENTILES ||
                                graphType === BENCHMARKS_GRAPH_TYPES.CPM_PERCENTILES
                            ) {
                                formatter = seriesRangeValueFormatter;
                            } else if (graphType === BENCHMARKS_GRAPH_TYPES.IPM_PERCENTILES) {
                                formatter = seriesRangeValueFormatterNoSymbol;
                            }

                            const label = PERCENTILES_GRAPH_TH_LABELS[graphType];
                            const { limit } = chartData[graphType] || {};

                            return (
                                <th key={graphType}>
                                    {renderTh(label, limit || PERCENTILES_GRAPH_DEFAULT_LIMITS[graphType], formatter)}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(logoByName)
                        .filter(networkName => networkHasData(networkName, chartData))
                        .sort()
                        .map(networkName => {
                            return (
                                <tr key={networkName}>
                                    <td className={css.tdNetwork}>
                                        <div className={css.tdNetworkWrapper} title={networkName}>
                                            <img className={css.tdNetworkIcon} src={logoByName[networkName]} />
                                            <Label type="tableTd" text={networkName} className={css.tdNetworkName} />
                                        </div>
                                    </td>
                                    {BENCHMARKS_PERCENTILES_GRAPHS.map(graphType => {
                                        const { data = {}, limit } = chartData[graphType] || {};
                                        const networkData = data[networkName];

                                        return (
                                            <td
                                                key={`${graphType}-${networkName}`}
                                                className={classNames(css.tdPercentile, {
                                                    [css.tdPercentileNoData]: !networkData,
                                                })}
                                            >
                                                {networkData
                                                    ? renderCell(graphType, networkData, networkName, limit)
                                                    : renderNoDataCell()}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

percentilesChart.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    logoByName: PropTypes.objectOf(PropTypes.any),
};

percentilesChart.defaultProps = {
    chartData: {},
    logoByName: {},
};

export default withHighcharts(percentilesChart, Highcharts);
