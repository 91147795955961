import React from 'react';
import PropTypes from 'prop-types';
import { partnerShape } from '../types';
import { APPLE_SEARCH_ADS_PARTNER_NAME, FACEBOOK_PARTNER_NAME, SNAPCHAT_PARTNER_NAME } from '../../consts';
import { WarningMessage } from '../../../components/widgets';
import { OS } from '../../../utils/OS';
import css from './PartnerSetupInformation.css';

const PARTNER_INFO_MSG = {
    [FACEBOOK_PARTNER_NAME]: {
        [OS.IOS]: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.FACEBOOK_SETUP_INFORMATION',
    },
    [SNAPCHAT_PARTNER_NAME]: {
        [OS.IOS]: 'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.SNAPCHAT_ADVANCED_SETUP_INFORMATION',
        [OS.ANDROID]:
            'STATIC.PAGES.PARTNER_CONFIGURATION.SHELF.ATTRIBUTION_SETTINGS.SNAPCHAT_ADVANCED_SETUP_INFORMATION',
    },
};

function PartnerSetupInformation({ partner, platform }) {
    return (
        <div className={css.messageBox}>
            {PARTNER_INFO_MSG?.[partner.name]?.[platform] && (
                <WarningMessage show showIcon={false} type="info" message={PARTNER_INFO_MSG[partner.name][platform]} />
            )}
        </div>
    );
}

PartnerSetupInformation.propTypes = {
    partner: partnerShape.isRequired,
    platform: PropTypes.string,
};

PartnerSetupInformation.defaultProps = {
    platform: '',
};

export default PartnerSetupInformation;
