import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button, TextField } from '../../components/widgets';
import WarningMessage, { MessageTypes } from '../../teamManagement/components/WizardWarningMessage';
import DataSourcesService from '../service';
import css from './DataDestinationShelf.css';
import WizardFooter from '../../components/widgets/WizardFooter';
import DataDestinationField, { FIELD_TYPES } from './DataDestinationField';
import CustomSchemaFields from './CustomSchemaFields';
import { updateUans } from '../actions';
import { reportDestinationToMixpanel, saveDataDestination, SECRET_PLACEHOLDER } from '../utils';

const DataSourcesAPI = new DataSourcesService();
const MANDATORY_FIELD = 'This field is mandatory';
const USER_LEVEL_SOURCE_TYPES = ['admon_user_level', 'unilogs'];
const OUTPUT_KEY_PARTTEN_FIELD_NAME = 'etl_dest_config.key_pattern';
export const SCHEMA_FIELD_NAME = 'etl_source_config.preset';
const getRegexError = regex => `The output key pattern needs to end with:${regex}`;

const BEGIN_PRIVATE_KEY = '-----BEGIN (ENCRYPTED )?PRIVATE KEY-----';
const END_PRIVATE_KEY = '-----END (ENCRYPTED )?PRIVATE KEY-----';
const INVALID_PEM_MESSAGE = 'Invalid PEM certificate (make sure to paste the full content of the key file)';

// Values should be the parameters names sent to the server
const AuthFields = {
    USER: 'username',
    PASSWORD: 'password',
    TOKEN: 'token',
    TOKEN2: 'token2',
};

const FieldAnimationItem = posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 },
});

const LOGIN_SUCCESS_MESSAGE = {
    type: MessageTypes.SUCCESS,
    message: 'STATIC.PAGES.DATA_SOURCES.SHELF.TOP_MESSAGES.SUCCESS',
};

const LOGIN_FAILURE_MESSAGE = {
    type: MessageTypes.WARNING,
    message: 'STATIC.PAGES.DATA_SOURCES.SHELF.TOP_MESSAGES.LOGIN_ERROR',
};

const SAVING_FAILURE_MASSAGE = {
    type: MessageTypes.ERROR,
    message: 'STATIC.PAGES.DATA_DESTINATIONS.SHELF.SAVE_FAILED',
};

const getDataDestinationFields = (dataDestination, isEditMode, formFieldValues) => {
    if (!dataDestination) {
        return [];
    }

    const { fields: normalizedFields } = dataDestination;
    // Don't display field that depends on another field value
    // (e.g. don't display SSH fields when Access Type is not SSH Tunnel)
    const filteredFields = normalizedFields.filter(({ conditions }) => {
        if (!conditions) {
            return true;
        }

        for (const conditionField in conditions) {
            const fieldValue =
                formFieldValues[conditionField] || normalizedFields.find(field => field.name === conditionField)?.value; // In case of first render

            if (fieldValue !== conditions[conditionField]) {
                return false;
            }
        }

        return true;
    });

    return filteredFields;
};

export default function DataDestinationsShelf({
    uan,
    adnetwork,
    onClose,
    dataDestination,
    isEditMode,
    networkData,
    schemaField,
    translate,
}) {
    const dispatch = useDispatch();

    const [fieldValues, setFieldValues] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const [customFields, setCustomFields] = useState({});

    const [warningMessage, setWarningMessage] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    const fields = getDataDestinationFields(dataDestination, isEditMode, fieldValues);
    const fieldsWithoutSchema = fields.filter(({ name }) => name !== SCHEMA_FIELD_NAME);

    const { display_name: displayName } = uan || adnetwork || {};

    const {
        user,
        explanation,
        username_needed: usernameNeeded,
        username_placeholder: usernameLabel,
        password_needed: passwordNeeded,
        password_placeholder: passwordLabel,
        token_needed: tokenNeeded,
        token2_needed: token2Needed,
        token_text: tokenLabel,
        token2_text: token2Label,
        is_last_login_successful: isLoginSucceeded,
        source_type: sourceType,
        [isEditMode ? 'adn_name' : 'name']: adNetworkName,
    } = dataDestination || {};

    // Initialize form fields with edited data destination fields or default values
    useEffect(() => {
        const initialLoginValues = {
            [AuthFields.USER]: user || '',
            [AuthFields.PASSWORD]: isEditMode ? SECRET_PLACEHOLDER : '',
            [AuthFields.TOKEN]: isEditMode ? SECRET_PLACEHOLDER : '',
            [AuthFields.TOKEN2]: isEditMode ? SECRET_PLACEHOLDER : '',
        };

        const initialValues = fields.reduce(
            (total, field) => ({ ...total, [field.name]: field.value ?? field.defaultValue }),
            initialLoginValues
        );

        setFieldValues(initialValues);
        setFieldErrors({});
    }, [dataDestination]);

    useEffect(() => {
        if (!dataDestination || !isEditMode) {
            return;
        }

        const message = isLoginSucceeded ? LOGIN_SUCCESS_MESSAGE : LOGIN_FAILURE_MESSAGE;

        setWarningMessage(message);
    }, [dataDestination]);

    const onFieldChanged = (field, value) => {
        setFieldValues({ ...fieldValues, [field]: value });
        setFieldErrors({ ...fieldErrors, [field]: null });
    };

    const getFieldProps = ({ name, mandatory: _, ...fieldProps }) => ({
        ...fieldProps,
        key: name,
        className: classNames(css.field, { [css.schemaField]: schemaField?.name === name }),
        value: fieldValues[name],
        error: fieldErrors[name],
        onChange: value => onFieldChanged(name, value),
    });

    const isValidPEMCertificate = value => {
        // Validation needs to applied only for Snowflake destinations and when the value is not empty
        if (!adNetworkName.includes('snowflake') || [SECRET_PLACEHOLDER, ''].includes(value)) return true;
        const pemRegex = new RegExp(`${BEGIN_PRIVATE_KEY}[\\s\\S]*${END_PRIVATE_KEY}`);
        return typeof value === 'string' && pemRegex.test(value) && value.includes('\n');
    };

    const validateFields = async () => {
        const validationSchema = Yup.object().shape({
            ...(usernameNeeded ? { [AuthFields.USER]: Yup.string().required(MANDATORY_FIELD) } : {}),
            // There must be either a password or a token
            [AuthFields.PASSWORD]:
                passwordNeeded &&
                (!fieldValues[AuthFields.TOKEN] || fieldValues[AuthFields.TOKEN] === SECRET_PLACEHOLDER)
                    ? Yup.string().required(MANDATORY_FIELD)
                    : Yup.string().notRequired(),
            ...(tokenNeeded
                ? {
                      [AuthFields.TOKEN]: Yup.string().test('is-valid-pem', INVALID_PEM_MESSAGE, isValidPEMCertificate),
                  }
                : {}),
            ...fields.reduce(
                (total, { name, type, mandatory }) => ({
                    ...total,
                    [name]: Yup.lazy(() => {
                        let schema = Yup.string().nullable();

                        if (type === FIELD_TYPES.NUMBER) {
                            schema = Yup.number();
                        } else if (type === FIELD_TYPES.FILE) {
                            schema = Yup.object().nullable();
                        }

                        // for dropdowns empty string means there is a value
                        const emptyValue =
                            type === FIELD_TYPES.DROPDOWN ? fieldValues[name] === null : !fieldValues[name];
                        if (name === OUTPUT_KEY_PARTTEN_FIELD_NAME && !emptyValue) {
                            const userLevelSchema = USER_LEVEL_SOURCE_TYPES.includes(sourceType);
                            return Yup.string().matches(
                                userLevelSchema
                                    ? /^.*{timestamp}.*{extension}$/
                                    : /^.*({date}|{job_timestamp}|{job_date}).*{extension}$/,
                                getRegexError(
                                    userLevelSchema
                                        ? `{timestamp}{extension}`
                                        : '({date}|{job_timestamp}|{job_date}){extension}'
                                )
                            );
                        }

                        return mandatory && emptyValue ? schema.required(MANDATORY_FIELD) : schema;
                    }),
                }),
                {}
            ),
        });

        try {
            const response = await validationSchema.validate(fieldValues, { abortEarly: false });
            return !!response;
        } catch (err) {
            if (err) setFieldErrors(err.inner.reduce((total, e) => ({ ...total, [e.path]: e.message }), {}));
            return false;
        }
    };

    const saveDestination = async () => {
        const { username, password, token, token2, ...other } = fieldValues;
        // Don't pass passwords that haven't been filled in by the user
        const extraFields = Object.fromEntries(
            Object.entries(other).filter(([_, value]) => value !== SECRET_PLACEHOLDER)
        );
        const uanId = await saveDataDestination(
            DataSourcesAPI,
            schemaField,
            fieldValues[schemaField.name] || schemaField.value,
            customFields,
            extraFields,
            {
                adn_id: adnetwork?.id || uan?.adn_id,
                uan_id: uan?.uan_id,
                username,
                password: password === SECRET_PLACEHOLDER ? '' : password,
                // We want to remove all whitespaces of the token to avoid serialization issues
                token: token === SECRET_PLACEHOLDER ? '' : token.trim(),
                token2: token2 === SECRET_PLACEHOLDER ? '' : token2,
            }
        );

        return uanId;
    };

    const onSave = async () => {
        setIsSaving(true);
        setWarningMessage({});

        const valid = await validateFields();
        if (!valid) {
            setIsSaving(false);
            return;
        }

        const id = await saveDestination();
        if (!id) {
            setWarningMessage(SAVING_FAILURE_MASSAGE);
            setIsSaving(false);
            return;
        }

        reportDestinationToMixpanel(
            'Save Completed',
            schemaField,
            fieldValues[schemaField.name],
            customFields,
            isEditMode
        );

        // should not interrupt the closing of the shelf
        try {
            // call login async as it might take some time
            DataSourcesAPI.validateUan(id);
            const updatedState = await DataSourcesAPI.getEtlUanStates();
            dispatch(updateUans(updatedState));
        } catch (e) {}

        setIsSaving(false);
        onClose();
    };

    return (
        <>
            <WarningMessage
                show={!!warningMessage.message}
                duration={1000}
                type={warningMessage.type}
                message={warningMessage.message}
            />
            <div className={css.dataDestinationShelf}>
                {explanation && (
                    <a href={explanation} target="_blank" rel="noreferrer">
                        <Translate
                            id="STATIC.PAGES.DATA_DESTINATIONS.SHELF.INTEGRATION_LINK_TEXT"
                            data={{ adnName: displayName || '' }}
                        />
                    </a>
                )}
                {usernameNeeded && (
                    <TextField
                        label={usernameLabel}
                        value={fieldValues[AuthFields.USER]}
                        error={fieldErrors[AuthFields.USER]}
                        onChange={value => onFieldChanged(AuthFields.USER, value)}
                        containerClass={css.field}
                    />
                )}
                {passwordNeeded && (
                    <TextField
                        password
                        label={passwordLabel}
                        value={fieldValues[AuthFields.PASSWORD]}
                        error={fieldErrors[AuthFields.PASSWORD]}
                        onChange={value => onFieldChanged(AuthFields.PASSWORD, value)}
                        containerClass={css.field}
                    />
                )}
                {tokenNeeded && (
                    <TextField
                        label={tokenLabel}
                        value={fieldValues[AuthFields.TOKEN]}
                        error={fieldErrors[AuthFields.TOKEN]}
                        onChange={value => onFieldChanged(AuthFields.TOKEN, value)}
                        containerClass={css.field}
                        type="textarea"
                        inputClassName={css.textArea}
                    />
                )}
                {token2Needed && (
                    <TextField
                        password
                        label={token2Label}
                        value={fieldValues[AuthFields.TOKEN2]}
                        error={fieldErrors[AuthFields.TOKEN2]}
                        onChange={value => onFieldChanged(AuthFields.TOKEN2, value)}
                        containerClass={css.field}
                    />
                )}
                <PoseGroup animateOnMount>
                    {fieldsWithoutSchema.map(field => (
                        <FieldAnimationItem key={field.name}>
                            <DataDestinationField
                                {...getFieldProps(field)}
                                networkData={networkData}
                                translate={translate}
                            />
                        </FieldAnimationItem>
                    ))}
                </PoseGroup>
                {schemaField && (
                    <>
                        <DataDestinationField {...getFieldProps(schemaField)} />
                        <CustomSchemaFields
                            schema={fieldValues[schemaField.name] || schemaField.value}
                            readonly={isEditMode}
                            sourceType={sourceType}
                            onChange={setCustomFields}
                        />
                    </>
                )}
            </div>
            <WizardFooter
                buttons={[
                    <Button type="flat" onClick={onClose}>
                        <Translate id="STATIC.BUTTONS.CANCEL" />
                    </Button>,
                    <Button submit onClick={onSave} showSpinner={isSaving}>
                        <Translate id="STATIC.BUTTONS.SAVE" />
                    </Button>,
                ]}
            />
        </>
    );
}

DataDestinationsShelf.propTypes = {
    uan: PropTypes.objectOf(PropTypes.any),
    adnetwork: PropTypes.objectOf(PropTypes.any),
    onClose: PropTypes.func,
};

DataDestinationsShelf.defaultProps = {
    uan: null,
    adnetwork: null,
    onClose: () => {},
};
