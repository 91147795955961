import React, { useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { Button, TextField } from '../../components/widgets';
import WarningMessage from '../../teamManagement/components/WizardWarningMessage';
import DataSourcesService from '../service';
import css from './GoogleSheetShelf.css';
import WizardFooter from '../../components/widgets/WizardFooter';
import DataDestinationField from './DataDestinationField';
import CustomSchemaFields from './CustomSchemaFields';
import { updateUans, validateLogin } from '../actions';
import TableCellIcon from '../../components/widgets/TableCellIcon';
import {
    DATA_SOURCES_STATUSES,
    STATUSES_COLOR_MAP,
    parseGSheetsInfo,
    saveDataDestination,
    reportDestinationToMixpanel,
} from '../utils';
import { downloadFileResponse } from '../../global/utils';
import Table from '../../components/organisms/Table';
import TableCellLinkWithImg from '../../components/widgets/TableCellLinkWithImg';
import { GeneralPopup, PopupTypes, OAuthLoginField } from '../../components/partials';
import SpinnerIcon from '../../resources/svg/singular_loader.svg';
import TableCellLink from '../../components/widgets/TableCellLink';

const DataSourcesAPI = new DataSourcesService();
const GSHEETS_ARRAY_NAME = 'gsheets';
const BASE_FILE_NAME_OPTION = 'etl_dest_config.base_file_name';

export default function GoogleSheetShelf({
    uan,
    adnetwork,
    isEditMode,
    onClose,
    schemaField,
    networkData,
    dataDestination,
    adminMode,
    translate,
}) {
    const dispatch = useDispatch();

    const authToken = useRef('');

    const [isValidated, setIsValidated] = useState(!!networkData.uan_id);
    const [warningMessage, setWarningMessage] = useState({});

    const [username, setUsername] = useState(uan?.user);
    const [customFields, setCustomFields] = useState({});
    const [schemaFieldValue, setSchemaFieldValue] = useState(schemaField.value);

    const [newSheetName, setNewSheetName] = useState('');
    const [shouldCreateSheet, setShouldCreateSheet] = useState(false);

    const [createdCheckMark, setCreatedCheckMark] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showReplaceSheetPopup, setShowReplaceSheetPopup] = useState(false);

    const sheets = dataDestination?.fields?.find(({ name }) => name === GSHEETS_ARRAY_NAME)?.value;
    const [tableSheets, setTableSheets] = useState(parseGSheetsInfo(sheets || [], false));

    const downloadSheet = async sheetId => {
        const resp = await DataSourcesAPI.downloadGSheet(uan.uan_id, sheetId);
        downloadFileResponse(resp, false);
    };

    const metadataConfig = [
        {
            name: 'sheetName',
            subValue: 'sheetLink',
            cellComponent: TableCellLinkWithImg,
            displayName: translate('STATIC.PAGES.DATA_DESTINATIONS.SHELF.GSHEET.SHEET_LINK'),
            headerAlign: 'center',
            cellProps: {
                align: 'center',
            },
            tdStyle: {
                minWidth: '175px',
                maxWidth: '175px',
            },
            cellEvents: {
                onClick: ({ subValue }) => {
                    window.open(subValue, '_blank');
                },
            },
        },
        {
            name: 'created',
            displayName: translate('STATIC.PAGES.DATA_DESTINATIONS.SHELF.GSHEET.DATE_CREATED'),
            headerAlign: 'center',
            cellProps: {
                align: 'center',
            },
            tdStyle: {
                minWidth: '175px',
                maxWidth: '175px',
            },
        },
        {
            name: 'status',
            subValue: 'stoppedAt',
            displayName: translate('STATIC.PAGES.DATA_DESTINATIONS.SHELF.GSHEET.STATUS'),
            headerAlign: 'center',
            cellComponent: TableCellIcon,
            cellProps: {
                align: 'center',
                textAlign: 'center',
                color: STATUSES_COLOR_MAP,
                iconElement: {
                    paused: null,
                },
                text: {
                    [DATA_SOURCES_STATUSES.ACTIVE]: 'Live',
                    paused: 'Paused',
                },
            },
            tdStyle: {
                minWidth: '175px',
                maxWidth: '175px',
            },
        },
    ];

    if (adminMode) {
        metadataConfig.push({
            name: 'download',
            displayName: translate('STATIC.PAGES.DATA_DESTINATIONS.SHELF.GSHEET.DOWNLOAD_SHEET'),
            headerAlign: 'center',
            cellComponent: TableCellLink,
            cellValues: ['sheetId'],
            cellEvents: {
                onClick: propsVal => {
                    downloadSheet(propsVal.sheetId);
                },
            },
            cellProps: {
                align: 'center',
                textAlign: 'center',
                label: <a>Download</a>,
            },
            tdStyle: {
                minWidth: '100px',
                maxWidth: '100px',
            },
        });
    }

    const createSheet = async uanId => {
        const resp = await DataSourcesAPI.createNewGsheet(uanId, newSheetName);
        const lastIdx = tableSheets.length - 1;
        if (lastIdx >= 0) {
            tableSheets[lastIdx].stoppedAt = moment().format('YYYY-MM-DD');
            tableSheets[lastIdx].status = 'paused';
        }

        tableSheets.push(...parseGSheetsInfo([resp], true));
        setTableSheets(tableSheets);
        setCreatedCheckMark(true);
        setShouldCreateSheet(false);
        setNewSheetName('');
        setTimeout(() => {
            tableSheets.pop();
            tableSheets.push(...parseGSheetsInfo([resp], false));
            setTableSheets(tableSheets);
            setCreatedCheckMark(false);
        }, 3000);
    };

    const onSave = async (popupAccepted = false) => {
        setWarningMessage({});

        if (!newSheetName || (!isEditMode && (!(authToken.current || uan) || !username))) {
            return;
        }

        if (isEditMode && shouldCreateSheet) {
            if (!popupAccepted) {
                setShowReplaceSheetPopup(true);
                return;
            }
            setIsSaving(true);
            await createSheet(uan.uan_id);
            setIsSaving(false);
            return;
        }

        setIsSaving(true);
        const id = await saveDataDestination(
            DataSourcesAPI,
            schemaField,
            schemaFieldValue,
            customFields,
            { [BASE_FILE_NAME_OPTION]: newSheetName },
            { adn_id: adnetwork?.id || uan?.adn_id, uan_id: uan?.uan_id, username, token: authToken.current }
        );

        if (!id) {
            setWarningMessage({ message: 'Save failed!', type: 'error' });
            setIsSaving(false);
            return;
        }

        dispatch(validateLogin(id));
        await createSheet(id);

        reportDestinationToMixpanel('Save Complete', schemaField, schemaFieldValue, customFields, isEditMode, {
            destination_type: 'gsheet',
        });

        // should not interrupt the closing of the shelf
        try {
            const updatedState = await DataSourcesAPI.getEtlUanStates();
            dispatch(updateUans(updatedState));
        } catch (e) {}

        setIsSaving(false);
        onClose();
    };

    function handleAuthPopupResponse(uanData, errorMsg) {
        if (uanData) {
            setUsername(uanData.login_email);
            authToken.current = uanData.refresh_token;
            setIsValidated(true);
            return true;
        }
        return false;
    }

    const onSheetPopupAccept = async () => {
        setShowReplaceSheetPopup(false);
        setIsSaving(true);
        await onSave(true);
    };

    const sheetNameField = (
        <div className={css.field}>
            <div className={css.fieldText}>
                <span className={css.mandatory}>*</span>
                <span>
                    <Translate id="STATIC.PAGES.DATA_DESTINATIONS.SHELF.GSHEET.NEW_SHEET_TITLE" />
                </span>
            </div>
            <TextField
                containerClass={css.sheetName}
                placeholder={translate('STATIC.PAGES.DATA_DESTINATIONS.SHELF.GSHEET.NEW_SHEET_PLACEHOLDER')}
                value={newSheetName}
                onChange={name => {
                    setNewSheetName(name || '');
                    setShouldCreateSheet(true);
                }}
            />
        </div>
    );

    const onAuthError = error => {
        setWarningMessage({ message: 'Error authenticating', type: 'error' });
    };

    const disableSave = (!uan && !authToken) || !username || !schemaFieldValue || !newSheetName;

    return (
        <>
            <GeneralPopup
                open={showReplaceSheetPopup}
                type={PopupTypes.WARNING_WITH_CANCEL}
                text="STATIC.PAGES.DATA_DESTINATIONS.SHELF.GSHEET.REPLACE_SHEET_TEXT"
                acceptText="STATIC.PAGES.DATA_DESTINATIONS.SHELF.GSHEET.REPLACE_SHEET_ACCEPT"
                onReject={() => setShowReplaceSheetPopup(false)}
                onAccept={onSheetPopupAccept}
            />
            <WarningMessage
                show={!!warningMessage.message}
                duration={1000}
                type={warningMessage.type}
                message={warningMessage.message}
            />
            <div className={css.dataDestinationShelf}>
                <OAuthLoginField
                    networkData={networkData}
                    isValidated={isValidated}
                    authPopupHandler={handleAuthPopupResponse}
                    usernameValue={username}
                    onError={onAuthError}
                />
                {schemaField && (
                    <>
                        <DataDestinationField
                            {...schemaField}
                            key={schemaField.name}
                            className={classNames(css.field, css.schemaField)}
                            value={schemaFieldValue}
                            onChange={setSchemaFieldValue}
                        />
                        <CustomSchemaFields
                            schema={schemaFieldValue || schemaField.value}
                            readonly={isEditMode}
                            sourceType={dataDestination?.source_type}
                            onChange={setCustomFields}
                        />
                    </>
                )}

                {tableSheets.length === 0 && sheetNameField}
                {isValidated && tableSheets.length > 0 && (
                    <div className={css.sheetsTable}>
                        <Table
                            tableClass={css.tableContainer}
                            thClass={css.thead}
                            tbodyClass={css.tbody}
                            data={tableSheets}
                            metadata={metadataConfig}
                            zebraTable
                        />
                        <div className={css.addSheetDiv}>
                            {!shouldCreateSheet ? (
                                <Button
                                    submit
                                    onClick={() => setShouldCreateSheet(true)}
                                    type="primary"
                                    className={css.tableButton}
                                >
                                    <Translate id="STATIC.PAGES.DATA_DESTINATIONS.SHELF.GSHEET.ADD_SHEET" />
                                </Button>
                            ) : isSaving ? (
                                <SpinnerIcon className={css.spinnerIcon} />
                            ) : (
                                sheetNameField
                            )}
                        </div>
                    </div>
                )}
            </div>
            <WizardFooter
                buttons={[
                    <Button type="flat" onClick={onClose}>
                        <Translate id="STATIC.BUTTONS.CANCEL" />
                    </Button>,
                    <Button
                        submit
                        onClick={() => onSave()}
                        showSpinner={!createdCheckMark && isSaving}
                        disabledDark={disableSave}
                        showV={createdCheckMark}
                    >
                        <Translate id="STATIC.BUTTONS.SAVE" />
                    </Button>,
                ]}
            />
        </>
    );
}

GoogleSheetShelf.propTypes = {
    uan: PropTypes.shape({
        uan_id: PropTypes.number,
        user: PropTypes.string,
        adn_id: PropTypes.number,
    }),
    adnetwork: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
    }),
    isEditMode: PropTypes.bool,
    schemaField: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
    }),
    networkData: PropTypes.shape({
        auth_type: PropTypes.string,
        uan_id: PropTypes.number,
    }),
    dataDestination: PropTypes.shape({
        source_type: PropTypes.string,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                value: PropTypes.string,
            })
        ),
    }),
    translate: PropTypes.func,
    adminMode: PropTypes.bool,
    onClose: PropTypes.func,
};

GoogleSheetShelf.defaultProps = {
    uan: null,
    adnetwork: null,
    isEditMode: false,
    onClose: () => {},
    schemaField: {},
    networkData: {},
    dataDestination: {},
    adminMode: false,
    translate: () => '',
};
