import React from 'react';
import PropTypes from 'prop-types';
import Button from '../atoms/Button';
import css from './EventsItemFooter.css';
import PermissionsWrapper from '../../containers/PermissionsWrapper';

class EventsItemFooter extends React.PureComponent {
    render() {
        const {
            lastModified,
            modifiedBy,
            created,
            createdBy,
            onCancelClicked,
            onSaveClicked,
            saving,
            saveDisabled,
            readOnly,
        } = this.props;

        return (
            <div className={css.container}>
                <div>
                    {lastModified ? (
                        <div className={css.lastModified}>
                            Last Modified {lastModified} {modifiedBy ? `by ${modifiedBy}` : ''}
                        </div>
                    ) : null}
                    {created ? (
                        <div className={css.created}>
                            Created {created} {createdBy ? `by ${createdBy}` : ''}
                        </div>
                    ) : null}
                </div>
                <div className={css.buttonsArea}>
                    <Button
                        type={readOnly ? 'primary' : 'flat'}
                        text="STATIC.BUTTONS.CANCEL"
                        containerClass={css.cancelButton}
                        onClick={onCancelClicked}
                        disabled={saving}
                    />
                    <PermissionsWrapper write>
                        <Button
                            type="primary"
                            level="level1"
                            text="STATIC.BUTTONS.SAVE"
                            className={css.buttonMarginLeft}
                            onClick={onSaveClicked}
                            disabled={saveDisabled}
                            showSpinner={saving}
                        />
                    </PermissionsWrapper>
                </div>
            </div>
        );
    }
}

EventsItemFooter.propTypes = {
    lastModified: PropTypes.string,
    modifiedBy: PropTypes.string,
    created: PropTypes.string,
    createdBy: PropTypes.string,
    onCancelClicked: PropTypes.func,
    onSaveClicked: PropTypes.func,
    saving: PropTypes.bool,
    saveDisabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

EventsItemFooter.defaultProps = {
    lastModified: '',
    modifiedBy: '',
    created: '',
    createdBy: '',
    onCancelClicked: () => {},
    onSaveClicked: () => {},
    saving: false,
    saveDisabled: false,
    readOnly: false,
};

export default EventsItemFooter;
