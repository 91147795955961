import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from '../../components/ConversionModelHeader.css';
import { Label, Toggle } from '../../../components/widgets';
import ContextMenu from '../../../components/widgets/ContextMenu';
import Tooltip from '../../../components/widgets/Tooltip';
import { ModelsApiContext } from '../../hooks';
import { getModelKindName } from '../../utils';
import { SUPPORTED_MODEL_KINDS } from '../../components/skan4/modelTypes/Factories/ModelTypeFactory';
import OverflowingText from '../../../components/foundations/OverflowingText';
import { downloadFileResponse } from '../../../global/utils';
import toaster from '../../../utils/toaster';
import SKAdnetworkAPI from '../../service';
import SkanPlusWhiteIcon from '../../../resources/svg/skan_plus/skan-small-white.svg';
import SkanPlusIcon from '../../../resources/svg/skan_plus/skan-small.svg';
import { conversionModelTypeShape, optimizedModelsShape } from '../../components/skan4/types';
import { OPTIMIZED_MODELS_EVENT_PREFIX, trackMixpanelEvent } from '../../../utils/general';

const api = new SKAdnetworkAPI();

function ConversionModelsGroupHeader({
    name,
    groupId,
    model,
    editable,
    onToggleClick,
    onArchiveClick,
    relevantOptimizedModels,
    isSkan4,
    translate,
}) {
    const { onModelGroupEditClick, handleUpgradeToSkan4Click, isAdminMode } = useContext(ModelsApiContext);

    const [optimizedModelImprovementPercentage, setOptimizedModelImprovementPercentage] = useState(0);
    const [isOptimizedModelButtonHovered, setIsOptimizedModelButtonHovered] = useState(false);

    const { enabled, typeDisplayName, modelType, revenueType, eventsType } = model;
    const isModelEditable = editable || isAdminMode;
    const isMenuEnabled = SUPPORTED_MODEL_KINDS.includes(getModelKindName(modelType, revenueType, eventsType));

    useEffect(() => {
        if (relevantOptimizedModels.length) {
            setOptimizedModelImprovementPercentage(
                Math.max(
                    ...relevantOptimizedModels.map(({ optimized_model_improvement: modelImprovement }) =>
                        Math.floor(modelImprovement * 100)
                    )
                )
            );
        } else {
            setOptimizedModelImprovementPercentage(0);
        }
    }, [relevantOptimizedModels]);

    const onOptimizedModelAvailableClicked = () => {
        trackMixpanelEvent(OPTIMIZED_MODELS_EVENT_PREFIX, 'Optimized Model Available Clicked', {});
        onModelGroupEditClick(groupId, true);
    };

    const onPreviewClick = () => {
        api.previewGroupModel(groupId)
            .then(res => {
                downloadFileResponse(res, true);
            })
            .catch(() => {
                toaster.error(translate('STATIC.GENERAL_MESSAGES.UNEXPECTED_ERROR'));
            });
    };

    const menuItems = [
        {
            name: 'STATIC.PAGES.SKADNETWORK.EDIT_BUTTON',
            disabled: !isModelEditable,
            tooltip: isModelEditable ? null : 'STATIC.PAGES.SKADNETWORK.CUSTOM_MODEL_EDIT_DISABLED_TOOLTIP',
            onClick: () => {
                onModelGroupEditClick(groupId);
            },
        },
        {
            name: 'STATIC.PAGES.SKADNETWORK.PREVIEW_MODEL',
            onClick: onPreviewClick,
        },
        {
            name: 'STATIC.PAGES.SKADNETWORK.ARCHIVE_BUTTON',
            disabled: !isModelEditable || enabled,
            tooltip: isModelEditable ? null : 'STATIC.PAGES.SKADNETWORK.ENABLED_MODEL_ARCHIVE_DISABLED_TOOLTIP',
            onClick: () => {
                onArchiveClick(groupId);
            },
        },
    ];

    if (!isSkan4) {
        menuItems.push({
            name: 'STATIC.PAGES.SKADNETWORK.SKAN_4_UPGRADE',
            onClick: () => handleUpgradeToSkan4Click(groupId),
            isNew: true,
        });
    }

    const actionMenuOnSelect = (item, propsVal) => {
        item.onClick(propsVal);
    };

    return (
        <>
            <div className={css.container}>
                <Tooltip title={name}>
                    <OverflowingText>
                        <Label className={css.modelName} text={name} type="subtitle" />
                    </OverflowingText>
                </Tooltip>
                <div
                    className={css.rightArea}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    {optimizedModelImprovementPercentage > 0 && (
                        <div
                            className={classNames(css.optimizedModelButton, {
                                [css.optimizedModelButtonHover]: isOptimizedModelButtonHovered,
                            })}
                            onClick={onOptimizedModelAvailableClicked}
                            onMouseEnter={() => setIsOptimizedModelButtonHovered(true)}
                            onMouseLeave={() => setIsOptimizedModelButtonHovered(false)}
                        >
                            <Tooltip
                                interactive
                                titleTranslationKey="STATIC.PAGES.SKADNETWORK.OPTIMIZED_MODEL_AVAILABLE_TOOLTIP"
                                titleTranslationData={{ optimizedModelImprovementPercentage }}
                            >
                                <div
                                    className={classNames(css.optimizedModelButtonLabel, {
                                        [css.optimizedModelButtonLabelHover]: isOptimizedModelButtonHovered,
                                    })}
                                >
                                    {isOptimizedModelButtonHovered ? <SkanPlusWhiteIcon /> : <SkanPlusIcon />}
                                    <span className={css.optimizedModelButtonLabelSpan}>
                                        <Translate id="STATIC.PAGES.SKADNETWORK.OPTIMIZED_MODEL_AVAILABLE" />
                                    </span>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    <div className={css.modelHeaderFieldContainer}>
                        <div className={css.modelHeaderField}>
                            <Translate id="STATIC.PAGES.SKADNETWORK.MODEL_TYPE" />
                            <div className={css.modelHeaderFieldValue}>{typeDisplayName}</div>
                        </div>
                    </div>
                    <Toggle className={css.modelToggle} checked={enabled} onToggle={() => onToggleClick(groupId)} />
                    <div className={css.separator} />
                    <div className={css.actionMenuContainer}>
                        <Tooltip
                            titleTranslationKey="STATIC.PAGES.SKADNETWORK.SKAN_4_BETA_TOOLTIP"
                            disabled={isMenuEnabled}
                        >
                            <ContextMenu
                                className={css.actionMenuButton}
                                items={menuItems}
                                onSelect={actionMenuOnSelect}
                                menuDisabled={!isMenuEnabled}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>
    );
}

ConversionModelsGroupHeader.propTypes = {
    groupId: PropTypes.number.isRequired,
    model: conversionModelTypeShape.isRequired,
    name: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    onToggleClick: PropTypes.func,
    onArchiveClick: PropTypes.func,
    relevantOptimizedModels: PropTypes.arrayOf(optimizedModelsShape),
    isSkan4: PropTypes.bool,
    translate: PropTypes.func,
};

ConversionModelsGroupHeader.defaultProps = {
    editable: true,
    onToggleClick: () => {},
    onArchiveClick: () => {},
    relevantOptimizedModels: [],
    isSkan4: false,
    translate: () => {},
};

export default withLocalize(ConversionModelsGroupHeader);
