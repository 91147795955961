import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { PageHeader, PageWrapper } from '../components/partials';
import AttributesTable from './components/AttributesTable';
import css from './styles.css';
import NetworkInstructions from './components/NetworkInstructions';
import { attributeInputMap } from './constants';
import { FieldGroup, Label, Button, Spinner, Toggle } from '../components/widgets';

class WebTrackingLinks extends Component {
    constructor(props) {
        super(props);
        this.partnerDiscover = this._partnerDiscover.bind(this);
        this.generateLink = this._generateLink.bind(this);
        this.handleCopyButtonClicked = this._handleCopyButtonClicked.bind(this);
    }

    _partnerDiscover(partnerID) {
        const { updateGeneralField, partnerDiscover } = this.props;

        if (partnerID) {
            updateGeneralField('partnerId', null, partnerID);
            partnerDiscover(partnerID);
        }
    }

    _generateLink() {
        const { attributes, generateLink, url, landingPage, target, partnerId, reEnabled } = this.props;
        const fullURL = landingPage.length > 0 ? `${url}${url[url.length - 1] === '/' ? '' : '/'}${landingPage}` : url;
        generateLink(fullURL, attributes, target.id, partnerId, landingPage, reEnabled);
    }

    _handleCopyButtonClicked() {
        const copyButtonElement = this.copyButton;
        copyButtonElement.innerText = 'Copied!';
        const copyText = this.linkInput;
        copyText.select();
        document.execCommand('Copy');
        window.setTimeout(() => {
            copyButtonElement.innerText = 'Copy full link';
            window.getSelection().removeAllRanges();
        }, 3000);
    }

    render() {
        const {
            target,
            partnerId,
            targets,
            urls,
            url,
            landingPage,
            landingPages,
            partners,
            show,
            updateTarget,
            updateGeneralField,
            updateAttribute,
            trackingLink,
            attributes,
            attributesMeta,
            toggleInstructionsShelf,
            instructions,
            reEnabled,
            onReToggleChanged,
            hasPcConsoleApps,
        } = this.props;

        const partner = partners.find((p) => p.partnerId === partnerId);

        const generalSection = {
            target: {
                id: 'target',
                label: 'Target (App)',
                group: {
                    type: 'autocomplete',
                    data: {
                        selectOptions: {
                            options: targets.map((t) => ({ label: t.app, value: t.id })),
                        },
                        placeholder: 'Select an App',
                        containerStyle: {
                            width: '100%',
                        },
                        defaultValue: target,
                    },
                },
                onFieldClick: (e) => {
                    const target = targets.find((t) => t.id === e.value);
                    updateTarget(target);
                },
            },
            url: {
                id: 'url',
                label: 'Domain',
                group: {
                    type: 'dropdown',
                    data: {
                        selected: {
                            name: url || 'default',
                            display_name: url || 'Select a URL',
                        },
                        containerStyle: {
                            width: '100%',
                            display: 'inline-block',
                            marginRight: '10px',
                        },
                    },
                    disabled: !target,
                },
                fields: urls,
                onFieldClick: (e) => {
                    updateGeneralField('url', 'display_name', e);
                },
            },
            landingPage: {
                id: 'landingPage',
                label: 'Landing Page (Optional)',
                group: {
                    type: 'creatable',
                    data: {
                        selectOptions: {
                            options: landingPages.map((field) => ({ label: field, value: field })),
                            isClearable: true,
                        },
                        creatableOptions: {
                            noOptionsMessage: () => 'Start typing to add a landing page',
                            formatCreateLabel: (inputValue) => `Add new landing page: ${inputValue}`,
                        },
                        controlledValue: landingPage === null ? null : { label: landingPage, value: landingPage },
                        disabled: url === null || url.length === 0,
                        placeholder: 'Type a landing page path',
                        containerStyle: {
                            width: '100%',
                            display: 'inline-block',
                        },
                    },
                },
                onFieldClick: (e) => {
                    const val = e || '';
                    updateGeneralField('landingPage', null, val);
                },
            },
            partner: {
                id: 'partner',
                label: 'Partner',
                group: {
                    type: 'autocomplete',
                    data: {
                        selectOptions: {
                            options: partners.map((field) => ({ label: field.display_name, value: field.partnerId })),
                        },
                        placeholder: 'Select a Partner',
                        containerStyle: {
                            width: '100%',
                        },
                        defaultValue: '',
                    },
                },
                onFieldClick: (e) => {
                    const val = e ? e.value : null;
                    this._partnerDiscover(val);
                },
            },
        };

        const pendingMessage = '';
        const attributeInputs = attributesMeta.map((attribute) => {
            const input = {
                id: attribute.name,
                label: attribute.display_name,
                hidden: attribute.hidden === true,
                group: {
                    type: attributeInputMap[attribute.type],
                    value: attributes[attribute.name] || pendingMessage,
                    disabled: show.fetchingPartner,
                    placeholder: `Enter a ${attribute.display_name}`,
                },
                onFieldClick: (e) => {
                    updateAttribute(attribute.name, e);
                },
            };

            if (input.group.type === 'autocomplete') {
                input.group.data = {
                    selectOptions: {
                        options: attribute.options.map((field) => ({ label: field.display_name, value: field.code })),
                    },
                    placeholder: `Select a ${attribute.display_name}`,
                    containerStyle: {
                        width: '100%',
                    },
                    defaultValue: null,
                };

                input.onFieldClick = (e) => {
                    const val = e ? e.value : null;
                    updateAttribute(attribute.name, val);
                };
            }

            return input;
        });

        return (
            <PageWrapper>
                <PageHeader
                    text="STATIC.PAGE_HEADERS.WEB_TRACKING_LINKS"
                    leftComponents={[]}
                    rightComponent={[]}
                    subText={{
                        faqLink: 'https://support.singular.net/hc/en-us/articles/360041317471-Creating-Website-Links',
                    }}
                />
                {show.fetchingMeta ? (
                    <Spinner show={show.fetchingMeta} style={{ paddingTop: 50 }} />
                ) : (
                    <div>
                        <Label text="Configuration" type="h2" />
                        <div>
                            <div className={css.fieldGroupRow}>
                                <FieldGroup labelPosition="top" {...generalSection.target} />
                            </div>
                            <div className={css.fieldGroupRow}>
                                <div>
                                    <div style={{ width: '49%', float: 'left' }}>
                                        <FieldGroup labelPosition="top" {...generalSection.url} />
                                    </div>
                                    <div style={{ width: '49%', float: 'right' }}>
                                        <FieldGroup labelPosition="top" {...generalSection.landingPage} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {url && landingPage && (
                                    <div className={css.fullLinkWrapper}>
                                        Full Link: {`${url}${url[url.length - 1] === '/' ? '' : '/'}${landingPage}`}
                                    </div>
                                )}
                            </div>
                            <div className={css.fieldGroupRow}>
                                <FieldGroup labelPosition="top" {...generalSection.partner} />
                            </div>
                        </div>
                        {partnerId && (
                            <div>
                                {instructions && (
                                    <div className={css.openInstructionsWrapper}>
                                        <span
                                            className={css.openInstructions}
                                            onClick={() => {
                                                toggleInstructionsShelf();
                                            }}
                                        >
                                            {`How to configure links with ${partner.display_name}`}
                                        </span>
                                    </div>
                                )}
                                <NetworkInstructions
                                    instructions={instructions}
                                    open={show.instructionsShelf}
                                    onClose={() => {
                                        toggleInstructionsShelf();
                                    }}
                                    networkName={partner.display_name}
                                />
                            </div>
                        )}
                        {hasPcConsoleApps && (
                            <Toggle
                                label={<Translate id="STATIC.PAGES.WEB_TRACKING_LINKS.RE_ENABLED" />}
                                checked={reEnabled}
                                onToggle={onReToggleChanged}
                                styleOverride={{ labelStyle: { color: '#515864', fontWeight: 300 } }}
                                className={css.toggleLinks}
                            />
                        )}
                        <div>
                            {attributeInputs.filter((item) => !item.hidden).length > 0 && (
                                <span>
                                    <div className={css.separator} />
                                    <Label text="Attributes" type="h2" />
                                    <div>
                                        {attributeInputs
                                            .filter((item) => !item.hidden)
                                            .map((item) => (
                                                <div key={item.id} className={css.fieldGroupRow}>
                                                    <FieldGroup
                                                        labelPosition="top"
                                                        {...item}
                                                        key={`${item.id}_${partnerId}_${item.label}`}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </span>
                            )}
                        </div>
                        <span>
                            <div className={css.generateLinkContainer}>
                                <div className={css.generateLinkWrapper}>
                                    <Button
                                        onClick={this.generateLink}
                                        disabled={!target || !partnerId || !url}
                                        containerClass={css.generateLinkButton}
                                    >
                                        <Translate id="STATIC.PAGES.GENERATE_TRACKING_LINK.GENERATE_LINK" />
                                    </Button>
                                </div>
                            </div>
                        </span>
                        {show.trackingLink && (
                            <div>
                                <div className={css.separator} />
                                <div className={css.copyButtonWrapper}>
                                    <span
                                        className={css.copyButton}
                                        onClick={this.handleCopyButtonClicked}
                                        ref={(el) => {
                                            this.copyButton = el;
                                        }}
                                    >
                                        Copy full link
                                    </span>
                                </div>
                                <div className={css.linkBox}>
                                    <textarea
                                        readOnly
                                        className={css.link}
                                        value={trackingLink || ''}
                                        ref={(el) => {
                                            this.linkInput = el;
                                        }}
                                    />
                                </div>
                                <div className={css.separator} />
                                <Label text="Parameters" type="h2" />
                                <div className={css.attributesTableWrapper}>
                                    <AttributesTable trackingLink={trackingLink} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </PageWrapper>
        );
    }
}

WebTrackingLinks.propTypes = {
    updateTarget: PropTypes.func,
    updateGeneralField: PropTypes.func,
    updateAttribute: PropTypes.func,
    partnerDiscover: PropTypes.func,
    generateLink: PropTypes.func,
    partners: PropTypes.arrayOf(PropTypes.object),
    targets: PropTypes.arrayOf(PropTypes.object),
    trackingLink: PropTypes.string,
    url: PropTypes.string,
    landingPage: PropTypes.string,
    landingPages: PropTypes.arrayOf(PropTypes.string),
    partnerId: PropTypes.number,
    target: PropTypes.objectOf(PropTypes.any),
    attributesMeta: PropTypes.arrayOf(PropTypes.object),
    attributes: PropTypes.objectOf(PropTypes.string),
    show: PropTypes.objectOf(PropTypes.any),
    toggleInstructionsShelf: PropTypes.func,
    showInstructionsShelf: PropTypes.bool,
    instructions: PropTypes.string,
    reEnabled: PropTypes.bool,
    onReToggleChanged: PropTypes.func,
    hasPcConsoleApps: PropTypes.bool,
};

WebTrackingLinks.defaultProps = {
    updateTarget: () => {},
    updateGeneralField: () => {},
    updateAttribute: () => {},
    generateLink: () => {},
    partnerDiscover: () => {},
    partners: [],
    targets: [],
    trackingLink: null,
    url: null,
    landingPage: null,
    landingPages: [],
    partnerId: null,
    target: null,
    attributesMeta: [],
    attributes: {},
    show: {},
    toggleInstructionsShelf: () => {},
    showInstructionsShelf: false,
    instructions: '',
    reEnabled: false,
    onReToggleChanged: () => {},
    hasPcConsoleApps: false,
};

export default WebTrackingLinks;
