import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';
import TableRowActions from './TableRowActions';
import Label from '../foundations/Label';

const TableCellDropdown = ({
    valuesList,
    getValuesList,
    selected,
    getSelected,
    onValueSelection,
    tdStyle,
    className,
    id,
    disabled,
    dropdownContainerStyle,
    selectedContainerStyle,
    isEditable,
    showRowActions,
    getIsShowAdd,
    getIsShowDelete,
    getIsAddDisabled,
    getIsDeleteDisabled,
    onDelete,
    onAdd,
    addDisabledTooltip,
    rowIndex,
    getIsRowReadOnly,
    error,
    warning,
    ErrorIcon,
    WarningIcon,
    popperModifiers,
    wrapperStyle,
    containerClass,
    onChange,
    dropDownBodyTestId,
    value,
    bodyClassName,
}) => {
    const selectedItem = selected || getSelected?.(value, rowIndex) || value;
    return (
        <td className={className} style={tdStyle}>
            {getIsRowReadOnly(rowIndex) ? (
                <Label text={selectedItem?.display_name} style={selectedContainerStyle} />
            ) : (
                <Dropdown
                    items={valuesList || getValuesList(rowIndex)}
                    selected={selectedItem}
                    disabled={disabled}
                    onSelection={val => {
                        onValueSelection
                            ? onValueSelection(val || value, id, rowIndex)
                            : onChange(val || value, id, rowIndex);
                    }}
                    containerStyle={dropdownContainerStyle}
                    selectedContainerStyle={selectedContainerStyle}
                    error={error}
                    ErrorIcon={ErrorIcon}
                    warning={warning}
                    WarningIcon={WarningIcon}
                    popperModifiers={popperModifiers}
                    wrapperStyle={wrapperStyle}
                    containerClass={containerClass}
                    dropDownBodyTestId={dropDownBodyTestId}
                    bodyClassName={bodyClassName}
                />
            )}
            {isEditable && showRowActions && (
                <TableRowActions
                    onAdd={() => onAdd(rowIndex)}
                    onDelete={() => onDelete(rowIndex)}
                    isDeleteDisabled={getIsDeleteDisabled(rowIndex)}
                    isAddDisabled={getIsAddDisabled(rowIndex)}
                    addDisabledTooltip={addDisabledTooltip}
                    isShowAdd={getIsShowAdd(rowIndex)}
                    isShowDelete={getIsShowDelete(rowIndex)}
                />
            )}
        </td>
    );
};

TableCellDropdown.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    valuesList: PropTypes.arrayOf(PropTypes.any),
    getValuesList: PropTypes.func,
    selected: PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        display_name: PropTypes.string,
    }),
    value: PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        display_name: PropTypes.string,
    }),
    getSelected: PropTypes.func,
    onValueSelection: PropTypes.func,
    tdStyle: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    dropdownContainerStyle: PropTypes.objectOf(PropTypes.any),
    selectedContainerStyle: PropTypes.objectOf(PropTypes.any),
    rowIndex: PropTypes.number,
    isEditable: PropTypes.bool,
    showRowActions: PropTypes.bool,
    getIsShowAdd: PropTypes.func,
    getIsShowDelete: PropTypes.func,
    getIsAddDisabled: PropTypes.func,
    getIsDeleteDisabled: PropTypes.func,
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
    addDisabledTooltip: PropTypes.string,
    getIsRowReadOnly: PropTypes.func,
    error: PropTypes.string,
    warning: PropTypes.string,
    ErrorIcon: PropTypes.func,
    WarningIcon: PropTypes.func,
    popperModifiers: PropTypes.shape({
        preventOverflow: PropTypes.shape({
            enabled: PropTypes.bool,
            boundariesElement: PropTypes.string,
        }),
    }),
    wrapperStyle: PropTypes.objectOf(PropTypes.any),
    containerClass: PropTypes.string,
    onChange: PropTypes.func,
    dropDownBodyTestId: PropTypes.string,
    bodyClassName: PropTypes.string,
};

TableCellDropdown.defaultProps = {
    id: '',
    disabled: false,
    valuesList: null,
    getValuesList: () => [],
    selected: undefined,
    getSelected: undefined,
    onValueSelection: undefined,
    tdStyle: {},
    className: '',
    dropdownContainerStyle: null,
    selectedContainerStyle: null,
    rowIndex: undefined,
    isEditable: false,
    showRowActions: false,
    getIsShowAdd: () => true,
    getIsShowDelete: () => true,
    getIsAddDisabled: () => false,
    getIsDeleteDisabled: () => false,
    onDelete: undefined,
    onAdd: undefined,
    addDisabledTooltip: undefined,
    getIsRowReadOnly: () => {},
    error: undefined,
    warning: undefined,
    ErrorIcon: undefined,
    WarningIcon: undefined,
    popperModifiers: undefined,
    wrapperStyle: {},
    containerClass: '',
    onChange: () => {},
    dropDownBodyTestId: '',
    value: undefined,
    bodyClassName: '',
};

export default TableCellDropdown;
