import React from 'react';
import { Translate } from 'react-localize-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { TextField, SwitchField, Button } from '../../components/widgets';
import css from './EditOrg.css';

class EditOrg extends React.PureComponent {
    render() {
        const { fields, edit, editChange, editConfirm } = this.props;
        return (
            <Card className={css.container}>
                <CardContent>
                    <div style={{ textAlign: 'right' }}>
                        <SwitchField
                            label="Active"
                            checked={edit.distribution.active}
                            onChange={(checked) => {
                                editChange('active', checked);
                            }}
                        />
                    </div>
                    {fields.map((field) => {
                        switch (field.ui_type) {
                            case 'text':
                            case 'password':
                            default:
                                return (
                                    <TextField
                                        key={field.name}
                                        label={field.display}
                                        value={edit.distribution.config[field.name]}
                                        password={field.ui_type === 'password'}
                                        onChange={(value) => {
                                            editChange(field.name, value, true);
                                        }}
                                        autoCompleteOff
                                    />
                                );
                        }
                    })}
                </CardContent>
                <CardActions className={css.cardActions}>
                    <Button onClick={editConfirm}>
                        <Translate id="STATIC.BUTTONS.SAVE" />
                    </Button>
                </CardActions>
            </Card>
        );
    }
}

export default EditOrg;
