import React, { useEffect, useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';

import RadioButtonsGroup from 'components/widgets/RadioButtonsGroup';
import { OPTIMIZED_MODELS_EVENT_PREFIX, trackMixpanelEvent } from 'utils/general';
import PropTypes from 'prop-types';
import Table from '../../../components/organisms/Table';
import { getFlexibleRevenueTableData, getFlexibleRevenueTableMetadata } from '../skan4/modelTypes/RevenueTable';
import ShelfGroup from '../../../components/widgets/ShelfGroup';
import { Button } from '../../../components/widgets';
import css from './OptimizedModelsGroup.css';
import cssRevenueForm from '../modelTypeForms/RevenueConversionModelForm.css';
import { conversionModelTypeShape, optimizedModelsShape } from '../skan4/types';

function OptimizedModelsGroup({
    optimizedModels,
    values,
    onSave,
    expandOptimizedModelsGroup,
    isModelNew,
    measurementPeriod,
    isMultiStep,
}) {
    const [selectedModel, setSelectedModel] = useState(optimizedModels[0]);
    const [optimizedModelsOptions, setOptimizedModelsOptions] = useState([]);
    const [previousRevenueType, setPreviousRevenueType] = useState('');
    const [valuesOptimized, setValuesOptimized] = useState({});

    const groupRef = useRef(null);

    let optimizedModelsGroupEnterTime = null;

    useEffect(() => {
        setSelectedModel(optimizedModels[0]);
    }, [optimizedModels, measurementPeriod]);

    useEffect(() => {
        setTimeout(() => {
            if (expandOptimizedModelsGroup && groupRef.current) {
                groupRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }, 500);
    }, [expandOptimizedModelsGroup]);

    useEffect(() => {
        // we use the same values as in the existing model form, just change the model buckets to the optimized model buckets
        setValuesOptimized({ ...values, buckets: selectedModel?.optimized_conversion_model });
    }, [values, selectedModel]);

    const _onSave = () => {
        trackMixpanelEvent(OPTIMIZED_MODELS_EVENT_PREFIX, 'Optimized Model Saved', {
            buckets: selectedModel.optimized_model_num_of_buckets,
            measurementPeriod: valuesOptimized.measurementPeriod,
        });
        onSave({ buckets: valuesOptimized.buckets });
    };

    useEffect(() => {
        if (previousRevenueType !== valuesOptimized.subType) {
            setSelectedModel(optimizedModels[0]);
        }
        setPreviousRevenueType(valuesOptimized.subType);

        setOptimizedModelsOptions(
            optimizedModels
                .filter(optimizedModel => isModelNew || optimizedModel.optimized_model_improvement > 0)
                .map(optimizedModel => {
                    const optimizedModelImprovement = Math.floor(optimizedModel.optimized_model_improvement * 100);
                    const label = isModelNew
                        ? 'STATIC.PAGES.SKADNETWORK.OPTIMIZED_MODEL_DESCRIPTION_NEW_MODEL'
                        : 'STATIC.PAGES.SKADNETWORK.OPTIMIZED_MODEL_DESCRIPTION';
                    return {
                        id: optimizedModel.id,
                        optimized_conversion_model: optimizedModel.optimized_conversion_model,
                        optimizedModelImprovement,
                        label,
                        data: { optimizedModelImprovement, buckets: optimizedModel.optimized_model_num_of_buckets },
                        checked: optimizedModel.id === selectedModel.id,
                    };
                })
        );
    }, [optimizedModels, selectedModel]);

    return (
        <div ref={groupRef}>
            <ShelfGroup
                expanded={expandOptimizedModelsGroup}
                className={css.optimizedModelsShelfGroup}
                labelContainer={css.optimizedModelsShelfGroupLabel}
                contentContainer={css.shelfContentContainer}
                isSkanPlus
                label={
                    isModelNew
                        ? 'STATIC.PAGES.SKADNETWORK.OPTIMIZED_MODEL_AVAILABLE'
                        : 'STATIC.PAGES.SKADNETWORK.OPTIMIZED_MODEL_AVAILABLE_WITH_PERCENTAGE'
                }
                labelData={{
                    optimizedModelImprovement:
                        optimizedModelsOptions.length && optimizedModelsOptions[0].optimizedModelImprovement,
                }}
            >
                <div
                    className={css.optimizedModelsPanel}
                    onMouseEnter={() => {
                        optimizedModelsGroupEnterTime = new Date();
                    }}
                    onMouseLeave={() => {
                        const hoverTime = new Date() - optimizedModelsGroupEnterTime;
                        trackMixpanelEvent(OPTIMIZED_MODELS_EVENT_PREFIX, 'Hover on Section', { hoverTime });
                    }}
                >
                    <div className={css.groupExplanation}>
                        <Translate id="STATIC.PAGES.SKADNETWORK.OPTIMIZED_MODELS_EXPLANATION" />
                    </div>
                    <RadioButtonsGroup radioItems={optimizedModelsOptions} onChange={setSelectedModel} />
                    <div className={cssRevenueForm.revenueTableWrapperOuter}>
                        <div className={cssRevenueForm.revenueTableWrapperInner}>
                            <Table
                                metadata={getFlexibleRevenueTableMetadata(valuesOptimized, () => {})}
                                data={getFlexibleRevenueTableData(valuesOptimized, false, true, {}, isMultiStep)}
                                rowHoverEnabled={false}
                            />
                        </div>
                    </div>
                    <div className={css.optimizedModelsSave}>
                        <Button className={css.optimizeModelsSaveButton} type="skanPlus" onClick={_onSave}>
                            <Translate id="STATIC.PAGES.SKADNETWORK.OPTIMIZED_MODELS_SAVE" />
                        </Button>
                    </div>
                </div>
            </ShelfGroup>
        </div>
    );
}

OptimizedModelsGroup.propTypes = {
    optimizedModels: PropTypes.arrayOf(optimizedModelsShape).isRequired,
    values: conversionModelTypeShape,
    onSave: PropTypes.func,
    expandOptimizedModelsGroup: PropTypes.bool,
    isModelNew: PropTypes.bool,
    measurementPeriod: PropTypes.number,
    isMultiStep: PropTypes.bool,
};

OptimizedModelsGroup.defaultProps = {
    values: {},
    onSave: () => {},
    expandOptimizedModelsGroup: false,
    isModelNew: false,
    measurementPeriod: 1,
    isMultiStep: false,
};

export default OptimizedModelsGroup;
