import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { ModelsApiContext, useScrollTo } from '../../../hooks';
import Dropdown from '../../../../components/widgets/Dropdown';
import Label from '../../../../components/foundations/Label';
import css from '../../ConversionModelShelf.css';

import revenueConversionModelCss from '../../modelTypeForms/RevenueConversionModelForm.css';
import Table from '../../../../components/organisms/Table';
import {
    DEFAULT_BUCKET_SIZE,
    DEFAULT_CUSTOM_REVENUE_BUCKETS,
    DEFAULT_MAX_NUMBER_OF_BUCKETS,
    ModelKinds,
    ModelTypes,
    REVENUE_SUB_TYPES,
    DECIMAL_PLACES,
    MAX_REVENUE_VALUE,
} from '../../../consts';
import {
    getDefaultBuckets,
    getInitialCustomMapping,
    getMaxNumberOfBuckets,
    getModelMeasurementPeriod,
    calculateUsedRevenueSlots,
} from '../../../utils';
import Tooltip from '../../../../components/widgets/Tooltip';
import Toggle from '../../../../components/atoms/Toggle';
import MaxSlotsIndicator from '../../conversionModelForm/MaxSlotsIndicator';
import { isObjectEmpty } from '../../../../utils/general';
import { conversionModelTypeShape, modelErrorsShape } from '../types';
import ValueLabel from '../../../../components/widgets/ValueLabel';
import OptimizedModelsGroup from '../../conversionModelForm/OptimizedModelsGroup';
import { getOptimizedModelsData } from '../optimizedModelsUtils';
import { useScrollingIntoView } from '../../../../utils/customHooks';
import {
    getFlexibleRevenueTableData,
    getFlexibleRevenueTableMetadata,
    getCustomMappingRevenueTableMetadata,
} from './RevenueTable';

function getRevenueOptions(maxNumberOfBuckets) {
    return Array(maxNumberOfBuckets)
        .fill()
        .map((_, i) => i + 1)
        .filter(i => i > 2) // we need at least 3 buckets to determine the revenue of values in the last bucket
        .map(i => ({ name: i.toString(), display_name: `${i}` }))
        .reverse();
}

function RevenueType({ onChange, values, errors, currencySymbol, maxSlots, isMultiStep }) {
    const { optimizedModels, expandOptimizedModelsGroup } = useContext(ModelsApiContext);

    const maxBuckets = getMaxNumberOfBuckets(maxSlots);

    const {
        id: modelId,
        numberOfRevenueBuckets,
        unit,
        isCustomRevenue,
        buckets,
        customMapping,
        modelType,
        revenueType,
    } = values;

    const measurementPeriod = getModelMeasurementPeriod(values);
    const [relevantOptimizedModels, setRelevantOptimizedModels] = useState([]);
    const [isShowOptimizedModel, setIsShowOptimizedModel] = useState(true);

    const nonCustomRevenueCustomMappingRef = useRef();
    const nonCustomRevenueBucketsRef = useRef();

    const customRevenueBucketsRef = useRef(getDefaultBuckets(true, modelType === ModelTypes.MIXED));
    const customRevenueCustomMappingRef = useRef(getInitialCustomMapping(DEFAULT_CUSTOM_REVENUE_BUCKETS.length + 1));

    useEffect(() => {
        if (!optimizedModels?.length || !values) return;

        setRelevantOptimizedModels(
            getOptimizedModelsData(optimizedModels, modelType, revenueType, buckets, measurementPeriod).optimizedModels
        );
    }, [optimizedModels, revenueType, measurementPeriod]);

    const onOptimizedModelSave = ({ buckets: optimizedBuckets }) => {
        const optimizedCustomMapping = getInitialCustomMapping(optimizedBuckets.length + 1);
        const updatedFields = {
            buckets: optimizedBuckets,
            isCustomRevenue: true,
            customMapping: optimizedCustomMapping,
        };

        nonCustomRevenueCustomMappingRef.current = optimizedCustomMapping;

        onChange(updatedFields);
        setIsShowOptimizedModel(false);
    };

    const isCustomMappingConfigure = !isObjectEmpty(customMapping);

    const innerTableRef = useRef();
    useScrollTo(isCustomRevenue, null, buckets, innerTableRef);

    const scrollingRef = useRef();
    useScrollingIntoView([errors.tableGeneralError], scrollingRef);

    const bucketOptions = useMemo(() => getRevenueOptions(maxBuckets), [maxBuckets]);

    useLayoutEffect(() => {
        if (modelType === ModelKinds.MIXED) return;

        onChange({
            usedSlots: calculateUsedRevenueSlots(isCustomRevenue, numberOfRevenueBuckets, buckets),
        });
    }, [isCustomRevenue, numberOfRevenueBuckets, buckets]);

    useLayoutEffect(() => {
        // cache customMapping
        if (isCustomMappingConfigure) {
            if (isCustomRevenue) {
                customRevenueCustomMappingRef.current = customMapping;
            } else {
                nonCustomRevenueCustomMappingRef.current = customMapping;
            }
        }
    }, [customMapping]);

    useLayoutEffect(() => {
        // cache buckets
        if (isCustomRevenue) {
            customRevenueBucketsRef.current = buckets;
        } else {
            nonCustomRevenueBucketsRef.current = buckets;
        }
    }, [buckets]);

    useEffect(() => {
        // numberOfRevenueBuckets will be missing in case of updating an existing model which wasn't revenue
        if (!numberOfRevenueBuckets) {
            onChange({ numberOfRevenueBuckets: DEFAULT_MAX_NUMBER_OF_BUCKETS });
        }
    }, [isCustomRevenue]);

    useEffect(() => {
        const newFormValues = {};

        if (isCustomRevenue) {
            if (customRevenueBucketsRef.current) {
                const bucketsCount = customRevenueBucketsRef.current.length + 1;
                const bucketsOverflow = bucketsCount > maxBuckets;

                if (bucketsOverflow) {
                    console.warn('Revenue buckets overflow - setting default buckets');
                }

                newFormValues.buckets = bucketsOverflow
                    ? DEFAULT_CUSTOM_REVENUE_BUCKETS
                    : customRevenueBucketsRef.current;

                if (isCustomMappingConfigure) {
                    if (bucketsOverflow || !customRevenueCustomMappingRef.current) {
                        newFormValues.customMapping = getInitialCustomMapping(bucketsCount);
                    } else if (customRevenueCustomMappingRef.current) {
                        newFormValues.customMapping = customRevenueCustomMappingRef.current;
                    }
                }
            }
        } else {
            const newUnit = unit || DEFAULT_BUCKET_SIZE;
            newFormValues.unit = newUnit;
            newFormValues.revenueBucketSize = newUnit;

            const currentNumberOfRevenueBuckets = values.buckets.length + 1;
            const newNumberOfRevenueBuckets = Math.min(numberOfRevenueBuckets, maxBuckets);

            newFormValues.numberOfRevenueBuckets = newNumberOfRevenueBuckets;
            newFormValues.buckets = getDefaultBuckets(
                isCustomRevenue,
                modelType === ModelKinds.MIXED,
                newNumberOfRevenueBuckets,
                newUnit
            );

            if (isCustomMappingConfigure) {
                if (
                    nonCustomRevenueCustomMappingRef.current &&
                    currentNumberOfRevenueBuckets === newNumberOfRevenueBuckets
                ) {
                    newFormValues.customMapping = nonCustomRevenueCustomMappingRef.current;
                } else {
                    newFormValues.customMapping = getInitialCustomMapping(newNumberOfRevenueBuckets);
                }
            }
        }

        !isObjectEmpty(newFormValues) && onChange(newFormValues);
    }, [isCustomRevenue, revenueType, numberOfRevenueBuckets, unit]);

    const tableMetadata = isCustomMappingConfigure
        ? getCustomMappingRevenueTableMetadata(values, onChange, errors, bucketOptions, isMultiStep)
        : getFlexibleRevenueTableMetadata(values, onChange, errors, bucketOptions);

    // table without customMapping handles errors with the columns metadata
    const tableDataErrors = isCustomMappingConfigure ? errors : {};
    const tableData = getFlexibleRevenueTableData(values, true, true, tableDataErrors, isMultiStep);

    return (
        <>
            <div className={css.shelfInfoBox}>
                <div className={css.shelfInputBox}>
                    <Label type="fieldLabel" text="STATIC.PAGES.SKADNETWORK.REVENUE_TYPE_LABEL" mandatory />
                    <Dropdown
                        containerClass={css.shelfInput}
                        wrapperClass={css.shelfInput}
                        items={REVENUE_SUB_TYPES}
                        selected={REVENUE_SUB_TYPES.find(({ name }) => revenueType === name)}
                        onSelection={({ name: selectedRevenueType }) => {
                            onChange({ revenueType: selectedRevenueType });
                        }}
                    />
                </div>
            </div>
            <MaxSlotsIndicator label="STATIC.PAGES.SKADNETWORK.MAX_CONVERSION_BUCKETS" slots={maxBuckets}>
                <Tooltip
                    titleTranslationKey="STATIC.PAGES.SKADNETWORK.FLEXIBLE_BUCKETS_TOOLTIP"
                    wrapperClass={revenueConversionModelCss.advancedRevenueToggleWrapper}
                >
                    <Toggle
                        className={revenueConversionModelCss.advancedRevenueToggle}
                        checked={isCustomRevenue}
                        label="STATIC.PAGES.SKADNETWORK.FLEXIBLE_BUCKETS_LABEL"
                        onToggle={() => onChange({ isCustomRevenue: !isCustomRevenue })}
                    />
                </Tooltip>
            </MaxSlotsIndicator>
            {!isCustomRevenue && (
                <div>
                    <div className={css.shelfInputBox}>
                        <Label type="fieldLabel" text="STATIC.PAGES.SKADNETWORK.BUCKETS_LABEL" mandatory />
                        <Dropdown
                            containerClass={css.shelfInput}
                            wrapperClass={css.shelfInput}
                            items={bucketOptions}
                            selected={bucketOptions.find(({ name }) => numberOfRevenueBuckets?.toString() === name)}
                            onSelection={selectedNumberOfRevenueBuckets =>
                                onChange({
                                    numberOfRevenueBuckets: parseInt(selectedNumberOfRevenueBuckets.name, 10),
                                })
                            }
                        />
                    </div>
                    {unit && (
                        <div className={css.shelfInputBox}>
                            <Label
                                type="fieldLabel"
                                text="STATIC.PAGES.SKADNETWORK.UNIT_LABEL"
                                textReplace={{ currency: currencySymbol }}
                                mandatory
                            />
                            <ValueLabel
                                containerClass={css.shelfInput}
                                labelClass={css.labelFormatting}
                                onValueChanged={newUnit => (newUnit ? onChange({ unit: newUnit }) : null)}
                                value={unit}
                                label="STATIC.PAGES.SKADNETWORK.REVENUE_VALUE_LABEL"
                                translationArgs={{ currency: currencySymbol }}
                                inputStyle={{ paddingLeft: currencySymbol === '$' ? '15px' : '20px' }}
                                maxValue={MAX_REVENUE_VALUE}
                                minValue={0}
                                decimalPlaces={DECIMAL_PLACES}
                                isEditable
                                disableAutoScroll
                            />
                        </div>
                    )}
                </div>
            )}
            <div className={revenueConversionModelCss.revenueTableWrapperOuter}>
                <div className={revenueConversionModelCss.revenueTableWrapperInner} ref={innerTableRef}>
                    <Table metadata={tableMetadata} data={tableData} rowHoverEnabled={false} />
                </div>
                {errors.tableGeneralError && (
                    <div className={css.modelError} ref={scrollingRef}>
                        <Translate id={errors.tableGeneralError.errorMsg} />
                    </div>
                )}
            </div>
            {!!relevantOptimizedModels?.length && isShowOptimizedModel && (
                <OptimizedModelsGroup
                    optimizedModels={relevantOptimizedModels}
                    values={values}
                    onSave={onOptimizedModelSave}
                    expandOptimizedModelsGroup={expandOptimizedModelsGroup}
                    isModelNew={!modelId}
                    measurementPeriod={measurementPeriod}
                    isMultiStep={isMultiStep}
                />
            )}
        </>
    );
}

RevenueType.propTypes = {
    onChange: PropTypes.func.isRequired,
    values: conversionModelTypeShape.isRequired,
    errors: modelErrorsShape,
    currencySymbol: PropTypes.string,
    maxSlots: PropTypes.number,
    isMultiStep: PropTypes.bool,
};

RevenueType.defaultProps = {
    errors: {},
    currencySymbol: undefined,
    maxSlots: 6,
    isMultiStep: false,
};

export default RevenueType;
