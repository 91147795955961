import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Button } from '../../components/widgets';
import WhitePlus from '../../resources/svg/plus_white.svg';
import { partnerShape } from './types';
import { isUserAdNetwork } from '../../selectors/user';
import { trackPartnerConfigMixpanelEvent } from './shelf/utils';

export default function AddAppSiteButton({ className, partner, onAddAppSite }) {
    const isPartner = useSelector(state => isUserAdNetwork(state));

    const onAddAppSiteClicked = () => {
        const mixpanelParams = { partner: partner.displayName, button: 'Add App Site' };
        trackPartnerConfigMixpanelEvent('Add Clicked', partner, null, mixpanelParams);

        onAddAppSite(partner.id);
    };

    if (isPartner) {
        return null;
    }

    return (
        <Button className={className} Icon={WhitePlus} onClick={onAddAppSiteClicked}>
            <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.ADD_APP_SITE" />
        </Button>
    );
}

AddAppSiteButton.propTypes = {
    partner: partnerShape.isRequired,
    className: PropTypes.string,
    onAddAppSite: PropTypes.func,
};

AddAppSiteButton.defaultProps = {
    className: '',
    onAddAppSite: () => {},
};
