import React from 'react';
import PropTypes from 'prop-types';
import { localize } from 'react-localize-redux';
import css from './AttributesTable.css';
import tableCss from '../../components/organisms/Table.css';

class AttributesTable extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickToCopy = this.handleClickToCopy.bind(this);
    }

    handleClickToCopy(key) {
        const valueElement = this[`attribute_ref_${key}`];
        const copyButtonElement = this[`copy_button_${key}`];
        copyButtonElement.innerText = 'Copied!';
        const range = document.createRange();
        range.selectNode(valueElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('Copy');
        window.setTimeout(() => {
            copyButtonElement.innerText = 'Click to copy';
            window.getSelection().removeAllRanges();
        }, 3000);
    }

    render() {
        const { trackingLink } = this.props;
        const _url = new URL(trackingLink);

        let urlQueryString = _url.search;
        if (_url.hash) {
            urlQueryString += _url.hash;
        }

        const _params = new URLSearchParams(urlQueryString);
        const attributes = {};
        Array.from(_params.keys()).forEach(key => {
            attributes[key] = _params.get(key);
        });

        return (
            <div className={css.tableWrapper}>
                <table className={tableCss.table}>
                    <thead className={css.tableHead}>
                        <tr className={css.tableHeadRow}>
                            <th>Attribute</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(attributes)
                            .sort()
                            .map(key => (
                                <tr key={key}>
                                    <td>
                                        <div className={css.attributeValue}>{key}</div>
                                    </td>
                                    <td className={css.clickToCopyCell}>
                                        <div
                                            className={css.attributeValue}
                                            ref={el => {
                                                this[`attribute_ref_${key}`] = el;
                                            }}
                                        >
                                            {attributes[key]}
                                        </div>
                                        <div
                                            className={css.clickToCopy}
                                            onClick={() => {
                                                this.handleClickToCopy(key);
                                            }}
                                            ref={el => {
                                                this[`copy_button_${key}`] = el;
                                            }}
                                        >
                                            Click to copy
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

AttributesTable.propTypes = {
    trackingLink: PropTypes.string,
};

AttributesTable.defaultProps = {
    trackingLink: '',
};

export default AttributesTable;
