import { createTheme } from '@mui/material/styles';
import { fonts } from './utils';

const theme = createTheme({
    typography: {
        fontFamily: fonts,
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 600,
            sm: 900,
            md: 1200,
            lg: 1536,
            xl: 1920,
            xxl: 2400,
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '1rem',
                },
            },
            defaultProps: {
                slotProps: {
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    },
                },
            },
        },
    },
});

export default theme;
