import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import { useQuery } from '@apollo/client';
import { useFormikContext } from 'formik-2';
import WizardLayout from './WizardLayout';
import StepButtons from './StepButtons';
import css from './TestDetails.css';
import { AutoCompleteField, Spinner, TextField } from '../../../../components/widgets';
import Datepicker from '../../../../components/widgets/Datepicker';
import Checkbox from '../../../../components/atoms/Checkbox';
import { DEFAULT_END_DATE } from '../consts';
import QuestionMark from '../../../../components/widgets/QuestionMark';
import { GET_APP_TO_SEGMENTS } from '../../../queries';
import useWizard from '../hook/useWizard';
import { getSegmentDropdownExtra } from './wizardUtils';

const translatePrefix = 'STATIC.PAGES.AUDIENCES.AUDIENCE_AB_TESTING.WIZARD.SECTION_COMPONENTS.TEST_DETAILS';
export const WIZARD_DATA_TEST_ID_TEST_DETAILS = 'test-details-wizard';
const EMPTY_CUSTOM_RANGES = Object.freeze({});

const normalizeAppToSegments = (translate, appToSegments) => {
    if (!appToSegments) return [];
    return appToSegments.map(e => {
        return {
            label: `${e.appName} - ${e.platform}`,
            value: e.appsiteId,
            className: css.dropdownRow,
            icon: <img src={e.icon} alt="" className={css.icon} />,
            segments: e.segments.map(segment => ({
                label: segment.name,
                value: segment.id,
                reach: segment.reach,
                extra: getSegmentDropdownExtra(translate, translatePrefix, segment),
            })),
        };
    });
};

const TestDetails = ({ onSaveDraft, pageTitle, onNext, translate }) => {
    const { setFieldValue, values, setValues } = useFormikContext();
    const { shouldShowDraft, isAlreadyEnded, isAlreadyStarted } = useWizard(values);
    const { loading, data: appsiteToSegmentData } = useQuery(GET_APP_TO_SEGMENTS);

    const normalizedAppToSegments = useMemo(
        () => normalizeAppToSegments(translate, appsiteToSegmentData?.appToSegments),
        [appsiteToSegmentData?.appToSegments]
    );

    const minStartDate = useMemo(() => new Date().toISOString(), []);

    const onAppSelect = useCallback(
        async selectedAppsite => {
            if (selectedAppsite?.value === values.appsite?.value) return;
            setValues({
                ...values,
                segment: '',
                appsite: selectedAppsite,
                events: [],
            });
        },
        [setFieldValue, values]
    );

    const onSegmentSelect = useCallback(async selectedSegment => setFieldValue('segment', selectedSegment), [
        setFieldValue,
    ]);

    const handleDatesChanged = useCallback(
        async dates => {
            if (!isAlreadyStarted) {
                if (values.isTestOngoing) {
                    // If ongoing, set both dates to the same value
                    setFieldValue('startDate', dates.start_date);
                    setFieldValue('endDate', dates.start_date);
                } else {
                    // If not ongoing, set start and end dates separately
                    setFieldValue('startDate', dates.start_date);
                    setFieldValue('endDate', dates.end_date);
                }
            } else if (!values.isTestOngoing) {
                // Already started, not ongoing - can only modify end date
                setFieldValue('endDate', dates.start_date || dates.end_date);
            }
        },
        [setFieldValue, isAlreadyStarted, values.isTestOngoing]
    );

    const onToggleOngoing = useCallback(
        async newValue => {
            setValues({
                ...values,
                isTestOngoing: newValue,
                endDate: newValue ? values.startDate : DEFAULT_END_DATE,
            });
        },
        [setFieldValue, values, isAlreadyStarted]
    );

    const relevantAppsite = useMemo(
        () => normalizedAppToSegments?.find(app => app.value === values.appsite?.value)?.segments || [],
        [normalizedAppToSegments, values.appsite]
    );

    if (loading) {
        return <Spinner show expanded />;
    }

    return (
        <WizardLayout
            title={pageTitle}
            isContentScrollable={false}
            onSaveDraft={onSaveDraft}
            shouldShowDraft={shouldShowDraft}
            buttons={<StepButtons onNext={onNext} nextTitle="STATIC.BUTTONS.NEXT" />}
        >
            {loading ? (
                <Spinner show expanded />
            ) : (
                <div className={css.container} data-testid={WIZARD_DATA_TEST_ID_TEST_DETAILS}>
                    <div className={css.subTitle}>
                        <Translate id={`${translatePrefix}.SUBTITLE`} />
                    </div>
                    <TextField
                        containerClass={css.formItem}
                        labelTextClass={css.label}
                        onChange={val => setFieldValue(`testName`, val)}
                        label={`${translatePrefix}.TEST_NAME.DISPLAY_NAME`}
                        placeholder={`${translatePrefix}.TEST_NAME.PLACEHOLDER`}
                        disabled={isAlreadyEnded}
                        value={values.testName}
                        debounce={200}
                    />
                    <TextField
                        labelTextClass={css.label}
                        onChange={val => setFieldValue(`description`, val)}
                        containerClass={css.containerClass}
                        inputConfig={{ rows: 3 }}
                        type="textarea"
                        disabled={isAlreadyEnded}
                        value={values.description}
                        label={`${translatePrefix}.TEST_DESCRIPTION.DISPLAY_NAME`}
                        placeholder={`${translatePrefix}.TEST_DESCRIPTION.PLACEHOLDER`}
                        debounce={200}
                    />
                    <div className={css.formItem}>
                        <div className={css.label}>
                            <Translate id={`${translatePrefix}.APPSITE.DISPLAY_NAME`} />
                        </div>
                        <AutoCompleteField
                            isMulti={false}
                            placeholder={`${translatePrefix}.APPSITE.PLACEHOLDER`}
                            wrapperClass={css.wrapper}
                            options={normalizedAppToSegments}
                            disabled={values.status}
                            value={values.appsite}
                            onChange={onAppSelect}
                        />
                    </div>
                    <div className={css.formItem}>
                        <div className={css.label}>
                            <Translate id={`${translatePrefix}.SEGMENT.DISPLAY_NAME`} />
                        </div>
                        <AutoCompleteField
                            isMulti={false}
                            value={values.segment}
                            wrapperClass={css.wrapper}
                            options={relevantAppsite}
                            controlled
                            disabled={values.status || !values.appsite}
                            placeholder={`${translatePrefix}.SEGMENT.PLACEHOLDER`}
                            onChange={onSegmentSelect}
                        />
                    </div>
                    <div className={css.dateContainer}>
                        <div className={css.dateTitleContainer}>
                            <span className={css.checkmarkLabel}>
                                <Translate id={`${translatePrefix}.EXPERIMENT_PERIOD.DISPLAY_NAME`} />
                            </span>
                            <QuestionMark message={`${translatePrefix}.EXPERIMENT_PERIOD.TOOLTIP`} />
                        </div>
                        <Datepicker
                            minDate={minStartDate}
                            onApply={handleDatesChanged}
                            drops="up"
                            ranges={EMPTY_CUSTOM_RANGES}
                            autoApply
                            singleDatePicker={values.isTestOngoing || (isAlreadyStarted && !values.isTestOngoing)}
                            disabled={isAlreadyEnded || (isAlreadyStarted && values.isTestOngoing)}
                            buttonType="button"
                            startDate={!isAlreadyStarted || values.isTestOngoing ? values.startDate : values.endDate}
                            endDate={values.endDate}
                        />
                        <div className={css.checkboxContainer}>
                            <Checkbox
                                checked={values.isTestOngoing}
                                size="small"
                                disabled={isAlreadyEnded}
                                onChange={onToggleOngoing}
                                labelClass={css.checkmarkLabel}
                                label={<Translate id={`${translatePrefix}.IS_TEST_ONGOING.DISPLAY_NAME`} />}
                                labelTooltip={`${translatePrefix}.IS_TEST_ONGOING.TOOLTIP`}
                            />
                        </div>
                    </div>
                </div>
            )}
        </WizardLayout>
    );
};

TestDetails.propTypes = {
    onSaveDraft: PropTypes.func,
    translate: PropTypes.func,
    onNext: PropTypes.func,
    pageTitle: PropTypes.string,
};

export default withLocalize(TestDetails);
