import React from 'react';
import Button from '../atoms/Button';

class ReportUnenrichedButton extends React.Component {
    render() {
        const { unenriched, ...restProps } = this.props;

        return (
            <Button
                text="STATIC.BUTTONS.UNENRICHED"
                type="secondary"
                forceActive={unenriched}
                style={{ marginRight: '10px' }}
                {...restProps}
            />
        );
    }
}

export default ReportUnenrichedButton;
