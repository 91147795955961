import React from 'react';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import css from '../style.css';
import Button from '../../components/atoms/Button';
import TrialExpiredSrc from '../../resources/png/trial_expired.png';
import SingularLogoIcon from '../../resources/svg/singular_flat_logo_white.svg';

export default function SignupTrialExpired() {
    return (
        <div className={css.signupTrialExpired}>
            <div className={css.signupTrialExpiredInner}>
                <SingularLogoIcon className={css.singularIcon} />
                <div className={css.signupTrialExpiredTitle}>
                    <Translate id="STATIC.PAGES.SIGNUP.TRIAL_EXPIRED_PAGE_TITLE_TEXT" />
                </div>
                <div className={css.signupTrialExpiredSubtitle}>
                    <span className={classNames('showInNonMobile')}>Need more time with Singular? </span>
                    <Translate id="STATIC.PAGES.SIGNUP.TRIAL_EXPIRED_PAGE_BODY_TEXT" />
                </div>
                <div className={css.signupTrialExpiredCTA}>
                    <Button type="primary" level="level1" text="STATIC.PAGES.SIGNUP.TRIAL_EXPIRED_PAGE_CTA_TEXT" />
                </div>
            </div>
            <div className={css.signupTrialExpiredFooter}>
                <img src={TrialExpiredSrc} className={css.signupTrialExpiredImg} />
            </div>
            <div className={classNames(css.signupTrialExpiredCTASectionMobile, 'showInMobile')}>
                <Button
                    type="primary"
                    level="level1"
                    text="STATIC.PAGES.SIGNUP.TRIAL_EXPIRED_PAGE_CTA_TEXT"
                    textClassName={css.signupTrialExpiredCTATextMobile}
                    className={css.signupTrialExpiredCTAMobile}
                />
            </div>
        </div>
    );
}
