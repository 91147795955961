import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Dialog, Label, ShelfHeader, Button, TextField, WarningMessage } from '../widgets';
import css from './ChangePasswordDialog.css';

class ChangePasswordDialog extends React.Component {
    constructor(props) {
        super(props);
        const s = document.createElement('script'); // use global document since Angular's $document is weak
        s.src = 'https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/1.0/zxcvbn.js';
        document.body.appendChild(s);
        this.state = { passwordStrength: '0' };
        this._onSave = this.onSave.bind(this);
        this._newPasswordChanged = this.newPasswordChanged.bind(this);
    }

    onSave() {
        const { onSave, success, onClose } = this.props;
        if (success) {
            onClose();
            return;
        }
        const { oldPassword, newPassword } = this.state;
        onSave(oldPassword, newPassword);
    }

    newPasswordChanged(newPassword) {
        // check password weakness
        const result = zxcvbn(newPassword, []);
        let passwordStrength = result.score;
        if (newPassword && passwordStrength === 0) {
            passwordStrength = '1';
        }
        this.setState({ newPassword, passwordStrength });
    }

    render() {
        const { onClose, saving, success, open, error } = this.props;
        const { passwordStrength } = this.state;
        if (!open && passwordStrength !== '0') {
            this.setState({ newPassword: '', passwordStrength: '0' });
        }
        const passwordStrengthText = {
            '0': '',
            '1': 'Weak',
            '2': 'So-so',
            '3': 'Good',
            '4': 'Great!',
        }[passwordStrength];
        return (
            <Dialog open={open} onEscapePress={onClose} noPadding className={css.dialog}>
                <ShelfHeader
                    text="STATIC.TOPBAR_HEADERS.CHANGE_PASSWORD"
                    onClose={() => {
                        onClose();
                    }}
                />
                <React.Fragment>
                    <WarningMessage show={!!error} message={error} type="error" showIcon={false} />
                    <WarningMessage
                        show={success}
                        message="Password changed successfully"
                        type="success"
                        showIcon={false}
                    />
                    <div className={css.dialogBody}>
                        <Label type="fieldLabel" text="STATIC.PLACEHOLDERS.CURRENT_PASSWORD" />
                        <TextField password onChange={(oldPassword) => this.setState({ oldPassword })} />
                        <Label type="fieldLabel" text="STATIC.PLACEHOLDERS.NEW_PASSWORD" />
                        <TextField password onChange={this._newPasswordChanged} />
                        <div className={css.strengthMeter}>
                            <div className={css.strengthMeterFill} data-strength={passwordStrength} />
                        </div>
                        <span className={css.strengthMeterText} data-strength={passwordStrength}>
                            {passwordStrengthText}
                        </span>
                    </div>
                    <div className={css.dialogFooter}>
                        <Button
                            type="flat"
                            text="STATIC.BUTTONS.CANCEL"
                            onClick={onClose}
                            disabled={saving}
                            style={{ marginRight: 10 }}
                        />
                        <Button
                            text={success ? 'STATIC.BUTTONS.CLOSE' : 'STATIC.BUTTONS.SAVE'}
                            type="primary"
                            onClick={this._onSave}
                            showSpinner={saving}
                            disabled={passwordStrength <= 2}
                        />
                    </div>
                </React.Fragment>
            </Dialog>
        );
    }
}

ChangePasswordDialog.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    saving: PropTypes.bool,
    success: PropTypes.bool,
    open: PropTypes.bool,
    error: PropTypes.string,
    translate: PropTypes.func,
};

ChangePasswordDialog.defaultProps = {
    onSave: () => {},
    onClose: () => {},
    saving: false,
    success: false,
    open: false,
    error: false,
    translate: (s) => s,
};

export default withLocalize(ChangePasswordDialog);
