import PropTypes from 'prop-types';
import React, { Children, useState } from 'react';
import classNames from 'classnames';
import css from './Step.css';
import Label from '../foundations/Label';
import ChevronIcon from '../../resources/icons/chevron.svg';
import QuestionMark from '../widgets/QuestionMark';

export const ExpandedContent = ({ children }) => <>{children}</>;

const Step = ({
    children,
    title,
    titleTooltipText,
    stepNumber,
    className,
    titleClassName,
    isExpandable,
    onExpendContent,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const expandedContent = [];
    const regularContent = [];

    Children.toArray(children).forEach(child => {
        if (child.type === ExpandedContent) {
            if (isExpandable) {
                expandedContent.push(child);
            }
        } else {
            regularContent.push(child);
        }
    });

    const toggleExpandedContent = () => {
        const updatedIsExpended = !isExpanded;
        setIsExpanded(updatedIsExpended);
        try {
            onExpendContent && onExpendContent(updatedIsExpended);
        } catch (e) {}
    };

    return (
        <div className={classNames(css.container, { [css.expandableContainer]: isExpandable }, className)}>
            <div className={css.innerContainer}>
                {stepNumber && <div className={css.number}>{stepNumber}</div>}
                <div className={css.content}>
                    <div className={classNames(css.titleContainer, titleClassName)}>
                        <Label text={title} className={css.titleFont} />
                        {titleTooltipText && <QuestionMark message={titleTooltipText} />}
                    </div>
                    {regularContent}
                    {isExpanded && expandedContent}
                </div>
            </div>
            {isExpandable && expandedContent.length > 0 && (
                <span className={css.expandButton} onClick={toggleExpandedContent}>
                    <span className={css.expandButtonText}>{isExpanded ? 'Less' : 'More'}</span>
                    <ChevronIcon className={!isExpanded ? css.collapsed : ''} />
                </span>
            )}
        </div>
    );
};

ExpandedContent.propTypes = {
    children: PropTypes.node.isRequired,
};

Step.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    title: PropTypes.string.isRequired,
    titleTooltipText: PropTypes.string,
    stepNumber: PropTypes.number,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    isExpandable: PropTypes.bool,
    onExpendContent: PropTypes.func,
};

Step.defaultProps = {
    children: null,
    titleTooltipText: undefined,
    className: undefined,
    titleClassName: undefined,
    isExpandable: false,
    stepNumber: undefined,
    onExpendContent: null,
};

export default Step;
