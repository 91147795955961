import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import css from './PlanCard.css';
import { Button } from '../../components/widgets';
import Label from '../../components/foundations/Label';
import { useAsync } from '../../utils/customHooks';
import { PLAN_CARD_TIERS } from '../../utils/consts';
import withTranslate from '../../containers/widgets/withTranslate';
import ExternalLink from '../../resources/icons/externalLink.svg';
import { TIER_CARD_COLORS } from '../consts';
import { getUserData } from '../../selectors/user';

const defaultFooter = (
    <Button
        type="flat"
        text="STATIC.PAGES.ACCOUNT_PLAN.PRICING_CARDS.FOOTER.DEFAULT"
        Icon={ExternalLink}
        iconLocation="right"
        className={css.pricingFooter}
        iconClass={css.footerIcon}
        onClick={() => window.open('https://www.singular.net/pricing/#plan-comparison', '_blank')}
    />
);

const PlanCard = ({ translate, tier, badgeText, ctaExtraComponent, ctaAction, footer = defaultFooter, ctaEnabled }) => {
    const [response, run] = useAsync();
    const { data: isCtaData, isLoading: isCtaLoading } = response;
    const { success: ctaSuccess } = isCtaData || {};
    const orgTier = useSelector(state => getUserData(state).organization_tier);

    const ctaActionWrapper = () => {
        if (ctaEnabled) {
            run(ctaAction());
        }
    };
    const splitPrice = input => {
        const regex = /^\$?(\d+(\.\d+)?)/;
        const match = input.match(regex);
        const [priceValue, priceText] = match ? [match[0], input.slice(match[0].length).trim()] : [undefined, input];
        return { priceText, priceValue };
    };

    const price = translate(`STATIC.PAGES.ACCOUNT_PLAN.PRICING_CARDS.PRICING.${tier}`);
    const { priceText, priceValue } = splitPrice(price);

    const smallScreen = useMediaQuery('(max-width:1350px)');
    const name = translate(`STATIC.PAGES.ACCOUNT_PLAN.PRICING_CARDS.TITLE.${tier}`);
    const description = translate(`STATIC.PAGES.ACCOUNT_PLAN.PRICING_CARDS.DESCRIPTION.${tier}`);
    const ctaText = translate(`STATIC.PAGES.ACCOUNT_PLAN.PRICING_CARDS.CTA.${tier}`);
    const body = translate(`STATIC.PAGES.ACCOUNT_PLAN.PRICING_CARDS.BODY.${tier}`);
    const color = TIER_CARD_COLORS[tier];
    const isSelected = orgTier === tier;
    return (
        <div
            className={classNames(
                css.planCard,
                { [css.cardSelected]: isSelected, [css.smallScreen]: smallScreen },
                css[`${name.toLowerCase()}`]
            )}
            style={{ '--card-color': color }}
        >
            {isSelected && (
                <div className={classNames(css.selectedBadge, css[`${name.toLowerCase()}`])}>{badgeText}</div>
            )}
            <div className={css.cardNameContainer}>
                <Label className={css.cardName} text={name} />
                <div className={css.cardDesc}>{description}</div>
            </div>
            <div className={css.ctaContainer}>
                <div className={css.priceContainer}>
                    {priceValue && <Label className={css.cardPrice} text={priceValue} />}
                    {priceText && <Label className={css.cardPriceText} text={priceText} />}
                </div>
                {!!ctaExtraComponent && ctaExtraComponent}
                <Button
                    text={ctaText}
                    showV={ctaSuccess}
                    onClick={ctaActionWrapper}
                    type="primary"
                    level="important"
                    className={classNames(css.ctaButton, { [css.ctaButtonDisabled]: ctaSuccess || !ctaEnabled })}
                    showSpinner={isCtaLoading}
                    disabled={ctaSuccess || !ctaEnabled}
                />
            </div>
            <div className={css.lineSeperator} />
            <div className={css.bodyContainer}>{body}</div>
            {footer && <div className={css.footerContainer}>{footer}</div>}
        </div>
    );
};

PlanCard.propTypes = {
    translate: PropTypes.func,
    tier: PropTypes.oneOf(PLAN_CARD_TIERS).isRequired,
    badgeText: PropTypes.string,
    ctaExtraComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    ctaAction: PropTypes.func,
    footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    ctaEnabled: PropTypes.bool,
};

PlanCard.defaultProps = {
    badgeText: 'Your plan',
    translate: () => {},
};
export default withTranslate(PlanCard);
