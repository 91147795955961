import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { withLocalize } from 'react-localize-redux';
import { Spinner, Table as SingularTable } from '../../components/widgets';
import css from './ABTestingTable.css';
import { sortTestChannelByControlGroup, tableColumns } from './tableUtils';
import { GET_AB_TESTING_METADATA } from '../queries';

export const TABLE_DATA_TEST_ID = 'ab-testing-table';

function ABTestingTable({ onEdit, onDelete, translate }) {
    const { loading, data } = useQuery(GET_AB_TESTING_METADATA, {
        fetchPolicy: 'cache-first',
    });

    const normalizeData = useMemo(() => {
        if (loading) return [];
        return (
            data?.abTestingMetadata.map(abTest => ({
                ...abTest,
                testChannels: sortTestChannelByControlGroup(abTest.testChannels),
            })) || []
        );
    }, [loading, data]);

    return (
        <div className={css.sitesTable}>
            {loading ? (
                <Spinner show expanded className={css.loadingContainer} />
            ) : (
                <SingularTable
                    dataTestId={TABLE_DATA_TEST_ID}
                    data={normalizeData}
                    tableClass={css.tableContainer}
                    metadata={tableColumns(normalizeData, onEdit, onDelete, translate)}
                    trClass={css.tableCell}
                    thClass={css.thClass}
                    theadClass={css.tableHeader}
                    zebraTable={false}
                />
            )}
        </div>
    );
}

ABTestingTable.propTypes = {
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    translate: PropTypes.func,
};

ABTestingTable.defaultProps = {};

export default withLocalize(ABTestingTable);
