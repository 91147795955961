import React from 'react';
import css from '../../GetSDKFiles/content/content.css';
import StepsList from '../../../../../../components/organisms/StepsList';
import Step from '../../../../../../components/organisms/Step';
import CodeBlock from '../../../../../../components/organisms/CodeBlock';
import { useSDKCodeSnippetContext } from '../../../../codeSnippets/context/useSDKCodeSnippetContext';
import {
    ANDROID_MANIFEST_CODE_BLOCK_CONFIG,
    STEP_1_CODE_SNIPPET,
    STEP_2_CODE_SNIPPET,
    STEP_3_CODE_SNIPPET_APP_LINKS,
    STEP_3_CODE_SNIPPET_URI_SCHEME,
} from './consts';
import { Label, Alert } from '../../../../../../components/widgets';
import { sendMixpanelEventSdkIntegration } from '../../../../utils';
import { ANDROID_NATIVE, SDK_INTEGRATION_SECTIONS, TARGET_PLATFORM_TO_PARAM } from '../../../../consts';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../../../consts';
import useMixpanelEventOnPageLoad from '../../../../../hooks/useMixpanelEventOnPageLoad';

const { CODEBLOCK_THEME_TOGGLED, COPY_CODE, SHOW_KEYS } = SDK_INTEGRATION_MIXPANEL_EVENTS;
const { ANDROID_MANIFEST_SETTINGS } = SDK_INTEGRATION_SECTIONS;

const AndroidManifestSettings = () => {
    const { codeBlockDarkTheme, toggleCodeBlockTheme } = useSDKCodeSnippetContext();

    useMixpanelEventOnPageLoad(ANDROID_MANIFEST_SETTINGS, ANDROID_NATIVE);

    const handleSendMixpanelEvent = event =>
        sendMixpanelEventSdkIntegration(event, ANDROID_MANIFEST_SETTINGS, TARGET_PLATFORM_TO_PARAM[ANDROID_NATIVE]);

    const handleToggleCodeBlockTheme = toggle => {
        toggleCodeBlockTheme(toggle);
        handleSendMixpanelEvent(CODEBLOCK_THEME_TOGGLED, {
            step: ANDROID_MANIFEST_SETTINGS,
        });
    };

    return (
        <div className={css.container}>
            <StepsList>
                <Step
                    className={css.step}
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.ANDROID_MANIFEST_SETTINGS.STEP_1"
                    titleClassName={css.doubleMargin}
                >
                    <CodeBlock
                        {...ANDROID_MANIFEST_CODE_BLOCK_CONFIG}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        initialCode={STEP_1_CODE_SNIPPET}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
                <Step
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.ANDROID_MANIFEST_SETTINGS.STEP_2"
                    titleClassName={css.doubleMargin}
                >
                    <CodeBlock
                        {...ANDROID_MANIFEST_CODE_BLOCK_CONFIG}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        initialCode={STEP_2_CODE_SNIPPET}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
                <Step title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.ANDROID_MANIFEST_SETTINGS.STEP_3.TITLE">
                    <Label
                        type="h3"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.ANDROID_MANIFEST_SETTINGS.STEP_3.APP_LINKS_SUBTITLE"
                    />
                    <Label
                        type="p"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.ANDROID_MANIFEST_SETTINGS.STEP_3.APP_LINKS_CONTENT"
                    />
                    <Alert
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.ANDROID_MANIFEST_SETTINGS.STEP_3.APP_LINKS_ALERT"
                        textClassName={css.stepAlertText}
                        className={css.stepAlertContainer}
                    />
                    <CodeBlock
                        {...ANDROID_MANIFEST_CODE_BLOCK_CONFIG}
                        initialCode={STEP_3_CODE_SNIPPET_APP_LINKS}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                    <Label
                        type="h3"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.ANDROID_MANIFEST_SETTINGS.STEP_3.URI_SCHEME_SUBTITLE"
                    />
                    <Label
                        type="p"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.ANDROID_MANIFEST_SETTINGS.STEP_3.URI_SCHEME_CONTENT"
                    />
                    <Alert
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.ANDROID_MANIFEST_SETTINGS.STEP_3.URI_SCHEME_ALERT"
                        textClassName={css.stepAlertText}
                        className={css.stepAlertContainer}
                    />
                    <CodeBlock
                        {...ANDROID_MANIFEST_CODE_BLOCK_CONFIG}
                        initialCode={STEP_3_CODE_SNIPPET_URI_SCHEME}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
            </StepsList>
        </div>
    );
};

export default AndroidManifestSettings;
