import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Dialog } from 'components/widgets';
import CustomDimensionsTabs from 'components/widgets/CustomDimensionsTabs';
import CustomDimension from 'customDimensions/containers/CustomDimension';
import { DeleteDialog, PageWrapper, PopupTypes, PageHeader } from 'components/partials';
import Spinner from 'components/widgets/Spinner';
import TextField from 'components/widgets/TextField';
import Button from '../components/atoms/Button';
import WarningMessage from '../components/utilities/WarningMessage';
import css from './styles.css';
import { TOUR_LINK_IDS } from '../utils/consts';
import { trackShowVideoMixpanelEvent } from '../utils/general';
import GeneralPopup from '../components/organisms/GeneralPopup';

function Page(props) {
    const {
        pageLoaded,
        dimensionsList,
        onFilterTextChanged,
        filterText,
        unsavedCustomDimension,
        onAddCustomDimension,
        onDeleteApproved,
        onDeleteRejected,
        showDeleteDialog,
        deleteDialogDimension,
        maxDimensions,
        totalDimensions,
        onTabSwitch,
        tab,
        tabsToShow,
        dimensionRelatedObjects,
        translate,
    } = props;
    const saveDisabled = unsavedCustomDimension || totalDimensions >= maxDimensions;
    const [showCustomVideoDialog, setShowCustomVideoDialog] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_MESSAGE');
    const [acceptDelete, setAcceptDelete] = useState(
        translate('STATIC.PAGES.CUSTOM_DIMENSIONS.ACCEPT_BUTTON_JUST_EVENT')
    );
    useEffect(() => {
        if (
            !dimensionRelatedObjects ||
            (!dimensionRelatedObjects?.bookmarks.length &&
                !dimensionRelatedObjects?.dashboards.length &&
                !dimensionRelatedObjects?.anonymousReports.length &&
                !dimensionRelatedObjects?.goals.length)
        ) {
            setDeleteMessage('STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_MESSAGE');
            setAcceptDelete(translate('STATIC.PAGES.CUSTOM_DIMENSIONS.ACCEPT_BUTTON_JUST_EVENT'));
        } else {
            setAcceptDelete(translate('STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_WITH_RELATED.ACCEPT_BUTTON_WITH_RELATED'));
            const currentDeleteMessage = `${translate(
                'STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_WITH_RELATED.MESSAGE_HEADER'
            )}</br></br>
            ${
                dimensionRelatedObjects.bookmarks.length
                    ? `${translate(
                          'STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_WITH_RELATED.BOOKMARKS'
                      )}: ${dimensionRelatedObjects.bookmarks.map(x => x.name).join(', ')}</br></br>`
                    : ''
            }
            ${
                dimensionRelatedObjects.anonymousReports.length
                    ? `${translate(
                          'STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_WITH_RELATED.ANONYMOUS_REPORTS'
                      )}: ${dimensionRelatedObjects.anonymousReports.map(x => x.name).join(', ')}</br></br>`
                    : ''
            }
            ${
                dimensionRelatedObjects.dashboards.length
                    ? `${translate(
                          'STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_WITH_RELATED.DASHBOARDS'
                      )}: ${dimensionRelatedObjects.dashboards
                          .map(
                              ({ dashboardName, widgetName }) =>
                                  `${translate(
                                      'STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_WITH_RELATED.DASHBOARD'
                                  )} ${dashboardName} - ${translate(
                                      'STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_WITH_RELATED.WIDGET'
                                  )} ${widgetName}`
                          )
                          .join(', ')}</br></br>`
                    : ''
            }
            ${
                dimensionRelatedObjects.goals.length
                    ? `${translate(
                          'STATIC.PAGES.CUSTOM_DIMENSIONS.DELETE_WITH_RELATED.GOALS'
                      )}: ${dimensionRelatedObjects.goals.map(x => x.name).join(', ')}</br></br>`
                    : ''
            }`;
            setDeleteMessage(currentDeleteMessage);
        }
    }, [dimensionRelatedObjects, deleteDialogDimension, translate]);

    const onVideoClick = () => {
        trackShowVideoMixpanelEvent({ watchedFrom: 'Custom Dimensions' });
        setShowCustomVideoDialog(true);
    };

    const onVideoRejected = () => {
        setShowCustomVideoDialog(false);
    };
    return (
        <PageWrapper className={css.pageWrapper}>
            <PageHeader
                text="STATIC.CUSTOM_DIMENSIONS"
                subText={{
                    faqLink: 'https://singular.zendesk.com/hc/en-us/articles/208579346-Creating-Custom-Dimensions',
                    tourId: TOUR_LINK_IDS.customDimensions,
                }}
                onVideoClick={onVideoClick}
            />
            <div className={css.headerRow} style={{ display: pageLoaded ? 'flex' : 'none' }}>
                <div className={css.filterDimensionsWrapper}>
                    <TextField onChange={onFilterTextChanged} value={filterText} placeholder="Search dimension names" />
                </div>
                <Button
                    onClick={() => {
                        if (!saveDisabled) onAddCustomDimension();
                    }}
                    type="primary"
                    level="level1"
                    disabled={saveDisabled}
                >
                    <Translate id="STATIC.BUTTONS.NEW_CUSTOM_DIMENSION" />
                </Button>
            </div>
            <WarningMessage
                show={pageLoaded && maxDimensions <= totalDimensions}
                showIcon={false}
                className={css.warningMessage}
                message={`You may only define up to ${maxDimensions} custom dimensions, you already have ${totalDimensions} configured`}
            />
            {pageLoaded && tabsToShow.length > 1 && (
                <CustomDimensionsTabs tabs={tabsToShow} onSwitch={onTabSwitch} selected={tab} />
            )}
            {pageLoaded ? (
                dimensionsList.map(cd => {
                    return (
                        <div style={{ marginBottom: 15 }} key={cd}>
                            <CustomDimension dimensionId={cd} />
                        </div>
                    );
                })
            ) : (
                <Spinner expanded show />
            )}
            <p />
            <Dialog open={showDeleteDialog} onEscapePress={onDeleteRejected}>
                <DeleteDialog
                    title={deleteMessage}
                    onAccept={onDeleteApproved}
                    onReject={onDeleteRejected}
                    acceptText={acceptDelete}
                    rejectText={translate('STATIC.PAGES.CUSTOM_DIMENSIONS.REJECT_BUTTON_TEXT')}
                    deleteType="custom dimension"
                />
            </Dialog>
            <GeneralPopup
                open={showCustomVideoDialog}
                type={PopupTypes.VIDEO}
                onReject={onVideoRejected}
                videoLink="https://player.vimeo.com/video/639167425?autoplay=1"
            />
        </PageWrapper>
    );
}

Page.propTypes = {
    // Add your props definitions here
    pageLoaded: PropTypes.bool,
    dimensionsList: PropTypes.arrayOf(PropTypes.string),
    onAddCustomDimension: PropTypes.func,
    filterText: PropTypes.string,
    onFilterTextChanged: PropTypes.func,
    unsavedCustomDimension: PropTypes.bool,
    onDeleteApproved: PropTypes.func,
    onDeleteRejected: PropTypes.func,
    onTabSwitch: PropTypes.func,
    showDeleteDialog: PropTypes.bool,
    deleteDialogDimension: PropTypes.string,
    dimensionRelatedObjects: PropTypes.objectOf(
        PropTypes.shape({
            bookmarks: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, inFilter: PropTypes.bool })),
            dashboards: PropTypes.arrayOf(
                PropTypes.shape({ dashboardName: PropTypes.string, widgetName: PropTypes.string })
            ),
            anonymousReports: PropTypes.arrayOf(PropTypes.string),
            goals: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
        })
    ),
    maxDimensions: PropTypes.number,
    totalDimensions: PropTypes.number,
    tabsToShow: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, display_name: PropTypes.string })),
    tab: PropTypes.string,
    translate: PropTypes.func,
};

Page.defaultProps = {
    // Add your props defaults here
    pageLoaded: false,
    dimensionsList: [],
    onAddCustomDimension: () => null,
    filterText: '',
    onFilterTextChanged: () => null,
    unsavedCustomDimension: false,
    onDeleteApproved: () => null,
    onDeleteRejected: () => null,
    onTabSwitch: () => null,
    showDeleteDialog: false,
    deleteDialogDimension: null,
    dimensionRelatedObjects: { bookmarks: [], dashboards: [], anonymousReports: [], goals: [] },
    maxDimensions: 0,
    totalDimensions: 0,
    tabsToShow: [],
    tab: '',
    translate: () => null,
};

export default withLocalize(Page);
