import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableCSS from '../../components/organisms/Table.css';
import css from '../styles.css';
import SourceLogoPlaceholderIcon from '../../resources/svg/source_logo_placeholder.svg';
import { sortAlphabetically } from '../../utils/sortUtil';
import FieldsAvailabilityTableHead from './fieldsAvailabilityTableHead';
import FieldsAvailabilityTableBody from './fieldsAvailabillityTableBody';
import { sortByAvailability } from '../utils';

const SourcesAvailabilityTable = ({ fieldName, availabilityBySource, sourcesDict, hideTooltip }) => {
    return (
        <table className={classNames(TableCSS.table, css.table)}>
            <FieldsAvailabilityTableHead headerTitles={['Source', 'Network', 'Tracker']} />
            <tbody>
                {Object.keys(availabilityBySource)
                    .sort((source1, source2) => {
                        return (
                            sortByAvailability(
                                availabilityBySource[source1],
                                availabilityBySource[source2],
                                fieldName
                            ) ||
                            sortAlphabetically(sourcesDict[source1].display_name, sourcesDict[source2].display_name)
                        );
                    })
                    .map(source => {
                        const { logo, display_name: displayName } = sourcesDict[source] || {};

                        return (
                            <FieldsAvailabilityTableBody
                                key={displayName}
                                availabilityDictKey={fieldName}
                                availabilityDict={availabilityBySource[source]}
                                LogoIcon={
                                    logo
                                        ? ({ logoSrc, className }) => {
                                              return <img className={className} src={logoSrc} />;
                                          }
                                        : SourceLogoPlaceholderIcon
                                }
                                logoSrc={logo}
                                displayName={displayName}
                                hideTooltip={hideTooltip}
                            />
                        );
                    })}
            </tbody>
        </table>
    );
};

SourcesAvailabilityTable.propTypes = {
    availabilityBySource: PropTypes.objectOf(PropTypes.any).isRequired,
    fieldName: PropTypes.string.isRequired,
    hideTooltip: PropTypes.bool,
};

SourcesAvailabilityTable.defaultProps = {
    hideTooltip: false,
};

export default SourcesAvailabilityTable;
