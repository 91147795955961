import React, { Component, Fragment } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import css from '../style.css';
import AdNetworkSelection from './AdNetworkSelection';
import { Button } from '../../components/widgets';
import PlusIconWhite from '../../resources/svg/plus_white.svg';

const Z_INDEX = { SHOW: 3, HIDE: 0 };
const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        zIndex: 5,
        x: 0,
        transition: {
            x: {
                type: 'tween',
                duration: 200,
                ease: 'anticipate',
            },
        },
    },
    exit: {
        opacity: 0,
        zIndex: 3,
        x: 150,
        transition: {
            x: {
                type: 'spring',
                duration: 0,
            },
        },
    },
});

class AddNewUan extends Component {
    constructor(props) {
        super(props);
        this.state = { showAdNetworkSelection: false };

        this.showAdNetworkSelection = this._showAdNetworkSelection.bind(this);
        this.hideAdNetworkSelection = this._hideAdNetworkSelection.bind(this);
    }

    _showAdNetworkSelection(element) {
        if (element && element.currentTarget.id === 'uanNameText' && !this.state.showAdNetworkSelection) {
            return;
        }
        this.setState({ showAdNetworkSelection: true });
    }

    _hideAdNetworkSelection() {
        this.setState({ showAdNetworkSelection: false });
    }

    render() {
        const {
            adNetworkOptions,
            selectedAdNetworkOption,
            onAdNetworkSelectionChange,
            buttonText,
            placeholderText,
            onInputChange,
            icon,
        } = this.props;

        const { showAdNetworkSelection } = this.state;

        return (
            <Fragment>
                <AnimationItem pose={showAdNetworkSelection ? 'enter' : 'exit'} style={{ zIndex: Z_INDEX.SHOW}}>
                    <div
                        id="uanNameText"
                        className={css.uanNameText}
                        onBlur={this.hideAdNetworkSelection}
                        style={{
                            width: '300px',
                            marginRight: '-1px',
                            right: showAdNetworkSelection ? 0 : '',
                            zIndex: showAdNetworkSelection ? Z_INDEX.SHOW : Z_INDEX.HIDE,
                        }}
                    >
                        <AdNetworkSelection
                            onChange={onAdNetworkSelectionChange}
                            onBlur={this.hideAdNetworkSelection}
                            options={adNetworkOptions}
                            controlledValue={selectedAdNetworkOption}
                            placeHolder={placeholderText}
                            onInputChange={onInputChange}
                            setFocus={showAdNetworkSelection}
                        />
                    </div>
                </AnimationItem>
                <Button
                    onClick={this.showAdNetworkSelection}
                    onBlur={this.hideAdNetworkSelection}
                    type="primary"
                    level="level1"
                    text={buttonText}
                    forceActive={showAdNetworkSelection}
                    style={{ borderRadius: showAdNetworkSelection ? '0 2px 2px 0' : '2px', zIndex: 4 }}
                    Icon={icon}
                />
            </Fragment>
        );
    }
}

AddNewUan.propTypes = {
    adNetworkOptions: PropTypes.arrayOf(PropTypes.any),
    selectedAdNetworkOption: PropTypes.objectOf(PropTypes.any),
    buttonText: PropTypes.string,
    placeholderText: PropTypes.string,
    onInputChange: PropTypes.func,
    onAdNetworkSelectionChange: PropTypes.func.isRequired,
    icon: PropTypes.shape('svg'),
};

AddNewUan.defaultProps = {
    adNetworkOptions: [],
    selectedAdNetworkOption: {},
    onInputChange: () => {},
    placeholderText: '',
    buttonText: '',
    icon: null,
};

export { AddNewUan };
export default withLocalize(AddNewUan);
