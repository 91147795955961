import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { withLocalize } from 'react-localize-redux';
import css from './Accordion.css';
import PlusIcon from '../../resources/svg/icons/plus_link.svg';
import MinusIcon from '../../resources/svg/icons/minus_link.svg';

const ExpandIcon = ({ expand }) => {
    return expand ? <MinusIcon /> : <PlusIcon />;
};

ExpandIcon.propTypes = {
    expand: PropTypes.bool.isRequired,
};

const Accordion = ({ items, translate, className, onItemClick }) => {
    const [expandedItems, setExpandedItems] = React.useState([]);

    const onExpand = (event, expanded, itemId) => {
        if (expanded) {
            setExpandedItems([...expandedItems, itemId]);
        } else {
            setExpandedItems(expandedItems.filter(id => id !== itemId));
        }
    };

    return (
        <div className={classNames(css.container, className)}>
            {items.map((item, index) => {
                if (!item.id) item.id = crypto.randomUUID();
                const { id, title, content } = item;

                return (
                    <MuiAccordion
                        key={id}
                        classes={{ root: css.accordionRoot, expanded: css.accordionRootExpanded }}
                        onChange={(event, expanded) => onExpand(event, expanded, item.id)}
                    >
                        <AccordionSummary
                            classes={{
                                expanded: index === 0 ? css.accordionSummaryExpandFirst : css.accordionSummaryExpand,
                                content: css.accordionSummaryExpandContent,
                            }}
                            expandIcon={<ExpandIcon expand={expandedItems.includes(id)} />}
                            onClick={() => onItemClick(item)}
                        >
                            {translate(title)}
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: css.accordionDetails }}>
                            {translate(content)}
                        </AccordionDetails>
                    </MuiAccordion>
                );
            })}
        </div>
    );
};

Accordion.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, content: PropTypes.string })).isRequired,
    translate: PropTypes.func.isRequired,
    className: PropTypes.string,
    onItemClick: PropTypes.func,
};

Accordion.defaultProps = {
    className: '',
    onItemClick: () => {},
};

export default withLocalize(Accordion);
