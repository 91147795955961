import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from '../../widgets/Popover';
import { useOutsideEventHandler } from '../../../utils/customHooks';
import css from './DimensionsAvailabilityPopover.css';
import SourcesAvailabilityTable from '../../../fieldsAvailability/components/sourcesAvailabilityTable';

const DimensionsAvailabilityPopover = ({
    fieldsAvailabilityData,
    showPopover,
    setShowPopover,
    anchorEl,
    dimension,
}) => {
    const wrapperRef = useRef(null);

    const closePopover = () => setShowPopover(false);
    const outsideEvents = [{ name: 'mousedown' }, { name: 'scroll', useCapture: true }];
    useOutsideEventHandler(wrapperRef, closePopover, outsideEvents);

    return (
        <Popover
            className={css.dimensionsPopover}
            showPopover={showPopover}
            anchorEl={anchorEl}
            wrapperRef={wrapperRef}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div className={css.dimensionsPopoverContent}>
                <SourcesAvailabilityTable
                    fieldName={dimension}
                    availabilityBySource={fieldsAvailabilityData.availabilityBySource}
                    sourcesDict={fieldsAvailabilityData.sourcesDict}
                    hideTooltip
                />
            </div>
        </Popover>
    );
};

DimensionsAvailabilityPopover.propTypes = {
    anchorEl: PropTypes.objectOf(PropTypes.any),
    showPopover: PropTypes.bool,
    setShowPopover: PropTypes.func,
    header: PropTypes.string,
};

DimensionsAvailabilityPopover.defaultProps = {
    etlTableContent: [],
    anchorEl: {},
    showPopover: false,
    setShowPopover: () => {},
    header: '',
};

export default DimensionsAvailabilityPopover;
