import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Label, Button } from '../../../../../components/widgets';
import css from './testRevenue.css';
import { sendMixpanelEventSdkIntegration } from '../../../utils';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../../consts';
import { SDK_INTEGRATION_SECTIONS, TARGET_PLATFORM_SHORT_NAME, TARGET_PLATFORM_TO_PARAM } from '../../../consts';
import Accordion from '../../../../../components/organisms/Accordion';
import { useSDKPlanningContext } from '../../../context/useSDKPlanningContext';
import { getSectionTranslateID } from '../../../sectionsWrapper/utils';

const { TEST_REVENUE } = SDK_INTEGRATION_SECTIONS;

const TestRevenue = ({ translate }) => {
    const { selectedTargetPlatform } = useSDKPlanningContext();
    const platform = TARGET_PLATFORM_SHORT_NAME[selectedTargetPlatform];

    const handleClick = () => {
        sendMixpanelEventSdkIntegration(
            SDK_INTEGRATION_MIXPANEL_EVENTS.GO_TO_TESTING_CONSOLE,
            SDK_INTEGRATION_SECTIONS.TEST_A_SESSION,
            TARGET_PLATFORM_TO_PARAM[platform]
        );
        window.location.hash = '#/react/testing-console';
    };

    const onItemClick = data => {
        sendMixpanelEventSdkIntegration(
            SDK_INTEGRATION_MIXPANEL_EVENTS.ACCORDION_ITEM_CLICK,
            TEST_REVENUE,
            selectedTargetPlatform,
            { question: translate(data.title) }
        );
    };

    return (
        <div>
            <Label
                className={css.title}
                type="sideLabel"
                text={getSectionTranslateID(TEST_REVENUE, `TITLE`, platform)}
            />
            <Accordion
                className={css.revenueAccordionFaq}
                onItemClick={onItemClick}
                items={Array(4)
                    .fill()
                    .map((item, key) => {
                        return {
                            title: getSectionTranslateID(
                                TEST_REVENUE,
                                `REVENUE_FAQ.QUESTION_${key + 1}_TITLE`,
                                platform
                            ),
                            content: getSectionTranslateID(
                                TEST_REVENUE,
                                `REVENUE_FAQ.ANSWER_${key + 1}_TEXT`,
                                platform
                            ),
                        };
                    })}
            />

            <Button
                level="important"
                text="STATIC.PAGES.SDK.BUTTONS.GO_TO_TESTING_CONSOLE"
                type="primary"
                onClick={() => handleClick()}
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
            />
        </div>
    );
};

TestRevenue.propTypes = {
    translate: PropTypes.func.isRequired,
};
export default withLocalize(TestRevenue);
