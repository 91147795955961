import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { useFetchAllSources, useFetchCampaigns } from './hooks';
import {
    DATA_TEST_ID_CAMPAIGNS_AUTOCOMPLETE,
    DATA_TEST_ID_SOURCES_AUTOCOMPLETE,
    SELECTED_CAMPAIGN_LOCAL_STORAGE_KEY,
    SELECTED_CAMPAIGN_MIXPANEL_EVENT_NAME,
    SELECTED_SOURCE_LOCAL_STORAGE_KEY,
    SELECTED_SOURCE_MIXPANEL_EVENT_NAME,
} from './consts';
import css from './filters.css';
import Label from '../../../components/foundations/Label';
import { AutoComplete } from '../../../components/widgets';
import { formatAutoCompleteObject, formatCampaignFilter } from './utils';
import { useLocalStorageState } from '../../../utils/customHooks';
import { trackMixpanelEvent } from '../../../utils/general';
import { CAMPAIGN_REPORT_MIXPANEL_PREFIX } from '../../consts';

const CampaignFilters = ({ onChange, startDate, endDate }) => {
    const { value: sources = [], isLoading: isLoadingSources } = useFetchAllSources();
    const [campaignFromLocalStorage, setCampaignInLocalStorage] = useLocalStorageState(
        SELECTED_CAMPAIGN_LOCAL_STORAGE_KEY,
        null
    );
    const [sourceFromLocalStorage, setSourceInLocalStorage] = useLocalStorageState(
        SELECTED_SOURCE_LOCAL_STORAGE_KEY,
        null
    );
    const [selectedCampaign, setSelectedCampaign] = useState(campaignFromLocalStorage);
    const [selectedSource, setSelectedSource] = useState(sourceFromLocalStorage);
    const { value: campaigns = [], isLoading: isLoadingCampaigns } = useFetchCampaigns(
        selectedSource?.value,
        startDate,
        endDate
    );

    const onCampaignSelected = selected => {
        setSelectedCampaign(selected);
        if (selected) {
            setCampaignInLocalStorage(selected);
            setSourceInLocalStorage(selectedSource);
            onChange(formatCampaignFilter(selected.value, selectedSource.value));
            trackMixpanelEvent(CAMPAIGN_REPORT_MIXPANEL_PREFIX, SELECTED_CAMPAIGN_MIXPANEL_EVENT_NAME, {
                campaign_name: selected.label,
            });
        } else {
            onChange([]);
        }
    };

    const onSourceSelected = selected => {
        setSelectedSource(selected);
        setSelectedCampaign(null);
        onChange([]);
        trackMixpanelEvent(CAMPAIGN_REPORT_MIXPANEL_PREFIX, SELECTED_SOURCE_MIXPANEL_EVENT_NAME, {
            source_name: selected.label,
        });
    };

    useEffect(() => {
        if (sources.length > 0 && !sourceFromLocalStorage) {
            onSourceSelected(formatAutoCompleteObject(sources[0]));
        }
    }, [sources]);

    useEffect(() => {
        if (campaigns.length > 0 && !campaignFromLocalStorage) {
            onCampaignSelected(formatAutoCompleteObject(campaigns[0]));
        }
    }, [campaigns]);

    return (
        <div className={css.filterContainer}>
            <div className={css.filterItem}>
                <Label
                    type="fieldLabel"
                    text="STATIC.PAGES.CAMPAIGN_REPORT.FILTERS.SOURCE_LABEL"
                    className={css.dropdownLabel}
                />
                <AutoComplete
                    placeholder="STATIC.PAGES.CAMPAIGN_REPORT.FILTERS.SOURCE_PLACEHOLDER"
                    containerClass={css.dropdown}
                    dataTestId={DATA_TEST_ID_SOURCES_AUTOCOMPLETE}
                    selectOptions={{
                        options: sources.map(source => formatAutoCompleteObject(source)),
                        isLoading: isLoadingSources,
                        value: selectedSource,
                    }}
                    onChange={onSourceSelected}
                />
            </div>
            <div className={css.filterItem}>
                <Label
                    type="fieldLabel"
                    text="STATIC.PAGES.CAMPAIGN_REPORT.FILTERS.CAMPAIGN_LABEL"
                    className={css.dropdownLabel}
                />
                <div title={selectedCampaign?.label}>
                    <AutoComplete
                        placeholder={`STATIC.PAGES.CAMPAIGN_REPORT.FILTERS.${
                            isLoadingCampaigns ? 'LOADING' : 'CAMPAIGN'
                        }_PLACEHOLDER`}
                        containerClass={css.dropdown}
                        dataTestId={DATA_TEST_ID_CAMPAIGNS_AUTOCOMPLETE}
                        selectOptions={{
                            options: campaigns.map(campaign => formatAutoCompleteObject(campaign)),
                            isLoading: isLoadingCampaigns,
                            value: selectedCampaign,
                            disabled: isLoadingCampaigns,
                            isClearable: true,
                        }}
                        onChange={onCampaignSelected}
                        virtualScrolling
                    />
                </div>
            </div>
        </div>
    );
};

CampaignFilters.propTypes = {
    onChange: PropTypes.func.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
};

export default withLocalize(CampaignFilters);
