import React from 'react';
import { connect } from 'react-redux';
import Table from '../components/organisms/Table';
import Spinner from '../components/widgets/Spinner';
import css from './style.css';
import { PageHeader, PageWrapper } from '../components/partials';

const mapStateToProps = state => ({
    data: state.athenaOverRealtime.data,
    showSpinner: state.athenaOverRealtime.showSpinner,
});

const AthenaOverRealtime = ({ showSpinner, data = [] }) => (
    <PageWrapper>
        <PageHeader text="Realtime Raw Data" leftComponents={[]} rightComponent={[]} />
        <div style={{ marginTop: 20 }}>All data is in UTC timezone.</div>
        <div>{showSpinner && <Spinner expanded show />}</div>
        <div style={{ overflow: 'scroll', marginTop: 25 }}>
            {!showSpinner && (
                <Table
                    tableClass={css.athenaTable}
                    data={data}
                    metadata={[
                        { className: css.date, name: 'Date', displayName: 'Date' },
                        { name: 'App', displayName: 'App' },
                        { name: 'Source', displayName: 'Source' },
                        { name: 'Site Public Id', displayName: 'Site Public Id' },
                        { name: 'Platform', displayName: 'Platform' },
                        { name: 'Campaign Name', displayName: 'Campaign Name' },
                        { name: 'Click Count', displayName: 'Click Count' },
                        { name: 'Install Count', displayName: 'Install Count' },
                    ]}
                />
            )}
        </div>
    </PageWrapper>
);

export default connect(mapStateToProps)(AthenaOverRealtime);
