import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Formik } from 'formik-2';
import * as Yup from 'yup';
import moment from 'moment/moment';
import { Label, Spinner, Button } from '../components/widgets';
import { DropdownFieldEnhanced } from '../utils/components/formUtils';
import Datepicker from '../components/widgets/Datepicker';
import { getApps, getConversionEvents, getLoading, getUserData } from './selectors';
import css from './page.css';
import { _getMtaMaxDate, models, MTA_EVENT_PREFIX } from './consts';
import MtaApi from './service';
import { trackMixpanelEvent } from '../utils/general';
import { getImpersonationFromQuery } from '../utils/url';

const api = new MtaApi();

const MTA_VALIDATION_SCHEMA = Yup.object().shape({
    app: Yup.string().required(),
    site: Yup.string().required(),
    conversionEvent: Yup.string().required(),
    model: Yup.string().required(),
});

const MTA_MIN_DATE = moment()
    .subtract(30, 'days')
    .format('YYYY-MM-DD');
export const MTA_MAX_DATE = _getMtaMaxDate();

function MtaForm({ onModelSelected }) {
    const apps = useSelector(state => getApps(state));
    const conversionEvents = useSelector(state => getConversionEvents(state));
    const loading = useSelector(state => getLoading(state));
    const userData = useSelector(state => getUserData(state));

    const [sites, setSites] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [eventsForSites, setEventsForSites] = useState([]);
    const [enableEvents, setEnableEvents] = useState(false);

    const onDownloadCsvClick = async values => {
        const data = { ...values };
        delete data.app;
        trackMixpanelEvent(MTA_EVENT_PREFIX, 'Report Downloaded', data);

        const res = await api.generateReport(data);
        const mtaId = res.value?.mtaId;
        if (userData && mtaId) {
            let url = `/logs/download?mtaId=${mtaId}`;
            const impersonatedUser = getImpersonationFromQuery();
            if (impersonatedUser) {
                url += `&user=${decodeURIComponent(impersonatedUser)}`;
            }
            window.open(url, '_blank');
        }
    };

    const onAppSelected = (appName, setFieldValue) => {
        const app = apps.filter(application => application.name === appName)[0];
        setSites(app.sites);

        // clean up conversionEvents.
        if (eventsForSites.length > 0) {
            setEventsForSites([]);
        }
        setFieldValue('conversionEvent', '');
        setSubmitted(false);
        setEnableEvents(false);
    };

    const onSiteSelected = (siteId, setFieldValue) => {
        const site = sites.filter(s => s.key === siteId)[0];
        const events = (conversionEvents || []).filter(event => event.site_id === site.id);
        setEventsForSites(events);
        setEnableEvents(true);
        setFieldValue('conversionEvent', '');
    };

    if (loading) {
        return <Spinner show expanded />;
    }

    const initialValues = {
        model: models[0].name,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    };

    return (
        <Formik initialValues={initialValues} validationSchema={MTA_VALIDATION_SCHEMA} onSubmit={onDownloadCsvClick}>
            {({ values, errors, validateForm, submitForm, setFieldValue }) => (
                <form>
                    <div className={css.formArea}>
                        <Label text="STATIC.PAGES.MTA.MTA_REPORT_LABEL" type="h2" className={css.reportTitle} />
                        <Label text="STATIC.PAGES.MTA.FORM_SELECTION_LABEL" type="h3" />
                        <a
                            className={css.learnMore}
                            href="https://support.singular.net/hc/en-us/articles/10791404560411"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more
                        </a>

                        <DropdownFieldEnhanced
                            name="app"
                            items={apps}
                            selected={values.app}
                            onChange={appName => onAppSelected(appName, setFieldValue)}
                            label="STATIC.PAGES.MTA.APP_LABEL"
                            labelClassName={css.fieldsLabels}
                            errorClassName={css.fieldsErrors}
                            error={submitted && errors.app ? errors.app : ''}
                        />
                        <DropdownFieldEnhanced
                            name="site"
                            items={sites}
                            valueField="key"
                            selected={values.site}
                            onChange={siteId => onSiteSelected(siteId, setFieldValue)}
                            label="STATIC.PAGES.MTA.SITE_LABEL"
                            labelClassName={css.fieldsLabels}
                            disabled={sites.length === 0}
                            mandatory={!!errors.site && submitted}
                            error={submitted ? errors.site : null}
                            errorClassName={css.fieldsErrors}
                        />

                        <Label text="STATIC.PAGES.MTA.DATE_LABEL" className={css.fieldsLabels} />
                        <div className={css.datepicker}>
                            <Datepicker
                                id="date"
                                name="date"
                                containerClass={css.datepickerContainer}
                                startDate={values.startDate}
                                endDate={values.endDate}
                                minDate={MTA_MIN_DATE}
                                maxDate={MTA_MAX_DATE}
                                dateLimit={{ days: 7 }}
                                onApply={({ start_date: newStartDate, end_date: newEndDate }) => {
                                    setFieldValue('startDate', newStartDate);
                                    setFieldValue('endDate', newEndDate);
                                }}
                                ranges={{
                                    Today: [moment(), moment()],
                                    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                }}
                                buttonType="button"
                            />
                            <Label text="STATIC.PAGES.MTA.LOOKBACK_WINDOW_LABEL" className={css.lookbackWindowLabel} />
                            <Label text="STATIC.PAGES.MTA.LOOKBACK_WINDOW_VALUE" className={css.lookbackWindowValue} />
                        </div>

                        <DropdownFieldEnhanced
                            name="conversionEvent"
                            items={eventsForSites}
                            valueField="key"
                            selected={values.conversionEvent}
                            label="STATIC.PAGES.MTA.CONVERSION_EVENT_LABEL"
                            labelClassName={css.fieldsLabels}
                            mandatory={!!errors.conversionEvent && submitted}
                            disabled={!enableEvents}
                            errorClassName={css.fieldsErrors}
                            error={submitted ? errors.conversionEvent : null}
                        />

                        <DropdownFieldEnhanced
                            name="model"
                            items={models}
                            label="STATIC.PAGES.MTA.MODEL_LABEL"
                            labelClassName={css.fieldsLabels}
                            selected={values.model}
                            onChange={onModelSelected}
                        />

                        <div className={css.submitButtons}>
                            <Button
                                onClick={() => {
                                    setSubmitted(true);
                                    validateForm().then(() => {
                                        submitForm();
                                    });
                                }}
                                text="STATIC.PAGES.MTA.DOWNLOAD_CSV"
                            />
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}

MtaForm.propTypes = {
    onModelSelected: PropTypes.func,
};

MtaForm.defaultProps = {
    onModelSelected: () => {},
};

export default withLocalize(MtaForm);
