import React, { useEffect, useState } from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Snackbar.css';
import SuccessIcon from '../../../resources/svg/checked_with_circle.svg';
import ErrorIcon from '../../../resources/svg/icons/error.svg';
import CloseIcon from '../../../resources/svg/close_icon.svg';
import Label from '../../foundations/Label';

const TYPE_TO_PROPERTIES = {
    success: {
        css: css.snackbarContainerSuccess,
        icon: SuccessIcon,
    },
    error: {
        css: css.snackbarContainerError,
        icon: ErrorIcon,
    },
};

const Snackbar = ({ open, autoHideDuration, onClose, title, message, type }) => {
    const [innerOpen, setInnerOpen] = useState(false);
    const { css: typeContainerCss, icon: TypeIcon } = TYPE_TO_PROPERTIES[type];

    useEffect(() => {
        setInnerOpen(open);
    }, [open]);

    const handleClose = () => {
        onClose();
        setInnerOpen(false);
    };

    return (
        <MuiSnackbar
            open={innerOpen}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleClose}
        >
            <div className={classNames(css.snackbarContainer, typeContainerCss)}>
                <div className={css.snackbarInnerContainer}>
                    <div className={css.snackbarTitle}>
                        <TypeIcon className={css.snackbarIcon} />
                        <Label className={css.snackbarTitle} text={title} />
                    </div>
                    {message && <Label className={css.snackbarText} text={message} />}
                </div>
                <CloseIcon onClick={handleClose} className={css.closeIcon} />
            </div>
        </MuiSnackbar>
    );
};

Snackbar.propTypes = {
    open: PropTypes.bool,
    autoHideDuration: PropTypes.number,
    onClose: PropTypes.func,
    message: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
};

Snackbar.defaultProps = {
    open: false,
    autoHideDuration: null,
    onClose: () => {},
    message: '',
    type: 'error',
};
export default Snackbar;
