import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import css from './style.css';
import Dialog from '../components/widgets/Dialog';
import Label from '../components/foundations/Label';
import CloseIcon from '../resources/svg/close_icon.svg';
import ErrorIcon from '../resources/svg/error_general.svg';

function GeneralErrorDialog({ errorMsg, clearErrorMsg }) {
    return (
        <Dialog classes={{ paperScrollBody: css.dialogPaper }} open={!!errorMsg} onClose={clearErrorMsg}>
            <div className={css.container}>
                <CloseIcon className={css.closeIcon} onClick={clearErrorMsg} />
                <div className={css.content}>
                    <ErrorIcon className={css.errorIcon} />
                    <div className={css.body}>
                        <Label type="h2" style={{ color: '#191e21' }} text="STATIC.GENERAL_ERROR_DIALOG_TITLE" />
                        <Label type="p" text={errorMsg} style={{ whiteSpace: 'pre' }} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

GeneralErrorDialog.propTypes = {
    errorMsg: PropTypes.string,
    clearErrorMsg: PropTypes.func.isRequired,
};

GeneralErrorDialog.defaultProps = {
    errorMsg: undefined,
};

export default withLocalize(GeneralErrorDialog);
