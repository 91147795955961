import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Translate, withLocalize } from 'react-localize-redux';
import css from './EmptyState.css';
import IntroSingularPipeline from '../../../resources/png/intro_singular_pipeline.png';
import SingularDude from '../../../resources/png/figures/singular_dude.png';
import SingularLady from '../../../resources/png/figures/singular_lady.png';
import AppsConfiguration from '../../../resources/svg/empty_state_apps.svg';

import { Button } from '../../widgets';
import CloseIcon from '../../../resources/svg/close_icon.svg';
import Checked from '../../../resources/svg/v_icon.svg';
import { EmptyStateImageSrc, hideEmptyStateOption, trackEmptyStateMixPanelEvents } from './utils';
import { emptyStateDefaultProps, emptyStatePropsType } from './types';

const EmptyState = ({
    translate,
    titleText,
    subTitleText,
    tasksTodo,
    onCloseEmptyState,
    tasksDone,
    showCloseIcon,
    pageName,
    onTaskClick,
    imageSrc,
}) => {
    const pageNameTranslated = translate(pageName);

    const onManuallyCloseEmptyState = () => {
        trackEmptyStateMixPanelEvents('close', { page: pageNameTranslated });
        hideEmptyStateOption(pageNameTranslated);
        onCloseEmptyState();
    };

    const getImageClassAndSrc = () => {
        switch (imageSrc) {
            case EmptyStateImageSrc.SINGULAR_LADY:
                return { className: css.ladyImage, src: SingularLady };

            case EmptyStateImageSrc.SINGULAR_DUDE:
                return { className: css.pipelineImage, src: SingularDude };

            case EmptyStateImageSrc.APPS_CONFIGURATION:
                return { className: css.pipelineImage, src: AppsConfiguration };

            default:
                return { className: css.pipelineImage, src: IntroSingularPipeline };
        }
    };

    const onGoClick = task => {
        trackEmptyStateMixPanelEvents('clicked', {
            page: pageNameTranslated,
            clicked: task.topic,
        });
        if (task.goRedirect) window.location.href = task.goRedirect;
        else onTaskClick(task.topic);
    };

    const todoList = tasksTodo.map((task, index) => (
        <div key={`number-icon-${task.name}`} className={css.taskInList}>
            {task.icon ? <task.icon /> : <div className={classNames(css.indexNumber)}>{index + 1}</div>}
            <li className={css.text} key={task.name}>
                <Translate id={task.name} />
            </li>
            {!task.disabled &&
                (tasksDone[task.topic] ? (
                    <Checked className={css.checked} />
                ) : (
                    !task.hideGoButton && (
                        <Button
                            type="primary"
                            level="level2"
                            className={css.goButton}
                            textClassName={css.textGoButton}
                            text="Go"
                            onClick={() => onGoClick(task)}
                        />
                    )
                ))}
        </div>
    ));

    return (
        <div className={css.pageContainer}>
            <div>
                <div className={css.title}>
                    <Translate id={titleText} />
                </div>
                <div className={css.subTitle}>
                    <Translate id={subTitleText} />
                </div>
                <div className={css.todoList}>{todoList}</div>
            </div>
            <img {...getImageClassAndSrc()} alt="_blank" />
            {showCloseIcon && <CloseIcon className={css.closeIcon} onClick={onManuallyCloseEmptyState} />}
        </div>
    );
};

EmptyState.propTypes = {
    translate: PropTypes.func.isRequired,
    ...emptyStatePropsType,
};

EmptyState.defaultProps = emptyStateDefaultProps;

export default withLocalize(EmptyState);
