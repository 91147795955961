import React from 'react';
import PropTypes from 'prop-types';
import css from './sdkConfigurationStepPages.css';
import SDKConfigurationQuestionsTable from './sdkConfigurationQuestionsTable';
import { TableWidgetMetadataShape } from '../../../global/widgetTypes';
import Label from '../../../components/foundations/Label';

const SDKConfigurationQuestionsTablePage = ({ stepTitle, tableData, tableMetadata, zebraTable, tableClassName }) => {
    return (
        <div className={css.stepContainer} key={stepTitle}>
            <Label className={css.stepTitle} text={stepTitle} />
            <SDKConfigurationQuestionsTable
                tableMetadata={tableMetadata}
                tableData={tableData}
                zebraTable={zebraTable}
                className={tableClassName}
            />
        </div>
    );
};

SDKConfigurationQuestionsTablePage.propTypes = {
    tableMetadata: TableWidgetMetadataShape.isRequired,
    tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
    stepTitle: PropTypes.string.isRequired,
    zebraTable: PropTypes.bool,
    tableClassName: PropTypes.string,
};

SDKConfigurationQuestionsTablePage.defaultProps = {
    zebraTable: false,
    tableClassName: '',
};

export default SDKConfigurationQuestionsTablePage;
