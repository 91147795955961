import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './GridCustomColumnsToolPanel.css';
import Checkbox from '../atoms/Checkbox';
import Chevron from '../../resources/icons/chevron.svg';

function GridCustomColumnsToolPanel({ api, getGroupId, getGroupName }) {
    const INITIAL_CHECK_STATUS = true;

    const [dimensions, setDimensions] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [metricGroups, setMetricsGroups] = useState([]);
    const [collapsedGroupIds, setCollapsedGroupIds] = useState([]);

    useEffect(() => {
        if (!metrics.length) return;

        const groupIds = [];
        const groupIdToFields = {};

        metrics.forEach(metric => {
            const groupId = getGroupId(metric);

            if (groupIdToFields[groupId]) {
                groupIdToFields[groupId].push(metric);
            } else {
                groupIds.push(groupId);
                groupIdToFields[groupId] = [metric];
            }
        });

        setMetricsGroups(
            groupIds.map(groupId => ({ groupId, groupName: getGroupName(groupId), fields: groupIdToFields[groupId] }))
        );
    }, [metrics]);

    const getColumnsByFieldType = fieldType => {
        return api.columnController.columnDefs
            .filter(col => col.fieldType === fieldType)
            .map(col => ({ ...col, checked: INITIAL_CHECK_STATUS }));
    };

    useEffect(() => {
        if (!api || dimensions.length || metrics.length) return;

        setDimensions(getColumnsByFieldType('dimension'));
        setMetrics(getColumnsByFieldType('metric'));
    }, [api, dimensions, metrics]);

    const getColumnApi = ({ columnController }) => {
        return columnController.columnApi;
    };

    const handleFieldCheck = (fields, id, isChecked) => {
        const colApi = getColumnApi(api);
        const checked = !isChecked;

        return fields.map(field => {
            if (field.id !== id) {
                return field;
            }

            const { colId } = field;
            colApi.setColumnVisible(colId, checked);
            return { ...field, checked };
        }, []);
    };

    const isGroupChecked = useCallback(
        groupId => {
            return !metrics.find(metric => getGroupId(metric) === groupId && !metric.checked);
        },
        [metrics]
    );

    const handleGroupCheck = (groupId, isChecked) => {
        const checked = !isChecked;
        const colIds = [];

        const newMetrics = metrics.map(metric => {
            if (getGroupId(metric) === groupId) {
                colIds.push(metric.colId);
                return { ...metric, checked };
            }

            return metric;
        });

        setMetrics(newMetrics);
        getColumnApi(api).setColumnsVisible(colIds, checked);
    };

    const handleGroupCollapse = (groupId, isCollapsed) => {
        let newCollapsedGroupIds;

        if (isCollapsed) {
            newCollapsedGroupIds = collapsedGroupIds.filter(id => id !== groupId);
        } else {
            newCollapsedGroupIds = [...collapsedGroupIds, groupId];
        }

        setCollapsedGroupIds(newCollapsedGroupIds);
    };

    const isGroupCollapsed = useCallback(
        groupId => {
            return collapsedGroupIds.includes(groupId);
        },
        [collapsedGroupIds]
    );

    const getFieldElement = (headerName, id, checked, onCheck, isGroup) => {
        const isCollapsed = isGroup && isGroupCollapsed(id);

        return (
            <div key={id} className={css.fieldContainer}>
                {isGroup && (
                    <Chevron
                        onClick={() => handleGroupCollapse(id, isCollapsed)}
                        className={classNames(css.chevronIcon, { [css.collapseIcon]: isCollapsed })}
                    />
                )}
                <Checkbox style={{ margin: 0 }} checked={checked} onChange={onCheck} />
                <div
                    onClick={onCheck}
                    className={classNames(css.field, {
                        [css.groupFieldName]: isGroup,
                    })}
                >
                    {headerName}
                </div>
            </div>
        );
    };

    return (
        <div className={css.container}>
            <div className={classNames(css.fieldTypeTitle, css.field)}>Dimensions</div>
            <div className={css.fieldTypeContent}>
                {dimensions.map(({ headerName, id, checked }) => {
                    return getFieldElement(headerName, id, checked, () => {
                        setDimensions(handleFieldCheck(dimensions, id, checked));
                    });
                })}
            </div>

            <div className={classNames(css.fieldTypeTitle, css.field)}>Metrics</div>
            <div className={css.fieldTypeContent}>
                {metricGroups.map(({ groupId, groupName, fields }) => {
                    const metricsElements = fields.map(({ headerName, id, checked }) => {
                        return getFieldElement(headerName, id, checked, () => {
                            setMetrics(handleFieldCheck(metrics, id, checked));
                        });
                    });

                    if (groupName) {
                        const groupChecked = isGroupChecked(groupId);

                        return (
                            <div key={groupId}>
                                {getFieldElement(
                                    groupName,
                                    groupId,
                                    groupChecked,
                                    () => {
                                        handleGroupCheck(groupId, groupChecked);
                                    },
                                    true
                                )}
                                {!isGroupCollapsed(groupId) && (
                                    <div className={css.groupFieldsSector}>{metricsElements}</div>
                                )}
                            </div>
                        );
                    }

                    return (
                        <div
                            key={groupId}
                            className={classNames({
                                [css.nonGroupFields]: metricGroups.length > 1,
                            })}
                        >
                            {metricsElements}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

GridCustomColumnsToolPanel.propTypes = {
    api: PropTypes.objectOf(PropTypes.any),
    getGroupId: PropTypes.func,
    getGroupName: PropTypes.func,
};

GridCustomColumnsToolPanel.defaultProps = {
    api: null,
    getGroupId: () => {},
    getGroupName: () => {},
};

export default GridCustomColumnsToolPanel;
