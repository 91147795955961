import React from 'react';
import { useSDKCodeSnippetContext } from '../../../../codeSnippets/context/useSDKCodeSnippetContext';
import css from './content.css';
import StepsList from '../../../../../../components/organisms/StepsList';
import Step from '../../../../../../components/organisms/Step';
import CodeBlock from '../../../../../../components/organisms/CodeBlock';
import { Label } from '../../../../../../components/widgets';
import {
    STEP_2_CODE_SNIPPET,
    STEP_3_CODE_SNIPPET,
    STEP_4_CODE_SNIPPET,
    defaultConfigAndroid,
    GET_SDK_FILES_VERSION_PLACEHOLDER,
} from './consts';
import { useSDKPlanningContext } from '../../../../context/useSDKPlanningContext';
import { replaceCodeSnippetPlaceHolder } from '../utils';
import { sendMixpanelEventSdkIntegration } from '../../../../utils';
import { ANDROID_NATIVE, SDK_INTEGRATION_SECTIONS } from '../../../../consts';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../../../../consts';

const { CODEBLOCK_THEME_TOGGLED, COPY_CODE, SHOW_KEYS } = SDK_INTEGRATION_MIXPANEL_EVENTS;
const { GET_SDK_FILES } = SDK_INTEGRATION_SECTIONS;

const Gradle = () => {
    const { sdkVersion } = useSDKPlanningContext();
    const { codeBlockDarkTheme, toggleCodeBlockTheme } = useSDKCodeSnippetContext();

    const handleSendMixpanelEvent = event => sendMixpanelEventSdkIntegration(event, GET_SDK_FILES, ANDROID_NATIVE);

    const handleToggleCodeBlockTheme = toggle => {
        toggleCodeBlockTheme(toggle);
        sendMixpanelEventSdkIntegration(CODEBLOCK_THEME_TOGGLED, GET_SDK_FILES, ANDROID_NATIVE);
    };

    return (
        <div className={css.container}>
            <StepsList>
                <Step
                    className={css.step}
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.GET_SDK_FILES.GRADLE.STEP_1.TITLE"
                >
                    <Label
                        type="p"
                        text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.GET_SDK_FILES.GRADLE.STEP_1.TEXT"
                    />
                </Step>
                <Step
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.GET_SDK_FILES.GRADLE.STEP_2.TITLE"
                    titleClassName={css.doubleMargin}
                >
                    <CodeBlock
                        {...STEP_2_CODE_SNIPPET}
                        {...defaultConfigAndroid}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        containerClassName={css.codeBlock}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
                <Step
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.GET_SDK_FILES.GRADLE.STEP_3.TITLE"
                    titleClassName={css.doubleMargin}
                >
                    <CodeBlock
                        {...replaceCodeSnippetPlaceHolder(
                            STEP_3_CODE_SNIPPET,
                            GET_SDK_FILES_VERSION_PLACEHOLDER,
                            sdkVersion
                        )}
                        {...defaultConfigAndroid}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        containerClassName={css.codeBlock}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
                <Step
                    title="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.ANDROID.GET_SDK_FILES.GRADLE.STEP_4.TITLE"
                    titleClassName={css.doubleMargin}
                >
                    <CodeBlock
                        {...STEP_4_CODE_SNIPPET}
                        {...defaultConfigAndroid}
                        darkTheme={codeBlockDarkTheme}
                        onCodeBlockThemeToggle={handleToggleCodeBlockTheme}
                        containerClassName={css.codeBlock}
                        onCopyToClipboard={() => handleSendMixpanelEvent(COPY_CODE)}
                        onPlaceholdersVisibilityChange={() => handleSendMixpanelEvent(SHOW_KEYS)}
                    />
                </Step>
            </StepsList>
        </div>
    );
};

export default Gradle;
