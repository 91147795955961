import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import css from './BulletList.css';
import Label from '../foundations/Label';

const BulletList = ({ textItems }) => {
    return (
        <List>
            {textItems.map((item, index) => {
                const key = `${item}-${index}`;
                return (
                    <ListItem className={css.bulletItem} key={key}>
                        <div className={css.bullet} />
                        <Label text={item} className={css.bulletText} type="p" />
                    </ListItem>
                );
            })}
        </List>
    );
};

BulletList.propTypes = {
    textItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BulletList;
