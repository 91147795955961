import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableCSS from '../../components/organisms/Table.css';
import css from '../styles.css';
import FieldsAvailabilityTableCell from './fieldsAvailabilityTableCell';
import { getAvailabilityDictByTableType } from '../utils';

const FieldsAvailabilityTableBody = ({
    infoText,
    availabilityDict,
    LogoIcon,
    logoSrc,
    displayName,
    availabilityDictKey,
    hideTooltip,
}) => {
    const { networkAvailability, trackerAvailability, creativeAvailability } = getAvailabilityDictByTableType(
        availabilityDict
    );

    return (
        <tr className={classNames(TableCSS.zebraRows, TableCSS.tableTr)} key={displayName}>
            <td>
                <div className={TableCSS.tableRowContainer} title={displayName}>
                    {LogoIcon && <LogoIcon className={css.sourceImg} logoSrc={logoSrc} />}
                    <div>{displayName}</div>
                    {infoText && <span className={css.tableTdInfoText}> [{infoText}]</span>}
                </div>
            </td>
            <td className={css.indicationTd}>
                <FieldsAvailabilityTableCell
                    displayName={displayName}
                    availabilityList={[...networkAvailability, ...creativeAvailability]}
                    availabilityKey={availabilityDictKey}
                    hideTooltip={hideTooltip}
                />
            </td>
            <td className={css.indicationTd}>
                <FieldsAvailabilityTableCell
                    displayName={displayName}
                    availabilityList={trackerAvailability}
                    availabilityKey={availabilityDictKey}
                    hideTooltip={hideTooltip}
                />
            </td>
        </tr>
    );
};

FieldsAvailabilityTableBody.propTypes = {
    availabilityDict: PropTypes.objectOf(PropTypes.any).isRequired,
    availabilityDictKey: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    sourceLogo: PropTypes.string,
    hideTooltip: PropTypes.bool,
};

FieldsAvailabilityTableBody.defaultProps = {
    sourceLogo: undefined,
    hideTooltip: false,
};

export default FieldsAvailabilityTableBody;
