import React, { useState, useEffect } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useMutation } from '@apollo/client';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik-2';
import NewCodeForm from 'governance/codes/newCodeForm';
import { Button, WarningMessage, WizardFooter } from 'components/widgets';
import { GENERATE_CODE } from '../queries';
import Shelf from '../../components/widgets/Shelf';
import { FIELD_TYPES } from 'governance/consts';
import { csrftoken } from '../../services/api';

function CodeShelf({ schema, newCode, setNewCode, reloadCodes, translate }) {
    const newCodeTemplate = {
        schema: {
            id: schema.id,
        },
        governanceId: null,
        creatorEmail: null,
        created: null,
        fields: schema.fields.map(schemaField => ({
            guid: schemaField.guid,
            name: schemaField.name,
            kind: schemaField.kind,
            optional: schemaField.optional,
            values: [...schemaField.default],
        })),
    };
    const UPLOAD_URL = '/api/governance/asset_upload';
    const [code, setCode] = useState(newCodeTemplate);
    const [error, setError] = useState('');
    const [saveStatus, setSaveStatus] = useState('');
    const [shelfOpen, setShelfOpen] = useState(false);

    useEffect(() => {
        setShelfOpen(true);
    }, []);

    const [generateCode] = useMutation(GENERATE_CODE, {
        update: () => {
            setSaveStatus('saved');
            setTimeout(() => {
                setCode(newCodeTemplate);
                setError('');
                setSaveStatus('');
                setNewCode(false);
                reloadCodes();
            }, 1000);
        },
    });

    async function onGenerate(fields) {
        setSaveStatus('saving');

        // Upload assets first, and create a copy of the fields array (not to mess with the UI)
        try {
            const newFields = await Promise.all(
                Object.entries(fields).map(async ([fieldKey, fieldValue]) => {
                    const codeField = code.fields.find(cf => cf.guid === fieldKey);
                    const newField = {
                        ...codeField,
                    };

                    const field = schema.fields.find(sf => sf.guid === fieldKey);

                    switch (codeField.kind) {
                        case 'text': {
                            newField.values = [{ code: fieldValue, value: fieldValue }];
                            break;
                        }
                        case 'single_asset': {
                            const formData = new FormData();
                            formData.append('asset', fieldValue);

                            const rawResp = await fetch(UPLOAD_URL, {
                                method: 'POST',
                                body: formData,
                                headers: { 'X-CSRFToken': csrftoken },
                            });
                            const resp = await rawResp.json();
                            if (!resp.success) {
                                throw new Error('Asset upload error');
                            }
                            newField.values = [{ code: resp.path, value: resp.path }];
                            break;
                        }
                        case 'autocomplete': {
                            newField.values = field.values.filter(sv => fieldValue === sv.code);
                            break;
                        }
                        case 'tag': {
                            newField.values = field.values.filter(sv => fieldValue.includes(sv.code));
                            break;
                        }
                        default:
                            break;
                    }

                    return newField;
                })
            );

            generateCode({
                variables: {
                    schemaId: code.schema.id,
                    fields: newFields.map(x => omit(x, ['optional'])),
                },
            }).catch(e => {
                throw new Error(e);
            });
        } catch (e) {
            setSaveStatus('');
            setError(`Code generation failed! ${e}`);
            console.log('Exception', e);
        }
    }

    if (!newCode) {
        return null;
    }

    const validationRules = Yup.object().shape(
        Object.fromEntries(
            schema.fields.map(field => {
                let entry = Yup.string().nullable();
                if (!field.optional) {
                    entry = entry.required('Required');
                }
                return [field.guid, entry];
            })
        )
    );
    return (
        <Shelf
            open={shelfOpen}
            shelfSize="medium"
            headerText={`${translate('STATIC.PAGES.GOVERNANCE.MANAGE_CODES.SHELF.HEADER')} ${schema.name}`}
            onClose={() => {
                setCode(newCodeTemplate);
                setSaveStatus('');
                setError('');
                setNewCode(false);
            }}
            enterAnimationDisabled={false}
        >
            <WarningMessage
                type="error"
                show={!!error}
                duration={1000}
                message="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.SHELF.SAVING_FAILURE_MESSAGE"
            />
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        actions.setSubmitting(false);
                    }, 1000);
                }}
                validationSchema={validationRules}
            >
                {({ values, setValues, errors, touched, setTouched }) => {
                    const hasErrors = !!Object.keys(errors).length;

                    return (
                        <div>
                            <NewCodeForm
                                schema={schema}
                                values={values}
                                setValues={setValues}
                                errors={errors}
                                touched={touched}
                                setTouched={setTouched}
                            />

                            <WizardFooter
                                buttons={[
                                    <Button
                                        type="flat"
                                        onClick={() => {
                                            setCode(newCodeTemplate);
                                            setSaveStatus('');
                                            setError('');
                                            setNewCode(false);
                                        }}
                                    >
                                        <Translate id="STATIC.BUTTONS.CANCEL" />
                                    </Button>,
                                    <Button
                                        disabledDark={hasErrors || Object.keys(values).length === 0}
                                        onClick={() => onGenerate(values)}
                                        showSpinner={saveStatus === 'saving'}
                                        showV={saveStatus === 'saved'}
                                    >
                                        <Translate id="STATIC.BUTTONS.GENERATE" />
                                    </Button>,
                                ]}
                            />
                        </div>
                    );
                }}
            </Formik>
        </Shelf>
    );
}

CodeShelf.propTypes = {
    schema: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        pattern: PropTypes.string,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                guid: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                kind: PropTypes.oneOf(FIELD_TYPES.map(x => x.value)),
                values: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: PropTypes.string,
                        value: PropTypes.string,
                    })
                ).isRequired,
                default: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: PropTypes.string,
                        value: PropTypes.string,
                    })
                ).isRequired,
                optional: PropTypes.bool,
                hidden: PropTypes.bool,
            })
        ).isRequired,
    }).isRequired,
    newCode: PropTypes.bool.isRequired,
    setNewCode: PropTypes.func.isRequired,
    reloadCodes: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

export default withLocalize(CodeShelf);
