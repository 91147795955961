import { scaleLinear } from 'd3-scale';
import { getTextWidth, REPORTS_EVENT_PREFIX, trackMixpanelEvent } from './general';
import {
    COMPARE_CHANGE,
    COMPARE_PERIOD,
    drilldownReportTypes,
    expandMetadata,
    expandRow,
    isCompareField,
    isObject,
    reportTypes,
} from './reports';
import GridCustomFilter from '../components/widgets/GridCustomFilter';
import GridService from '../services/grid';
import css from '../components/partials/Grid.css';
import {
    ADMON_DATA_AD_REVENUE_SOURCE,
    ADMON_DATA_SOURCE_CALCULATED,
    ADMON_DATA_SOURCE_DISPLAY_NAMES,
    FACEBOOK_CENSORING_MESSAGE,
    FACEBOOK_CENSORING_MESSAGE_TRANSLATE,
    FACEBOOK_DISPLAY_NAME,
    INDICATION_FIELDS_PREFIX,
    TRANSPARENCY_COLUMN_LIST,
} from './reportsConsts';
import { METRIC_COHORT_SEPARATOR } from '../customDashboards/utils';
import reports from '../selectors/reportsConfig/reports';
import anonymous from '../selectors/reportsConfig/anonymous';
import { COMPARE_MODE } from '../components/pages/reports/consts';
import { BUDGET_PROGRESS_FORECAST } from './consts';

const gridService = new GridService();
export const comparisonColumns = Object.values(COMPARE_PERIOD);
const goalColumns = ['adn_cost', 'goal_completion', 'goal_budget', 'run_rate', 'goal', BUDGET_PROGRESS_FORECAST];
const DATE_FIELDS = ['date_field', 'estimated_install_date', 'skan_postback_date'];
export const COHORT_METRIC_TYPE = 'cohort_metric';
export const ACTUAL_PERIOD = '__actual';

const PRESET_SORT_ORDER = DATE_FIELDS.concat([
    'custom_cost',
    'adn_cost',
    'est_fraud_saved_cost',
    'roi_ltv',
    'roi_30d',
    'roi_14d',
    'roi_7d',
    'roi_1d',
    'tracker_installs',
    'revenue_ltv',
    'revenue_30d',
    'revenue_14d',
    'revenue_7d',
    'revenue_1d',
    'custom_installs',
    'adn_installs',
    'custom_clicks',
    'tracker_clicks',
    'adn_clicks',
]);

const FB_IMPRESSIONS_LIMIT = 1000;
const IMPRESSIONS_FIELDS = ['custom_impressions', 'adn_impressions', 'tracker_impressions'];
const FB_CENSORED_REPORT_TYPES = [reports.type, anonymous.type, reportTypes.pivot];

const gridFontStr = '400 13px open-sans';

const HEAT_MAP_COLOR_MIN = 'rgb(250, 250, 255)';
const HEAT_MAP_COLOR_MAX = 'rgb(80, 135, 190)';

const emptyValue = value => [null, undefined, 'N/A'].includes(value);

const calculatedMetricValue = customMetricObj => {
    if (
        customMetricObj === undefined ||
        customMetricObj === null ||
        customMetricObj === 'N/A' ||
        typeof customMetricObj !== 'object' ||
        !customMetricObj.fields
    ) {
        return customMetricObj;
    }

    const fields = customMetricObj.fields.slice();
    let firstValue = fields.shift()[1];
    if (typeof firstValue === 'object') {
        firstValue = calculatedMetricValue(firstValue);
    }

    return fields.reduce((previousValue, currentField) => {
        let currentValue = currentField[1];
        if (typeof currentValue === 'object') {
            currentValue = calculatedMetricValue(currentValue);
        }

        switch (customMetricObj.operator) {
            case 'divide':
                // eslint-disable-next-line no-case-declarations
                const divisorValue = currentValue;
                if (divisorValue === null || divisorValue === undefined || divisorValue === 0) {
                    return null;
                }
                return previousValue / divisorValue;

            case 'subtract':
                return previousValue - currentValue;

            case 'add':
                return previousValue + currentValue;

            case 'multiply':
                return previousValue * currentValue;

            default:
                console.warn(`calculatedMetricValue: operator ${customMetricObj.operator} is not implemented!`);
                return null;
        }
    }, firstValue);
};

const strMax = (a, b) => (a.length > b.length ? a : b);

const getColumnsWidths = (reportType, reportMetadata, rowData) => {
    const totalValues = rowData.reduce((total, current) => {
        Object.keys(current).forEach(key => {
            // If this column isn't defined in the metadata, skip
            if (!reportMetadata[key]) return;

            let val = current[key];

            // isObject is needed because val can be the header
            if (reportMetadata[key].metric_as_object && isObject(val)) {
                val = calculatedMetricValue(val);
            }

            if (typeof val === 'number') {
                val = parseFloat(val.toFixed(2)).toString();
            }

            total[key] = strMax(total[key] || '', `${val}`);
        });
        return total;
    }, {});

    const isDrilldownReport = drilldownReportTypes.includes(reportType);

    Object.keys(totalValues).forEach(key => {
        totalValues[key] = getTextWidth(totalValues[key], gridFontStr);

        totalValues[key] += 20 + 50; // +20 for the padding of 10 from each side, +50 for the header icons

        if (isDrilldownReport && TRANSPARENCY_COLUMN_LIST.indexOf(key) >= 0) {
            totalValues[key] += 35; // add extra width for columns with drilldown data
        }

        totalValues[key] = Math.min(totalValues[key], 800);
    });

    return totalValues;
};

const figureSortKey = columns => {
    // if the colID is in the predefined sort order -> give it priority. otherwise - prioritize by the id
    const colIds = columns
        .filter(c => c.type === 'metric' || DATE_FIELDS.includes(c.name))
        .sort((c1, c2) => {
            if (c1.sorting_id && !c2.sorting_id) {
                return 1;
            }
            if (c2.sorting_id && !c1.sorting_id) {
                return -1;
            }
            if (c1.sorting_id && c2.sorting_id) {
                return c1.sorting_id - c2.sorting_id;
            }
            return c1.id - c2.id;
        })
        .map(c => c.name);

    return PRESET_SORT_ORDER.find(name => colIds.includes(name)) || colIds[0];
};

const formatCell = (value, displayFormat) => {
    let cellValue = value;

    if (cellValue === undefined || cellValue === null) {
        cellValue = 'N/A';
    }

    if (displayFormat && cellValue !== 'N/A' && cellValue !== '') {
        switch (displayFormat.type) {
            case 'number':
                return cellValue.toLocaleString(undefined, {
                    minimumFractionDigits: displayFormat.precision,
                    maximumFractionDigits: displayFormat.precision,
                });
            case 'currency':
                return `${cellValue < 0 ? '-' : ''}${displayFormat.symbol}${Math.abs(cellValue).toLocaleString(
                    undefined,
                    {
                        minimumFractionDigits: displayFormat.precision,
                        maximumFractionDigits: displayFormat.precision,
                    }
                )}`;
            case 'percentage':
                return `${(cellValue * 100).toFixed(displayFormat.precision)}%`;
            default:
                return cellValue;
        }
    } else {
        return cellValue;
    }
};

export const getColConfidenceIntervalName = colDef => {
    const { colId, confidenceIntervalMetricName } = colDef;

    if (
        confidenceIntervalMetricName &&
        !confidenceIntervalMetricName.includes('cpe') &&
        !confidenceIntervalMetricName.includes('ecvr')
    ) {
        const cohortParts = colId.split('__');
        const cohortPeriod = cohortParts.length > 1 ? cohortParts[cohortParts.length - 1] : null;
        return cohortPeriod ? `${confidenceIntervalMetricName}__${cohortPeriod}` : confidenceIntervalMetricName;
    }

    return null;
};

const isGoalField = field => goalColumns.includes(field.field);
const hasStrikeOut = params => {
    if (!params.node.level || !params.node.parent || !params.node.parent.data || !params.node.parent.data.sources) {
        return false;
    }
    const columnSource = params.node.parent.data.sources.column_source[params.colDef.field];
    return columnSource && columnSource.source !== params.data.type;
};

const sumAggFunc = values => {
    // Nothing to aggregate
    if (values.length === 1) return values[0];

    let result = 0;
    values.forEach(value => {
        if (typeof value === 'number') {
            result += value;
        }
    });
    return result;
};

const calculatedMetricSumAggFunc = values => {
    // Nothing to aggregate
    if (values.length === 0) return null;

    // calc value for a single cell
    if (values.length === 1) return values[0];

    // Filter N/A
    const nonNaValues = values.filter(x => x !== null && x !== undefined && x !== 'N/A');
    if (nonNaValues.length === 0) return 'N/A';
    // calc value for a grouped rows
    const aggFields = nonNaValues
        .map(x => x.fields)
        .reduce((agg, fields) => {
            fields.forEach(([fieldName, fieldValue]) => {
                const fieldIdx = agg.findIndex(x => x[0] === fieldName);
                const calculatedValue = calculatedMetricValue(fieldValue);

                if (fieldIdx === -1) {
                    agg.push([fieldName, calculatedValue]);
                } else if (fieldValue) {
                    agg[fieldIdx][1] += calculatedValue;
                }
            });

            return agg;
        }, []);

    return {
        operator: nonNaValues[0].operator,
        fields: aggFields,
    };
};

// This is the column definition for "auto group" columns, that is, columns that represent pivot row-groups
const autoGroupColumnDef = {
    suppressMovable: true,
    suppressMenu: true,
    headerClass() {
        return [css.dimensionCell];
    },
    cellClass(params) {
        return [
            css.dimensionCell,
            params.value === null || params.value === undefined || params.value === 'N/A' ? css.mutedText : null,
        ];
    },
};

export const isSupportedApp = ({ data }, supportedAppNames) => {
    const appName = data?.app;

    if (!supportedAppNames || !appName || appName === 'N/A') return true;

    return supportedAppNames.includes(appName);
};

export function isFacebookCensored(rowData) {
    if (rowData?.source !== FACEBOOK_DISPLAY_NAME) {
        return false;
    }

    const impressionsConditions = IMPRESSIONS_FIELDS.map(
        field => Number.isInteger(rowData[field]) && rowData[field] < FB_IMPRESSIONS_LIMIT
    );
    return impressionsConditions.some(condition => condition);
}

const isCensoredFacebookRecord = (recordData, leafChildren) => {
    const dataList = recordData
        ? [recordData]
        : leafChildren
        ? leafChildren.filter(({ data }) => data).map(({ data }) => data)
        : [];

    return dataList.some(rowData => rowData && (!!rowData.parent || isFacebookCensored(rowData)));
};

export const shouldRowBeSkipped = row => {
    const { data: nodeData, allLeafChildren } = row?.node || {};
    return isCensoredFacebookRecord(nodeData, allLeafChildren);
};

export const isFacebookCensoredByParams = params => {
    const { data: paramsData, node } = params || {};
    const { allLeafChildren } = node || {};

    return isCensoredFacebookRecord(paramsData, allLeafChildren);
};

const setFacebookDataCensoringInColumnDefs = (columnDefs, reportType) => {
    // This method sets all the requirements for censoring FB's data by the ag-grid API
    const columnDefsFiltered = columnDefs.filter(col => col.sorting_id >= 0);
    const firstMetricIndex = columnDefsFiltered.findIndex(col => col.fieldType === 'metric');
    const metricsCount = columnDefsFiltered.length - firstMetricIndex;
    const firstMetric = columnDefsFiltered[firstMetricIndex];

    // set span
    firstMetric.colSpan = params => (isFacebookCensoredByParams(params) ? metricsCount : 1);

    // set value instead the original value
    const initialValueFormatter = firstMetric.valueFormatter;

    // For pivot reports there are no custom cells so only text can be set there (and not HTML).
    const facebookCensoringMessage =
        reportType === reportTypes.pivot ? FACEBOOK_CENSORING_MESSAGE : FACEBOOK_CENSORING_MESSAGE_TRANSLATE;

    firstMetric.valueFormatter = params =>
        isFacebookCensoredByParams(params) ? facebookCensoringMessage : initialValueFormatter(params);

    // set style
    const fbRules = {
        [css.facebookCensored]: params => {
            return isFacebookCensoredByParams(params);
        },
    };

    firstMetric.cellClassRules = {
        ...firstMetric.cellClassRules,
        ...fbRules,
    };

    // set the columns to be non-movable
    columnDefs.forEach(col => {
        col.suppressMovable = true;
    });
};

const simpleValueComparator = (a, b) => {
    if (a === b) return 0;
    if (a === 'N/A') return -1;
    if (b === 'N/A') return 1;

    return a < b ? -1 : +1;
};

const customMetricValueComparator = (a, b) => {
    return simpleValueComparator(calculatedMetricValue(a), calculatedMetricValue(b));
};

const buildCompareValueKey = compareValue => {
    return `__${compareValue}`;
};

// Value getter is how the grid gets data for columns. Change columns don't really have any data for it from the API.
// The value we return is a 2-item-array: [period1_value, period2_value]
// Storing it like that, instead of a number, is needed so the grid can perform aggregations when pivoting
function changeColumnValueGetter(row, apiColumnMetadata) {
    if (!row) {
        return null;
    }

    const isPercentField = apiColumnMetadata.name.includes(buildCompareValueKey(COMPARE_CHANGE.CHANGE));
    const replaceKey = isPercentField
        ? buildCompareValueKey(COMPARE_CHANGE.CHANGE)
        : buildCompareValueKey(COMPARE_CHANGE.ABS_CHANGE);

    const period1Field = apiColumnMetadata.name.replace(replaceKey, buildCompareValueKey(COMPARE_PERIOD.PERIOD_1));
    const period2Field = apiColumnMetadata.name.replace(replaceKey, buildCompareValueKey(COMPARE_PERIOD.PERIOD_2));

    return [row[period1Field], row[period2Field]];
}

// Aggregations are done by the grid, in pivot mode, to enable collapsing/nesting
// If this column is a standard numeric column, we use the sumAggFunc (that simply sums the value)
// If this column is a calculated metric, we aggregate the numerator and denominator parts of the calculated
// metrics (see calculatedMetricSumAggFunc)
// The value is still stored as a 2-item-array: [aggregated_period1_value, aggregated_period2_value]
function changeColumnAggregator(values, apiColumnMetadata) {
    const aggFunc = apiColumnMetadata.metric_as_object ? calculatedMetricSumAggFunc : sumAggFunc;
    return [aggFunc(values.map(x => x[0])), aggFunc(values.map(x => x[1]))];
}

// For change columns, the grid value is the 2-item-array. The only point in which we calculate the change,
// is when we're displaying the value. We calculate either absolute change (period1 - period2) or percent change
// (period1/period2 - 1). Keeping the value as a 2-item-array up until this point helps us perform aggregations.
//
// While regular numeric metrics like revenue didn't need the 2-item-array, calculated metrics like CVR do.
// If we need to calculate the CVR of Period1 vs CVR of Period 2, in some level of aggregation, we first aggregate
// the CVR of Period 1, and then aggregate the CVR of Period 2, and only then subtract.
// Think of cvr_period1 - cvr_period2 - the value has to stay as a 2-item-array while we're aggregating the CVR
export function changeColumnCalculateValue(value, apiColumnMetadata) {
    if (!value) {
        return null;
    }

    const isPercentField = apiColumnMetadata.name.includes(buildCompareValueKey(COMPARE_CHANGE.CHANGE));
    let [period1Value, period2Value] = value;

    if (apiColumnMetadata.metric_as_object) {
        period1Value = calculatedMetricValue(period1Value);
        period2Value = calculatedMetricValue(period2Value);
    }

    if (emptyValue(period1Value) && emptyValue(period2Value)) {
        return null;
    }

    if (isPercentField) {
        if (emptyValue(period2Value) || period2Value === 0) {
            return null;
        }

        return (emptyValue(period1Value) ? 0 : period1Value) / period2Value - 1;
    }

    return (emptyValue(period1Value) ? 0 : period1Value) - (emptyValue(period2Value) ? 0 : period2Value);
}

// This part is in charge of setting the right CSS class for the cell, to color it red/green, and show the up/down
// arrows, depending on the value and the metadata
function changeColumnCellClass(params, apiColumnMetadata) {
    if (!params.value) return [];

    const classes = [css.pullRight];
    const changeValue = changeColumnCalculateValue(params.value, apiColumnMetadata);

    if (params.value) {
        if (changeValue > 0) {
            classes.push(css.increase);
            if (apiColumnMetadata.increases_are_good === null) {
                classes.push(css.unknown);
            } else {
                classes.push(apiColumnMetadata.increases_are_good ? css.positive : css.negative);
            }
        }
        if (changeValue < 0) {
            classes.push(css.decrease);
            if (apiColumnMetadata.increases_are_good === null) {
                classes.push(css.unknown);
            } else {
                classes.push(apiColumnMetadata.increases_are_good ? css.negative : css.positive);
            }
        }
    }

    return classes;
}

const createGridColDef = (
    reportType,
    compare,
    groupId,
    sortKey,
    apiColumnMetadata,
    columnOrder,
    postProcessFiltersUI,
    showConfidenceInterval,
    dataTypeName,
    reportConfig
) => {
    const {
        id,
        isChange,
        type,
        name,
        display_name: displayName,
        display_format: displayFormat,
        sub_type: subType,
        increases_are_good: increasesAreGood,
        metric_as_object: metricAsObject,
        sorting_id: colSortingId,
        confidence_interval_metric_name: confidenceIntervalMetricName,
        unified_report_type: unifiedReportType,
    } = apiColumnMetadata;

    const cellClassRules = {
        [css['striked-text']]: params => hasStrikeOut(params),
    };

    // NOTE: KILL THIS SHIT
    let colCellClassRules = {};

    if (name === BUDGET_PROGRESS_FORECAST) {
        colCellClassRules = {
            [css.positive]: params => {
                return params.value <= 1.1 && params.value >= 0.9;
            },
            [css.negative]: params => {
                return !(params.value <= 1.1 && params.value >= 0.9);
            },
        };
    }

    function valueFormatter(value) {
        return formatCell(value && value.value !== undefined ? value.value : value, displayFormat);
    }

    const isDimension = type === 'dimension';

    const columnDef = {
        id,
        sorting_id: colSortingId,
        headerName: displayName,
        headerTooltip: displayName,
        confidenceIntervalMetricName: showConfidenceInterval ? confidenceIntervalMetricName : undefined,
        field: name,
        colId: name,
        minWidth: 50,
        isChange,
        increasesAreGood,
        fieldType: type,
        fieldGroup: groupId,
        type,
        hide: false,
        enableRowGroup: isDimension,
        enablePivot: isDimension,
        enableValue: !isDimension,
        toolPanelClass: `sidebar-column-${type}`,
        displayFormat,
        menuTabs: ['generalMenuTab'],
        unSortIcon: true,
        cellClassRules: {
            ...cellClassRules,
            ...colCellClassRules,
        },
        comparator: metricAsObject ? customMetricValueComparator : simpleValueComparator,
        valueFormatter: params => valueFormatter(params.value),
        isSkanCohort:
            dataTypeName === 'skan' &&
            !isDimension &&
            (name.includes(METRIC_COHORT_SEPARATOR) || subType === COHORT_METRIC_TYPE),
        sortingKey: reportConfig?.getSortingKey?.(apiColumnMetadata),
        unifiedReportType,
    };

    if (reportType !== 'pivot') {
        columnDef.floatingFilterComponentFramework = GridCustomFilter;
        columnDef.floatingFilterComponentParams = { suppressFilterButton: true };
        columnDef.filter = isDimension ? 'agTextColumnFilter' : 'agNumberColumnFilter';
        columnDef.defaultFilterValue = (postProcessFiltersUI[name] || {}).filter;
    } else {
        columnDef.filter = isDimension ? 'agSetColumnFilter' : 'agNumberColumnFilter';
    }

    // dimension
    if (isDimension) {
        columnDef.cellClass = params => [
            css.dimensionCell,
            params.value === null || params.value === undefined || params.value === 'N/A' ? css.mutedText : null,
        ];

        columnDef.headerClass = () => [css.dimensionCell];
    }

    // Client-side comparison fields
    else if (isChange) {
        // Absolute change is calculating period1 - period2
        if (isCompareField(name)) {
            columnDef.valueGetter = params => changeColumnValueGetter(params.data, apiColumnMetadata);
            columnDef.aggFunc = values => changeColumnAggregator(values, apiColumnMetadata);
            columnDef.valueFormatter = params =>
                formatCell(changeColumnCalculateValue(params.value, apiColumnMetadata), displayFormat);
            columnDef.cellClass = params => changeColumnCellClass(params, apiColumnMetadata);
            columnDef.comparator = (valueA, valueB) => {
                const func = metricAsObject ? customMetricValueComparator : simpleValueComparator;
                return func(
                    changeColumnCalculateValue(valueA, apiColumnMetadata),
                    changeColumnCalculateValue(valueB, apiColumnMetadata)
                );
            };
        }
    }

    // Calculated metrics (A / B, or A -  B)
    else if (metricAsObject) {
        columnDef.aggFunc = calculatedMetricSumAggFunc;
        columnDef.valueFormatter = params => valueFormatter(calculatedMetricValue(params.value));
        columnDef.filterValueGetter = params => {
            let value = calculatedMetricValue(params.getValue(params.colDef.field));
            const { displayFormat: colDisplayFormat } = params.colDef;

            if (colDisplayFormat.type) {
                value = (value * 100).toFixed(colDisplayFormat.precision);
            }

            return value;
        };
        columnDef.cellClass = params => [css.pullRight, calculatedMetricValue(params.value) ? null : css.mutedText];
        columnDef.headerClass = () => [css[reportConfig?.colDefClassRule?.(apiColumnMetadata)]];
    }

    // Regular metrics
    else {
        columnDef.aggFunc = sumAggFunc;

        columnDef.cellClass = params => [
            css.pullRight,
            params.value === null || params.value === undefined || params.value === 'N/A' ? css.mutedText : null,
            // alternating colors per group in compare mode
            compare ? css[`group${params.colDef.fieldGroup % 2}`] : null,
        ];

        columnDef.headerClass = params => [
            css[reportConfig?.colDefClassRule?.(apiColumnMetadata)],
            compare ? css[`group${params.colDef.fieldGroup % 2}`] : null,
        ];
    }

    if (columnDef.field === sortKey) {
        columnDef.sort = 'desc';
    }

    return columnDef;
};

const mkGridColDefCmp = mode => {
    return (a, b) => {
        if (a.sortingKey !== b.sortingKey && b.sortingKey !== undefined) {
            // sorting key can be set in report config, see unifiedReport config for example
            return a.sortingKey - b.sortingKey;
        }
        if (a.sorting_id === b.sorting_id) {
            // if not configured org_option with sorting_id for both fields
            if (a.id === b.id) {
                // handle comparison case where 2 fields have the same id.
                const aSuffix = a.field.slice(a.field.lastIndexOf('_') + 1);
                const bSuffix = b.field.slice(b.field.lastIndexOf('_') + 1);
                return comparisonColumns.indexOf(aSuffix) - comparisonColumns.indexOf(bSuffix);
            }
            if (!(mode === 'goal') || a.fieldType !== b.fieldType) {
                return a.id - b.id;
            } else {
                // handle case where not in goals mode, or 2 metrics are with the same type.
                const aIsGoal = isGoalField(a);
                const bIsGoal = isGoalField(b);

                if (aIsGoal && !bIsGoal) {
                    return -1;
                }

                if (!aIsGoal && bIsGoal) {
                    return 1;
                }

                return a.id - b.id;
            }
        } else if (a.sorting_id === null || a.sorting_id === undefined) {
            return 1;
        } else if (b.sorting_id === null || b.sorting_id === undefined) {
            return -1;
        } else {
            return a.sorting_id - b.sorting_id;
        }
    };
};

// Get report columns (fields) and add them a sorting_id property by the fields index in the columnOrder list.
function getColumnsList(tableMetadata, columnOrder) {
    return Object.values(tableMetadata).map(col => {
        // Attempt to find the current column in the columnOrder array.
        // In certain cases:
        // - For some custom metrics, the ACTUAL_PERIOD suffix might be missing from the column name but present in columnOrder.
        // - Cohort suffixes are attached using a single underscore instead of two underscores (e.g., total_revenue_ltv) - https://singular.zendesk.com/agent/tickets/118342
        // We handle these cases by falling back to undefined instead of -1 as the sorting_id.
        const { sorting_id: defaultSortingId } = col;

        let index = columnOrder.findIndex(name => name === col.name);
        if (index === -1) {
            index = columnOrder.findIndex(name => name === col.name.concat(ACTUAL_PERIOD));
        }

        const sortingId = index !== -1 ? index : undefined;
        return { ...col, sorting_id: sortingId, defaultSortingId };
    });
}

// In case of a new field that is not in the columnOrder list was added,
// calculate the relevant sorting_id to place it in the report table after the original field
// it should be regardless the columnOrder list.
function calculateColumnsSorting(tableMetaDataValues) {
    const defaultSortingIds = tableMetaDataValues.reduce((acc, col) => {
        acc[col.id] = col.defaultSortingId;
        return acc;
    });

    const colIdToSortingId = tableMetaDataValues.reduce((acc, col) => {
        acc[col.id] = col.sorting_id;
        return acc;
    }, {});

    // Building a list of sorted columns by their original (default) sorting id or column id.
    const sortedColIds = Object.keys(colIdToSortingId)
        .map(id => parseInt(id, 10))
        .sort((a, b) => {
            const aSortingId = defaultSortingIds[a.toString()] || a;
            const bSortingId = defaultSortingIds[b.toString()] || b;

            return aSortingId - bSortingId;
        });

    let isChangedSortingId = false;

    // Iterating all columns in their original order and setting the sorting_id for the new columns.
    // There are two cases to handle:
    // 1. The new column is first in the original order of columns.
    // 2. The new column is not first in the original order of columns.
    // In case the new column is first - Get the sorting_id from the next column in the original order,
    // and set it to be minus 1 - after, decrease 1 from all sorting_ids that are lower / equal to the new sorting_id.
    // In case the new column is not first - Get the sorting_id from the previous column in the original order,
    // and set it to be plus 1 - after, increase 1 from all sorting_ids that are higher / equal to the new sorting_id.
    for (let i = 0; i < sortedColIds.length; i++) {
        const colId = sortedColIds[i];
        const sortingId = colIdToSortingId[colId];

        if (sortingId === undefined) {
            isChangedSortingId = true;

            if (i === 0) {
                const nextColId = sortedColIds[i + 1];
                const previousColSortingId = colIdToSortingId[nextColId] - 1;
                colIdToSortingId[colId] = previousColSortingId;

                sortedColIds.forEach(changedColId => {
                    if (changedColId === colId) {
                        return;
                    }

                    const changedSortingId = colIdToSortingId[changedColId];

                    if (changedSortingId <= previousColSortingId) {
                        colIdToSortingId[changedColId] = previousColSortingId - 1;
                    }
                });
            } else {
                const previousColId = sortedColIds[i - 1];
                const nextColSortingId = colIdToSortingId[previousColId] + 1;
                colIdToSortingId[colId] = nextColSortingId;

                sortedColIds.forEach(changedColId => {
                    if (changedColId === colId) {
                        return;
                    }

                    const changedSortingId = colIdToSortingId[changedColId];

                    if (changedSortingId >= nextColSortingId) {
                        colIdToSortingId[changedColId] = nextColSortingId + 1;
                    }
                });
            }
        }
    }

    return isChangedSortingId
        ? tableMetaDataValues.map(col => {
              return { ...col, sorting_id: colIdToSortingId[col.id] };
          })
        : tableMetaDataValues;
}

const reportDataToTableData = (
    reportType,
    reportData,
    mode,
    shouldDrilldown,
    filteredTotal,
    showDimensionDrilldownIcon,
    columnOrder = [],
    postProcessFiltersUI = {},
    showConfidenceInterval = false,
    dataTypeName = null,
    reportConfig
) => {
    const isCompare = mode === COMPARE_MODE;

    // Metadata describes the report fields. The expandMetadata expands the metadata for columns that have cohorts
    // (e.g. revenue__1d, revenue__7d) and also for comparison (adn_cost__period1, adn_cost__period2, etc...).
    // This metadata will directly turn into table grid columns
    const tableMetadata = expandMetadata(reportData.metadata, isCompare);

    // Due to FB's requirement's we need to censor some data, and we are doing it in grid configuration
    // So we need to know if data is censored, ahead of the columns settings.
    let shouldCensorFacebookData = false;

    const rowData = (reportData.results || []).map(row => {
        if (!shouldCensorFacebookData && isFacebookCensored(row)) {
            shouldCensorFacebookData = true;
            trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'facebook censored message viewed', {
                report_id: reportData.report_id,
            });
        }
        // We expand the results. In most API endpoints, we get the cohorted values nested in a JSON.
        // This code flattens it from {revenue: {14d: 1000}} to {revenue__14d: 1000}
        return expandRow(tableMetadata, row);
    });

    const totalsData = expandRow(
        tableMetadata,
        filteredTotal.status !== null ? filteredTotal.data || [] : reportData.total || {}
    );

    let columns = null;

    if (columnOrder.length) {
        columns = getColumnsList(tableMetadata, columnOrder);
        const shouldCalculateSorting = columns.length > 1 && columns.length !== columnOrder.length;
        columns = shouldCalculateSorting ? calculateColumnsSorting(columns) : columns;
    } else {
        columns = Object.values(tableMetadata);
    }

    let groupId = 1;
    const sortKey = figureSortKey(columns);
    const columnDefs = columns
        .map((column, index) => {
            if (index > 0 && column.type !== 'dimension' && column.baseName !== columns[index - 1].baseName) {
                groupId++;
            }
            return createGridColDef(
                reportType,
                isCompare,
                groupId,
                sortKey,
                column,
                columnOrder,
                postProcessFiltersUI,
                showConfidenceInterval,
                dataTypeName,
                reportConfig
            );
        })
        .sort(mkGridColDefCmp(mode))
        .filter(column => {
            return !column.colId || !column.colId.includes(INDICATION_FIELDS_PREFIX);
        });

    if (reportType !== reportTypes.pivot) {
        // set totals column on the first column after sorting
        const firstColumn = columnOrder.length > 0 ? columnDefs.find(x => x.field === columnOrder[0]) : null;

        if (firstColumn) {
            firstColumn.pinnedRowCellRenderer = () => `<span class="${css.totalsText}">Totals</span>`;
        }

        if (showDimensionDrilldownIcon) {
            const indexOfDrilldownIcon = columnDefs.findIndex((col, i) => {
                return (
                    col.fieldType === 'dimension' &&
                    i < columnDefs.length - 1 &&
                    columnDefs[i + 1].fieldType === 'metric'
                );
            });

            columnDefs[Math.max(indexOfDrilldownIcon, 0)].showDimensionDrilldown = true;
        }
    }

    if (FB_CENSORED_REPORT_TYPES.includes(reportType) && shouldCensorFacebookData) {
        setFacebookDataCensoringInColumnDefs(columnDefs, reportType);
    }

    // Build the header row which is simply a dict from a field name to a field display name
    const headerRowData = columnDefs.map(d => ({ [d.field]: d.headerName })).reduce((a, b) => ({ ...a, ...b }), {});

    const colWidthMapping = getColumnsWidths(reportType, tableMetadata, [...rowData, headerRowData]);

    columnDefs.forEach(col => {
        col.width = colWidthMapping[col.colId] || col.width;
    });

    return {
        columnDefs,
        rowData,
        totalsData,
        columnTypes: {
            dimension: {
                pinnedRowCellRenderer: () => '',
            },
            metric: {
                pinnedRowCellRenderer: reportType === 'pivot' ? () => '' : 'totalsCell',
            },
        },
        sortKey,
    };
};

const formatColValue = colValue => (typeof colValue === 'number' ? colValue : 0);

export const getHeatMapColumnDefs = (columnDefs, heatMapOptions) => {
    const { heatColumn, isHeatColumnCustomMetric } = heatMapOptions;

    const cellStyle = params => {
        const currColumnValue = isHeatColumnCustomMetric
            ? calculatedMetricValue(params.data[params.column.colId])
            : params.data[params.column.colId];
        const percentageValue = formatColValue(calculatedMetricValue(currColumnValue));
        const color = scaleLinear()
            .domain([0, 100])
            .range([HEAT_MAP_COLOR_MIN, HEAT_MAP_COLOR_MAX])(percentageValue * 100);
        return { backgroundColor: `${color}!important` };
    };

    return columnDefs.map(column => {
        if (column.colId.includes(heatColumn)) {
            return { ...column, cellStyle };
        } else {
            return column;
        }
    });
};

const getTableDataWithTransparency = (tableData, transparencyData, reportType) => {
    if (transparencyData.error) {
        return tableData;
    }
    const drilldownData = transparencyData;
    const reportDimensionsNames = tableData.columnDefs
        .filter(column => column.type === 'dimension')
        .map(column => column.field);
    // let transparencySources = [];
    const tableRowData = tableData.rowData;

    tableRowData.forEach(row => {
        const dimensionsValues = [];
        reportDimensionsNames.forEach(dimensionName => dimensionsValues.push(row[dimensionName]));
        const rowDrilldownSources = drilldownData[dimensionsValues.join('::')];
        row.sources = rowDrilldownSources;
        if (row.sources && row.sources.is_missing_data_source) {
            const missingSourceObject = {
                type: 'adnetwork',
                username: 'Add as source',
                source_status: this.source_statuses.missing,
                source: {
                    value: row.source,
                    message: 'The source is missing',
                },
            };

            row.sources.uans.push(missingSourceObject);
        }
    });

    const api = gridService.gridApi(`report_${reportType}`);
    if (api) {
        api.setRowData(tableRowData);
    }
    return tableData;
};

const getGridFilteredData = (reportType = 'reports') => {
    if (gridService && gridService.gridMap[`report_${reportType}`]) {
        const api = gridService.gridApi(`report_${reportType}`);
        return api.rowModel.rowsToDisplay.filter(row => row.level === 0).map(row => row.data);
    }
    return null;
};

export const parseAdmonDataSourcDisplayName = admonDataSource => {
    const admonDataSourceDisplayName = ADMON_DATA_SOURCE_DISPLAY_NAMES[admonDataSource] || admonDataSource;

    return admonDataSourceDisplayName
        .split('_')
        .map(str => str.charAt(0).toUpperCase() + str.slice(1))
        .join(' ');
};

export const getAdmonDataSources = (admonDataSourcesUanConfig, adRevenueFieldName) => {
    let admonDataSources = [];
    const admonSourcesRawData = admonDataSourcesUanConfig[adRevenueFieldName]
        ? admonDataSourcesUanConfig[adRevenueFieldName].value
        : '';
    const admonSourcesRawDataLength = admonSourcesRawData && admonSourcesRawData.length;

    if (admonSourcesRawDataLength > 0) {
        admonDataSources = admonSourcesRawData
            .substring(0, admonSourcesRawDataLength - 1) // remove last ';'
            .split(';')
            .map(admonSourceRawData => {
                const admonSourceRawDataSplitted = admonSourceRawData.split(':');
                const admonDataSource = admonSourceRawDataSplitted[0];
                const adRevenue = admonSourceRawDataSplitted[1];

                return { admonDataSource: parseAdmonDataSourcDisplayName(admonDataSource), adRevenue };
            });
    }

    return admonDataSources;
};

export const getAdmonDataSourceType = admonDataSource => {
    if (admonDataSource.toLowerCase() === ADMON_DATA_SOURCE_CALCULATED) {
        return ADMON_DATA_SOURCE_CALCULATED;
    } else {
        return ADMON_DATA_AD_REVENUE_SOURCE;
    }
};

export const getAdmonSourceContentText = admonDataSources => {
    if (
        admonDataSources.length === 1 &&
        admonDataSources[0].admonDataSource.toLowerCase() === ADMON_DATA_SOURCE_CALCULATED
    ) {
        return 'STATIC.PAGES.REPORTS.AD_REVENUE_DRILLDOWN_CALCULATED_LABEL';
    } else if (admonDataSources.length > 1) {
        return 'STATIC.PAGES.REPORTS.AD_REVENUE_DRILLDOWN_MIXED_LABEL';
    } else {
        return 'STATIC.PAGES.REPORTS.AD_REVENUE_DRILLDOWN_SINGLE_SOURCE_LABEL';
    }
};

export {
    reportDataToTableData,
    autoGroupColumnDef,
    getTableDataWithTransparency,
    getGridFilteredData,
    formatCell,
    calculatedMetricValue,
    sumAggFunc,
    calculatedMetricSumAggFunc,
    simpleValueComparator,
    customMetricValueComparator,
    figureSortKey,
};
