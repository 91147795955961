import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { Button } from '../../../components/widgets';
import PageHeader from '../PageHeader';
import baseCss from './BasePartnerAppSitesSummary.css';
import partnerCss from './UserConfigurationAppSitesSummary.css';
import SettingsIcon from '../../../resources/svg/settings.svg';
import AppSitesTable from '../AppSitesTable';
import { OPEN_USER_CONFIGURATION_MODAL } from '../../consts';
import { partnerShape } from '../types';
import AddAppSiteButton from '../AddAppSiteButton';

const css = { ...baseCss, ...partnerCss };

export const USER_CONFIGURATION_PARTNER_NAMES = [
    'twitter',
    'tencent',
    'google-ads-offline-conversions-api-s2s',
    'snap-conversions-api-s2s',
    'microsoft-ads-conversions-api-s2s',
    'google-ads-web',
];

export default function UserConfigurationAppSitesSummary({ partner, filter, onAppSiteForPartner, onRouteToOldPage }) {
    const onUsersConfigurationClick = () => onRouteToOldPage({ [OPEN_USER_CONFIGURATION_MODAL]: true });

    return (
        <div className={css.appSitesSummary}>
            <PageHeader partner={partner} />
            <div className={css.actions}>
                <AddAppSiteButton partner={partner} onAddAppSite={onAppSiteForPartner} />
                <Button
                    type="secondary"
                    className={css.userConfiguration}
                    Icon={SettingsIcon}
                    iconClass={css.userConfigurationIcon}
                    onClick={onUsersConfigurationClick}
                >
                    <Translate id="STATIC.PAGES.PARTNER_CONFIGURATION.USER_CONFIGURATION" />
                </Button>
            </div>
            <AppSitesTable
                filter={filter}
                partner={partner}
                onEditAppSite={onAppSiteForPartner}
                onDuplicateAppSite={(...args) => onAppSiteForPartner(...args, true)}
            />
        </div>
    );
}

UserConfigurationAppSitesSummary.propTypes = {
    partner: partnerShape.isRequired,
    filter: PropTypes.string,
    onAppSiteForPartner: PropTypes.func,
    onRouteToOldPage: PropTypes.func,
};

UserConfigurationAppSitesSummary.defaultProps = {
    filter: '',
    onAppSiteForPartner: () => {},
    onRouteToOldPage: () => {},
};
