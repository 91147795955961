import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import { ErrorBoundary } from 'react-error-boundary';
import Grid from '../components/partials/Grid';
import {
    COMPARE_SEPARATOR,
    extractUnifiedType,
    FIELD_NAME_SEPARATOR,
    getCohortPeriod,
    isTimeDimension,
    METRIC_COHORT_SEPARATOR,
    METRIC_COHORT_SINGLE_SEPARATOR,
    renameTimebreakdownDimension,
    shouldUseDataType,
    WIDGET_CHANGE_TYPES,
} from './utils';
import { useFetchDataTypesToMetricName, useFetchTableData } from './hooks';
import { ACTUAL_PERIOD, calculatedMetricValue, comparisonColumns, shouldRowBeSkipped } from '../utils/grids';
import { getExportWidgetFileName } from '../utils/export';
import { isCompareField, isObject } from '../utils/reports';
import ChartWidgetWrapper from '../components/partials/ChartWidgetWrapper';
import { ACTION_TYPES } from '../components/partials/ChartWidgetWrapper/consts';
import { DataTypes } from './widgetDataTypes';

import { UNIFIED_REPORT_TYPES_TO_DISPLAY_NAME } from '../components/pages/reports/consts';
import { SKAN_DATE_DIMENSION_NAMES } from './consts';

function AgGridWidget({
    dispatchWidgetVersions,
    dispatchWidgetsLoading,
    forceRefreshCounter,
    dashboardId,
    widget,
    globalFilters,
    startDate,
    endDate,
    startDate2,
    endDate2,
    compare,
    height,
    onMenuClick,
    onCloneClick,
    onDeleteClick,
    onUpdateWidget,
    updatingGraphQL,
    allowedActions,
    displayUnenriched,
    periods,
}) {
    const [gridTableError, setGridTableError] = useState(false);
    const [exportParams, setExportParams] = useState(null);
    const [showTotals, setShowTotals] = useState(true);
    const reportType = widget.type === 'pivot' ? 'pivot' : 'reports';
    const isHeatMap = widget.type === 'heatMapTable';
    const { unified_data_types_to_metric_name: dataTypesToMetricName } = useFetchDataTypesToMetricName();
    const [loading, tableData, error] = useFetchTableData(
        dashboardId,
        widget.gridParams.i,
        widget.query,
        globalFilters,
        startDate,
        endDate,
        startDate2,
        endDate2,
        reportType,
        dispatchWidgetVersions,
        dispatchWidgetsLoading,
        compare,
        widget.dataType,
        displayUnenriched,
        updatingGraphQL,
        forceRefreshCounter,
        isHeatMap ? widget.heatMapOptions : undefined
    );

    const onExportClick = () => {
        const paramToExport = {
            fileName: getExportWidgetFileName(
                widget.name,
                {
                    start_date: startDate,
                    end_date: endDate,
                    start_date_2: startDate2,
                    end_date_2: endDate2,
                },
                compare
            ),
            allColumns: true,
            skipPinnedTop: true,
            shouldRowBeSkipped,
            processCellCallback: props => {
                // eslint-disable-next-line react/prop-types
                const { value, column } = props;
                if (isCompareField(column.colId)) return props.column.colDef.valueFormatter(props);
                return isObject(value) ? calculatedMetricValue(value) : value;
            },
        };

        if (reportType === 'pivot') {
            paramToExport.allColumns = false;
        }

        setExportParams(paramToExport);
    };

    // Create a new metrics list with two permutations to the cohort metrics (A_1d, A__1d).
    // Sometimes we get the result from the server as A_1d and sometimes as A__1d.
    // For cohort custom events we get uuid + __actual so we want to keep it as is.
    // For cohort custom metrics like CPE we get uuid + __CPE + __actual so we get 3 parts and need to keep uuid + __CPE cause that's the format in the data.
    // TODO: split dashboard cohort metrics and periods to two different fields.
    // isPivotCompare - add to handle a bug in which column were missing in pivot tables -
    // - some cohort metrics has the time-window add to the metric name (i.e. retention_rate_1d) so we add some more permutations and split those names
    // - we use the inner pushComparisonColumnsMetricsIfNeeded function to handle it (https://singular.zendesk.com/agent/tickets/137517)
    function getMetricsPermutations(metrics, dataTypeFields, isPivotCompare = false) {
        function pushComparisonColumnsMetricsIfNeeded(
            metricsArray,
            name,
            nameAddition,
            seperator = METRIC_COHORT_SEPARATOR
        ) {
            if (isPivotCompare) {
                comparisonColumns.forEach(suffix => {
                    metricsArray.push(
                        `${name}${seperator}${suffix}${nameAddition ? `${seperator}${nameAddition}` : ''}`
                    );
                });
            }
        }
        const newMetrics = [];

        metrics.forEach(metric => {
            const metricParts = metric.split(METRIC_COHORT_SEPARATOR);
            const metricName = metricParts.slice(0, -1).join(METRIC_COHORT_SEPARATOR);
            const metricPeriod = metricParts.length > 1 ? metricParts.at(-1) : null;
            const metricPeriodDisplayName = periods.find(p => p.name === metricPeriod)?.display_name || null;
            newMetrics.push(metric);
            pushComparisonColumnsMetricsIfNeeded(newMetrics, metric, null);
            // for cohort metrics we might need to break the name into two, example:
            // retention_rate_ltv -> we break it to [retention_rate, ltv] so that we will get the permutations:
            // [retention_rate__period1__ltv ...] instead of [retention_rate_ltv__period1...] which will break the table
            if (metricParts.length === 2) {
                pushComparisonColumnsMetricsIfNeeded(newMetrics, metricParts[0], metricParts[1]);
            }

            if (metricName !== metric && metricName) {
                newMetrics.push(metricName);
                pushComparisonColumnsMetricsIfNeeded(newMetrics, metricName, null);
            }

            if (metricName && metricPeriod) {
                newMetrics.push(`${metricName + METRIC_COHORT_SINGLE_SEPARATOR + metricPeriod}`);
                if (metricPeriodDisplayName && metricPeriod !== metricPeriodDisplayName) {
                    newMetrics.push(`${metricName + METRIC_COHORT_SEPARATOR + metricPeriodDisplayName}`);
                    pushComparisonColumnsMetricsIfNeeded(newMetrics, metricName, metricPeriodDisplayName);
                    newMetrics.push(`${metricName + METRIC_COHORT_SINGLE_SEPARATOR + metricPeriodDisplayName}`);
                    pushComparisonColumnsMetricsIfNeeded(
                        newMetrics,
                        metricName,
                        metricPeriodDisplayName,
                        METRIC_COHORT_SINGLE_SEPARATOR
                    );
                }
            }

            // In case no cohort period in metric, add it with actual postfix for custom metrics.
            if (!metricPeriod) {
                newMetrics.push(`${metric}${ACTUAL_PERIOD}`);
            }

            const fieldsParts = metric.split(FIELD_NAME_SEPARATOR);

            if (fieldsParts.length > 1) {
                newMetrics.push(fieldsParts.at(-1));
            }

            if (dataTypesToMetricName) {
                dataTypeFields.forEach(dataTypeField => {
                    if (shouldUseDataType(dataTypeField)) {
                        const dataType = extractUnifiedType(dataTypeField);
                        metrics.forEach(unifiedMetric => {
                            const groupName = extractUnifiedType(unifiedMetric);
                            const period = getCohortPeriod(unifiedMetric);
                            newMetrics.push(
                                `${dataTypesToMetricName[dataType][groupName]}${period ? `__${period}` : ''}`
                            );
                        });
                    }
                });
            }
        });

        return newMetrics;
    }

    const pivotTable = useMemo(() => {
        const allMetrics = getMetricsPermutations(widget.query.metrics, widget.query.dataTypeFields, compare);
        const values = allMetrics;
        return {
            rowGroup: renameTimebreakdownDimension(widget.options.rows || [], widget.dataType),
            columns: renameTimebreakdownDimension(widget.options.columns || [], widget.dataType),
            values,
        };
    }, [widget.options, widget.query, compare, dataTypesToMetricName]);

    const noData = tableData == null || tableData.rowData == null || tableData.rowData.length === 0;

    function getSkanCohortMetrics(columnDefs) {
        const skanCohortMetrics = [];

        columnDefs.forEach(def => {
            if (def.isSkanCohort) {
                skanCohortMetrics.push(def.field);
            }
        });

        return skanCohortMetrics;
    }

    const orderedTableData = useMemo(() => {
        if (noData) {
            return tableData;
        }
        const { dimensions, metrics, dataTypeFields } = widget.query;
        const allMetrics = getMetricsPermutations(metrics, dataTypeFields);
        const skanCohortMetrics = getSkanCohortMetrics(tableData.columnDefs);
        // Filter out metrics that are not in the original request.
        // This is because in the request, the cohort metrics and the periods are not on the same field.
        // e.g: if the user requests: A_1d and B_2d, the result will be: A_1d, A_2d, B_1d, B_2d.
        const resultColumns = tableData.columnDefs.filter(def => {
            const { type } = def;
            const field = compare ? def.field.split(COMPARE_SEPARATOR)[0] : def.field;

            if (field === 'date_field' || type === 'dimension') {
                return true;
            }
            return allMetrics.indexOf(field) !== -1;
        });
        if (widget.dataType === DataTypes.UNIFIED) {
            resultColumns.forEach(def => {
                if (UNIFIED_REPORT_TYPES_TO_DISPLAY_NAME[def.unifiedReportType]) {
                    // Add the column name with the unified report type
                    def.headerName = `${UNIFIED_REPORT_TYPES_TO_DISPLAY_NAME[def.unifiedReportType]} - ${
                        def.headerName
                    }`;
                }
            });
        }

        // Align the names of the custom metrics and events to be under the same name in the data and in the columns.
        // For cohort events we get the data without the __actual from the BE, so we replace all the names to be with the __actual.
        // We do not set the column name earlier since the columnOrder also hold the names without the __actual.
        skanCohortMetrics.forEach(metricName => {
            const fullName = metricName.concat(ACTUAL_PERIOD);
            if (tableData.totalsData[metricName]) {
                delete Object.assign(tableData.totalsData, { [fullName]: tableData.totalsData[metricName] })[
                    metricName
                ];
            }
            tableData.rowData.forEach(row => {
                if (row[metricName]) {
                    delete Object.assign(row, { [fullName]: row[metricName] })[metricName];
                }
            });
            tableData.columnDefs.forEach(def => {
                if (def.field === metricName) {
                    def.field = fullName;
                    def.colId = fullName;
                }
            });
        });
        return {
            ...tableData,
            totalsData: showTotals ? tableData.totalsData : {},
            columnDefs: sortBy(resultColumns, def => {
                const { type } = def;
                const field = compare ? def.field.split(COMPARE_SEPARATOR)[0] : def.field;

                if (field === 'date_field' || field === SKAN_DATE_DIMENSION_NAMES.POSTBACK_DATE) {
                    return dimensions.findIndex(isTimeDimension);
                }

                if (type === 'dimension') {
                    return dimensions.indexOf(field);
                }

                return dimensions.length + allMetrics.indexOf(field);
            }),
        };
    }, [widget.type, tableData, widget.query, dataTypesToMetricName, showTotals]);

    /***
     * We are removing the filters, since we are not calculating them at the moment, and getting tickets
     * this behaviour should be removed once we'll add the calculations
     * ticket that raised the issue: #132275
     * @param {array} filtersModel the selected devices
     */
    const onFilterChange = filtersModel => {
        setShowTotals(!filtersModel.length);
    };

    const onColumnReorder = useCallback(
        ({ columnOrder }) => {
            const { dimensions: widgetDimensions, metrics: widgetMetrics } = widget.query;

            const dimensions = columnOrder.filter(x => widgetDimensions.indexOf(x) >= 0 || x === 'date_field');
            const dateFieldIndex = dimensions.indexOf('date_field');

            if (dateFieldIndex >= 0) {
                for (const dateFieldName of ['__day', '__week', '__month']) {
                    if (widgetDimensions.indexOf(dateFieldName) >= 0) {
                        dimensions[dateFieldIndex] = dateFieldName;
                    }
                }
            }

            onUpdateWidget(
                {
                    ...widget,
                    query: {
                        ...widget.query,
                        dimensions,
                        metrics: columnOrder
                            .map(col => {
                                let metricIndex = widgetMetrics.indexOf(col);

                                // For non-cohort custom metric the result from server includes actual postfix.
                                if (metricIndex === -1) {
                                    metricIndex = widgetMetrics.indexOf(col.split(ACTUAL_PERIOD)[0]);
                                }

                                return metricIndex !== -1 ? widgetMetrics[metricIndex] : null;
                            })
                            .filter(col => !!col),
                    },
                },
                WIDGET_CHANGE_TYPES.TABLE_LAYOUT
            );
        },
        [widget, onUpdateWidget]
    );

    useEffect(() => {
        if (gridTableError) setGridTableError(false);
    }, [loading]);

    return (
        <ChartWidgetWrapper
            widgetTitle={widget.name}
            widgetType={widget.type}
            widgetDataType={widget.dataType}
            showSpinner={loading}
            onMenuClick={onMenuClick}
            onCloneClick={onCloneClick}
            onDeleteClick={onDeleteClick}
            onExportClick={onExportClick}
            showNoData={!loading && !error && noData}
            showError={(!loading && !!error) || gridTableError}
            allowedActions={allowedActions}
        >
            {!loading && !error && !noData && (
                <ErrorBoundary
                    onError={(gridError, info) => {
                        console.error('Widget Error - Grid.jsx component: ', gridError, JSON.stringify(info));
                        setGridTableError(true);
                    }}
                    fallback={null}
                >
                    <Grid
                        id={`${widget.type}-${tableData.reportId}`}
                        key={`${widget.type}-${tableData.reportId}`}
                        reportType={reportType}
                        pivotMode={reportType === 'pivot'}
                        pivotTable={reportType === 'pivot' ? pivotTable : undefined}
                        {...orderedTableData}
                        heightOverride={height}
                        hideResetTable
                        hidePivotHeaders
                        hideColumnsSideBar
                        disableCustomHeader
                        disableScrollingHooks
                        onColumnDropped={onColumnReorder}
                        exportParams={exportParams}
                        setExportParams={setExportParams}
                        onTableFilterChanged={onFilterChange}
                    />
                </ErrorBoundary>
            )}
        </ChartWidgetWrapper>
    );
}

AgGridWidget.propTypes = {
    dispatchWidgetVersions: PropTypes.func.isRequired,
    dispatchWidgetsLoading: PropTypes.func.isRequired,
    dashboardId: PropTypes.string.isRequired,
    widget: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataType: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['table', 'pivot', 'metricCards', 'heatMapTable']).isRequired,
        query: PropTypes.shape({
            dimensions: PropTypes.arrayOf(PropTypes.string),
            metrics: PropTypes.arrayOf(PropTypes.string),
            dataTypeFields: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        options: PropTypes.shape({
            rows: PropTypes.arrayOf(PropTypes.string),
            columns: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        heatMapOptions: PropTypes.shape({
            heatColumn: PropTypes.string,
            isHeatColumnCustomMetric: PropTypes.bool,
        }),
    }).isRequired,
    globalFilters: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            operator: PropTypes.oneOf(['in', 'not in']).isRequired,
            values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
        })
    ),
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate2: PropTypes.string,
    endDate2: PropTypes.string,
    compare: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    onMenuClick: PropTypes.func,
    onCloneClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    updatingGraphQL: PropTypes.bool,
    onUpdateWidget: PropTypes.func,
    allowedActions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ACTION_TYPES))),
    displayUnenriched: PropTypes.bool,
    periods: PropTypes.arrayOf(PropTypes.any),
};

AgGridWidget.defaultProps = {
    onUpdateWidget: () => {},
    onMenuClick: () => {},
    onCloneClick: () => {},
    onDeleteClick: () => {},
    startDate2: null,
    endDate2: null,
    globalFilters: [],
    updatingGraphQL: false,
    allowedActions: Object.values(ACTION_TYPES),
    displayUnenriched: false,
    periods: [],
};

export default memo(AgGridWidget);
