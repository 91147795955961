import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import css from './SubDomainsShelf.css';
import { Shelf, Tabs } from '../../../components/widgets';
import SingularLinksDomains from '../../containers/SingularLinksDomains';
import { closeSubDomainsShelf } from '../../actions';
import LinkIcon from '../../../resources/svg/link.svg';
import ChecklistIcon from '../../../resources/svg/checklist.svg';
import AllowedOverrides from './AllowedOverrides';
import tabsCss from '../../../components/widgets/Tabs.css';
import GeneralPopup, { PopupTypes } from '../../../components/organisms/GeneralPopup';
import { GET_ALLOWED_DOMAINS } from '../../queries';

const SINGULAR_LINKS_DOMAINS_TAB = 'SINGULAR_LINKS_DOMAINS';
const ALLOWED_OVERRIDES_TAB = 'ALLOWED_OVERRIDES';

const UnsavedChangesActions = {
    CLOSE_SHELF: 'close_shelf',
    CHANGE_TAB: 'change_tab',
};

export default function SubDomainsShelf(props) {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(SINGULAR_LINKS_DOMAINS_TAB);
    const [allowedOverridesChanged, setAllowedOverridesChanged] = useState(false);
    const [unsavedChangesAction, setUnsavedChangesAction] = useState(null);
    const [, allowedDomainsResponse] = useLazyQuery(GET_ALLOWED_DOMAINS);

    const onTabClick = tab => {
        if (allowedOverridesChanged && selectedTab === ALLOWED_OVERRIDES_TAB && tab === SINGULAR_LINKS_DOMAINS_TAB) {
            setUnsavedChangesAction(UnsavedChangesActions.CHANGE_TAB);
        } else {
            setSelectedTab(tab);
        }
    };

    const onDiscardChanges = () => {
        if (unsavedChangesAction === UnsavedChangesActions.CHANGE_TAB) {
            setSelectedTab(SINGULAR_LINKS_DOMAINS_TAB);
        } else if (unsavedChangesAction === UnsavedChangesActions.CLOSE_SHELF) {
            dispatch(closeSubDomainsShelf());
        }

        setUnsavedChangesAction(null);
        setAllowedOverridesChanged(false);
    };

    const onClose = () => {
        if (allowedOverridesChanged && selectedTab === ALLOWED_OVERRIDES_TAB) {
            setUnsavedChangesAction(UnsavedChangesActions.CLOSE_SHELF);
        } else {
            dispatch(closeSubDomainsShelf());
        }
    };

    const onSave = () => {
        setAllowedOverridesChanged(false);
        dispatch(closeSubDomainsShelf());
        allowedDomainsResponse.refetch();
    };

    return (
        <Shelf {...props} shelfSize="medium" onClose={onClose}>
            <div className={css.container}>
                <Tabs
                    containerClass={tabsCss.shelfTabs}
                    selected={selectedTab}
                    onClick={onTabClick}
                    tabs={[
                        {
                            id: SINGULAR_LINKS_DOMAINS_TAB,
                            label: 'STATIC.PAGES.MANAGE_LINKS.SINGULAR_LINKS_DOMAINS',
                            icon: LinkIcon,
                        },
                        {
                            id: ALLOWED_OVERRIDES_TAB,
                            label: 'STATIC.PAGES.MANAGE_LINKS.ALLOWED_OVERRIDE_DOMAINS',
                            icon: ChecklistIcon,
                        },
                    ]}
                />
                {selectedTab === SINGULAR_LINKS_DOMAINS_TAB && <SingularLinksDomains />}
                {selectedTab === ALLOWED_OVERRIDES_TAB && (
                    <AllowedOverrides
                        onChange={() => setAllowedOverridesChanged(true)}
                        onSave={onSave}
                        onClose={onClose}
                    />
                )}
                <GeneralPopup
                    open={!!unsavedChangesAction}
                    type={PopupTypes.WARNING_WITH_CANCEL}
                    title="STATIC.PAGES.MANAGE_LINKS.UNSAVED_CHANGES_MESSAGE"
                    acceptText="STATIC.PAGES.MANAGE_LINKS.UNSAVED_CHANGES_APPROVE_MESSAGE"
                    onAccept={onDiscardChanges}
                    onReject={() => setUnsavedChangesAction(null)}
                />
            </div>
        </Shelf>
    );
}
