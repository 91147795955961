import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { PageHeader, PageWrapper, PopupTypes } from '../components/partials';
import {
    Label,
    Button,
    AutoComplete,
    Spinner,
    RadioButtonsGroup,
    EmptyState,
    WarningMessage,
    TextField,
    Tabs,
    QuestionMark,
} from '../components/widgets';
import CustomEventEditor from './containers/CustomEventEditor';
import css from './page.css';
import { EVENT_SLOT_GROUP_TYPE, EVENT_TYPES, TOUR_LINK_IDS } from '../utils/consts';
import {
    fillEmptyStateTabDetail,
    formatMessageEventWithRelatedItems,
    isAddingEventEnabled,
    searchCustomEvents,
    searchEventSections,
} from './utils';
import GeneralPopup from '../components/organisms/GeneralPopup';
import PermissionsWrapper from '../containers/PermissionsWrapper';
import { trackMixpanelEvent, trackShowVideoMixpanelEvent } from '../utils/general';
import { EmptyStateImageSrc, EmptyStateTasks } from '../components/partials/EmptyState/utils';
import EventsEmptyStateImage from '../resources/png/events_empty_state.png';
import PlusIcon from '../resources/svg/plus_link.svg';
import Restore from '../resources/svg/restore.svg';
import Dropdown from '../components/widgets/Dropdown';
import { useFetchArchivedStandardEvents, useRestoreStandardEvent } from './hooks';
import { cancelClicked, refreshView } from './actions';
import {
    DATA_TEST_ID_ARCHIVED_DROP_DOWN_BODY,
    DATA_TEST_ID_CUSTOM_EVENT_EDITOR_WITH_EVENT_TYPE,
    DATA_TEST_ID_CUSTOM_EVENT_EMPTY_STATE,
    DATA_TEST_ID_EVENT_SECTION_TITLE,
    DATA_TEST_ID_EVENTS,
    DATA_TEST_ID_STANDARD_EVENT_EMPTY_STATE,
    ARCHIVED_EVENT_LIST_PLACEHOLDER,
    EVENT_TYPES_TOOLTIP_TEXT,
    EVENT_TYPES_TITLES,
    STANDARD_EVENTS_MIX_PANEL_PREFIX,
} from './consts';

function Page({
    customEvents,
    customEventsRelatedReports,
    globalEvents,
    onNewCustomEventClicked,
    newButtonDisabled,
    editOptions,
    onEditTypeChanged,
    appsList,
    autoCompleteConfig,
    onSpecificAppSelected,
    pageLoaded,
    emptyState,
    maxEvents,
    warningMessage,
    maxAppEvents,
    customEventsGroupTitle,
    eventSlotGroupTypes,
    onDelete,
    onCategoryChange,
    activeGroupType,
    allAppsChecked,
    emptyStateData,
    hideAddButton,
    customEventsLoaded,
    eventSections,
    maxEventsReached,
    standardEventsLayoutActive,
    selectedAppId,
    translate,
}) {
    const dispatch = useDispatch();
    const [toDelete, setToDelete] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.TITLE');
    const [deleteMessage, setDeleteMessage] = useState('');
    const [deleteAccept, setDeleteAccept] = useState(
        'STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.ACCEPT_BUTTON_JUST_EVENT'
    );
    const [deleteRelevantReady, setDeleteRelevantReady] = useState(false);
    const [showVideoDialog, setShowVideoDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showArchivedStandardEvents, setShowArchivedStandardEvents] = useState(false);
    const [selectedEventToRestore, setSelectedEventToRestore] = useState(ARCHIVED_EVENT_LIST_PLACEHOLDER);
    const [showRestorePopup, setShowRestorePopup] = useState(false);
    const [shouldTriggerRestore, setShouldTriggerRestore] = useState(false);
    const [showMaxReachedPopup, setShowMaxReachedPopup] = useState(false);
    const [selectedApp, setSelectedApp] = useState(undefined);
    const [emptyStatePerTab, setEmptyStatePerTab] = useState(
        fillEmptyStateTabDetail('TITLE_COHORT_EVENT', '', {}, {}, false)
    );
    const { value: archivedStandardEvents = [] } = useFetchArchivedStandardEvents(
        customEvents.length,
        customEventsLoaded
    );
    const { status: restoreStatus } = useRestoreStandardEvent(shouldTriggerRestore, selectedEventToRestore.id, () =>
        setShouldTriggerRestore(false)
    );

    useEffect(() => {
        return () => {
            dispatch(cancelClicked());
        };
    }, []);

    useEffect(() => {
        if (!shouldTriggerRestore && restoreStatus === 0) {
            dispatch(refreshView());
            setShowRestorePopup(false);
            setSelectedEventToRestore(ARCHIVED_EVENT_LIST_PLACEHOLDER);
            trackMixpanelEvent(STANDARD_EVENTS_MIX_PANEL_PREFIX, 'Event Restored', {
                standard_event: toDelete.name,
                is_unique: toDelete.is_unique,
            });
        }
    }, [restoreStatus, shouldTriggerRestore]);

    useEffect(() => {
        if (!allAppsChecked) {
            // Since we display the events count of each app in the "App Specific" view,
            // everytime an event is added / removed - we want to recalculate the count
            // and update the selected app label accordingly
            const appWithUpdatedCount = appsList.find(app => app.value === selectedAppId);
            if (appWithUpdatedCount) {
                setSelectedApp(appWithUpdatedCount);
            }
        }
    }, [customEvents]);

    const onDeleteClicked = useCallback(
        customEvent => {
            setToDelete(customEvent);
            setShowDeleteDialog(true);
        },
        [customEventsRelatedReports, toDelete, translate]
    );

    useEffect(() => {
        if (toDelete) {
            setDeleteTitle('STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.TITLE');
            const related = customEventsRelatedReports[toDelete.id];
            if (
                !related ||
                (!related.bookmarks.length &&
                    !related.dashboards.length &&
                    !related.anonymousReports.length &&
                    !related.customMetrics.length)
            ) {
                setDeleteAccept('STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.ACCEPT_BUTTON_JUST_EVENT');
                setDeleteMessage(
                    toDelete.is_standard ? 'STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.STANDARD_EVENT_MESSAGE' : ''
                );
            } else {
                setDeleteMessage(formatMessageEventWithRelatedItems(related, translate, toDelete.is_standard));
                setDeleteAccept('STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.ACCEPT_BUTTON_WITH_RELATED');
            }
        }
    }, [toDelete, customEventsRelatedReports, translate]);

    useEffect(() => {
        if (Object.keys(customEventsRelatedReports).length > 0) {
            setDeleteRelevantReady(true);
        }
    }, [customEventsRelatedReports]);
    const onDeleteApproved = () => {
        onDelete(toDelete);

        setShowDeleteDialog(false);
        if (toDelete.is_standard) {
            trackMixpanelEvent(STANDARD_EVENTS_MIX_PANEL_PREFIX, 'Event Deleted', {
                standard_event: toDelete.name,
                is_manual: true,
                is_unique: toDelete.is_unique,
            });
        }
    };

    const onDeleteRejected = () => {
        setShowDeleteDialog(false);
    };

    const onRestoreAccepted = () => {
        setShouldTriggerRestore(true);
    };

    const onRestoreRejected = () => {
        setShowRestorePopup(false);
        setSelectedEventToRestore(ARCHIVED_EVENT_LIST_PLACEHOLDER);
    };

    useEffect(() => {
        switch (activeGroupType) {
            case EVENT_SLOT_GROUP_TYPE.BASIC:
            case EVENT_SLOT_GROUP_TYPE.COHORT:
                setEmptyStatePerTab(
                    fillEmptyStateTabDetail(
                        'TITLE_COHORT_EVENT',
                        'COHORT_EVENT',
                        {
                            topic: EmptyStateTasks.ADD_APP,
                            name: 'STATIC.EMPTY_STATE.TASKS.ADD_APP_TASK',
                            goRedirect: '#/apps',
                        },
                        {
                            topic: EmptyStateTasks.SDK_INTEGRATED,
                            name: 'STATIC.EMPTY_STATE.TASKS.SDK_TASK',
                            goRedirect: '#/react/sdk_integration',
                        },
                        emptyStateData.show_cohort_events_empty_state
                    )
                );
                break;
            case EVENT_SLOT_GROUP_TYPE.CONVERSION:
                setEmptyStatePerTab(
                    fillEmptyStateTabDetail(
                        'TITLE',
                        'CONVERSION_EVENT',
                        {
                            topic: EmptyStateTasks.SDK_INTEGRATED,
                            name: 'STATIC.EMPTY_STATE.TASKS.SDK_TASK',
                            goRedirect: '#/react/sdk_integration',
                        },
                        {
                            topic: EmptyStateTasks.DATA_CONNECTOR_ADDED,
                            name: 'STATIC.EMPTY_STATE.TASKS.DATA_CONNECTORS_TASK',
                            goRedirect: '#/react/data-sources',
                            disabled: !isAddingEventEnabled(emptyStateData, emptyStatePerTab),
                        },
                        emptyStateData.show_conversion_events_empty_state
                    )
                );
                break;
            case EVENT_SLOT_GROUP_TYPE.SKAN:
                setEmptyStatePerTab(
                    fillEmptyStateTabDetail(
                        'TITLE',
                        'SKAN_EVENT',
                        {
                            topic: EmptyStateTasks.SDK_INTEGRATED,
                            name: 'STATIC.EMPTY_STATE.TASKS.SDK_TASK',
                            goRedirect: '#/react/sdk_integration',
                        },
                        {
                            topic: EmptyStateTasks.ADD_SKAN_MODELS,
                            name: 'STATIC.EMPTY_STATE.TASKS.ADD_SKAN_MODEL',
                            goRedirect: '#/react/skadnetwork',
                            disabled: !isAddingEventEnabled(emptyStateData, emptyStatePerTab),
                        },
                        emptyStateData.show_skan_events_empty_state
                    )
                );
                break;
            default:
                break;
        }
    }, [activeGroupType]);

    const onVideoClick = () => {
        trackShowVideoMixpanelEvent({ watchedFrom: 'Custom Events' });
        setShowVideoDialog(true);
    };

    const onVideoRejected = () => {
        setShowVideoDialog(false);
    };

    const emptyStateEventsData = {
        titleText: `STATIC.EMPTY_STATE.${emptyStatePerTab.title}`,
        subTitleText: `STATIC.EMPTY_STATE.SUB_TITLE.${emptyStatePerTab.subTitle}`,
        imageSrc: EmptyStateImageSrc.SINGULAR_DUDE,
        tasks: [emptyStatePerTab.firstTask, emptyStatePerTab.secondTask],
    };

    const generateEventsGroup = (events, disabled, title) => {
        return (
            <React.Fragment>
                <Label
                    text={title}
                    textReplace={{
                        number: events.length,
                    }}
                    type="h3"
                />
                {events.map(ce => {
                    return (
                        <div key={ce.id} className={css.eventEditorContainer}>
                            <CustomEventEditor id={ce.id} onDeleteClicked={onDeleteClicked} disabled={disabled} />
                        </div>
                    );
                })}
            </React.Fragment>
        );
    };

    const generateEventsList = (events, eventType) => {
        return events.map(ce => (
            <div key={ce.id} className={css.eventEditorContainer}>
                <CustomEventEditor
                    id={ce.id}
                    onDeleteClicked={onDeleteClicked}
                    eventType={eventType}
                    dataTestId={DATA_TEST_ID_CUSTOM_EVENT_EDITOR_WITH_EVENT_TYPE}
                />
            </div>
        ));
    };

    const generateEventSectionTitle = eventType => {
        return (
            <div className={css.titleAndEventsCountWrapper} data-testid={DATA_TEST_ID_EVENT_SECTION_TITLE}>
                <div className={css.eventSectionTitle}>
                    <Label text={EVENT_TYPES_TITLES[eventType]} type="h2" className={css.eventSectionLabel} />
                    <QuestionMark message={EVENT_TYPES_TOOLTIP_TEXT[eventType]} />
                </div>

                {eventType === EVENT_TYPES.STANDARD && (
                    <Label
                        text="STATIC.PAGES.CUSTOM_EVENTS.AVAILABLE_SLOTS_TEXT"
                        textReplace={{ eventsCount: maxAppEvents, maxEvents }}
                    />
                )}
            </div>
        );
    };

    const generateSingleEventSection = (events, eventType, getEmptyState = () => <></>, getAction = () => <></>) => {
        if (events.length) {
            return (
                <div className={css.eventSection}>
                    {generateEventSectionTitle(eventType)}
                    {generateEventsList(events, eventType)}
                    {getAction()}
                </div>
            );
        } else {
            return <div className={css.eventSection}>{getEmptyState()}</div>;
        }
    };

    const getRestoreEventAction = () => {
        if (archivedStandardEvents.length) {
            return (
                <PermissionsWrapper write>
                    <Button
                        level="important"
                        text="STATIC.PAGES.CUSTOM_EVENTS.RESTORE_STANDARD_EVENT_SLOT"
                        onClick={() => setShowArchivedStandardEvents(!showArchivedStandardEvents)}
                        style={{ paddingLeft: '0', paddingRight: '8px' }}
                        type="link"
                        Icon={Restore}
                    />
                    {showArchivedStandardEvents && (
                        <Dropdown
                            items={archivedStandardEvents}
                            wrapperStyle={{ width: '384px' }}
                            containerStyle={{ width: '100%' }}
                            selected={selectedEventToRestore}
                            dropDownBodyTestId={DATA_TEST_ID_ARCHIVED_DROP_DOWN_BODY}
                            onSelection={selected => {
                                if (maxEventsReached) {
                                    setShowMaxReachedPopup(true);
                                } else {
                                    setSelectedEventToRestore(selected);
                                    setShowRestorePopup(true);
                                }
                            }}
                        />
                    )}
                </PermissionsWrapper>
            );
        } else {
            return <></>;
        }
    };

    const getAddEventAction = () => {
        return (
            <PermissionsWrapper write>
                <Button
                    level="important"
                    text="STATIC.PAGES.CUSTOM_EVENTS.ADD_CUSTOM_EVENT"
                    onClick={() => {
                        if (maxEventsReached) {
                            setShowMaxReachedPopup(true);
                        } else {
                            onNewCustomEventClicked(standardEventsLayoutActive);
                        }
                    }}
                    disabled={newButtonDisabled}
                    style={{ paddingLeft: '0', paddingRight: '8px' }}
                    type="link"
                    Icon={PlusIcon}
                    iconStyle={{ width: '10px', height: '10px' }}
                />
            </PermissionsWrapper>
        );
    };

    const getStandardEventSectionEmptyState = () => {
        if (searchTerm === '') {
            return (
                <>
                    {generateEventSectionTitle(EVENT_TYPES.STANDARD)}
                    <div
                        className={classNames(
                            css.standardEventItemBorder,
                            css.eventItemBorder,
                            css.eventContainer,
                            css.eventHeaderContainer
                        )}
                        data-testid={DATA_TEST_ID_STANDARD_EVENT_EMPTY_STATE}
                    >
                        <Label
                            text={
                                archivedStandardEvents.length
                                    ? 'STATIC.PAGES.CUSTOM_EVENTS.EMPTY_STATE.STANDARD_EVENT_PANEL_ALL_ARCHIVED'
                                    : 'STATIC.PAGES.CUSTOM_EVENTS.EMPTY_STATE.STANDARD_EVENT_PANEL_NO_EVENTS'
                            }
                            type="p"
                        />
                    </div>
                    <div className={css.eventSectionAction}>{getRestoreEventAction()}</div>
                </>
            );
        } else {
            return <></>;
        }
    };

    const getCustomEventSectionEmptyState = () => {
        if (!emptyStateData.show_cohort_events_empty_state && searchTerm === '') {
            return (
                <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    data-testid={DATA_TEST_ID_CUSTOM_EVENT_EMPTY_STATE}
                >
                    <Label
                        text="STATIC.PAGES.CUSTOM_EVENTS.EMPTY_STATE.CUSTOM_EVENT_PANEL"
                        type="p"
                        style={{ paddingRight: '7px' }}
                    />
                    {getAddEventAction()}
                </div>
            );
        } else {
            return <></>;
        }
    };

    const generateEventSections = sections => {
        return (
            <>
                {generateSingleEventSection(
                    sections[EVENT_TYPES.STANDARD],
                    EVENT_TYPES.STANDARD,
                    getStandardEventSectionEmptyState,
                    getRestoreEventAction
                )}
                {generateSingleEventSection(sections[EVENT_TYPES.BUILT_IN], EVENT_TYPES.BUILT_IN)}
                {generateSingleEventSection(
                    sections[EVENT_TYPES.CUSTOM],
                    EVENT_TYPES.CUSTOM,
                    getCustomEventSectionEmptyState,
                    getAddEventAction
                )}
            </>
        );
    };

    const generateFilterSection = eventSlotGroupType => {
        if (
            eventSlotGroupType === EVENT_SLOT_GROUP_TYPE.CONVERSION ||
            ([EVENT_SLOT_GROUP_TYPE.COHORT, EVENT_SLOT_GROUP_TYPE.BASIC].includes(eventSlotGroupType) && allAppsChecked)
        ) {
            return (
                <TextField
                    containerClass={css.search}
                    value={searchTerm}
                    onChange={setSearchTerm}
                    clearable
                    debounce={500}
                    placeholder={translate('STATIC.PAGES.CUSTOM_EVENTS.PLACEHOLDERS.SEARCH_EVENTS')}
                />
            );
        } else if (eventSlotGroupType !== EVENT_SLOT_GROUP_TYPE.SKAN) {
            return (
                <AutoComplete
                    placeholder={autoCompleteConfig.placeholder}
                    containerStyle={{
                        flexBasis: '600px',
                        marginRight: '20px',
                    }}
                    selectOptions={{
                        options: appsList,
                        disabled: autoCompleteConfig.disabled,
                        value: selectedApp,
                    }}
                    onChange={onSpecificAppSelected}
                />
            );
        }
    };

    const renderAddEvent = eventSlotGroupType => {
        return (
            isAddingEventEnabled(emptyStateData, emptyStatePerTab) && (
                <div>
                    {eventSlotGroupType !== EVENT_SLOT_GROUP_TYPE.CONVERSION &&
                        eventSlotGroupType !== EVENT_SLOT_GROUP_TYPE.SKAN && (
                            <RadioButtonsGroup
                                id="editType"
                                radioItems={editOptions}
                                horizontal
                                onChange={onEditTypeChanged}
                            />
                        )}
                    <div className={css.filterRow}>
                        {generateFilterSection(eventSlotGroupType)}
                        {!hideAddButton && (
                            <PermissionsWrapper write>
                                <Button
                                    type="primary"
                                    level="level1"
                                    text="STATIC.BUTTONS.NEW_CUSTOM_EVENT"
                                    onClick={() => {
                                        if (maxEventsReached) {
                                            setShowMaxReachedPopup(true);
                                        } else {
                                            onNewCustomEventClicked(standardEventsLayoutActive);
                                        }
                                    }}
                                    className={css.newCustomEventButton}
                                    disabled={newButtonDisabled}
                                />
                            </PermissionsWrapper>
                        )}
                    </div>
                    <WarningMessage
                        show={!!warningMessage}
                        message={warningMessage}
                        textReplace={{
                            maxEvents,
                            maxAppEvents,
                        }}
                        showIcon={false}
                        containerStyle={{
                            marginBottom: 20,
                        }}
                    />
                </div>
            )
        );
    };

    const renderEventsContent = eventSlotGroupType => {
        let eventsView;
        let shouldShowEventsView;
        if (eventSections) {
            const filteredEventSections = searchEventSections(eventSections, searchTerm);
            eventsView = generateEventSections(filteredEventSections);
            shouldShowEventsView = true;
        } else {
            const filteredEvents = searchCustomEvents(customEvents, searchTerm);
            eventsView = generateEventsGroup(filteredEvents, false, customEventsGroupTitle);
            shouldShowEventsView = filteredEvents.length;
        }

        if (!emptyStatePerTab.showEmptyState || shouldShowEventsView) {
            return (
                <React.Fragment>
                    {renderAddEvent(eventSlotGroupType)}
                    {pageLoaded && shouldShowEventsView ? eventsView : null}
                    {pageLoaded && !shouldShowEventsView ? (
                        <EmptyState
                            {...emptyState}
                            header={
                                customEvents.length
                                    ? 'STATIC.PAGES.CUSTOM_EVENTS.EMPTY_STATE.NO_SEARCH_RESULTS'
                                    : emptyState.header
                            }
                            style={{ margin: '100px 0' }}
                        />
                    ) : null}
                    {pageLoaded && globalEvents.length
                        ? generateEventsGroup(globalEvents, true, 'STATIC.PAGES.CUSTOM_EVENTS.GLOBAL_EVENTS_CONT')
                        : null}
                    <Spinner show={!pageLoaded} />
                </React.Fragment>
            );
        } else {
            return (
                <div>
                    {renderAddEvent(eventSlotGroupType)}

                    <div className={css.emptyStateBottomContainer}>
                        No events have been added yet. Follow
                        <br />
                        the instructions above to get started
                        <img className={css.emptyStateImage} src={EventsEmptyStateImage} alt="_blank" />
                    </div>
                </div>
            );
        }
    };

    return (
        <PageWrapper
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '95%',
                overflow: 'auto',
                paddingTop: '0',
                flexWrap: 'wrap',
            }}
            dataTestId={DATA_TEST_ID_EVENTS}
        >
            <div className={css.pageTopContainer}>
                <PageHeader
                    text="STATIC.PAGE_HEADERS.CUSTOM_EVENTS"
                    subText={{
                        faqLink: 'https://singular.zendesk.com/hc/en-us/articles/208385833',
                        tourId: TOUR_LINK_IDS.customEvents,
                    }}
                    showCloseIcon={false}
                    emptyStateHeaderData={emptyStateEventsData}
                    emptyStateOrgData={emptyStateData}
                    showEmptyState={emptyStatePerTab.showEmptyState}
                    onCloseEmptyState={() => {}}
                    imageSrc={emptyStateEventsData.imageSrc}
                    onVideoClick={onVideoClick}
                />
                {eventSlotGroupTypes && eventSlotGroupTypes.length > 1 && (
                    <Tabs
                        containerClass={css.tabs}
                        selected={activeGroupType}
                        onClick={onCategoryChange}
                        tabs={eventSlotGroupTypes.map(type => {
                            return {
                                id: type,
                                label: `STATIC.PAGES.CUSTOM_EVENTS.TAB_LABEL_${type.toUpperCase()}`,
                            };
                        })}
                    />
                )}
                {renderEventsContent(activeGroupType)}
            </div>
            <GeneralPopup
                open={showDeleteDialog}
                type="delete"
                title={deleteTitle}
                text={deleteMessage}
                rejectText="STATIC.PAGES.CUSTOM_EVENTS.DELETE_MODAL.REJECT_BUTTON_TEXT"
                acceptText={deleteAccept}
                onAccept={onDeleteApproved}
                onReject={onDeleteRejected}
                loading={!deleteRelevantReady}
            />
            <GeneralPopup
                open={showMaxReachedPopup}
                type={PopupTypes.INFO}
                text="STATIC.PAGES.CUSTOM_EVENTS.MAX_SLOT_AMOUNT_REACHED_MODAL.MESSAGE"
                textReplace={{ maxEvents }}
                acceptText="STATIC.PAGES.CUSTOM_EVENTS.MAX_SLOT_AMOUNT_REACHED_MODAL.ACCEPT_BUTTON_TEXT"
                onAccept={() => setShowMaxReachedPopup(false)}
                onReject={() => {
                    setShowMaxReachedPopup(false);
                }}
            />
            <GeneralPopup
                open={showVideoDialog}
                type={PopupTypes.VIDEO}
                onReject={onVideoRejected}
                videoLink="https://vimeo.com/showcase/9069321/embed?autoplay=1"
            />
            <GeneralPopup
                open={showRestorePopup}
                type={PopupTypes.INFO_WITH_CANCEL}
                text="STATIC.PAGES.CUSTOM_EVENTS.RESTORE_MODAL.MESSAGE"
                textReplace={{ eventName: selectedEventToRestore.display_name }}
                onAccept={onRestoreAccepted}
                acceptText="STATIC.PAGES.CUSTOM_EVENTS.RESTORE_MODAL.ACCEPT_BUTTON_TEXT"
                rejectText="STATIC.PAGES.CUSTOM_EVENTS.RESTORE_MODAL.REJECT_BUTTON_TEXT"
                onReject={onRestoreRejected}
            />
        </PageWrapper>
    );
}

Page.propTypes = {
    customEvents: PropTypes.arrayOf(PropTypes.any),
    customEventsRelatedReports: PropTypes.objectOf(
        PropTypes.shape({
            bookmarks: PropTypes.arrayOf(PropTypes.string),
            dashboards: PropTypes.arrayOf(
                PropTypes.shape({ dashboardName: PropTypes.string, widgetName: PropTypes.string })
            ),
            anonymousReports: PropTypes.arrayOf(PropTypes.string),
            customMetrics: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    onNewCustomEventClicked: PropTypes.func,
    newButtonDisabled: PropTypes.bool,
    onDelete: PropTypes.func,
    editOptions: PropTypes.arrayOf(PropTypes.any),
    onEditTypeChanged: PropTypes.func,
    appsList: PropTypes.arrayOf(PropTypes.any),
    globalEvents: PropTypes.arrayOf(PropTypes.any),
    autoCompleteConfig: PropTypes.objectOf(PropTypes.any),
    onSpecificAppSelected: PropTypes.func,
    pageLoaded: PropTypes.bool,
    emptyState: PropTypes.objectOf(PropTypes.any),
    maxEvents: PropTypes.number,
    maxAppEvents: PropTypes.number,
    warningMessage: PropTypes.string,
    customEventsGroupTitle: PropTypes.string,
    eventSlotGroupTypes: PropTypes.PropTypes.arrayOf(PropTypes.string),
    onCategoryChange: PropTypes.func.isRequired,
    activeGroupType: PropTypes.string,
    pathParamsJson: PropTypes.objectOf(PropTypes.any),
    emptyStateData: PropTypes.objectOf(PropTypes.any),
    customEventsLoaded: PropTypes.bool,
    eventSections: PropTypes.objectOf(PropTypes.any),
    hideAddButton: PropTypes.bool,
    maxEventsReached: PropTypes.bool,
    standardEventsLayoutActive: PropTypes.bool,
    selectedAppId: PropTypes.number,
};

Page.defaultProps = {
    customEvents: [],
    customEventsRelatedReports: {},
    onNewCustomEventClicked: () => {},
    newButtonDisabled: false,
    onDelete: () => {},
    editOptions: [],
    onEditTypeChanged: () => {},
    appsList: [],
    globalEvents: [],
    autoCompleteConfig: {},
    onSpecificAppSelected: () => {},
    pageLoaded: false,
    emptyState: {
        header: '',
        subHeader: '',
    },
    maxEvents: 12,
    maxAppEvents: 0,
    warningMessage: 'STATIC.PAGES.CUSTOM_EVENTS.REACHED_MAXIMUM_WARNING',
    customEventsGroupTitle: '',
    eventSlotGroupTypes: undefined,
    activeGroupType: undefined,
    pathParamsJson: { t: '' },
    emptyStateData: {
        tasks: {
            sdk_integrated: false,
            data_connector_added: false,
        },
        show_cohort_events_empty_state: false,
        show_conversion_events_empty_state: false,
        show_skan_events_empty_state: false,
    },
    customEventsLoaded: false,
    eventSections: undefined,
    hideAddButton: false,
    maxEventsReached: false,
    standardEventsLayoutActive: false,
    selectedAppId: null,
};

export default withLocalize(Page);
