import PropTypes from 'prop-types';
import React from 'react';
import posed from 'react-pose/lib/index';
import { Tooltip } from 'react-tippy';
import Button from '../atoms/Button';

export default class Wizardable extends React.Component {
    constructor(props) {
        super(props);
        this.getButtons = this._getButtons.bind(this);

        // Without defining a function here, the validate won't appear to subclasses. Transpiler bug
        this._wizardable = () => 'DUMMY_FUNC';

        this.buttonDefs = {
            finish: {
                text: 'Finish',
                type: 'primary',
                onClick: props.onFinish,
                onClickArgs: {},
                validate: true,
                visible: true,
            },
            next: {
                text: 'Next',
                type: 'primary',
                onClick: props.onNext,
                onClickArgs: {},
                validate: true,
                skipCompleted: false,
                visible: true,
            },
            cancel: {
                text: 'Cancel',
                disabled: false,
                type: 'flat',
                onClick: props.onCancel,
                onClickArgs: {},
                skipCompleted: false,
                visible: true,
            },
            back: {
                text: 'Back',
                disabled: false,
                type: 'flat',
                onClick: props.onBack,
                onClickArgs: {},
                skipCompleted: false,
                visible: true,
            },
        };
        this.buttonOverrides = {
            finish: {},
            next: {},
            cancel: {},
            back: {},
        };
        this._animationItem = posed.div({
            visible: {
                x: 0,
                transition: {
                    x: {
                        type: 'spring',
                        damping: 50,
                        duration: 100,
                        mass: 0.5,
                        stiffness: 150,
                    },
                },
            },
            next: {
                x: 200,
                transition: {
                    x: {
                        type: 'spring',
                        duration: 100,
                        damping: 50,
                        mass: 0.5,
                        stiffness: 150,
                    },
                },
            },
            back: {
                x: -200,
                transition: {
                    x: {
                        type: 'spring',
                        duration: 100,
                        damping: 50,
                        mass: 0.5,
                        stiffness: 150,
                    },
                },
            },
        });
    }

    get AnimationItem() {
        return this._animationItem;
    }

    setTooltip(buttonIds, tooltipMessage) {
        let shouldUpdate = false;
        buttonIds.forEach(buttonId => {
            if (this.buttonDefs[buttonId].tooltipMessage !== tooltipMessage) {
                shouldUpdate = true;
            }
            this.buttonDefs[buttonId].tooltipMessage = tooltipMessage;
        });
        if (shouldUpdate) {
            this.setState({});
        }
    }

    validate() {
        return true;
    }

    _getTooltipMessage(buttonId) {
        return this.buttonDefs[buttonId].tooltipMessage || null;
    }

    __getButton(buttonId) {
        const buttonDef = this.buttonDefs[buttonId];
        const buttonOverrides = this.buttonOverrides[buttonId] || {};
        const buttonDefCombined = {
            ...buttonDef,
            ...buttonOverrides,
        };
        if (!buttonDefCombined.visible) {
            return null;
        }
        const tooltipMessage = this._getTooltipMessage(buttonId);
        return (
            <Tooltip
                html={<div>{tooltipMessage}</div>}
                position="top"
                inertia
                animation="scale"
                distance={10}
                theme="light"
                size="big"
                disabled={!tooltipMessage}
            >
                <Button
                    {...buttonDefCombined}
                    onClick={() => {
                        buttonDefCombined.onClick(buttonDefCombined.onClickArgs);
                    }}
                    disabled={buttonDef.validate ? !this.validate() : false}
                >
                    {buttonDefCombined.text}
                </Button>
            </Tooltip>
        );
    }

    _getButtons() {
        const { isLast, isFirst } = this.props;
        return [
            isFirst ? this.__getButton('cancel') : this.__getButton('back'),
            isLast ? this.__getButton('finish') : this.__getButton('next'),
        ];
    }
}

Wizardable.propTypes = {};

Wizardable.defaultProps = {};
