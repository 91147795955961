import React, { Fragment, useState } from 'react';

import { useQuery } from '@apollo/client';
import * as css from './eventsUploads.css';
import { PageHeader, PageWrapper } from '../components/partials';
import { Label } from '../components/widgets';
import ExpandablePanel from '../components/widgets/ExpandablePanel';
import Badge from '../components/atoms/Badge';

import { READ_UPLOADS_STATUS } from './queries';
import ExpandableGroup from '../teamManagement/components/ExpandableGroup';

function UploadStatus({ uploadStatus }) {
    function readableStatus() {
        return {
            EVENTS_UPLOAD_STATUS_INIT: 'Initializing',
            EVENTS_UPLOAD_STATUS_VALIDATION: 'Validating events',
            EVENTS_UPLOAD_STATUS_VALIDATION_FAILED: 'Validation failed',
            EVENTS_UPLOAD_STATUS_LOADING: 'Processing events',
            EVENTS_UPLOAD_STATUS_LOADING_FAILED: 'Finished with failures',
            EVENTS_UPLOAD_STATUS_SUCCESS: 'Finished successfully',
        }[uploadStatus.status];
    }

    function statusBackgroundColor() {
        switch (uploadStatus.status) {
            case 'EVENTS_UPLOAD_STATUS_VALIDATION_FAILED':
            case 'EVENTS_UPLOAD_STATUS_LOADING_FAILED':
                return '#E64D5F';
            case 'EVENTS_UPLOAD_STATUS_SUCCESS':
                return '#5CB85C';
            default:
                return '#FFAA20';
        }
    }

    const [expanded, setExpanded] = useState(false);
    const { eventsProcessed, totalEvents } = uploadStatus;
    let ratio = null;
    if (totalEvents > 0) {
        ratio = eventsProcessed / totalEvents;
    }
    return (
        <div style={{ marginBottom: 10 }}>
            <ExpandablePanel
                animate
                isSticky
                expanded={expanded}
                onExpandClick={() => setExpanded(!expanded)}
                header={
                    <Fragment>
                        <div className={css.ribbon} style={{ backgroundColor: statusBackgroundColor() }} />
                        <div style={{ display: 'flex', flex_direction: 'column' }}>
                            <div style={{ flex: 1 }}>
                                <div className={css.header}>{uploadStatus.name}</div>
                                <div style={{ fontStyle: 'italic', color: '#ccc', fontWeight: 200 }}>
                                    <span style={{ paddingRight: '20px' }}>Created: {uploadStatus.created}</span>
                                    <span>Last updated: {uploadStatus.modified}</span>
                                </div>
                            </div>
                            <div style={{ alignSelf: 'center' }}>
                                <Badge color={statusBackgroundColor()} children={readableStatus()} />
                            </div>
                        </div>
                    </Fragment>
                }
            >
                <div style={{ padding: 20 }} className={css.uploadBody}>
                    <div className={css.uploadBodySection}>Status: {readableStatus()}</div>
                    <div className={css.uploadBodySection}>File URL: {uploadStatus.filePath}</div>
                    {(ratio && (
                        <div style={{ display: 'flex', flex_direction: 'column', justify_content: 'space-between' }}>
                            <div className="progress" style={{ flex: 1 }}>
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${100 * ratio}%` }}
                                    aria-valuenow={eventsProcessed}
                                    aria-valuemin="0"
                                    aria-valuemax={totalEvents}
                                >
                                    {`${(100 * ratio).toPrecision(3)}%`}
                                </div>
                            </div>
                            <div style={{ width: '10px' }} />
                            <div>
                                {eventsProcessed}/{totalEvents}
                            </div>
                        </div>
                    )) ||
                        null}
                    {uploadStatus.failures && uploadStatus.failures.length > 0 && (
                        <div>
                            <ExpandableGroup title="Error log" rightComponents="(showing top 5 errors)" defaultExpanded>
                                <div style={{ backgroundColor: 'pink', color: 'red', padding: '10px' }}>
                                    {uploadStatus.failures.slice(0, 5).map((failure, i) => (
                                        <div key={`failure ${uploadStatus.id} ${i}`}>{failure}</div>
                                    ))}
                                    {uploadStatus.failures.length > 5 && <div>...</div>}
                                </div>
                            </ExpandableGroup>
                        </div>
                    )}
                </div>
            </ExpandablePanel>
        </div>
    );
}

function ViewEventsUploads() {
    const { loading, error, data } = useQuery(READ_UPLOADS_STATUS, {
        pollInterval: 500,
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error!</div>;
    }
    return (
        <div>
            {data.uploads.map((uploadStatus) => (
                <UploadStatus uploadStatus={uploadStatus} key={uploadStatus.id.toString()} />
            ))}
        </div>
    );
}

function Page() {
    return (
        <PageWrapper>
            <PageHeader text="STATIC.PAGE_HEADERS.ATTRIBUTION_UPLOAD_EVETNS" leftComponents={[]} rightComponent={[]} />
            <div style={{ display: 'flex', flex_direction: 'column' }}>
                <Label text="Upload events to Singular Attribution" type="h3" style={{ flex: 1 }} />
            </div>
            <ViewEventsUploads />
        </PageWrapper>
    );
}

export default Page;
