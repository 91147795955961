import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import NotificationWarningIcon from '../../resources/svg/icons/notification-warning.svg';
import NotificationInfoIcon from '../../resources/icons/notification_info.svg';
import css from './AdNetworkReportError.css';
import Button from '../../components/atoms/Button';
import { AUTH_TYPES } from '../utils';

const errorTypes = {
    MISSING_MANDATORY_HEADERS: 'MISSING_MANDATORY_HEADERS',
    MISSING_DATA_IN_MANDATORY_FIELD: 'MISSING_DATA_IN_MANDATORY_FIELD',
    INVALID_DATE_FORMAT: 'INVALID_DATE_FORMAT',
    NO_ATTACHMENTS: 'NO_ATTACHMENTS',
    TEMP_ERROR_REPORTED_INSIDE_THE_FILE: 'TEMP_ERROR_REPORTED_INSIDE_THE_FILE',
    UNSUPPORTED_FILE_TYPE: 'UNSUPPORTED_FILE_TYPE',
};

function AdNetworkReportError({ reportValidationError, channel, sendScrapeJob }) {
    const [fixedTheProblemButtonClicked, setFixedTheProblemButtonClicked] = useState(false);

    const isNoAttachmentsErrorOnly =
        Object.keys(reportValidationError).length === 1 &&
        Object.keys(reportValidationError)[0] === errorTypes.NO_ATTACHMENTS;

    function getFileKey(fileMetadata) {
        switch (fileMetadata.channel) {
            case AUTH_TYPES.mail:
                return `mail_${fileMetadata.message_id}_${fileMetadata.file_name}`;
            case AUTH_TYPES.s3:
            case AUTH_TYPES.gdrive:
                return `s3_gdrive_${fileMetadata.url}`;
            default:
                return null;
        }
    }
    const fileMetadataMapping = {};

    // supports only one error type per file
    const errorsByFile = {};
    Object.keys(reportValidationError).forEach(errorType => {
        if (isNoAttachmentsErrorOnly || errorType !== errorTypes.NO_ATTACHMENTS) {
            reportValidationError[errorType].forEach(element => {
                const fileKey = getFileKey(element.file_metadata);
                if (fileKey) {
                    fileMetadataMapping[fileKey] = element.file_metadata;
                    errorsByFile[fileKey] = {
                        data: element.data,
                        error: errorType,
                    };
                }
            });
        }
    });

    function fixTheIssueInstructions() {
        return (
            <div className={css.instructionsBackground}>
                <span className={css.instructionsInner}>
                    <span className={css.notificationInfo}>
                        <NotificationInfoIcon />
                    </span>
                    <span className={css.instructionsText}>
                        {channel === AUTH_TYPES.mail && (
                            <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.INSTRUCTIONS_EMAIL" />
                        )}
                        {channel === AUTH_TYPES.s3 && (
                            <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.INSTRUCTIONS_S3" />
                        )}
                        {channel === AUTH_TYPES.gdrive && (
                            <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.INSTRUCTIONS_GDRIVE" />
                        )}
                    </span>
                </span>
            </div>
        );
    }

    function onFixedTheProblemClick() {
        sendScrapeJob();
        setFixedTheProblemButtonClicked(true);
    }

    function fileMetadataInfo(fileMetadata) {
        return (
            <div>
                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA_INTRO" />
                {channel === AUTH_TYPES.mail && (
                    <span>
                        <Translate
                            id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.EMAIL_TITLE"
                            data={{ emailTitle: fileMetadata.email_title }}
                        />
                        {fileMetadata.received_at && (
                            <Translate
                                id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.RECEIVED_WHEN"
                                data={{ emailTimestamp: fileMetadata.received_at }}
                            />
                        )}
                        <Translate
                            id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.RECEIVED_FROM"
                            data={{ emailFrom: fileMetadata.received_from }}
                        />
                        {fileMetadata.file_name && (
                            <Translate
                                id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.ATTACHMENT_NAME"
                                data={{ attachmentName: fileMetadata.file_name }}
                            />
                        )}
                        {fileMetadata.presigned_url && (
                            <a href={fileMetadata.presigned_url}>
                                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.MAIL_ATTACHMENT_LINK" />
                            </a>
                        )}
                    </span>
                )}
                {channel === AUTH_TYPES.s3 && (
                    <span>
                        <Translate
                            id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.S3_FILE_NAME"
                            data={{ s3FileName: fileMetadata.key }}
                        />
                        <Translate
                            id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.S3_BUCKET"
                            data={{ bucketName: fileMetadata.bucket }}
                        />
                        {fileMetadata.last_modified && (
                            <Translate
                                id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.LAST_MODIFIED"
                                data={{ lastModified: fileMetadata.last_modified }}
                            />
                        )}
                        <Translate
                            id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.S3_ATTACHMENT_LINK"
                            data={{ attachmentUrl: fileMetadata.url }}
                        />
                    </span>
                )}
                {channel === AUTH_TYPES.gdrive && (
                    <span>
                        <Translate
                            id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.GDRIVE_TITLE"
                            data={{ attachmentName: fileMetadata.file_name }}
                        />
                        <Translate
                            id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.FILE_METADATA.GDRIVE_LINK"
                            data={{ attachmentUrl: fileMetadata.url }}
                        />
                    </span>
                )}
            </div>
        );
    }

    function invalidDateFormatDescription(availableDates, possibleDateFormats) {
        return (
            <div>
                {availableDates && (
                    <Translate
                        id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.INVALID_DATE_FORMAT_DESC"
                        data={{ availableDates }}
                    />
                )}
                {!availableDates && (
                    <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.INVALID_DATE_FORMAT_DESC__NO_DATE" />
                )}
                <span>
                    <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.INVALID_DATE_FORMAT_DESC__INSTRUCTIONS" />
                </span>
                {possibleDateFormats && (
                    <span>
                        <Translate
                            id={
                                possibleDateFormats.length === 1
                                    ? 'STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.INVALID_DATE_FORMAT_DESC__POSSIBLE_FORMAT'
                                    : 'STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.INVALID_DATE_FORMAT_DESC__MULTIPLE_POSSIBLE_FORMATS'
                            }
                            data={{ possibleDateFormats }}
                        />
                    </span>
                )}
            </div>
        );
    }

    return (
        <React.Fragment>
            <div>
                <span className={css.fixedButtonContainer}>
                    <span className={css.noDataContainer}>
                        <span className={css.noDataContainerInner}>
                            <NotificationWarningIcon />
                            <span className={css.noDataText}>
                                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.CANNOT_READ_DATA" />
                            </span>
                        </span>
                    </span>
                    {(channel === AUTH_TYPES.s3 || channel === AUTH_TYPES.gdrive) && (
                        <span>
                            <Button
                                className={classNames(css.fixedButton, {
                                    [css.fixedButtonClicked]: fixedTheProblemButtonClicked,
                                })}
                                text="STATIC.BUTTONS.FIXED_PROBLEM"
                                disabled={fixedTheProblemButtonClicked}
                                onClick={onFixedTheProblemClick}
                            />
                        </span>
                    )}
                </span>
                {Object.keys(errorsByFile).map(fileKey => {
                    const fileMetadata = fileMetadataMapping[fileKey];
                    const errorData = errorsByFile[fileKey];
                    return (
                        <div
                            key={fileKey}
                            className={classNames(css.reportErrorDescription, {
                                [css.reportErrorDescriptionDisabled]: fixedTheProblemButtonClicked,
                            })}
                        >
                            {errorData.error === errorTypes.MISSING_MANDATORY_HEADERS &&
                                !errorData.data.all_headers_missing &&
                                errorData.data.missing_headers_data &&
                                errorData.data.missing_headers_data
                                    .map(missingMandatoryHeaderData => (
                                        <div key={fileKey + missingMandatoryHeaderData.missing_header}>
                                            <Translate
                                                id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.MISSING_MANDATORY_HEADERS_DESC"
                                                data={{
                                                    missingMandatoryHeader: missingMandatoryHeaderData.missing_header,
                                                }}
                                            />
                                            {missingMandatoryHeaderData.aliases && (
                                                <Translate
                                                    id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.MISSING_MANDATORY_HEADERS_DESC_ALIASES"
                                                    data={{
                                                        missingMandatoryHeaderAliases: missingMandatoryHeaderData.aliases.join(
                                                            ', '
                                                        ),
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))
                                    .reduce((prev, curr) => [
                                        prev,
                                        <div id="rectangle" key={`${prev.key}-sep`} className={css.separator} />,
                                        curr,
                                    ])}
                            {errorData.error === errorTypes.MISSING_MANDATORY_HEADERS &&
                                errorData.data.all_headers_missing && (
                                    <div>
                                        <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.ALL_HEADERS_MISSING_DESC" />
                                    </div>
                                )}
                            {errorData.error === errorTypes.MISSING_DATA_IN_MANDATORY_FIELD &&
                                errorData.data.missing_fields && (
                                    <div>
                                        <Translate
                                            id={
                                                errorData.data.missing_fields.length === 1
                                                    ? 'STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.MISSING_MANDATORY_DATA_DESC__SINGLE'
                                                    : 'STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.MISSING_MANDATORY_DATA_DESC__MULTIPLE'
                                            }
                                            data={{
                                                missingFields: errorData.data.missing_fields.join(', '),
                                            }}
                                        />
                                    </div>
                                )}
                            {errorData.error === errorTypes.INVALID_DATE_FORMAT &&
                                invalidDateFormatDescription(
                                    errorData.data.invalid_dates_data.available_dates &&
                                        errorData.data.invalid_dates_data.available_dates.join(', '),
                                    errorData.data.invalid_dates_data.possible_date_formats &&
                                        errorData.data.invalid_dates_data.possible_date_formats.join(', ')
                                )}
                            {errorData.error === errorTypes.NO_ATTACHMENTS && (
                                <div>
                                    <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.NO_ATTACHMENTS_DESC" />
                                </div>
                            )}
                            {errorData.error === errorTypes.TEMP_ERROR_REPORTED_INSIDE_THE_FILE && (
                                <div>
                                    <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.TEMP_ERROR_REPORTED_INSIDE_THE_FILE" />
                                </div>
                            )}
                            {errorData.error === errorTypes.UNSUPPORTED_FILE_TYPE && (
                                <div>
                                    <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.REPORT_VALIDATION_ERROR.UNSUPPORTED_FILE_TYPE_DESC" />
                                </div>
                            )}
                            {fixTheIssueInstructions()}
                            {fileMetadataInfo(fileMetadata)}
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
}

AdNetworkReportError.propTypes = {
    reportValidationError: PropTypes.objectOf(PropTypes.any),
    channel: PropTypes.string,
    sendScrapeJob: PropTypes.func,
};

AdNetworkReportError.defaultProps = {
    reportValidationError: {},
    channel: null,
    sendScrapeJob: () => {},
};

export default AdNetworkReportError;
